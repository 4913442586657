import { Link, Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import { TextWithNewLine } from './TextWithNewLine'

export const TextWithLinks: React.FC<
  {
    children: string
  } & BoxProps
> = ({ children, ...props }) => {
  const body = React.useMemo(() => {
    const items = children.split(/(http[s]?:\/\/[^\s<]+)/g)
    return (
      <Box {...props}>
        {items.map((item, idx) => {
          const key = `text-with-link-${item}-${Math.random()}`
          if (item.startsWith('http')) {
            return (
              <Link
                key={key}
                textDecoration={'underline'}
                color={'blue'}
                href={item}
                target='_blank'
              >
                {item}
              </Link>
            )
          } else {
            return <TextWithNewLine key={key}>{item}</TextWithNewLine>
          }
        })}
      </Box>
    )
  }, [children, props])

  return body
}
