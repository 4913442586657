import { PagePath } from '@/PagePath'
import { useDialog } from '@/components/ui/Dialog'
import { LinkButton } from '@/components/ui/LinkButton'
import { useYearMonthContext } from '@/context/yearMonth'
import { createLocationFrom } from '@/helper/locationState'
import { YearMonth, yearMonthFromDate } from '@app/shared'
import {
  Text,
  VStack,
  HStack,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
} from '@chakra-ui/react'
import React from 'react'
import { MdOutlineArrowForward } from 'react-icons/md'

export const DialogToChoiceHowToCreate: React.FC<{
  projectId: number
  yearMonth: YearMonth
}> = ({ projectId, yearMonth }) => {
  const { closeDialog } = useDialog()
  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            タスクカード作成
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          alignItems={'flex-start'}
          justifyContent={'center'}
          spacing={'16px'}
          h={'full'}
        >
          <LinkButton
            to={PagePath.taskCard.bulkNew(projectId, yearMonth)}
            locationFrom={createLocationFrom(
              PagePath.taskCard.list(projectId, yearMonth),
            )}
            variant={'transparent-clickable'}
            onClick={closeDialog}
            w={'full'}
            h={'full'}
            p={0}
          >
            <HStack
              w={'full'}
              justifyContent={'center'}
              borderRadius={'12px'}
              h={'full'}
              py={'20px'}
              px={'20px'}
              spacing={'20px'}
              borderWidth={'1px'}
              borderColor={'orange.600'}
              // bgColor={'white'}
              whiteSpace={'normal'}
              textAlign={'left'}
            >
              <VStack w={'full'} alignItems={'flex-start'}>
                <Text fontWeight={'bold'} color={'orange.600'}>
                  一括作成
                </Text>
                <Text color={'gray.500'} fontWeight={'normal'}>
                  一ヶ月分のタスクカードを一括で作成します
                </Text>
              </VStack>
              <MdOutlineArrowForward />
            </HStack>
          </LinkButton>
          <LinkButton
            to={PagePath.taskCard.new(projectId, yearMonth)}
            locationFrom={createLocationFrom(
              PagePath.taskCard.list(projectId, yearMonth),
            )}
            onClick={closeDialog}
            variant={'transparent-clickable'}
            w={'full'}
            h={'full'}
            p={0}
          >
            <HStack
              w={'full'}
              justifyContent={'center'}
              borderRadius={'12px'}
              py={'20px'}
              px={'20px'}
              spacing={'20px'}
              borderWidth={'1px'}
              borderColor={'blue.600'}
              // bgColor={'white'}
              h={'full'}
              whiteSpace={'normal'}
              textAlign={'left'}
            >
              <VStack w={'full'} alignItems={'flex-start'}>
                <Text fontWeight={'bold'} color={'blue.600'}>
                  個別作成
                </Text>
                <Text color={'gray.500'} fontWeight={'normal'}>
                  １タスク分のタスクカードを作成することができます。１枚ずつしか作成はできません。
                </Text>
              </VStack>

              <MdOutlineArrowForward />
            </HStack>
          </LinkButton>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}></AlertDialogFooter>
    </AlertDialogContent>
  )
}
