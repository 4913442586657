import { TaskCardTable } from '@/components/ui/TaskCardTable'
import { useYearMonthContext } from '@/context/yearMonth'
import { trpc } from '@/lib/trpc'
import { RouterOutput } from '@app/api'
import {
  BusinessDatesPerProject,
  TaskCardDerivedStatus,
  createBusinessDatesPerProject,
  jstNow,
  notUndefinedOrNull,
  taskCardDerivedStatus,
  taskCardDerivedStatusDelayedList,
  toNextMonth,
  toPrevMonth,
  yearMonthFromDate,
} from '@app/shared'
import {
  Badge,
  Box,
  Button,
  HStack,
  Heading,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import React from 'react'
import { MdOutlineHelpOutline, MdOutlineInfo } from 'react-icons/md'
import { taskCardDerivedStatusOptions } from '../taskcard/TaskDerivedStatus'
import { UserAssignSelect } from '@/components/ui/UserAssignSelect'
import { UserSelect } from '@/components/ui/UserSelect'
import { ErrorPage } from '@/pages/ErrorPage'
import { useQueries } from '@tanstack/react-query'
import { useTaskCardTableFilter } from '@/components/ui/TaskCardFilter'
import { logger } from '@/lib/logger'

type TaskCard = RouterOutput['taskCard']['list'][number]
type Project = NonNullable<RouterOutput['project']['list']>[number]
type TaskCardsPerDerivedStatus = {
  [derivedStatus in TaskCardDerivedStatus]: TaskCard[] | undefined
}
type CountsPerDerivedStatus = {
  [key in TaskCardDerivedStatus | 'delayed']: number
}

const selectedDerivedStatusAtom = atom<TaskCardDerivedStatus | undefined>(
  undefined,
)
selectedDerivedStatusAtom.onMount = (set) => {
  set(undefined)
}

export const MonthlyProgress: React.FC<{
  projects: Project[]
  taskCards: TaskCard[]
}> = ({ projects, taskCards }) => {
  if (projects.length === 0) {
    return <>プロジェクトを選択してください</>
  }
  return (
    <React.Suspense fallback={<Spinner />}>
      <MonthlyProgressInner projects={projects} taskCards={taskCards} />
    </React.Suspense>
  )
}
export const MonthlyProgressInner: React.FC<{
  projects: Project[]
  taskCards: TaskCard[]
}> = ({ projects, taskCards }) => {
  const { yearMonth } = useYearMonthContext()

  const taskCardsPerDerivedStatus: TaskCardsPerDerivedStatus =
    React.useMemo(() => {
      return (
        taskCards
          // .filter(
          //   (taskCard) =>
          //     taskCard.projectMonth.year === yearMonth.year &&
          //     taskCard.projectMonth.month === yearMonth.month,
          // )
          .groupBy((taskCard) => taskCard.derivedStatus)
      )
    }, [taskCards])

  const countsPerDerivedStatus: CountsPerDerivedStatus = React.useMemo(() => {
    const all = Object.keys(taskCardDerivedStatus).reduce(
      (acc, key) => {
        const derivedStatus = key as TaskCardDerivedStatus
        return {
          ...acc,
          [derivedStatus]:
            taskCardsPerDerivedStatus[derivedStatus]?.length || 0,
        }
      },
      {} as { [key in TaskCardDerivedStatus | 'delayed']: number },
    )
    all['delayed'] =
      all['not_started/input_delayed'] +
      all['should_start/client_matter'] +
      all['should_start/self_matter'] +
      all['delayed/self_matter'] +
      all['delayed/client_matter']
    return all
  }, [taskCardsPerDerivedStatus])

  logger.info(`MonthlyProgressInner`, {
    taskCards,
    taskCardsPerDerivedStatus,
    countsPerDerivedStatus,
  })

  // const yearMonths = React.useMemo(() => [yearMonthFromDate(jstNow())], [])
  const yearMonths = React.useMemo(
    () => [toPrevMonth(yearMonth), yearMonth, toNextMonth(yearMonth)],
    [yearMonth],
  )
  const projectMonthsQuery = trpc.projectMonth.list.useQuery({
    filter: {
      projectIds: projects.map((p) => Number(p.id)),
      yearMonths, // 前後3ヶ月を含める
    },
  })

  const businessDatesPerProject: BusinessDatesPerProject | undefined =
    React.useMemo(() => {
      if (projectMonthsQuery.isLoading) {
        return undefined
      }
      return createBusinessDatesPerProject(
        projects,
        projectMonthsQuery.data || [],
        yearMonths,
      )
    }, [
      projectMonthsQuery.data,
      projectMonthsQuery.isLoading,
      projects,
      yearMonths,
    ])

  const todayTaskCardsCount = React.useMemo(() => {
    if (!businessDatesPerProject) {
      return 0
    }
    const now = jstNow()
    const todayBusinessDates = Object.entries(businessDatesPerProject).reduce(
      (acc, [projectId, bds]) => {
        const bd = bds[yearMonth.year][yearMonth.month].fromDateAtLeast(now)
        acc[Number(projectId)] = bd
        return acc
      },
      {} as { [projectId: number]: number },
    )

    return taskCards.filter((taskCard) => {
      const { year, month } = taskCard.projectMonth

      if (taskCard.derivedStatus === 'ok/done') {
        // 完了しているものは含めない
        return false
      }
      if (taskCardDerivedStatusDelayedList.includes(taskCard.derivedStatus)) {
        // 遅延ステータスのものは含める
        return true
      }

      if (
        // 今月
        year === yearMonth.year &&
        month === yearMonth.month
      ) {
        const todayBusinessDate = todayBusinessDates[Number(taskCard.projectId)]
        if (
          taskCard.startBusinessDate &&
          taskCard.startBusinessDate <= todayBusinessDate
        ) {
          // 実施日が今日以前のもの
          return true
        }
      }
      return false
    }).length
  }, [businessDatesPerProject, taskCards, yearMonth.month, yearMonth.year])

  return (
    <VStack w={'full'} spacing={'16px'} alignItems={'flex-start'}>
      <Box minW={'fit-content'} w={'30%'}>
        <TodayTaskCardCountCard count={todayTaskCardsCount} />
      </Box>

      <VStack
        w={'full'}
        spacing={'16px'}
        p={'24px'}
        bgColor={'white'}
        borderRadius={'8px'}
      >
        <HStack w={'full'} spacing={'16px'} alignItems={'flex-start'}>
          <VStack
            w={'140px'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'0'}
          >
            <Text fontSize={'xl'} fontWeight={'bold'}>
              本日の遅延
            </Text>
            <HStack
              spacing={'4px'}
              justifyContent={'flex-start'}
              alignItems={'baseline'}
            >
              <Text fontSize={'5xl'} color={'gray.700'} fontWeight={'normal'}>
                {countsPerDerivedStatus['delayed']}
              </Text>
              <Text fontSize={'20px'} color={'gray.500'}>
                件
              </Text>
            </HStack>
          </VStack>
          <ClientMatterDelayedProgress
            countsPerDerivedStatus={countsPerDerivedStatus}
          />
          <SelfMatterDelayedProgress
            countsPerDerivedStatus={countsPerDerivedStatus}
          />
        </HStack>
        <React.Suspense fallback={<Spinner />}>
          <DelayedTaskCardTable
            projects={projects}
            taskCards={taskCards}
            businessDatesPerProject={businessDatesPerProject}
          />
        </React.Suspense>
      </VStack>
    </VStack>
  )
}

const DelayedTaskCardTable: React.FC<{
  projects: Project[]
  taskCards: TaskCard[]
  businessDatesPerProject: BusinessDatesPerProject | undefined
}> = ({ projects, taskCards: _taskCards, businessDatesPerProject }) => {
  const { TaskCardFilterComponent, taskCardsFilter } = useTaskCardTableFilter({
    userCandidates: _taskCards
      .map((t) => t.assignedTo)
      .filter(notUndefinedOrNull),
    enabled: {
      attribute: false,
      assignedToUserId: true,
      outputStatus: false,
      onlyDelayed: false,
    },
  })
  const taskCards = React.useMemo(() => {
    return _taskCards.filter((taskCard) => {
      return taskCardsFilter.apply(taskCard)
    })
  }, [_taskCards, taskCardsFilter])

  const selectedDerivedStatus = useAtomValue(selectedDerivedStatusAtom)
  const filteredTaskCards = React.useMemo(() => {
    if (selectedDerivedStatus) {
      return taskCards.filter((taskCard) => {
        return taskCard.derivedStatus === selectedDerivedStatus
      })
    }
    return []
  }, [selectedDerivedStatus, taskCards])

  if (!businessDatesPerProject) {
    return <Spinner />
  }

  if (!selectedDerivedStatus) {
    return (
      <VStack
        w={'full'}
        bgColor={'gray.100'}
        spacing={'8px'}
        p={'24px'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text fontSize={'lg'} color={'gray.500'}>
          レポートを選択してください
        </Text>
        <Text fontSize={'md'} color={'gray.400'}>
          件数結果をクリックで各遅延タスクの一覧が表示されます
        </Text>
      </VStack>
    )
  }
  return (
    <VStack
      w={'full'}
      bgColor={'gray.50'}
      spacing={'8px'}
      p={'24px'}
      alignItems={'flex-start'}
      justifyContent={'center'}
    >
      <HStack w={'full'} spacing={'24px'} alignItems={'center'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          遅延タスクカード一覧
        </Text>
        <Badge
          bgColor={'blue.100'}
          color={'blue.700'}
          borderRadius={'full'}
          fontSize={'sm'}
          fontWeight={'medium'}
          px={'16px'}
          py={'4px'}
        >
          {taskCardDerivedStatusOptions[selectedDerivedStatus].label}
        </Badge>
      </HStack>
      <TaskCardTable
        projects={projects}
        paginationPosition='top'
        taskCards={filteredTaskCards}
        businessDatesPerProject={businessDatesPerProject}
        type='data'
        taskCardFilterComponent={TaskCardFilterComponent}
      />
    </VStack>
  )
}

const TodayTaskCardCountCard: React.FC<{
  count: number
}> = ({ count }) => {
  return (
    <VStack
      w={'full'}
      spacing={'4px'}
      bgColor={'white'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      p={'16px'}
      gap={'0'} // fontの高さが大きいので0に
    >
      <HStack
        spacing={'4px'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        gap={'1.5'}
      >
        <Text
          fontSize={'xl'}
          fontWeight={'bold'}
          color={'gray.700'}
          whiteSpace={'nowrap'}
        >
          本日のタスクカード
        </Text>
        <DescriptionTooltip>
          <>
            ステータスが「完了”以外”」のタスクカードの中で、実施予定日が本日以前のもの
            <br />
            または、実施予定日に関わらず、 資料受領状況が「資料受領遅延」のもの
          </>
        </DescriptionTooltip>
        {/* <Tooltip label={hint}>
          <Box w={'24px'} h={'24px'} color={'gray.500'}>
            <MdOutlineInfo size={'24px'} />
          </Box>
        </Tooltip> */}
      </HStack>
      <HStack
        spacing={'4px'}
        justifyContent={'flex-start'}
        alignItems={'baseline'}
      >
        <Text fontSize={'5xl'} color={'gray.700'} fontWeight={'normal'}>
          {count}
        </Text>
        <Text fontSize={'20px'} color={'gray.500'}>
          件
        </Text>
      </HStack>
    </VStack>
  )
}

const DescriptionTooltip: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  return (
    <Tooltip arrowSize={16} hasArrow label={children} openDelay={400}>
      <Box color={'gray.400'}>
        <MdOutlineHelpOutline size={'16px'} />
      </Box>
    </Tooltip>
  )
}

const ClientMatterDelayedProgress: React.FC<{
  countsPerDerivedStatus: CountsPerDerivedStatus
}> = ({ countsPerDerivedStatus }) => {
  return (
    <VStack
      w={'full'}
      p={'16px'}
      spacing={'16px'}
      bgColor={'gray.50'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        spacing={'4px'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Text fontSize={'md'} color={'gray.700'} fontWeight={'bold'}>
          先方都合
        </Text>
      </HStack>
      <HStack w={'full'}>
        <DelayedTaskCardPanel
          derivedStatus={'not_started/input_delayed'}
          countsPerDerivedStatus={countsPerDerivedStatus}
          tooltip={
            <>
              資料受領状況が「資料受領遅延」のタスクカードの中で、
              <br />
              実施予定日を過ぎていないもの
            </>
          }
        />
        <DelayedTaskCardPanel
          derivedStatus={'should_start/client_matter'}
          countsPerDerivedStatus={countsPerDerivedStatus}
          tooltip={
            <>
              資料受領状況が「資料受領遅延」かつステータスが「完了”以外”」のタスクカードの中で、
              <br />
              実施予定日を過ぎていて、期限を過ぎていないもの
            </>
          }
        />
        <DelayedTaskCardPanel
          derivedStatus={'delayed/client_matter'}
          countsPerDerivedStatus={countsPerDerivedStatus}
          tooltip={
            <>
              資料受領状況が「資料受領遅延」かつステータスが「完了”以外”」のタスクカードの中で、
              <br />
              期限がすぎているもの
            </>
          }
        />
      </HStack>
    </VStack>
  )
}

const SelfMatterDelayedProgress: React.FC<{
  countsPerDerivedStatus: CountsPerDerivedStatus
}> = ({ countsPerDerivedStatus }) => {
  return (
    <VStack
      w={'full'}
      p={'16px'}
      spacing={'16px'}
      bgColor={'gray.50'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        spacing={'4px'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Text fontSize={'md'} color={'gray.700'} fontWeight={'bold'}>
          当方都合
        </Text>
      </HStack>
      <HStack w={'full'}>
        <DelayedTaskCardPanel
          derivedStatus={'should_start/self_matter'}
          countsPerDerivedStatus={countsPerDerivedStatus}
          tooltip={
            <>
              実施予定日が過ぎていて、かつ期限前のタスクカードの中で
              <br />
              ・資料受領状況が「資料受領遅延”以外”」かつステータスが「完了”以外”」
              <br />
              となっているタスクカード
            </>
          }
        />
        <DelayedTaskCardPanel
          derivedStatus={'delayed/self_matter'}
          countsPerDerivedStatus={countsPerDerivedStatus}
          tooltip={
            <>
              期限が過ぎているタスクカードの中で、
              <br />
              ・資料受領状況が「資料受領遅延”以外”」かつステータスが「完了”以外”」
              <br />
              となっているタスクカード
            </>
          }
        />
      </HStack>
    </VStack>
  )
}

const DelayedTaskCardPanel: React.FC<{
  derivedStatus: TaskCardDerivedStatus
  countsPerDerivedStatus: CountsPerDerivedStatus
  tooltip: React.ReactElement
}> = ({ countsPerDerivedStatus, derivedStatus, tooltip }) => {
  const label = taskCardDerivedStatusOptions[derivedStatus].labelShort
  const [selectedDerivedStatus, setSelectedDerivedStatus] = useAtom(
    selectedDerivedStatusAtom,
  )
  const state: 'isSelected' | 'normal' =
    selectedDerivedStatus === derivedStatus ? 'isSelected' : 'normal'
  const isSelected = state === 'isSelected'
  return (
    <HStack
      spacing={'8px'}
      w={'full'}
      paddingX={'4'}
      paddingY={'3'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      borderWidth={'2px'}
      borderRadius={'4px'}
      borderColor={isSelected ? 'blue.400' : 'white'}
      {...(isSelected
        ? {
            bgColor: 'blue.50',
          }
        : { bgColor: 'white' })}
    >
      <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
        <HStack alignItems={'center'} justifyContent={'flex-start'} gap={'1'}>
          <Text
            fontSize={'sm'}
            color={isSelected ? 'blue.600' : 'gray.500'}
            fontWeight={'normal'}
            whiteSpace={'nowrap'}
          >
            {label}
          </Text>
          <DescriptionTooltip>{tooltip}</DescriptionTooltip>
        </HStack>
        <HStack
          spacing={'1'}
          justifyContent={'flex-start'}
          alignItems={'baseline'}
          gap={'0'}
        >
          <Button
            variant={'transparent'}
            p={0}
            cursor={isSelected ? 'default' : 'pointer'}
            onClick={() => setSelectedDerivedStatus(derivedStatus)}
          >
            <Text
              fontSize={'4xl'}
              color={'gray.700'}
              fontWeight={'normal'}
              textDecoration={'underline'}
              _hover={{ color: isSelected ? 'gray.700' : 'gray.500' }}
              colorScheme={'gray'}
              maxW={'320px'}
            >
              {countsPerDerivedStatus[derivedStatus]}
            </Text>
          </Button>
          <Text fontSize={'sm'} color={'gray.500'}>
            件
          </Text>
        </HStack>
      </VStack>
    </HStack>
  )
}
