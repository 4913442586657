import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { YearMonth, dateTimeFormatter, waitAtLeast } from '@app/shared'
import {
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  VStack,
  Box,
  Heading,
  StackDivider,
  SimpleGrid,
  useToast,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import { TaskCard } from '@prisma/client'
import { MdArrowRight, MdEdit } from 'react-icons/md'
import React from 'react'
import { BusinessDates } from '@app/shared'
import { TaskInputStatusSelect } from '../TaskCardInputStatus'
import {
  TaskCardOutputStatusOnChange,
  TaskCardOutputStatusSelect,
} from '../TaskCardOutputStatus'
import { TaskCardDetail } from '../types'
import { logger } from '@/lib/logger'
import { RouterInput, RouterOutput, trpc } from '@/lib/trpc'
import { useMutation } from '@tanstack/react-query'
import { YearMonthSelector } from '@/components/ui/YearMonthSelector'
import { TaskCardComments } from './comments'
import { TaskCardClientQuestions } from './ClientQuestion'
import { TaskAttributeTag } from '../../../ui/TaskCardAttribute'
import { TimeSlot } from '../../../ui/TimeSlot'
import { Link, LinkButton } from '@/components/ui/LinkButton'
import { useYearMonthContext } from '@/context/yearMonth'
import { Layout } from '@/components/Layout'
import { DescriptionLinkGrid } from '@/components/ui/DescriptionLinkGrid'
import { AssignedUser } from '@/components/ui/AssignedUser'
import { ProjectWithColor } from '@/components/ui/ProjectWithColor'
import { TextWithNewLine } from '@/components/ui/TextWithNewLine'
import { createLocationFrom } from '@/helper/locationState'

export const TaskCardShow: AuthenticatedComponent<{
  task: TaskCardDetail
}> = ({ self, firebaseUser, task }) => {
  logger.debug(`TaskCardShow`, { task })
  const businessDates = BusinessDates.of(task.projectMonth)
  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'56px'}
        paddingBottom={'120px'}
      >
        <TaskCardHeading task={task} businessDates={businessDates} />
        <TaskBasicInformation taskCard={task} />
        <TaskCardComments task={task} businessDates={businessDates} />
        <TaskCardClientQuestions task={task} businessDates={businessDates} />
      </VStack>
    </Layout>
  )
}

const TaskCardHeading = ({
  task,
  businessDates,
}: {
  task: TaskCardDetail
  businessDates: BusinessDates
}) => {
  const { yearMonth } = useYearMonthContext()
  return (
    <VStack spacing={'24px'} w={'full'}>
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          size={'sm'}
          variant={'white-blue'}
          to={PagePath.taskCard.edit(
            task.projectId,
            task.taskNumber,
            yearMonth,
          )}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <MdEdit />
            <Text>編集</Text>
          </HStack>
        </LinkButton>
        <LinkButton
          size={'sm'}
          variant={'blue-fill'}
          isDisabled={!task.templateId}
          locationFrom={createLocationFrom(
            PagePath.taskCard.list(Number(task.projectId), yearMonth),
          )}
          to={PagePath.template.show(task.projectId, task.taskNumber)}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            {task.templateId ? (
              <Text>テンプレートを確認</Text>
            ) : (
              <Text>テンプレートなし</Text>
            )}
          </HStack>
        </LinkButton>
      </HStack>
      <VStack
        spacing={0}
        borderRadius={'8px'}
        w={'full'}
        bgColor={'white'}
        boxShadow={'base'}
      >
        <TaskBreadCrumbs task={task} />
        <TaskHeadingContent taskCard={task} businessDates={businessDates} />
      </VStack>
    </VStack>
  )
}

const TaskBreadCrumbs = ({ task }: { task: TaskCardDetail }) => {
  return (
    <Breadcrumb
      w={'full'}
      px={'16px'}
      py={'8px'}
      bgColor={'orange.50'}
      borderTopRadius={'8px'}
      separator={<MdArrowRight />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={PagePath.project.show(Number(task.projectId))}
        >
          <ProjectWithColor project={task.project} />
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Text color={'gray.500'}>{task.title}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

const TaskHeadingContent = ({
  taskCard,
  businessDates,
}: {
  taskCard: TaskCardDetail
  businessDates: BusinessDates
}) => {
  const util = trpc.useContext()
  const toast = useToast()
  const onSuccess = React.useCallback(
    async (result: RouterOutput['taskCard']['update']) => {
      if (result.ok) {
        util.taskCard.show.invalidate({
          projectId: Number(taskCard.projectId),
          taskNumber: taskCard.taskNumber,
          yearMonth: {
            year: taskCard.projectMonth.year,
            month: taskCard.projectMonth.month,
          },
        })
        toast({
          title: 'ステータスを更新しました',
          status: 'success',
        })
      } else {
        toast({
          title: `ステータスの更新に失敗しました ${result.errorCode} ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    [
      taskCard.projectId,
      taskCard.projectMonth.month,
      taskCard.projectMonth.year,
      taskCard.taskNumber,
      toast,
      util.taskCard.show,
    ],
  )

  const mutation = trpc.taskCard.update.useMutation({
    onError: (e) => {
      logger.error('TaskCardShow', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const inputStatusMutation = useMutation({
    mutationFn: async (input: RouterInput['taskCard']['update']) => {
      return waitAtLeast(mutation.mutateAsync(input), 1500)
    },
    onSuccess,
  })
  const outputStatusMutation = useMutation({
    mutationFn: async (input: RouterInput['taskCard']['update']) => {
      return waitAtLeast(mutation.mutateAsync(input), 1500)
    },
    onSuccess,
  })
  const updateInputStatus = React.useCallback(
    (inputStatus: TaskCard['inputStatus']) => {
      return inputStatusMutation.mutateAsync({
        taskCardId: Number(taskCard.id),
        type: 'inputStatus',
        inputStatus,
      })
    },
    [inputStatusMutation, taskCard.id],
  )
  const updateOutputStatus: TaskCardOutputStatusOnChange = React.useCallback(
    (outputStatus) => {
      if (!outputStatus) {
        return
      }
      return outputStatusMutation.mutateAsync({
        taskCardId: Number(taskCard.id),
        type: 'outputStatus',
        outputStatus,
      })
    },
    [outputStatusMutation, taskCard.id],
  )
  const taskBusinessDates = React.useMemo(
    () => businessDates.task(taskCard),
    [businessDates, taskCard],
  )

  return (
    <VStack w={'full'} pt={'16px'} pb={'24px'} px={'24px'} spacing={'16px'}>
      <HStack w={'full'} alignItems={'center'} justifyContent={'space-between'}>
        <Box w={'fit-content'}>
          <YearMonthSelector />
        </Box>
        <HStack color={'gray.400'}>
          <Text>
            作成日 {dateTimeFormatter.jst['YYYY/MM/DD'](taskCard.createdAt)}
          </Text>
          <Text>
            最終更新 {dateTimeFormatter.jst['YYYY/MM/DD'](taskCard.updatedAt)}
          </Text>
        </HStack>
      </HStack>

      <HStack w={'full'} alignItems={'center'} justifyContent={'flex-start'}>
        <TaskAttributeTag attribute={taskCard.attribute} />
        <Text>[{taskCard.taskNumber}]</Text>
        <Heading size={'lg'}>{taskCard.title}</Heading>
      </HStack>

      <StackDivider borderWidth={'1px'} />
      <HStack w={'full'} spacing={'32px'}>
        <HStack color={'red.600'} alignItems={'center'} spacing={'12px'}>
          <Text wordBreak={'keep-all'}>期限</Text>
          <HStack fontSize={'2xl'} fontWeight={'bold'} alignItems={'center'}>
            <Text>
              {taskBusinessDates.deadlineDate?.end
                ? dateTimeFormatter.jst['YYYY/MM/DD'](
                    new Date(taskBusinessDates.deadlineDate.end),
                  )
                : '-'}
            </Text>
            <TimeSlot
              textColor={'red.600'}
              wordBreak={'keep-all'}
              whiteSpace={'nowrap'}
              start={taskCard.deadlineTimeslotStart}
              end={taskCard.deadlineTimeslotEnd}
            />
            <Text
              textColor={'gray.400'}
              fontSize={'sm'}
              fontWeight={'normal'}
              wordBreak={'keep-all'}
            >
              {`${
                taskCard.deadlineBusinessDate
                  ? `[第${taskCard.deadlineBusinessDate}営業日]`
                  : '[第-営業日]'
              }`}
            </Text>
            <TimeSlot
              fontSize={'sm'}
              fontWeight={'normal'}
              wordBreak={'keep-all'}
              whiteSpace={'nowrap'}
              textColor={'gray.400'}
              start={taskCard.deadlineTimeslotStart}
              end={taskCard.deadlineTimeslotEnd}
            />
          </HStack>
        </HStack>
        <HStack alignItems={'center'}>
          <Text wordBreak={'keep-all'}>担当者</Text>
          <AssignedUser user={taskCard.assignedTo} />
        </HStack>
      </HStack>

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'center'}
        whiteSpace={'nowrap'}
        spacing={'32px'}
        paddingX={'16px'}
        paddingY={'16px'}
        divider={<StackDivider />}
        bgColor={'gray.50'}
      >
        <HStack
          spacing={'16px'}
          flexGrow={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text fontWeight={'medium'}>実施予定</Text>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'4px'}
            borderRadius={'4px'}
          >
            <HStack alignItems={'center'} justifyContent={'flex-start'}>
              <Text fontSize={'md'} color={'gray.500'} fontWeight={'medium'}>
                {`${
                  taskBusinessDates.startDate?.start
                    ? dateTimeFormatter.jst['YYYY/MM/DD'](
                        taskBusinessDates.startDate.start,
                      )
                    : '-'
                }`}
              </Text>
              <TimeSlot
                fontSize={'md'}
                fontWeight={'medium'}
                textColor={'gray.500'}
                start={taskCard.startTimeslotStart}
                end={taskCard.startTimeslotEnd}
              />
            </HStack>
            <HStack alignItems={'center'} justifyContent={'flex-start'}>
              <Text fontSize={'sm'} color={'gray.400'}>
                {`${
                  taskCard.startBusinessDate
                    ? `[第${taskCard.startBusinessDate}営業日]`
                    : '[第-営業日]'
                }`}
              </Text>
              <TimeSlot
                fontSize={'sm'}
                start={taskCard.startTimeslotStart}
                end={taskCard.startTimeslotEnd}
              />
            </HStack>
          </VStack>
        </HStack>
        <VStack
          flexGrow={3}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
        >
          <HStack
            spacing={'16px'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Text w={'8rem'} fontWeight={'medium'}>
              input資料受領状況
            </Text>

            <TaskInputStatusSelect
              inputStatus={taskCard.inputStatus}
              onChange={updateInputStatus}
            />
            {inputStatusMutation.isLoading && (
              <Box>
                <Spinner />
              </Box>
            )}
            {taskCard.inputStatusUpdatedAt && (
              <Text color={'gray.400'}>
                {dateTimeFormatter.jst['YYYY/MM/DD'](
                  taskCard.inputStatusUpdatedAt,
                )}{' '}
                {taskBusinessDates.inputStatusUpdatedBusinessDate &&
                  `[第${taskBusinessDates.inputStatusUpdatedBusinessDate}営業日]`}{' '}
                {dateTimeFormatter.jst['hh:mm'](taskCard.inputStatusUpdatedAt)}
              </Text>
            )}
          </HStack>

          <HStack
            spacing={'16px'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Text w={'8rem'} fontWeight={'medium'}>
              ステータス
            </Text>

            <TaskCardOutputStatusSelect
              defaultValue={taskCard.outputStatus}
              mustSelect={true}
              onChange={updateOutputStatus}
            />
            {outputStatusMutation.isLoading && (
              <Box>
                <Spinner />
              </Box>
            )}
            {taskCard.outputStatusUpdatedAt && (
              <Text color={'gray.400'}>
                {dateTimeFormatter.jst['YYYY/MM/DD'](
                  taskCard.outputStatusUpdatedAt,
                )}{' '}
                {taskBusinessDates.outputStatusUpdatedBusinessDate &&
                  `[第${taskBusinessDates.outputStatusUpdatedBusinessDate}営業日]`}{' '}
                {dateTimeFormatter.jst['hh:mm'](taskCard.outputStatusUpdatedAt)}
              </Text>
            )}
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  )
}

const TaskBasicInformation = ({
  taskCard: taskCard,
}: {
  taskCard: TaskCardDetail
}) => {
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
      spacing={'24px'}
      color={'gray.700'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'24px'}
          w={'full'}
        >
          <Text w={'80px'} fontWeight={'bold'} size={'lg'}>
            input
          </Text>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <TextWithNewLine>{taskCard.inputDescription}</TextWithNewLine>
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              borderColor={'gray.200'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              spacing={'8px'}
              px={'20px'}
              py={'16px'}
              w={'full'}
            >
              <Text fontSize={'sm'} fontWeight={'bold'}>
                参考情報
              </Text>
              <DescriptionLinkGrid links={taskCard.inputLinks} />
            </VStack>
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          w={'full'}
          spacing={'24px'}
        >
          <Text fontWeight={'bold'} size={'lg'} w={'80px'}>
            作業内容
          </Text>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <DescriptionLinkGrid links={taskCard.description} />
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          w={'full'}
          spacing={'24px'}
        >
          <Text w={'80px'} fontWeight={'bold'} size={'lg'}>
            output
          </Text>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <TextWithNewLine>{taskCard.outputDescription}</TextWithNewLine>
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              borderColor={'gray.200'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              spacing={'8px'}
              px={'20px'}
              py={'16px'}
              w={'full'}
            >
              <Text fontSize={'sm'} fontWeight={'bold'}>
                参考情報
              </Text>
              <DescriptionLinkGrid links={taskCard.outputLinks} />
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
