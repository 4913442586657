import { locationAtom } from '@/helper/location'
import { trpc } from '@/lib/trpc'
import type { ProjectListFilterSchema } from '@app/api/src/routers/trpc/project/list'
import { Text, Spinner, HStack, Box } from '@chakra-ui/react'
import { atom, useAtom, useAtomValue } from 'jotai'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ColorMark } from '../ColorMark'
import { AutoCompleteInput, Choice } from '../AutoCompleteInput'
import { SelectedProject } from './type'

const selectedSingleProjectAtomInternal = atom<
  SelectedProject['id'] | undefined
>(undefined)

// URLにprojectId=が入っていればそれを優先する
// なければ前回選択した結果を使う
const selectedSingleProjectAtom = atom<
  SelectedProject['id'] | undefined,
  (SelectedProject['id'] | undefined)[],
  void
>(
  (get) => {
    const id = get(locationAtom).searchParams?.get('projectId')
    return id ? Number(id) : get(selectedSingleProjectAtomInternal)
  },
  (get, set, update) => {
    set(locationAtom, (prev) => {
      const searchParams = prev.searchParams || new URLSearchParams()
      searchParams.set('projectId', update ? String(update) : '')
      set(selectedSingleProjectAtomInternal, update)
      return {
        ...prev,
        searchParams,
      }
    })
  },
)

const useSelectedSingleProject = () => {
  const projectId = useAtomValue(selectedSingleProjectAtom)
  const projectQuery = trpc.project.show.useQuery(
    {
      id: projectId!,
    },
    {
      enabled: !!projectId,
    },
  )
  const [location, setLocation] = useAtom(locationAtom)
  React.useEffect(() => {
    const paramProjectId = location.searchParams?.get('projectId')
    if (projectId && !paramProjectId) {
      setLocation((prev) => {
        const searchParams = prev.searchParams || new URLSearchParams()
        searchParams.set('projectId', String(projectId))
        return {
          ...prev,
          searchParams,
        }
      })
    }
  }, [location.searchParams, projectId, setLocation])

  return {
    selectedProjectId: projectId,
    selectedProject: projectQuery.data,
    isLoading:
      projectQuery.isInitialLoading || (projectId && projectQuery.isLoading),
  }
}

export const ProjectSingleSelector: React.FC<{
  placeholder?: string
  filter?: ProjectListFilterSchema
}> = ({ placeholder, filter }) => {
  const [value, setValue] = useAtom(selectedSingleProjectAtom)
  const projectQuery = trpc.project.list.useQuery({
    filter,
  })
  const [params] = useSearchParams()

  const projectToChoice = React.useCallback((project: SelectedProject) => {
    return {
      id: project.id,
      text: project.name,
      color: project.color,
      type: 'project',
      element: (
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={project.color}></ColorMark>
          <Text>{project.name}</Text>
        </HStack>
      ),
    } as Choice
  }, [])
  const choices: Choice[] = React.useMemo(() => {
    return (projectQuery.data || [])
      .filter((project) => {
        return !value || value !== project.id
      })
      .map(projectToChoice)
  }, [projectQuery.data, projectToChoice, value])

  const defaultChoice = React.useMemo(() => {
    const projectId =
      value ||
      (params.has('projectId') ? Number(params.get('projectId')) : undefined)
    const project = projectId
      ? (projectQuery.data || []).find((c) => Number(c.id) === projectId)
      : undefined
    return project ? projectToChoice(project) : undefined
  }, [params, projectQuery.data, projectToChoice, value])

  if (projectQuery.isLoading) {
    return <Spinner />
  }
  return (
    <Box w={'20em'}>
      {value && projectQuery.isLoading ? (
        <Spinner />
      ) : (
        <AutoCompleteInput
          type={'mustSelect'}
          emptyText={'プロジェクトの候補がありません'}
          choices={choices}
          onChange={(choice) => {
            // filterを選択していたとしても、projectを追加すると選択中のfilterは解除されて紐づいていたprojectだけが残る
            setValue(Number(choice.id))
          }}
          placeholder={'プロジェクトを選択'}
          defaultValue={defaultChoice}
        />
      )}
    </Box>
  )
}

// useSelectedSingleProjectを使ったcomponentのヘルパー
export const WithSingleProjectSelection: React.FC<{
  children: (selectedProject: SelectedProject) => React.ReactNode
}> = ({ children }) => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <WithSingleProjectSelectionInner>
        {children}
      </WithSingleProjectSelectionInner>
    </React.Suspense>
  )
}

const WithSingleProjectSelectionInner: React.FC<{
  children: (selectedProject: SelectedProject) => React.ReactNode
}> = ({ children }) => {
  const props = useSelectedSingleProject()
  if (props.isLoading) {
    return <Spinner />
  }
  if (!props.selectedProjectId) {
    return <></>
  }
  if (props.selectedProject) {
    return children(props.selectedProject)
  } else {
    return <Text>プロジェクトが見つかりません</Text>
  }
}
