import { TaskCommentCategory } from '@prisma/client'

export const taskCardCommentLabel: {
  [key in TaskCommentCategory | 'issue']: {
    label: string
    color: string
    bgColor: string
  }
} = {
  issue: { label: '課題点', color: 'orange.900', bgColor: 'orange.200' },
  issue_data_processing: {
    label: '課題点(加工が手間)',
    color: 'orange.900',
    bgColor: 'orange.200',
  },
  issue_input_delay: {
    label: '課題点(input資料が遅い)',
    color: 'orange.900',
    bgColor: 'orange.200',
  },
  issue_input_quality: {
    label: '課題点(input資料の精度が低い)',
    color: 'orange.900',
    bgColor: 'orange.200',
  },
  issue_other: {
    label: '課題点(その他)',
    color: 'orange.900',
    bgColor: 'orange.200',
  },
  report_new_rule: {
    label: '新ルール',
    color: 'green.900',
    bgColor: 'green.200',
  },
  report_work: { label: '作業内容', color: 'gray.900', bgColor: 'gray.200' },
  report_miss: { label: 'ミス', color: 'red.900', bgColor: 'red.200' },
  request_task_modification: {
    label: 'タスクカード修正依頼',
    color: 'cyan.100',
    bgColor: 'cyan.700',
  },
  report_other: { label: 'その他', color: 'gray.900', bgColor: 'gray.200' },
  question_client: { label: '質問', color: 'blue.900', bgColor: 'blue.200' },
}
