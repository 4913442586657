import React from 'react'
import { VStack, Divider, Heading, StackDivider, Box } from '@chakra-ui/react'
import { trpc } from '@/lib/trpc'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { YearMonth } from '@/context/yearMonth'
import { atom, useAtom } from 'jotai'
import { Paging } from '../ui/PaginationIndicator'
import type { TaskCommentFilterSchema } from '@app/api/src/routers/trpc/taskComment/list'
import { Layout } from '../Layout'
import { ClientQuestionsElement } from './ClientQuestions/ClientQuestionElement'
import { isClientQuestion } from '@/helper/comments'
import { YearMonthSelector } from '../ui/YearMonthSelector'
import {
  ProjectSingleSelector,
  WithSingleProjectSelection,
} from '../ui/ProjectSelector/ProjectSingleSelector'
import { SelectedProject } from '../ui/ProjectSelector/type'
import { ErrorPage } from '@/pages/ErrorPage'
import { RefreshButton } from '../ui/RefreshButton'

type ClientQuestionListSort = {
  field: 'userId' | 'taskCardId' | 'createdAt'
  direction: 'asc' | 'desc'
}
const commentListSortAtom = atom<ClientQuestionListSort>({
  field: 'createdAt',
  direction: 'asc',
})

// pagingはせずに全件取得する方向
const clientQuestionsListPagingAtom = atom<
  Paging & { isLoading: boolean; total: number | undefined }
>({
  total: undefined,
  isLoading: false,
  perPage: 1000,
  page: 1,
})

const selectedClientQuestionIdsAtom = atom<number[]>([])

export const ClientQuestions: AuthenticatedComponent<{
  yearMonth: YearMonth
}> = ({ self, yearMonth }) => {
  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        w={'full'}
        borderRadius={'4px'}
        bgColor={'white'}
        padding={'16px'}
        boxShadow={'base'}
      >
        <RefreshButton />
        <Box w={'fit-content'}>
          <YearMonthSelector />
        </Box>

        <Heading size={'lg'}>質問事項進捗表</Heading>

        <StackDivider />

        <ProjectSingleSelector />
      </VStack>
      <WithSingleProjectSelection>
        {(selectedProject) => {
          return (
            <ClientQuestionsInner
              project={selectedProject}
              yearMonth={yearMonth}
            />
          )
        }}
      </WithSingleProjectSelection>
    </Layout>
  )
}

const ClientQuestionsInner: React.FC<{
  project: SelectedProject
  yearMonth: YearMonth
}> = ({ project, yearMonth }) => {
  const projectMonthQuery = trpc.projectMonth.show.useQuery({
    projectId: project.id,
    yearMonth,
  })
  const [paging] = useAtom(clientQuestionsListPagingAtom)
  const filter: TaskCommentFilterSchema = {
    yearMonths: [yearMonth],
    projectIds: [project.id],
    category: { in: ['question_client'] },
  }
  const commentsQuery = trpc.taskComment.list.useQuery({
    filter,
    pagination: {
      perPage: paging.perPage,
      page: paging.page,
      sort: {
        field: 'updatedAt',
        direction: 'desc',
      },
    },
  })
  const questions = React.useMemo(() => {
    return (commentsQuery.data || []).filter(isClientQuestion)
  }, [commentsQuery.data])

  if (projectMonthQuery.error) {
    return <ErrorPage errorMessage={projectMonthQuery.error.message} />
  }
  if (commentsQuery.error) {
    return <ErrorPage errorMessage={commentsQuery.error.message} />
  }
  return (
    <ClientQuestionsElement
      questions={questions}
      project={project}
      projectMonth={projectMonthQuery.data || null}
    />
  )
}
