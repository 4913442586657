import { dateTimeFormatter } from '@app/shared'
import { Text, HStack } from '@chakra-ui/react'

export const DateTimeWithBusinessDate: React.FC<{
  timestamp: Date
  businessDate: number | undefined
}> = ({ timestamp, businessDate }) => {
  return (
    <HStack alignItems={'center'} justifyContent={'flex-start'}>
      <Text>{dateTimeFormatter.jst['YYYY/MM/DD(E)'](timestamp)}</Text>
      <Text>[第{businessDate || '-'}営業日]</Text>
      {/* <Text color={'gray.400'}> */}
      <Text>{dateTimeFormatter.jst['hh:mm'](timestamp)}</Text>
    </HStack>
  )
}
