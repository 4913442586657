import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as React from 'react'
import { trpc, trpcClient } from './lib/trpc'
import {
  FirebaseAuthProviderContainer,
  useFirebaseAuthState,
} from './components/providers/FirebaseAuthProvider'
import { SelfProviderContainer } from './components/providers/SelfProvider'
import { logger } from './lib/logger'
import {
  Spinner,
  ChakraBaseProvider,
  HStack,
  Text,
  Center,
} from '@chakra-ui/react'
import { DialogContainer } from './components/ui/Dialog'
import { customTheme } from './theme'
import { BrowserRouter } from 'react-router-dom'
import { TextSpinner } from './components/ui/TextSpinner'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false, // default: true
      suspense: true,
      useErrorBoundary: false, // trueにするとthrowされる
      cacheTime: 1000 * 60 * 3, // 3min
      staleTime: 0, // immediately
    },
  },
})
export const ProvidersOutSideRouter: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <ChakraBaseProvider
      theme={customTheme}
      toastOptions={{
        defaultOptions: {
          isClosable: true,
          position: 'top',
          variant: 'left-accent',
          duration: 3000,
        },
      }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ChakraBaseProvider>
  )
}
export const ProvidersInSideRouter: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <FirebaseAuthProviderContainer>
      <InternalProviders>
        <DialogContainer>{children}</DialogContainer>
      </InternalProviders>
    </FirebaseAuthProviderContainer>
  )
}

const InternalProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { initialized } = useFirebaseAuthState()
  if (initialized) {
    logger.info('initialized InternalProviders')
    return (
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <SelfProviderContainer>{children}</SelfProviderContainer>
      </trpc.Provider>
    )
  } else {
    return <TextSpinner />
  }
}
