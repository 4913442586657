import { NamedLinkSchema } from '@app/api/src/routers/trpc/schema'
import {
  VStack,
  Divider,
  FormControl,
  HStack,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { MdOutlineDelete } from 'react-icons/md'

export const NamedLinkArrayInput: React.FC<{
  name: 'description' | 'inputLinks' | 'outputLinks'
}> = ({ name }) => {
  const {
    register,
    clearErrors,
    setError,
    control,
    formState: { errors },
  } = useFormContext<{ [key in typeof name]: NamedLinkSchema[] }>()
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  })
  return (
    <VStack
      spacing={'16px'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      w={'full'}
    >
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        divider={<Divider />}
        spacing={'24px'}
        w={'full'}
      >
        {fields.map((field, index) => {
          return (
            <FormControl
              isRequired
              isInvalid={Boolean(errors?.[name]?.[index])}
              w={'full'}
              key={`${field.id}-${name}-${index}`}
            >
              <HStack
                w={'full'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                spacing={'24px'}
              >
                <FormLabel>リンク先{index + 1}</FormLabel>
                <HStack alignItems={'center'} justifyContent={'flex-start'}>
                  <Input
                    {...register(`${name}.${index}.url`, {
                      required: true,
                      pattern: {
                        value: /^https?:\/\//,
                        message: 'URLを入力してください',
                      },
                    })}
                    defaultValue={field.name}
                    placeholder='https://example.com'
                  />
                  <Input
                    {...register(`${name}.${index}.name`, {
                      required: true,
                      validate: (v) => {
                        if (!v) {
                          return '必須です'
                        }
                      },
                    })}
                    defaultValue={field.url}
                    placeholder='名称を入力'
                  />
                  <Button
                    variant={'transparent-clickable'}
                    size={'xl'}
                    onClick={() => remove(index)}
                    p={'8px'}
                  >
                    <MdOutlineDelete size={'16px'} />
                  </Button>
                </HStack>
              </HStack>
              <FormErrorMessage>
                {errors[name]?.[index]?.url &&
                  errors[name]?.[index]?.url?.message}
              </FormErrorMessage>
            </FormControl>
          )
        })}
      </VStack>
      <HStack justifyContent={'flex-end'} w={'full'}>
        <Button
          variant={'white-blue'}
          size={'sm'}
          onClick={() => append({ name: '', url: '' })}
        >
          追加
        </Button>
      </HStack>
    </VStack>
  )
}
