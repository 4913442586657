import { TextWithLinks } from '@/components/ui/TextWithLInks'
import { Picture } from '@/components/ui/user/picture'
import { dateTimeFormatter, BusinessDates } from '@app/shared'
import { Text, VStack, HStack, Heading, Badge, Box } from '@chakra-ui/react'
import React from 'react'
import { RouterOutput } from '@/lib/trpc'
import { useYearMonthContext } from '@/context/yearMonth'
import { QuestionToClientStatus } from '@/components/ui/ClientQuestionStatus'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { TaskCardBanner } from '@/components/ui/TaskCardBanner'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'

export type QuestionToClient = Omit<
  RouterOutput['taskComment']['list'][number],
  'type'
> & { type: 'question_client' }
type QuestionToClientWithReplies = {
  question: QuestionToClient
  replies: QuestionToClient[]
}

type Project = RouterOutput['project']['list'][number]
type ProjectMap = {
  [projectId: number]: Project
}
export const ClientQuestionsElement: React.FC<{
  project: SelectedProject
  projectMonth: RouterOutput['projectMonth']['show']
  questions: QuestionToClient[]
}> = ({ project, projectMonth, questions }) => {
  const { yearMonth } = useYearMonthContext()
  const businessDates = React.useMemo(() => {
    if (projectMonth) {
      return BusinessDates.of(projectMonth)
    } else {
      return BusinessDates.fallback(yearMonth, project)
    }
  }, [project, projectMonth, yearMonth])

  const groupedQuestions = React.useMemo(() => {
    return (questions || [])
      .sort((a, b) => {
        // 投稿日時の降順
        // 返信のtimestampは親よりもあとに来ることを前提とする(usecaseで保証している)
        const cmp = b.timestamp.getTime() - a.timestamp.getTime()
        if (cmp !== 0) {
          return cmp
        } else {
          return Number(b.id - a.id)
        }
      })
      .reduceRight(
        // 日付の昇順で処理する必要があるためreduceRight
        (acc, comment) => {
          if (comment.replyToCommentId) {
            for (const _key of ['todo', 'resolved']) {
              const key = _key as 'todo' | 'resolved'
              const idx = (acc[key] || []).findIndex(
                (item) => item.question.id === comment.replyToCommentId,
              )
              if (idx >= 0) {
                acc[key][idx].replies.push(comment)
                return acc
              }
            }
          } else {
            const key = comment.status === 'resolved' ? 'resolved' : 'todo'
            acc[key].push({ question: comment, replies: [] })
          }
          return acc
        },
        { todo: [], resolved: [] } as {
          todo: QuestionToClientWithReplies[]
          resolved: QuestionToClientWithReplies[]
        },
      )
  }, [questions])

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      spacing={'24px'}
    >
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        mt={'56px'}
        spacing={'24px'}
      >
        <HStack
          justifyContent={'flex-start'}
          alignItems={'baseline'}
          spacing={'8px'}
        >
          <Heading size={'lg'} fontWeight={'bold'}>
            未回答
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            {groupedQuestions.todo.length}件
          </Text>
        </HStack>
        <QuestionToClientElement
          project={project}
          businessDates={businessDates}
          questionWithRepliesList={groupedQuestions.todo}
        />
      </VStack>

      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'24px'}
      >
        <HStack
          justifyContent={'flex-start'}
          alignItems={'baseline'}
          spacing={'8px'}
        >
          <Heading size={'lg'} fontWeight={'bold'}>
            解決済み
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            {groupedQuestions.resolved.length}件
          </Text>
        </HStack>
        <QuestionToClientElement
          project={project}
          businessDates={businessDates}
          questionWithRepliesList={groupedQuestions.resolved}
        />
      </VStack>
    </VStack>
  )
}

const QuestionToClientElement: React.FC<{
  project: SelectedProject
  businessDates: BusinessDates
  questionWithRepliesList: QuestionToClientWithReplies[]
}> = ({ project, businessDates, questionWithRepliesList }) => {
  if (questionWithRepliesList.length === 0) {
    return (
      <Text px={'12px'} py={'12px'}>
        該当する質問がありません
      </Text>
    )
  }

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      w={'full'}
      spacing={'24px'}
      borderRadius={'8px'}
      // boxShadow={'base'}
    >
      {questionWithRepliesList.map((questionWithReplies) => {
        if (!businessDates) {
          return (
            <Text key={questionWithReplies.question.id} color={'red'}>
              営業日を計算できませんでした
            </Text>
          )
        }
        return (
          <ClientQuestionElement
            key={questionWithReplies.question.id}
            project={project}
            businessDates={businessDates}
            questionWithReplies={questionWithReplies}
          />
        )
      })}
    </VStack>
  )
}

const ClientQuestionElement: React.FC<{
  project: Project
  businessDates: BusinessDates
  questionWithReplies: QuestionToClientWithReplies
}> = ({
  project,
  businessDates,
  questionWithReplies: { question, replies },
}) => {
  const businessDate = React.useMemo(
    () => businessDates.fromDate(question.timestamp),
    [businessDates, question.timestamp],
  )
  const resolvedAtBusinessDate = React.useMemo(
    () =>
      question.resolvedAt
        ? businessDates.fromDate(question.timestamp)
        : undefined,
    [businessDates, question.resolvedAt, question.timestamp],
  )

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      bgColor={'white'}
      px={'20px'}
      py={'16px'}
      spacing={'12px'}
      borderRadius={'8px'}
      boxShadow={'base'}
    >
      <TaskCardBanner project={project} taskCard={question.taskCard} />
      <HStack
        spacing={'16px'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        w={'full'}
      >
        <Box w={'fit-content'}>
          <UserWithIcon user={question.user} />
        </Box>
        <Badge
          variant={'outline'}
          borderRadius={'2px'}
          borderWidth={'1px'}
          borderColor={'gray.400'}
          color={'gray.400'}
          px={'4px'}
          py={'2px'}
        >
          投稿日時
        </Badge>
        <DateTimeWithBusinessDate
          timestamp={question.timestamp}
          businessDate={businessDate}
        />
        <QuestionToClientStatus status={question.status} />
        {question.resolvedAt && (
          <DateTimeWithBusinessDate
            timestamp={question.resolvedAt}
            businessDate={resolvedAtBusinessDate}
          />
        )}
      </HStack>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <TextWithLinks w={'80%'}>{question.body}</TextWithLinks>
      </HStack>
      <QuestionToClientReplies
        businessDates={businessDates}
        replies={replies}
      />
    </VStack>
  )
}
const QuestionToClientReplies: React.FC<{
  businessDates: BusinessDates
  replies: QuestionToClient[]
}> = ({ businessDates, replies }) => {
  if (replies.length === 0) {
    return <></>
  }

  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'flex-start'}>
      {replies.map((reply) => {
        const businessDate = businessDates.fromDate(reply.timestamp)
        return (
          <VStack
            key={`reply-${reply.replyToCommentId}-${reply.id}`}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            bgColor={'gray.50'}
            px={'20px'}
            py={'16px'}
            borderRadius={'8px'}
            boxShadow={'base'}
            w={'full'}
          >
            <HStack
              spacing={'16px'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <Picture user={reply.user} />
              <Text>{reply.user.name}</Text>

              <DateTimeWithBusinessDate
                timestamp={reply.timestamp}
                businessDate={businessDate}
              />
            </HStack>
            <TextWithLinks w={'full'}>{reply.body}</TextWithLinks>
          </VStack>
        )
      })}
    </VStack>
  )
}

const DateTimeWithBusinessDate: React.FC<{
  timestamp: Date
  businessDate: number | undefined
}> = ({ timestamp, businessDate }) => {
  return (
    <HStack alignItems={'center'} justifyContent={'flex-start'}>
      <Text>{dateTimeFormatter.jst['YYYY/MM/DD(E)'](timestamp)}</Text>
      <Text>[第{businessDate || '-'}営業日]</Text>
      {/* <Text color={'gray.400'}> */}
      <Text>{dateTimeFormatter.jst['hh:mm'](timestamp)}</Text>
    </HStack>
  )
}
