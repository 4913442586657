import { Badge, Center, Select, Tag } from '@chakra-ui/react'
import { TaskCard, TaskOutputStatus } from '@prisma/client'
import React, { ChangeEvent } from 'react'

export const taskCardOutputStatusOptions = {
  not_started: {
    value: 'not_started',
    label: '未着手',
    color: 'red.500',
    colorScheme: 'red',
  },
  in_progress: {
    value: 'in_progress',
    label: '進行中',
    color: 'orange.500',
    colorScheme: 'orange',
  },
  done: {
    value: 'done',
    label: '完了',
    color: 'green.600',
    colorScheme: 'green',
  },
} as const

export const TaskCardOutputStatusTag = ({
  outputStatus,
}: {
  outputStatus: TaskCard['outputStatus']
}) => {
  const option = taskCardOutputStatusOptions[outputStatus]
  return (
    <Badge
      variant={'solid'}
      w={'52px'}
      h={'24px'}
      colorScheme={option?.colorScheme || 'gray'}
      textAlign={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      size={'sm'}
      borderRadius={'6px'}
      whiteSpace={'nowrap'}
    >
      <Center w={'full'} h={'full'}>
        {option?.label || '不明'}
      </Center>
    </Badge>
  )
}

export type TaskCardOutputStatusOnChange = (
  outputStatus: TaskCard['outputStatus'] | null,
) => void
export const TaskCardOutputStatusSelect = ({
  mustSelect,
  defaultValue,
  onChange,
}: {
  mustSelect?: boolean
  defaultValue?: TaskCard['outputStatus']
  onChange: TaskCardOutputStatusOnChange
}) => {
  const [_outputStatus, setOutputStatus] = React.useState<
    TaskCard['outputStatus'] | undefined
  >(defaultValue)
  const color = _outputStatus
    ? taskCardOutputStatusOptions[_outputStatus]?.color
    : undefined

  const _onChange = React.useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value === '') {
        setOutputStatus(undefined)
        onChange(null)
      } else {
        const newValue = e.target.value as TaskCard['outputStatus']
        setOutputStatus(newValue)
        onChange(newValue)
      }
    },
    [onChange],
  )

  return (
    <Select
      color={_outputStatus ? 'white' : 'gray.500'}
      _focusWithin={{
        '> option': {
          color: 'gray.500',
        },
      }}
      bgColor={color || 'white'}
      w={'160px'}
      defaultValue={_outputStatus}
      onChange={_onChange}
      size={'md'}
      px={'6px'}
      h={'36px'}
      placeholder={mustSelect ? undefined : 'ステータスを選択'}
      borderRadius={'6px'}
    >
      {Object.keys(taskCardOutputStatusOptions).map((key) => {
        const outputStatus = key as TaskOutputStatus
        const option = taskCardOutputStatusOptions[outputStatus]
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </Select>
  )
}
