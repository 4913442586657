export const inputStyle = {
  Select: {
    variants: {
      outline: {
        padding: {
          base: '{space.2} {space.3}',
          lg: '{space.8} {space.10}',
        },
        field: {
          background: '{gray.30}',
        },
      },
    },
  },
  Input: {
    variants: {
      outline: {
        padding: {
          base: '{space.2} {space.3}',
          lg: '{space.8} {space.10}',
        },
        field: {
          background: '{gray.30}',
        },
      },
    },
  },
};
