import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import {
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  VStack,
  Button,
  Heading,
  StackDivider,
  Link,
  useToast,
  FormControl,
  Input,
  FormLabel,
  Textarea,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react'
import { MdArrowRight } from 'react-icons/md'
import React from 'react'
import { trpc } from '@/lib/trpc'
import { TaskAttributeSelect } from '@/components/ui/TaskCardAttribute'
import { LinkButton } from '@/components/ui/LinkButton'
import { StickyHeader } from '@/components/ui/StickyHeader'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { logger } from '@/lib/logger'
import { ColorMark } from '@/components/ui/ColorMark'
import { UserAssignSelect } from '@/components/ui/UserAssignSelect'
import { User } from '@prisma/client'
import { NamedLinkArrayInput } from '@/components/ui/NamedLinkArrayInput'
import { useNavigate } from 'react-router-dom'
import { TaskCardTemplateCreateSchema } from '@app/api/src/usecase/taskCardTemplate/createTaskCardTemplate'
import { TaskCardTemplateUpdateSchema } from '@app/api/src/usecase/taskCardTemplate/updateTaskCardTemplate'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { locationFromToPath, useLocationFrom } from '@/helper/locationState'
import {
  TaskCardDeadlineInput,
  TaskCardStartInput,
} from '@/components/ui/TaskCardDateInput'

export const TaskCardTemplateCreate: AuthenticatedComponent<{
  project: SelectedProject
}> = ({ project }) => {
  const locationFrom = useLocationFrom()
  const form = useForm<TaskCardTemplateCreateSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      projectId: Number(project.id),
      assignedToUserId: project.assignedPmUserId, // デフォルトでPM
      attribute: 'sales',
      startBusinessDate: 1,
      startTimeslotStart: '09:00',
      startTimeslotEnd: '12:00',
      deadlineBusinessDate: 1,
      deadlineTimeslotStart: '09:00',
      deadlineTimeslotEnd: '12:00',
    },
  })

  const toast = useToast()
  const mutation = trpc.taskCardTemplate.create.useMutation({
    onError: (e) => {
      logger.error('TaskCardTemplateCreate', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const navigate = useNavigate()
  const util = trpc.useContext()
  const { setError } = form
  const onSubmitToCreate = React.useCallback(
    async (data: TaskCardTemplateCreateSchema) => {
      if (
        data.startBusinessDate &&
        data.deadlineBusinessDate &&
        (data.startBusinessDate > data.deadlineBusinessDate ||
          (data.startTimeslotStart &&
            data.deadlineTimeslotStart &&
            data.startBusinessDate === data.deadlineBusinessDate &&
            data.startTimeslotStart > data.deadlineTimeslotStart))
      ) {
        setError('deadlineBusinessDate', {
          type: 'custom',
          message: '実施予定日よりも後の日付を入力してください',
        })
        return false
      }

      mutation
        .mutateAsync(data)
        .then(async (result) => {
          toast.closeAll()
          if (result.ok) {
            await util.taskCardTemplate.list.invalidate()
            toast({
              title: '作成しました',
              status: 'success',
            })
            navigate(
              PagePath.template.show(
                result.result.projectId,
                result.result.taskNumber,
              ),
              {
                state: locationFrom, // もとの画面に戻せるようにする
              },
            )
          } else {
            logger.error('TaskCardTemplateCreate failed', {
              project: project.id,
              result,
            })
            toast({
              title: `作成に失敗しました ${result.errorMessage}`,
              status: 'error',
            })
          }
        })
        .catch((e) => {
          logger.error('TaskCardTemplateCreate failed', {
            project: project.id,
            error: e,
          })
          toast({
            title: `作成に失敗しました ${e}`,
            status: 'error',
          })
        })
    },
    [
      locationFrom,
      mutation,
      navigate,
      project.id,
      setError,
      toast,
      util.taskCardTemplate.list,
    ],
  )

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(onSubmitToCreate)}
      >
        <TaskCardTemplateCreateStickyHeader
          project={project}
          isLoading={mutation.isLoading}
        />
        <LayoutWithStickyHeader>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'56px'}
            paddingBottom={'120px'}
            mt={'24px'}
          >
            <TaskCardTemplateHeading project={project} />
            <TaskCardTemplateBasicInformationCreate />
          </VStack>
        </LayoutWithStickyHeader>
      </form>
    </FormProvider>
  )
}
const TaskCardTemplateCreateStickyHeader: React.FC<{
  project: SelectedProject
  isLoading: boolean
}> = ({ project, isLoading }) => {
  const locationFrom = useLocationFrom()
  const {
    formState: { isValid },
  } = useFormContext()
  return (
    <StickyHeader
      bgColor={'blue.100'}
      color={'blue.700'}
      title={'タスクカードテンプレート作成'}
    >
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          variant={'white-blue'}
          size={'sm'}
          to={
            locationFromToPath(locationFrom) ||
            PagePath.taskCard.list(project.id)
          }
        >
          <Text>やめる</Text>
        </LinkButton>
        <Button
          variant={'blue-fill'}
          type={'submit'}
          size={'sm'}
          isLoading={isLoading}
          isDisabled={!isValid}
        >
          <Text>この内容で作成</Text>
        </Button>
      </HStack>
    </StickyHeader>
  )
}

const TaskCardTemplateHeading = ({ project }: { project: SelectedProject }) => {
  return (
    <VStack spacing={'24px'} w={'full'}>
      <VStack
        spacing={0}
        borderRadius={'8px'}
        w={'full'}
        bgColor={'white'}
        boxShadow={'base'}
      >
        <TaskBreadCrumbs project={project} />
        <TaskCardTemplateHeadingContent project={project} />
      </VStack>
    </VStack>
  )
}

const TaskBreadCrumbs = ({ project }: { project: SelectedProject }) => {
  return (
    <Breadcrumb
      w={'full'}
      px={'16px'}
      py={'8px'}
      bgColor={'orange.50'}
      borderTopRadius={'8px'}
      separator={<MdArrowRight />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} href={PagePath.project.show(project.id)}>
          {project.name}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {/* 
      <BreadcrumbItem>
        <Text color={'gray.500'}>{taskCardTemplate.title}</Text>
      </BreadcrumbItem> */}
    </Breadcrumb>
  )
}

const TaskCardTemplateHeadingContent = ({
  project,
}: {
  project: SelectedProject
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<TaskCardTemplateCreateSchema>()

  return (
    <VStack
      w={'full'}
      pt={'16px'}
      pb={'24px'}
      px={'24px'}
      spacing={'16px'}
      bgColor={'white'}
      boxShadow={'base'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          プロジェクト名
        </Text>
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={project.color} />
          <Text fontWeight={'bold'}>{project.name}</Text>
        </HStack>
      </HStack>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={Boolean(errors?.taskNumber)}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            No.
          </FormLabel>
          <Input
            {...register('taskNumber', {
              required: true,
              validate: (v) => {
                if (v.includes(' ') || v.includes('　')) {
                  return '空白は含めないでください'
                }
              },
            })}
            placeholder={'例: 92-2'}
            w={'8em'}
          />
          <FormErrorMessage>
            {errors.taskNumber && errors.taskNumber.message}
          </FormErrorMessage>
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!errors?.attribute}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            属性
          </FormLabel>
          <Box w={'fit-content'}>
            <TaskAttributeSelect
              defaultValue={undefined}
              onChange={(attribute) =>
                attribute && setValue('attribute', attribute)
              }
            />
          </Box>
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!errors?.title}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            タスクカード名
          </FormLabel>
          <VStack maxW={'full'}>
            <Input
              {...register('title', { required: true })}
              placeholder={'請求書作成'}
              type={'text'}
              w={'30em'}
            />
            <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
          </VStack>
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!errors?.startBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            実施予定
          </FormLabel>
          <TaskCardStartInput />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!errors?.deadlineBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            期限日
          </FormLabel>

          <TaskCardDeadlineInput />
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!errors?.assignedToUserId}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            担当者
          </FormLabel>
          <Box w={'fit-content'}>
            <UserAssignSelect
              projectId={Number(project.id)}
              defaultValue={project.assignedPmUser}
              onChange={(user: User) => {
                setValue('assignedToUserId', user.id)
              }}
            />
          </Box>
        </HStack>
      </FormControl>
    </VStack>
  )
}
const TaskCardTemplateBasicInformationCreate = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TaskCardTemplateUpdateSchema>()
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              input
            </Text>
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('inputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'inputLinks'} />
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              作業内容
            </Text>
          </VStack>
          <NamedLinkArrayInput name={'description'} />
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              output
            </Text>
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('outputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'outputLinks'} />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
