import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import {
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  VStack,
  Button,
  Heading,
  StackDivider,
  Link,
  useToast,
  FormControl,
  Input,
  FormLabel,
  Textarea,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react'
import { MdArrowRight, MdOutlineDelete } from 'react-icons/md'
import React from 'react'
import { RouterOutput, trpc } from '@/lib/trpc'
import { TaskAttributeSelect } from '@/components/ui/TaskCardAttribute'
import { LinkButton } from '@/components/ui/LinkButton'
import { StickyHeader } from '@/components/ui/StickyHeader'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { TaskCardTemplateUpdateSchema } from '@app/api/src/usecase/taskCardTemplate/updateTaskCardTemplate'
import type { NamedLinkSchema } from '@app/api/src/routers/trpc/schema'
import { logger } from '@/lib/logger'
import { ColorMark } from '@/components/ui/ColorMark'
import { UserAssignSelect } from '@/components/ui/UserAssignSelect'
import { TaskCardUpdateDetailByPMSchema } from '@app/api/src/usecase/taskCard/updateTask'
import { User } from '@prisma/client'
import { NamedLinkArrayInput } from '@/components/ui/NamedLinkArrayInput'
import { useNavigate } from 'react-router-dom'
import { TaskCardTemplateDeleteConfirmationDialog } from './DeleteConfirmationModal'
import { useDialog } from '@/components/ui/Dialog'
import { sleep } from '@app/shared'
import {
  TaskCardDeadlineInput,
  TaskCardStartInput,
} from '@/components/ui/TaskCardDateInput'
import { locationFromToPath, useLocationFrom } from '@/helper/locationState'

type TaskCardTemplate = NonNullable<RouterOutput['taskCardTemplate']['show']>
export const TaskCardTemplateEdit: AuthenticatedComponent<{
  taskCardTemplate: TaskCardTemplate
}> = ({ self, firebaseUser, taskCardTemplate }) => {
  const form = useForm<TaskCardTemplateUpdateSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...taskCardTemplate,
      id: Number(taskCardTemplate.id),
      description: (taskCardTemplate.description as NamedLinkSchema[]) || [],
      inputLinks: (taskCardTemplate.inputLinks as NamedLinkSchema[]) || [],
      outputLinks: (taskCardTemplate.outputLinks as NamedLinkSchema[]) || [],
    },
  })

  const toast = useToast()
  const mutation = trpc.taskCardTemplate.update.useMutation({
    onError: (e) => {
      logger.error('TaskCardTemplateEdit', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const navigate = useNavigate()
  const util = trpc.useContext()
  const { setError } = form
  const locationFrom = useLocationFrom()
  const onSubmitToUpdate = React.useCallback(
    async (data: TaskCardTemplateUpdateSchema) => {
      if (
        data.startBusinessDate &&
        data.deadlineBusinessDate &&
        (data.startBusinessDate > data.deadlineBusinessDate ||
          (data.startTimeslotStart &&
            data.deadlineTimeslotStart &&
            data.startBusinessDate === data.deadlineBusinessDate &&
            data.startTimeslotStart > data.deadlineTimeslotStart))
      ) {
        setError('deadlineBusinessDate', {
          type: 'custom',
          message: '実施予定日よりも後の日付を入力してください',
        })
        return false
      }
      const result = await mutation.mutateAsync(data)
      toast.closeAll()
      if (result.ok) {
        await util.taskCardTemplate.show.invalidate({
          projectId: Number(taskCardTemplate.projectId),
          taskNumber: taskCardTemplate.taskNumber,
        })
        toast({
          title: '保存しました',
          status: 'success',
        })
        navigate(
          PagePath.template.show(
            taskCardTemplate.projectId,
            taskCardTemplate.taskNumber,
          ),
          { state: locationFrom },
        )
      } else {
        logger.error('TaskCardHeadingEdit', {
          taskCardTemplate: Number(taskCardTemplate.id),
          result,
        })
        toast({
          title: `保存に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    [
      locationFrom,
      mutation,
      navigate,
      setError,
      taskCardTemplate.id,
      taskCardTemplate.projectId,
      taskCardTemplate.taskNumber,
      toast,
      util.taskCardTemplate.show,
    ],
  )

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(onSubmitToUpdate)}
      >
        <TaskCardTemplateEditStickyHeader
          taskCardTemplate={taskCardTemplate}
          isLoading={mutation.isLoading}
        />
        <LayoutWithStickyHeader>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'56px'}
            paddingBottom={'120px'}
            mt={'24px'}
          >
            <TaskCardTemplateHeading taskCardTemplate={taskCardTemplate} />
            <TaskCardTemplateBasicInformationEdit
              taskCardTemplate={taskCardTemplate}
            />
          </VStack>
        </LayoutWithStickyHeader>
      </form>
    </FormProvider>
  )
}
const TaskCardTemplateEditStickyHeader: React.FC<{
  taskCardTemplate: TaskCardTemplate
  isLoading: boolean
}> = ({ taskCardTemplate, isLoading }) => {
  const {
    formState: { isValid },
  } = useFormContext()

  const dialog = useDialog()
  const util = trpc.useContext()
  const navigate = useNavigate()
  const toast = useToast()
  const locationFrom = useLocationFrom()

  const deleteMutation = trpc.taskCardTemplate.delete.useMutation({
    onSuccess: async (result) => {
      dialog.closeDialog()
      if (result.ok) {
        toast({
          title: 'テンプレートを削除しました',
          status: 'success',
        })
        await sleep(1000)
        util.taskCardTemplate.invalidate()
        navigate(
          PagePath.dashboard({
            projectIds: [Number(taskCardTemplate.projectId)],
          }),
        )
      } else {
        logger.error('TaskCardTemplateEdit failed to delete', {
          template: Number(taskCardTemplate.id),
          result,
        })
        toast({
          title: `テンプレートの削除に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      dialog.closeDialog()
      logger.error('TaskCardTemplateEdit failed to delete', {
        template: Number(taskCardTemplate.id),
        error,
      })
      toast({
        title: `テンプレートの削除に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  const onClickToDelete = React.useCallback(async () => {
    dialog.openDialog({
      body: (
        <TaskCardTemplateDeleteConfirmationDialog
          taskCardTemplate={taskCardTemplate}
          onSubmit={async () => {
            await deleteMutation.mutateAsync({
              id: Number(taskCardTemplate.id),
            })
          }}
        />
      ),
    })
  }, [deleteMutation, dialog, taskCardTemplate])

  return (
    <StickyHeader
      bgColor={'blue.100'}
      color={'blue.700'}
      title={'タスクカードテンプレート編集'}
    >
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          variant={'white-blue'}
          size={'sm'}
          to={
            locationFromToPath(locationFrom) ||
            PagePath.template.show(
              taskCardTemplate.projectId,
              taskCardTemplate.taskNumber,
            )
          }
        >
          <Text>やめる</Text>
        </LinkButton>
        <Button
          variant={'blue-fill'}
          type={'submit'}
          size={'sm'}
          isLoading={isLoading}
          isDisabled={!isValid}
        >
          <Text>保存して終了</Text>
        </Button>
        <Button size={'sm'} variant={'white-red'} onClick={onClickToDelete}>
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <MdOutlineDelete />
            <Text>テンプレートを削除</Text>
          </HStack>
        </Button>
      </HStack>
    </StickyHeader>
  )
}

const TaskCardTemplateHeading = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  return (
    <VStack spacing={'24px'} w={'full'}>
      <VStack
        spacing={0}
        borderRadius={'8px'}
        w={'full'}
        bgColor={'white'}
        boxShadow={'base'}
      >
        <TaskBreadCrumbs taskCardTemplate={taskCardTemplate} />
        <TaskCardTemplateHeadingContent taskCardTemplate={taskCardTemplate} />
      </VStack>
    </VStack>
  )
}

const TaskBreadCrumbs = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  return (
    <Breadcrumb
      w={'full'}
      px={'16px'}
      py={'8px'}
      bgColor={'orange.50'}
      borderTopRadius={'8px'}
      separator={<MdArrowRight />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          href={PagePath.project.show(Number(taskCardTemplate.projectId))}
        >
          {taskCardTemplate.project.name}
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Text color={'gray.500'}>{taskCardTemplate.title}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

const TaskCardTemplateHeadingContent = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  const util = trpc.useContext()
  const toast = useToast()
  const { register, control, setValue, formState } =
    useFormContext<TaskCardUpdateDetailByPMSchema>()

  return (
    <VStack
      w={'full'}
      pt={'16px'}
      pb={'24px'}
      px={'24px'}
      spacing={'16px'}
      bgColor={'white'}
      boxShadow={'base'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          プロジェクト名
        </Text>
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={taskCardTemplate.project.color} />
          <Text fontWeight={'bold'}>{taskCardTemplate.project.name}</Text>
        </HStack>
      </HStack>
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          No.
        </Text>
        <Text fontWeight={'bold'}>{taskCardTemplate.taskNumber}</Text>
      </HStack>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.attribute}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <Text w={'160px'} color={'gray.600'}>
            カテゴリ
          </Text>
          <Box w={'fit-content'}>
            <TaskAttributeSelect
              defaultValue={taskCardTemplate.attribute}
              onChange={(attribute) =>
                attribute && setValue('attribute', attribute)
              }
            />
          </Box>
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!formState.errors?.title}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <Text w={'160px'} color={'gray.600'}>
            タスクカードテンプレート名
          </Text>
          <VStack maxW={'full'}>
            <Input
              {...register('title', { required: true })}
              type={'text'}
              w={'30em'}
            />
            <FormErrorMessage>
              {formState.errors?.title?.message}
            </FormErrorMessage>
          </VStack>
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.startBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <Text w={'160px'} color={'gray.600'}>
            実施予定
          </Text>
          <TaskCardStartInput />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.deadlineBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <Text w={'160px'} color={'gray.600'}>
            期限日
          </Text>
          <TaskCardDeadlineInput />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.assignedToUserId}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <Text w={'160px'} color={'gray.600'}>
            担当者
          </Text>
          <Box w={'fit-content'}>
            <UserAssignSelect
              projectId={Number(taskCardTemplate.projectId)}
              defaultValue={taskCardTemplate.assignedTo || undefined}
              onChange={(user: User) => {
                setValue('assignedToUserId', user.id)
              }}
            />
          </Box>
        </HStack>
      </FormControl>
    </VStack>
  )
}
const TaskCardTemplateBasicInformationEdit = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  const { register, formState } =
    useFormContext<TaskCardUpdateDetailByPMSchema>()
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              input
            </Text>
            {/* <Button variant={'transparent'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <MdEdit />
                <Text>編集</Text>
              </HStack>
            </Button> */}
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!formState.errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('inputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'inputLinks'} />
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              作業内容
            </Text>
          </VStack>
          <NamedLinkArrayInput name={'description'} />
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              output
            </Text>
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!formState.errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('outputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'outputLinks'} />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
