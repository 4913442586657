import { useDialog } from '@/components/ui/Dialog'
import { TextWithLinks } from '@/components/ui/TextWithLInks'
import { BusinessDates } from '@app/shared'
import { logger } from '@/lib/logger'
import { dateTimeFormatter } from '@app/shared'
import {
  Text,
  useToast,
  VStack,
  HStack,
  Heading,
  Button,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Circle,
  Box,
  StackDivider,
  Badge,
  Divider,
} from '@chakra-ui/react'
import { MdArrowForwardIos, MdOutlineAdd } from 'react-icons/md'
import { TaskCardCommentAddDialog, TaskCardCommentInput } from './CommentDialog'
import { TaskCardDetail, TaskCardDetailComment } from '../types'
import React from 'react'
import { trpc } from '@/lib/trpc'
import { TaskCommentCategory } from '@prisma/client'
import { TaskCardCommentCategoryTag } from '@/components/ui/TaskCardCommentCategory'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'

export const TaskCardComments: React.FC<{
  task: TaskCardDetail
  businessDates: BusinessDates
}> = ({ task, businessDates }) => {
  const allComments = React.useMemo(() => {
    return (task.comments || [])
      .filter((comment) => {
        // クライアントへの質問は別で表示する
        return comment.category !== 'question_client'
      })
      .sort((a, b) => {
        // 共有事項の投稿日時の降順
        return b.timestamp.getTime() - a.timestamp.getTime()
      })
  }, [task.comments])
  const comments = React.useMemo(() => {
    return allComments.reduce(
      (acc, comment) => {
        const type = comment.category.startsWith('issue_')
          ? 'issue'
          : comment.category
        acc[type] ||= [] as TaskCardDetailComment[]
        acc[type].push(comment)
        return acc
      },
      {} as Record<TaskCommentCategory | 'issue', TaskCardDetailComment[]>,
    )
  }, [allComments])

  const { isOpen: dialogIsOpen, openDialog, closeDialog } = useDialog()
  const utils = trpc.useContext()
  const createCommentMutation = trpc.taskComment.create.useMutation({
    onSuccess: async (data) => {
      logger.info('createCommentMutation.onSuccess', { data })
      await utils.taskCard.show.invalidate()
    },
  })
  const toast = useToast()

  const onClickAdd = React.useCallback(() => {
    if (!dialogIsOpen) {
      openDialog({
        body: (
          <TaskCardCommentAddDialog
            task={task}
            onSubmit={async (data: TaskCardCommentInput) => {
              logger.info('onSubmit', { data })
              const input: Parameters<
                typeof createCommentMutation.mutateAsync
              >[0] = {
                taskCardId: Number(task.id),
                text: data.text,
                timestamp: data.dateTime,
                category: data.category,
              }
              const result = await createCommentMutation.mutateAsync(input)
              if (result.ok) {
                toast({
                  title: '共有事項を追加しました',
                  status: 'success',
                })
                closeDialog()
              } else {
                toast({
                  title: `共有事項の追加に失敗しました ${result.errorCode} ${result.errorMessage}`,
                  status: 'error',
                })
              }
            }}
          />
        ),
        size: '2xl',
      })
    }
  }, [
    closeDialog,
    createCommentMutation,
    dialogIsOpen,
    openDialog,
    task,
    toast,
  ])

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      spacing={'24px'}
      color={'gray.700'}
    >
      <HStack spacing={'24px'}>
        <Heading size={'lg'}>共有事項</Heading>
        <Button
          size={'sm'}
          color={'white'}
          variant={'blue-fill'}
          onClick={onClickAdd}
        >
          <HStack>
            <MdOutlineAdd />
            <Text>投稿</Text>
          </HStack>
        </Button>
      </HStack>
      <Tabs position='relative' variant='unstyled' w={'full'}>
        <TabList>
          <Tab>タイムライン(すべて)</Tab>
          <Tab>新ルール</Tab>
          <Tab>作業内容</Tab>
          <Tab>ミス</Tab>
          <Tab>課題点</Tab>
          <Tab>タスクカード修正依頼</Tab>
          <Tab>その他</Tab>
        </TabList>
        <TabIndicator
          mt='-1px'
          height='2px'
          bg='blue.500'
          w={'full'}
          borderRadius='1px'
          zIndex={2}
        />
        <Divider
          borderColor={'gray.200'}
          borderWidth={'2px'}
          w={'full'}
          zIndex={1}
          mt={'-2px'}
          mb={'16px'}
        />
        <TabPanels>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={allComments}
              commentType='all'
            />
          </TabPanel>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={comments['report_new_rule'] || []}
              commentType='report_new_rule'
            />
          </TabPanel>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={comments['report_work'] || []}
              commentType='report_work'
            />
          </TabPanel>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={comments['report_miss'] || []}
              commentType='report_miss'
            />
          </TabPanel>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={comments['issue'] || []}
              commentType='issue'
            />
          </TabPanel>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={comments['request_task_modification'] || []}
              commentType='request_task_modification'
            />
          </TabPanel>
          <TabPanel p={0}>
            <TaskCardCommentsElement
              task={task}
              businessDates={businessDates}
              comments={comments['report_other'] || []}
              commentType='report_other'
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

const taskCardCommentDefaultCount = 5
const TaskCardCommentsElement: React.FC<{
  task: TaskCardDetail
  businessDates: BusinessDates
  comments: TaskCardDetailComment[]
  commentType: TaskCommentCategory | 'issue' | 'all'
  // businessDates: TaskBusinessDates
}> = ({ task, businessDates, comments, commentType }) => {
  const [showMore, setShowMore] = React.useState(false)
  const hasMore = React.useMemo(
    () => comments.length > taskCardCommentDefaultCount,
    [comments.length],
  )

  const commentsPerDay = React.useMemo(() => {
    // 日付でgroupBy
    return (
      showMore ? comments : comments.slice(0, taskCardCommentDefaultCount)
    ).reduce(
      (acc, comment) => {
        const date = dateTimeFormatter.jst['YYYY/MM/DD'](comment.timestamp)
        acc[date] ||= [] as TaskCardDetailComment[]
        acc[date].push(comment)
        return acc
      },
      {} as { [date: string]: TaskCardDetailComment[] },
    )
  }, [comments, showMore])

  if (comments.length === 0) {
    return (
      <Text px={'12px'} py={'12px'}>
        該当する共有事項はありません
      </Text>
    )
  }

  return (
    <VStack w={'full'} spacing={'24px'}>
      {Object.entries(commentsPerDay).map(([date, comments]) => {
        const businessDate = businessDates.fromDate(new Date(date))
        return (
          <HStack
            key={`${commentType}-${date}`}
            w={'full'}
            alignItems={'stretch'}
            justifyContent={'flex-start'}
            h={'fit-content'}
          >
            {/* <VStack spacing={0} alignItems={'center'}>
              <Circle
                size={'24px'}
                borderWidth={'5px'}
                borderColor={'blue.600'}
              />
              <Box flexGrow={1} w={'4px'} minH={'10px'} bgColor={'blue.600'} />
            </VStack> */}
            <VStack w={'full'} alignItems={'flex-start'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <Heading size={'md'} color={'gray.700'}>
                  {date}
                </Heading>
                <Text fontSize={'xs'} color={'gray.500'}>
                  {dateTimeFormatter.jst.weekday(new Date(date))}曜日
                </Text>

                <Badge
                  borderRadius={'2px'}
                  paddingX={'4px'}
                  paddingY={'2px'}
                  borderWidth={'1px'}
                  fontWeight={'bold'}
                  // borderColor={'red.500'}
                  colorScheme={'red'}
                  variant={'outline'}
                  fontSize={'xs'}
                >
                  第{businessDate || '-'}営業日
                </Badge>
              </HStack>
              <VStack
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                w={'full'}
                spacing={0}
                bgColor={'white'}
                divider={<StackDivider />}
                borderRadius={'8px'}
              >
                {comments
                  .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                  .map((comment) => {
                    return (
                      <TaskCardComment
                        key={comment.id}
                        task={task}
                        comment={comment}
                      />
                    )
                  })}
              </VStack>
            </VStack>
          </HStack>
        )
      })}
      {hasMore && !showMore && (
        <HStack w={'full'} justifyContent={'center'}>
          <Button
            size={'sm'}
            onClick={() => setShowMore(true)}
            variant={'transparent-clickable'}
          >
            <HStack w={'full'} justifyContent={'center'} color={'gray.500'}>
              <Box w={'12px'} style={{ rotate: '90deg' }}>
                <MdArrowForwardIos />
              </Box>
              <Text>もっと見る</Text>
            </HStack>
          </Button>
        </HStack>
      )}
    </VStack>
  )
}

const TaskCardComment: React.FC<{
  task: TaskCardDetail
  comment: TaskCardDetail['comments'][number]
}> = ({ task, comment }) => {
  const util = trpc.useContext()
  const toast = useToast()
  const mutation = trpc.taskComment.updateStatus.useMutation({
    onSuccess: async (data) => {
      if (data.ok) {
        await Promise.all([
          util.taskCard.show.invalidate(),
          util.taskCard.list.invalidate(),
        ])

        toast({
          title: '解決済みにしました',
          status: 'success',
        })
      } else {
        logger.error(`failed to resolve comment`, { task, comment, data })
        toast({
          title: `解決済みにできませんでした ${data.errorMessage}`,
          status: 'error',
        })
      }
    },
  })

  const onClickToResolve = React.useCallback(async () => {
    if (
      comment.category !== 'request_task_modification' ||
      comment.status === 'resolved'
    ) {
      return
    }
    try {
      await mutation.mutateAsync({
        id: Number(comment.id),
        status: 'resolved',
      })
    } catch (error) {
      logger.error(`failed to resolve comment`, { task, comment, error })
      toast({
        title: `解決済みにできませんでした ${error}`,
        status: 'error',
      })
    }
  }, [comment, mutation, task, toast])

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      bgColor={'white'}
      px={'20px'}
      py={'16px'}
      spacing={'10px'}
      borderRadius={'8px'}
    >
      <HStack
        spacing={'16px'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        w={'full'}
      >
        <Box>
          <UserWithIcon user={comment.user} />
        </Box>
        <TaskCardCommentCategoryTag category={comment.category} />
        <Badge
          colorScheme={'gray'}
          variant={'outline'}
          borderRadius={'2px'}
          borderWidth={'1px'}
          // borderColor={'gray.400'}
          fontSize={'xs'}
          px={'6px'}
          py={'2px'}
        >
          投稿時間
        </Badge>
        <Text color={'gray.400'}>
          {dateTimeFormatter.jst['hh:mm'](comment.timestamp)}
        </Text>
      </HStack>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        spacing={'32px'}
      >
        <TextWithLinks fontSize={'sm'}>{comment.body}</TextWithLinks>
        {comment.category === 'request_task_modification' &&
          (comment.status === 'resolved' ? (
            <Button
              size={'xs'}
              isDisabled
              colorScheme={'gray'}
              variant={'solid'}
            >
              対応済み
            </Button>
          ) : (
            <Button
              size={'xs'}
              variant={'blue-fill'}
              onClick={onClickToResolve}
              isLoading={mutation.isLoading}
            >
              解決済みにする
            </Button>
          ))}
      </HStack>
    </VStack>
  )
}
