import { PagePath } from '@/PagePath'
import { Layout } from '@/components/Layout'
import { AssignedUser } from '@/components/ui/AssignedUser'
import { Link, LinkButton } from '@/components/ui/LinkButton'
import { TaskAttributeTag } from '@/components/ui/TaskCardAttribute'
import { trpc, type RouterOutput } from '@/lib/trpc'
import { YearMonth } from '@app/shared'
import {
  Text,
  HStack,
  Heading,
  VStack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Box,
  Button,
  Checkbox,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { MdAdd, MdOutlineInfo } from 'react-icons/md'
import { ColorMark } from '@/components/ui/ColorMark'
import { ProjectMonthlyEventDates } from '@app/api/src/model/prisma'
import { MonthlyProjectCalendarView } from '@/components/ui/ProjectCalendar/view'
import { ProjectMonth } from '@prisma/client'
import { FaArrowRight } from 'react-icons/fa6'
import { YearMonthSelector } from '@/components/ui/YearMonthSelector'
import { atom, useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { logger } from '@/lib/logger'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { createLocationFrom } from '@/helper/locationState'
import { TextEllipsis } from '@/components/ui/TextEllipsis'
import {
  SortableTableHeader,
  useTableSort,
} from '@/components/ui/SortableTableHeader'
import { RefreshButton } from '@/components/ui/RefreshButton'

type Template = RouterOutput['taskCardTemplate']['list'][number]
const selectedIdsAtom = atom<Set<number>>(new Set([]))
selectedIdsAtom.onMount = (set) => {
  set(new Set([]))
}

export const TaskCardsBulkNew: React.FC<{
  project: SelectedProject
  projectMonth: ProjectMonth | undefined
  templates: Template[]
  yearMonth: YearMonth
}> = ({ project, projectMonth, templates, yearMonth }) => {
  const [selectedIds, setSelectedIds] = useAtom(selectedIdsAtom)
  const onCheckAll = React.useCallback(() => {
    setSelectedIds(new Set(templates.map((t) => Number(t.id))))
  }, [setSelectedIds, templates])
  const toast = useToast()
  const mutation = trpc.taskCard.bulkCreate.useMutation({
    onError: (e) => {
      logger.error('TaskCardsBulkNew', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const navigate = useNavigate()
  const onSubmit = React.useCallback(async () => {
    const payload = {
      projectId: project.id,
      yearMonth,
      templateIds: Array.from(selectedIds),
    }
    try {
      const result = await mutation.mutateAsync(payload)
      if (result.ok) {
        setSelectedIds(new Set([]))
        toast({
          title: `タスクカード(${result.result.count}件)を一括生成しました`,
          status: 'success',
        })
        navigate(PagePath.taskCard.list(project.id, yearMonth))
      } else {
        logger.error(`タスクカードの一括生成に失敗しました`, {
          payload,
          result,
        })
        toast({
          title: `タスクカードの一括生成に失敗しました ${result.errorMessage}`,
          status: 'error',
          duration: 10000,
        })
      }
    } catch (error) {
      logger.error(`タスクカードの一括生成に失敗しました`, { payload, error })
      toast({
        title: `タスクカードの一括生成に失敗しました ${error}`,
        status: 'error',
      })
    }
  }, [
    mutation,
    navigate,
    project.id,
    selectedIds,
    setSelectedIds,
    toast,
    yearMonth,
  ])
  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'56px'}
        paddingBottom={'120px'}
      >
        <RefreshButton />
        <VStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'24px'}
        >
          <Heading size={'lg'}>タスクカード一括作成</Heading>
          <HStack
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={'16px'}
          >
            <ColorMark color={project.color} />
            <Text fontWeight={'bold'} fontSize={'2xl'} color={'gray.700'}>
              {project.name}
            </Text>
          </HStack>
        </VStack>
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'24px'}
        >
          <Text fontWeight={'bold'} fontSize={'2xl'} color={'gray.700'}>
            対象月の選択
          </Text>
          <VStack
            w={'full'}
            minW={'992px'}
            spacing={'16px'}
            bgColor={'white'}
            p={'12px'}
          >
            <YearMonthSelector bgColor={'gray.50'} />
            {projectMonth ? (
              <MonthlyProjectCalendarView
                onClickCell={undefined}
                yearMonth={yearMonth}
                monthlyEvents={
                  project.monthlyEventDates as ProjectMonthlyEventDates
                }
                nonBusinessDates={projectMonth.nonBusinessDates || []}
              />
            ) : (
              <Text>対象月の休業日が未設定です</Text>
            )}
          </VStack>
          <NoteForProjectMonth project={project} />
        </VStack>

        <VStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'24px'}
        >
          <Text fontWeight={'bold'} fontSize={'2xl'} color={'gray.700'}>
            対象のテンプレートを選択
          </Text>
          <VStack w={'full'} spacing={'16px'}>
            <HStack w={'full'} justifyContent={'space-between'}>
              <Button size={'sm'} variant={'blue-fill'} onClick={onCheckAll}>
                一括選択する
              </Button>
              <LinkButton
                variant={'white-blue'}
                size={'sm'}
                to={PagePath.template.new(project.id)}
                locationFrom={createLocationFrom(
                  PagePath.taskCard.bulkNew(project.id, yearMonth),
                  yearMonth,
                )}
              >
                <HStack>
                  <MdAdd />
                  <Text>テンプレートを追加</Text>
                </HStack>
              </LinkButton>
            </HStack>
            <TemplateList
              project={project}
              templates={templates}
              yearMonth={yearMonth}
            />
            <Button
              size={'lg'}
              colorScheme='orange'
              onClick={onSubmit}
              isLoading={mutation.isLoading}
              isDisabled={selectedIds.size === 0}
            >
              この内容で一括生成する
            </Button>
          </VStack>
        </VStack>
      </VStack>
    </Layout>
  )
}

const NoteForProjectMonth: React.FC<{ project: SelectedProject }> = ({
  project,
}) => {
  return (
    <VStack
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={'8px'}
    >
      <HStack
        w={'full'}
        boxShadow={'base'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        bgColor={'blue.50'}
        spacing={'8px'}
        py={'12px'}
        px={'16px'}
      >
        <Box w={'32px'} h={'32px'} color={'blue.500'}>
          <MdOutlineInfo size={'32px'} />
        </Box>
        <Text>
          タスク一括生成前にプロジェクトの休業日・月次/年間イベントが正しい情報か確認してください。
          <br />
          タスクの実施予定・期限営業日数に影響する場合があります
        </Text>
      </HStack>
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        spacing={'16px'}
        color={'blue.500'}
      >
        <Link
          _hover={{ textDecoration: 'none' }}
          to={PagePath.project.show(project.id)}
        >
          <HStack
            spacing={0}
            borderBottomWidth={'1px'}
            borderBottomColor={'blue.500'}
            textDecor={'none'}
          >
            <Text>休業日・月次/年間イベントを修正する</Text>
            <Box w={'16px'} style={{ rotate: '-45deg' }}>
              <FaArrowRight />
            </Box>
          </HStack>
        </Link>
      </HStack>
    </VStack>
  )
}

type TemplateListSort = {
  field: 'startBusinessDate' | 'deadlineBusinessDate' | undefined
  direction: 'asc' | 'desc' | undefined
}
const templateListSortAtom = atom<TemplateListSort>({
  field: 'startBusinessDate',
  direction: 'asc',
})
const TemplateList: React.FC<{
  project: SelectedProject
  templates: Template[]
  yearMonth: YearMonth
}> = ({ project, templates: _templates, yearMonth }) => {
  const useSort = useTableSort(templateListSortAtom)
  const { field, direction } = useSort[0]
  const templates = React.useMemo(() => {
    if (!field) {
      return _templates
    }
    return _templates.sort((a, b) => {
      const [f1, f2] = [a[field], b[field]]
      if (!f1) {
        return direction === 'asc' ? -1 : -1
      }
      if (!f2) {
        return direction === 'asc' ? 1 : 1
      }
      return direction === 'asc' ? f1 - f2 : f2 - f1
    })
  }, [_templates, field, direction])

  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'flex-start'}>
      <TableContainer w={'full'}>
        <Table variant='striped'>
          <Thead>
            <Tr height={'3rem'} paddingY={'10px'}>
              <Th></Th>
              <SortableTableHeader
                field={'startBusinessDate'}
                useSort={useSort}
              >
                実施予定日
              </SortableTableHeader>
              <SortableTableHeader
                field={'deadlineBusinessDate'}
                useSort={useSort}
              >
                期限
              </SortableTableHeader>
              <Th w={'fit-content'}>属性</Th>
              <Th w={'fit-content'}>No.</Th>
              <Th w={'fit-content'}>タスクカード名</Th>
              <Th w={'fit-content'}>担当者</Th>
              <Th w={'20px'}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {templates.length > 0 ? (
              templates.map((template) => {
                return (
                  <TemplateTableRow
                    key={template.id}
                    project={project}
                    template={template}
                    yearMonth={yearMonth}
                  />
                )
              })
            ) : (
              <Tr>
                <Td colSpan={8}>テンプレートがありません</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}

const TemplateTableRow: React.FC<{
  project: SelectedProject
  template: Template
  yearMonth: YearMonth
}> = ({ project, template, yearMonth }) => {
  const [selectedIds, setSelectedIds] = useAtom(selectedIdsAtom)
  const id = Number(template.id)
  const onChange = React.useCallback(() => {
    if (selectedIds.has(id)) {
      selectedIds.delete(id)
    } else {
      selectedIds.add(id)
    }
    setSelectedIds(new Set(selectedIds))
  }, [id, selectedIds, setSelectedIds])

  return (
    <Tr>
      <Td textAlign={'center'} minWidth={'fit-content'}>
        <Checkbox
          bg={'white'}
          onChange={onChange}
          isChecked={selectedIds.has(id)}
        />
      </Td>
      <Td>
        <HStack>
          <Text color={'gray.600'}>{`[第${
            template.startBusinessDate || '-'
          }営業日]`}</Text>
          <Text color={'gray.400'}>
            {' '}
            {template.startTimeslotStart || ''}-
            {template.startTimeslotEnd || ''}
          </Text>
        </HStack>
      </Td>
      <Td>
        <HStack>
          <Text color={'gray.600'}>{`[第${
            template.deadlineBusinessDate || '-'
          }営業日]`}</Text>
          <Text color={'gray.400'}>
            {' '}
            {template.deadlineTimeslotStart || ''}-
            {template.deadlineTimeslotEnd || ''}
          </Text>
        </HStack>
      </Td>
      <Td>
        <TaskAttributeTag attribute={template.attribute} />
      </Td>
      <Td>{template.taskNumber}</Td>
      <Td>
        <TextEllipsis>{template.title}</TextEllipsis>
      </Td>
      <Td>
        <AssignedUser user={template.assignedTo} />
      </Td>
      <Td>
        <LinkButton
          to={PagePath.template.show(template.projectId, template.taskNumber)}
          bgColor={'transparent'}
          locationFrom={createLocationFrom(
            PagePath.taskCard.bulkNew(project.id, yearMonth),
            yearMonth,
          )}
          color={'gray.500'}
        >
          <FaExternalLinkAlt />
        </LinkButton>
      </Td>
    </Tr>
  )
}
