import { trpc } from '@/lib/trpc'
import { Spinner } from '@chakra-ui/react'
import { User } from '@prisma/client'
import React from 'react'
import { UserSelect } from './UserSelect'

export const UserAssignSelect: React.FC<{
  type?: 'input' | 'mustSelect'
  projectId: number
  defaultValue: User | undefined
  placeholder?: string
  onChange: (user: User) => void
}> = ({ type, projectId, placeholder, defaultValue, onChange }) => {
  const query = trpc.user.list.useQuery({
    filter: {
      accessibleProjectId: projectId,
    },
  })
  return query.isLoading ? (
    <Spinner />
  ) : query.data ? (
    <UserSelect
      type={type}
      candidates={query.data}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={(u) => u && onChange(u)}
    />
  ) : (
    <Spinner />
  )
}
