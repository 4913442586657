export const dayOfWeeks = [
  { id: 'sun', name: '日', day: 0, color: 'red.600' },
  { id: 'mon', name: '月', day: 1, color: 'gray.500' },
  { id: 'tue', name: '火', day: 2, color: 'gray.500' },
  { id: 'wed', name: '水', day: 3, color: 'gray.500' },
  { id: 'thu', name: '木', day: 4, color: 'gray.500' },
  { id: 'fri', name: '金', day: 5, color: 'gray.500' },
  { id: 'sat', name: '土', day: 6, color: 'blue.500' },
] as const
export type DayOfWeek = (typeof dayOfWeeks)[number]
