import { PagePathParams } from '@/PagePath'
import { TaskCardCreate } from '@/components/pages/taskcard/new'
import { BackButton } from '@/components/ui/BackButton'
import { YearMonth, YearMonthContextProvider } from '@/context/yearMonth'
import { logger } from '@/lib/logger'
import { trpc } from '@/lib/trpc'
import {
  Authenticated,
  AuthenticatedComponent,
} from '@/pages/AuthenticatedPage'
import { Spinner, useToast } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ErrorPage } from '../ErrorPage'

export const TaskCardCreatePage = () => {
  const { projectId } = useParams<PagePathParams['tasks']['new']>()
  if (!projectId || Number.isNaN(Number(projectId))) {
    return <ErrorPage errorMessage='パラメータが不正です' />
  }

  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <YearMonthContextProvider>
            {(yearMonth) => (
              <TaskCardCreatePageInternal
                {...{
                  projectId: Number(projectId),
                  yearMonth,
                  firebaseUser,
                  self,
                }}
              ></TaskCardCreatePageInternal>
            )}
          </YearMonthContextProvider>
        )
      }}
    </Authenticated>
  )
}

const TaskCardCreatePageInternal: AuthenticatedComponent<{
  projectId: number
  yearMonth: YearMonth
}> = ({ projectId, yearMonth, firebaseUser, self }) => {
  const toast = useToast()
  const query = trpc.project.show.useQuery({
    id: projectId,
    yearMonth,
  })
  React.useEffect(() => {
    if (query.error) {
      toast({
        title: `error ${query.error.message}`,
        status: 'error',
      })
    }
  }, [query.error, toast])

  if (self.role === 'member') {
    return <ErrorPage errorMessage='権限がありません' />
  }

  if (query.isLoading) {
    return <Spinner />
  }
  if (query.error) {
    return <ErrorPage errorMessage={query.error.message} />
  }
  if (!query.data) {
    return <ErrorPage errorMessage='データが取得できませんでした' />
  }

  return (
    <TaskCardCreate
      firebaseUser={firebaseUser}
      self={self}
      project={query.data}
      yearMonth={yearMonth}
    />
  )
}
