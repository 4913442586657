import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'
import { components } from './components'
import { shadows } from './shadows'

// extendsBaseThemeすると色々不便なのでがまん
export const customTheme = extendTheme({
  colors,
  shadows,
  components,
  fonts: {
    heading: 'Noto Sans JP',
    body: 'Noto Sans JP',
  },
})
