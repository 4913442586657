import { dateTimeFormatter } from '@app/shared'
import {
  AlertDialogContent,
  Text,
  AlertDialogHeader,
  VStack,
  AlertDialogCloseButton,
  AlertDialogBody,
  HStack,
  Select,
  Input,
  Textarea,
  AlertDialogFooter,
  Button,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
import { TaskCommentCategory } from '@prisma/client'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TaskCardDetail } from '../types'
import { useMutation } from '@tanstack/react-query'
import { taskCardCommentLabel } from '@/constants/taskCardCommentType'

export type TaskCardCommentInput = {
  category: TaskCommentCategory
  dateTime: Date
  text: string
}
type CommentInputForm = {
  category: TaskCommentCategory
  date: string
  time: string
  text: string
}
export const TaskCardCommentAddDialog: React.FC<{
  task: TaskCardDetail
  onSubmit: (data: TaskCardCommentInput) => Promise<void>
}> = ({ task, onSubmit }) => {
  const now = React.useMemo(() => new Date(), [])
  const form = useForm<CommentInputForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      date: dateTimeFormatter.jst['YYYY-MM-DD'](now),
      time: dateTimeFormatter.jst['hh:mm'](now),
    },
  })
  const mutation = useMutation({
    mutationFn: async () => {
      const input = form.getValues()
      const data = {
        category: input.category,
        text: input.text,
        dateTime: dateTimeFormatter.jst.fromString(input.date, input.time),
      }
      return onSubmit(data)
    },
  })
  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            共有事項の投稿
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <form onSubmit={form.handleSubmit((data) => mutation.mutateAsync())}>
          <VStack
            w={'full'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            spacing={'10px'}
          >
            <HStack
              w={'full'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <FormControl isRequired>
                <Select
                  w={'fit-content'}
                  borderRadius={'6px'}
                  size={'sm'}
                  {...form.register('category')}
                  placeholder={'カテゴリを選択'}
                >
                  {Object.entries(taskCardCommentLabel)
                    .filter(([key, _]) => {
                      return key !== 'issue' && key !== 'question_client'
                    })
                    .map(([key, { label }]) => {
                      return (
                        <option key={key} value={key}>
                          {label}
                        </option>
                      )
                    })}
                </Select>
                <FormErrorMessage>
                  {form.formState?.errors.category?.message}
                </FormErrorMessage>
              </FormControl>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <FormControl isRequired>
                  <Input
                    {...form.register('date')}
                    type={'date'}
                    size={'sm'}
                    borderRadius={'6px'}
                  />
                  <FormErrorMessage>
                    {form.formState.errors.date &&
                      form.formState.errors.date.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                  <Input
                    {...form.register('time')}
                    type={'time'}
                    size={'sm'}
                    borderRadius={'6px'}
                  />
                  <FormErrorMessage>
                    {form.formState.errors.time &&
                      form.formState.errors.time.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
            </HStack>
            <FormControl isRequired>
              <Textarea
                {...form.register('text')}
                height={'6em'}
                placeholder={
                  '例）領収書の添付漏れがあり経理部の佐藤さんに確認中です。関連スレッドは以降をご確認ください。https://example.com/testpage'
                }
              />
            </FormControl>
            <HStack w={'full'} justifyContent={'flex-end'}>
              <Button
                type={'submit'}
                isLoading={mutation.isLoading}
                w={'fit-content'}
                variant={'blue-fill'}
                borderRadius={'6px'}
              >
                投稿する
              </Button>
            </HStack>
          </VStack>
        </form>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}>
        {/* <Button
          onClick={() => mutation.mutateAsync()}
          isLoading={mutation.isLoading}
          w={'fit-content'}
          variant={'blue-fill'}
          borderRadius={'6px'}
        >
          投稿する
        </Button> */}
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
