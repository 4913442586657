import {
  Text,
  AlertDialogContent,
  AlertDialogHeader,
  VStack,
  AlertDialogCloseButton,
  AlertDialogBody,
  HStack,
  Button,
  useToast,
  Alert,
} from '@chakra-ui/react'
import React from 'react'
import { TaskCardDetail } from '../types'
import { trpc } from '@/lib/trpc'
import { useDialog } from '@/components/ui/Dialog'
import { logger } from '@/lib/logger'
import { useMutation } from '@tanstack/react-query'

export const TaskCardArchiveConfirmationDialog: React.FC<{
  taskCard: TaskCardDetail
  onSubmit: () => Promise<void>
}> = ({ taskCard, onSubmit }) => {
  const { closeDialog } = useDialog()
  const toast = useToast()
  const mutation = useMutation({
    mutationFn: async () => {
      await onSubmit()
    },
  })
  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            タスクカードを削除しますか？
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          w={'full'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          spacing={'10px'}
        >
          <Alert
            status={'warning'}
            w={'full'}
            variant={'subtle'}
            boxShadow={'base'}
          >
            削除すると担当者はタスクカードの閲覧ができません。また、月次の集計からも除外されます。
            <br />
            一度削除したタスクカードは元に戻せません。
          </Alert>

          <HStack w={'full'} justifyContent={'flex-end'}>
            {/* <Button
              borderRadius={'6px'}
              colorScheme={'gray'}
              size={'sm'}
              onClick={closeDialog}
            >
              キャンセル
            </Button> */}
            <Button
              isLoading={mutation.isLoading}
              colorScheme='red'
              borderRadius={'6px'}
              size={'sm'}
              onClick={() => mutation.mutateAsync()}
            >
              削除する
            </Button>
          </HStack>
        </VStack>
      </AlertDialogBody>
    </AlertDialogContent>
  )
}
