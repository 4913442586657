import { locationAtom } from '@/helper/location'
import { RouterOutput, trpc } from '@/lib/trpc'
import {
  Box,
  Text,
  VStack,
  Spinner,
  Tag,
  TagLabel,
  HStack,
  TagCloseButton,
  Heading,
  Tooltip,
} from '@chakra-ui/react'
import { atom, useAtom, useAtomValue } from 'jotai'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ColorMark } from '../ColorMark'
import { useSelfState } from '../../providers/SelfProvider'
import { logger } from '@/lib/logger'
import { Choice, AutoCompleteInput } from '../AutoCompleteInput'
import { SelectedProject } from './type'

type SelectedProjects =
  | {
      type: 'project'
      projectIds: number[]
    }
  | {
      type: 'filter'
      filterId: number
    }

const selectionFromSearchParams = (
  searchParams: URLSearchParams | undefined,
): SelectedProjects => {
  if (searchParams?.get('projectIds')) {
    return {
      type: 'project',
      projectIds:
        searchParams
          ?.get('projectIds')
          ?.split(',')
          .filter(Boolean)
          .map(Number) || [],
    }
  }
  if (searchParams?.get('filterId')) {
    return {
      type: 'filter',
      filterId: Number(searchParams.get('filterId')),
    }
  }
  return {
    type: 'project',
    projectIds: [],
  }
}

const selectedProjectsAtom = atom<SelectedProjects, SelectedProjects[], void>(
  (get) => {
    const { searchParams } = get(locationAtom)
    return selectionFromSearchParams(searchParams)
  },
  (get, set, update) => {
    set(locationAtom, (prev) => {
      const searchParams = prev.searchParams || new URLSearchParams()
      if (update.type === 'project') {
        searchParams.set('projectIds', update.projectIds.join(','))
        searchParams.delete('filterId')
      } else {
        searchParams.set('filterId', String(update.filterId))
        searchParams.delete('projectIds')
      }
      return {
        ...prev,
        searchParams,
      }
    })
  },
)

export const useSelectedProjectsAndFiltersQuery = (options?: {
  urlParamSync: boolean
}) => {
  // とりあえず全部取ってきておく
  const allFilterListQuery = trpc.projectFilter.list.useQuery({})
  const allProjectsQuery = trpc.project.list.useQuery({})

  const { self } = useSelfState()
  const [urlParams] = useSearchParams()
  const [selectedProjectsAndFilters, setSelectedProjectsAndFilters] =
    useAtom(selectedProjectsAtom)

  const selectedFilterId = React.useMemo(
    () =>
      selectedProjectsAndFilters.type === 'filter'
        ? selectedProjectsAndFilters.filterId
        : undefined,
    [selectedProjectsAndFilters],
  )
  const selectedProjectIds = React.useMemo(
    () =>
      selectedProjectsAndFilters.type === 'project'
        ? selectedProjectsAndFilters.projectIds
        : [],
    [selectedProjectsAndFilters],
  )

  // filterIdが指定されている場合はそのprojectIdsを取得
  // それ以外の場合は指定したprojectIdsを取得
  const targetProjectIds = React.useMemo(() => {
    if (selectedProjectIds.length > 0) {
      return selectedProjectIds
    }
    if (selectedFilterId) {
      if (allFilterListQuery.isLoading) {
        return
      }
      const targets = (allFilterListQuery.data || []).find(
        (filter) => Number(filter.id) === selectedFilterId,
      )?.projectIds
      return targets
    }
    return
  }, [
    selectedFilterId,
    allFilterListQuery.data,
    allFilterListQuery.isLoading,
    selectedProjectIds,
  ])

  const [hasDefaultUsed, setHasDefaultUsed] = React.useState(false)
  const syncUrl = React.useCallback(() => {
    if (options?.urlParamSync === true) {
      if (urlParams.has('projectIds')) {
        const fromUrlParams = selectionFromSearchParams(urlParams)
        setSelectedProjectsAndFilters(fromUrlParams)
        setHasDefaultUsed(true)
        return
      }
    }
  }, [options?.urlParamSync, setSelectedProjectsAndFilters, urlParams])
  React.useEffect(() => syncUrl(), [syncUrl])

  const setToDefaults = React.useCallback(() => {
    // URLに?projectIds=がない場合は、自分がPMのプロジェクトをデフォルトで選択する
    if (urlParams.has('projectIds') || urlParams.has('filterId')) {
      return
    }
    // xボタンを押してプロジェクトを非選択にした場合は?projectIds=が付与された状態になるのでここには来ない
    const defaultProjectIds = (() => {
      if (!self) {
        return null
      }
      const allProjects = allProjectsQuery.data || []
      if (self.role === 'member') {
        return allProjects.map((p) => Number(p.id))
      } else {
        return allProjects
          .filter((p) => p.assignedPmUserId === self.id)
          .map((p) => Number(p.id))
      }
    })()
    logger.info(`useEffect`, {
      selectedProjectsAndFilters,
      urlParams: urlParams.toString(),
      defaultProjectIds,
      hasDefaultUsed,
    })
    if (hasDefaultUsed) {
      return
    }
    setSelectedProjectsAndFilters({
      type: 'project',
      projectIds: defaultProjectIds || [],
    })
    setHasDefaultUsed(true)
  }, [
    allProjectsQuery.data,
    hasDefaultUsed,
    selectedProjectsAndFilters,
    self,
    setSelectedProjectsAndFilters,
    urlParams,
  ])

  React.useEffect(() => {
    setToDefaults()
  }, [setToDefaults])

  // 選択済みのフィルタに対応するオブジェクト
  const selectedFilter = React.useMemo(() => {
    return selectedFilterId
      ? allFilterListQuery.data?.find(
          (filter) => Number(filter.id) === selectedFilterId,
        )
      : undefined
  }, [allFilterListQuery.data, selectedFilterId])

  // 選択済みのフィルタあるいはプロジェクトに対応するオブジェクト

  const selectedProjects = React.useMemo(() => {
    return allProjectsQuery.data?.filter((project) => {
      if (selectedFilterId) {
        return selectedFilter?.projectIds.includes(Number(project.id))
      } else {
        return selectedProjectIds.includes(Number(project.id))
      }
    })
  }, [
    allProjectsQuery.data,
    selectedFilter?.projectIds,
    selectedFilterId,
    selectedProjectIds,
  ])

  return {
    targetProjectIds,
    setSelectedProjectsAndFilters,
    allFilters: allFilterListQuery.data || [],
    allProjects: allProjectsQuery.data || [],
    selectedFilter,
    selectedProjects,
    selectedProjectIds,
    isLoading:
      allFilterListQuery.isInitialLoading || allProjectsQuery.isInitialLoading,
  }
}

export const ProjectMultiSelector = () => {
  const {
    allProjects,
    allFilters,
    selectedFilter,
    selectedProjects,
    selectedProjectIds,
    setSelectedProjectsAndFilters,
    isLoading,
  } = useSelectedProjectsAndFiltersQuery()
  // AutoCompleteInputの候補(プロジェクトのみ選択可能でフィルタは選択できない)
  const choices: Choice[] = React.useMemo(() => {
    const alreadySelectedIds = (selectedProjects || []).map((p) => p.id)
    return allProjects
      .filter((project) => {
        return !alreadySelectedIds.includes(project.id)
      })
      .map(
        (project) =>
          ({
            id: String(project.id),
            text: project.name,
            color: project.color,
            type: 'project',
            element: (
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <ColorMark color={project.color}></ColorMark>
                <Text>{project.name}</Text>
              </HStack>
            ),
          }) as Choice,
      )
  }, [allProjects, selectedProjects])

  const onClickRemove = React.useCallback(
    (input: { projectId: number }) => {
      setSelectedProjectsAndFilters({
        type: 'project',
        projectIds: (selectedProjects || [])
          .map((p) => p.id)
          .filter((id) => id !== Number(input.projectId)),
      })
    },
    [selectedProjects, setSelectedProjectsAndFilters],
  )

  const selectableProjectFilterTags: React.JSX.Element = React.useMemo(() => {
    const tags = (allFilters || []).map((projectFilter) => {
      const isSelected = selectedFilter?.id === projectFilter.id
      return (
        <Tooltip
          key={`tag-projectFilter-${projectFilter.id}`}
          label={projectFilter.name}
          openDelay={400}
          hasArrow
        >
          <Tag
            size={'md'}
            variant={isSelected ? 'solid' : 'outline'}
            _hover={{ bgColor: isSelected ? 'blue.500' : 'gray.100' }}
            _active={{ bgColor: isSelected ? 'blue.500' : 'gray.200' }}
            bgColor={isSelected ? 'blue.500' : 'inherit'}
            colorScheme={'gray'}
            maxW={'280px'}
            margin={'1'}
            cursor={isSelected ? 'default' : 'pointer'}
            onClick={() => {
              setSelectedProjectsAndFilters({
                type: 'filter',
                filterId: Number(projectFilter.id),
              })
            }}
          >
            <TagLabel>{projectFilter.name}</TagLabel>
          </Tag>
        </Tooltip>
      )
    })
    return <Box w={'full'}>{tags}</Box>
  }, [allFilters, selectedFilter?.id, setSelectedProjectsAndFilters])

  const selectedProjectTags: React.JSX.Element[] = React.useMemo(() => {
    return (selectedProjects || []).map((project) => (
      <Tooltip
        label={project.name}
        openDelay={400}
        key={`tag-project-${project.id}`}
      >
        <Tag size={'md'} variant={'outline'} m={'1'} maxW={'280px'}>
          <HStack spacing={'1.5'}>
            <ColorMark color={project.color} />
            <TagLabel>{project.name}</TagLabel>
          </HStack>
          <TagCloseButton
            onClick={() => onClickRemove({ projectId: Number(project.id) })}
          />
        </Tag>
      </Tooltip>
    ))
  }, [onClickRemove, selectedProjects])

  const SelectedProjectOrFilterView = React.useMemo(() => {
    return (
      <Box
        px={'8px'}
        minW={'fit-content'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {selectedProjectTags}
      </Box>
    )
  }, [selectedProjectTags])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      bgColor={'white'}
    >
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
        >
          <Heading fontSize={'sm'} fontWeight={'bold'} color={'gray.700'}>
            選択中のプロジェクト/フィルタ
          </Heading>
          {SelectedProjectOrFilterView}
        </VStack>

        <Box w={'20em'}>
          <AutoCompleteInput
            type={'input'}
            emptyText={'プロジェクトの候補がありません'}
            choices={choices}
            defaultValue={undefined}
            onChange={async (choice) => {
              // filterを選択していたとしても、projectを追加すると選択中のfilterは解除されて紐づいていたprojectだけが残る
              setSelectedProjectsAndFilters({
                type: 'project',
                projectIds: (selectedProjects || [])
                  ?.map((p) => Number(p.id))
                  .concat(Number(choice.id))
                  .uniq(),
              })
            }}
            placeholder={'プロジェクト名で絞り込み'}
          />
        </Box>
      </HStack>
      <HStack w={'full'} gap={'3'}>
        <Text
          fontSize={'sm'}
          fontWeight={'normal'}
          wordBreak={'keep-all'}
          color={'gray.700'}
        >
          作成済みフィルタ
        </Text>
        {selectableProjectFilterTags}
      </HStack>
    </VStack>
  )
}
