import { TaskAttribute } from '@prisma/client'

export const taskAttributeLabel: {
  [key in TaskAttribute]: {
    label: string
    color: string
    bgColor: string
  }
} = {
  sales: { label: '売上計上', color: 'orange.900', bgColor: 'orange.200' },
  expense: { label: '費用計上', color: 'pink.900', bgColor: 'orange.200' },
  salary: { label: '費用計上(給与)', color: 'blue.900', bgColor: 'blue.200' },
  cash_and_deposit: {
    label: '現預金入出金計上',
    color: 'green.900',
    bgColor: 'green.200',
  },
  payment: { label: '支払処理', color: 'cyan.900', bgColor: 'red.200' },
  other: { label: 'その他', color: 'gray.900', bgColor: 'gray.200' },
}
