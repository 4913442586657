import { PagePath } from '@/PagePath'
import { useSelfState } from '@/components/providers/SelfProvider'
import {
  Spinner,
  Box,
  HStack,
  VStack,
  Text,
  Divider,
  Button,
} from '@chakra-ui/react'
import { SidebarNavigationButton } from './NavigationButton'
import { trpc } from '@/lib/trpc'
import { LinkButton } from '../LinkButton'
import React from 'react'
import { ProjectWithColor } from '../ProjectWithColor'

export const ProjectList = () => {
  const { self } = useSelfState()
  const [isFolded, setIsFolded] = React.useState(true)
  const projectsQuery = trpc.project.list.useQuery(
    {
      filter: {
        status: 'active',
        // assignedPmId: self?.role === 'pm' ? self.id : undefined,
      },
    },
    {
      enabled: !!self,
    },
  )

  const list = React.useMemo(() => {
    const PROJECT_SHOW_NUM = 5
    const { projects, hasMore } = (() => {
      const projects = projectsQuery.data || []
      if (isFolded) {
        return {
          projects: projects.slice(0, PROJECT_SHOW_NUM),
          hasMore: projects.length > PROJECT_SHOW_NUM,
        }
      } else {
        return { projects, hasMore: false }
      }
    })()

    return (
      <VStack
        w={'full'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        spacing={'0'}
        minH={projects.length > 0 ? '10em' : '3em'}
      >
        {projects.map((project) => (
          <SidebarNavigationButton
            key={project.id}
            textAlign={'left'}
            w={'full'}
            justifyContent={'flex-start'}
            to={PagePath.project.show(project.id)}
          >
            <ProjectWithColor
              project={project}
              fontSize={'sm'}
              fontWeight={'normal'}
            />
          </SidebarNavigationButton>
        ))}
        {hasMore && (
          <Button
            variant={'transparent-clickable'}
            onClick={() => setIsFolded(!isFolded)}
          >
            <Text color={'gray.600'} textDecoration={'underline'}>
              ...すべてみる
            </Text>
          </Button>
        )}
      </VStack>
    )
  }, [isFolded, projectsQuery.data])

  if (projectsQuery.isLoading) {
    return <Spinner />
  }

  return (
    <VStack
      w={'full'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      spacing={'4px'}
    >
      <HStack w={'full'} alignItems={'center'} justifyContent={'space-between'}>
        <Text whiteSpace={'nowrap'} flexGrow={4}>
          プロジェクト
        </Text>
        {self?.role !== 'member' && (
          <LinkButton
            w={'fit-content'}
            flexGrow={0}
            variant={'outline'}
            colorScheme={'gray'}
            color={'gray.400'}
            size={'xs'}
            to={PagePath.project.new}
          >
            {/* <MdOutlineAdd /> */}
            <Text>+ 追加</Text>
          </LinkButton>
        )}
      </HStack>
      <Divider />
      {list}
    </VStack>
  )
}
