import {
  HStack,
  VStack,
  Text,
  Button,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Checkbox,
  CheckboxGroup,
  useToast,
  Alert,
  Box,
} from '@chakra-ui/react'
import { RouterOutput, trpc } from '@/lib/trpc'
import React from 'react'
import { ColorMark } from '../ColorMark'
import { useDialog } from '../Dialog'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { logger } from '@/lib/logger'

type FormInput = {
  name: string
  projectIds: { projectId: number }[]
}
type ProjectFilter = RouterOutput['projectFilter']['list'][number]

export const DialogToUpsertProjectFilter: React.FC<{
  projectFilter: ProjectFilter | undefined
}> = ({ projectFilter }) => {
  const { closeDialog } = useDialog()
  const mutation = trpc.projectFilter.upsert.useMutation({
    onError: (e) => {
      logger.error('DialogToUpsertProjectFilter', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const projectsQuery = trpc.project.list.useQuery({
    filter: {},
  })
  const form = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: projectFilter
      ? {
          name: projectFilter.name,
          projectIds: projectFilter.projectIds.map((id) => ({
            projectId: Number(id),
          })),
        }
      : {
          name: '',
          projectIds: [],
        },
  })
  const toast = useToast()
  const util = trpc.useContext()
  const onSubmit = React.useCallback(
    async (data: FormInput) => {
      const input = {
        name: data.name,
        projectIds: data.projectIds.map((p) => p.projectId),
      }
      const payload = projectFilter
        ? {
            id: Number(projectFilter.id),
            ...input,
          }
        : input
      try {
        toast.closeAll()
        const result = await mutation.mutateAsync(payload, {
          onSuccess: () => {
            util.projectFilter.list.invalidate()
          },
        })
        if (result.ok) {
          closeDialog()
          toast({
            title: 'フィルタの保存に成功しました',
            status: 'success',
          })
        } else {
          logger.error(`フィルタの保存に失敗しました`, { result, payload })
          toast({
            title: `フィルタの保存に失敗しました ${result.errorMessage}`,
            status: 'error',
          })
        }
      } catch (error) {
        logger.error(`フィルタの保存に失敗しました`, { error, payload })
        toast({
          title: `フィルタの保存に失敗しました ${error}`,
          status: 'error',
        })
      }
    },
    [closeDialog, mutation, projectFilter, toast, util.projectFilter.list],
  )

  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            {projectFilter ? 'フィルタを追加' : 'フィルタを編集'}
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            <Editor
              projectFilter={projectFilter}
              projects={projectsQuery.data || []}
            />
          </FormProvider>
        </form>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}></AlertDialogFooter>
    </AlertDialogContent>
  )
}

const Editor: React.FC<{
  projectFilter: ProjectFilter | undefined
  projects: RouterOutput['project']['list']
}> = ({ projectFilter, projects }) => {
  const form = useFormContext<FormInput>()
  const { control, formState, register, getValues } = form
  const { append, remove, fields } = useFieldArray<FormInput>({
    control: form.control,
    name: 'projectIds',
  })
  const dialog = useDialog()
  const deleteMutation = trpc.projectFilter.delete.useMutation()
  const onClickToShowDeleteDialog = React.useCallback(() => {
    if (!projectFilter) {
      return
    }
    dialog.openDialog({
      closeOnOverlayClick: true,
      size: 'xl',
      body: (
        <DeleteProjectFilterConfirmationDialog projectFilter={projectFilter} />
      ),
    })
  }, [dialog, projectFilter])
  const checkedProjectIds = React.useMemo(
    () => fields.map((p) => p.projectId),
    [fields],
  )
  const validationError: string | null = React.useMemo(() => {
    if (!formState.isValid) {
      return (
        formState.errors.name?.message ||
        formState.errors.projectIds?.message ||
        ''
      )
    }
    if (getValues('name').length > 0 && checkedProjectIds.length === 0) {
      return 'プロジェクトを選択してください'
    }
    return null
  }, [
    checkedProjectIds.length,
    formState.errors.name?.message,
    formState.errors.projectIds?.message,
    formState.isValid,
    getValues,
  ])
  return (
    <VStack
      w={'full'}
      justifyContent={'center'}
      borderRadius={'12px'}
      py={'16px'}
      px={'20px'}
      spacing={'20px'}
      bgColor={'white'}
    >
      <FormControl w={'full'} isRequired isInvalid={!!formState.errors.name}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <FormLabel>フィルタ名</FormLabel>
          <Input {...register('name')} />
          <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
        </VStack>
      </FormControl>
      <FormControl w={'full'} isInvalid={!!formState.errors.projectIds}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <FormLabel>プロジェクト選択</FormLabel>
          <CheckboxGroup>
            {projects.map((project, i) => {
              return (
                <Checkbox
                  key={`checkbox-${project.id}`}
                  name={`projectIds.${i}`}
                  isChecked={checkedProjectIds.includes(project.id)}
                  onChange={() => {
                    const idx = checkedProjectIds.findIndex(
                      (p) => p === project.id,
                    )
                    if (idx >= 0) {
                      remove(idx)
                    } else {
                      append({ projectId: project.id })
                    }
                  }}
                >
                  <HStack
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    w={'full'}
                  >
                    <ColorMark color={project.color} />
                    <Text>{project.name}</Text>
                  </HStack>
                </Checkbox>
              )
            })}
          </CheckboxGroup>
          <FormErrorMessage>
            {formState.errors.projectIds?.message}
          </FormErrorMessage>
        </VStack>
      </FormControl>
      <Alert
        status={'warning'}
        w={'full'}
        variant={'subtle'}
        px={'16px'}
        py={'12px'}
        bgColor={'orange.50'}
        color={'gray.700'}
        boxShadow={'base'}
      >
        フィルタを保存すると、選択したプロジェクト「すべて」に参加中のメンバーに公開されます。
      </Alert>
      {validationError && <Text>{validationError}</Text>}
      <HStack w={'full'} justifyContent={'space-between'}>
        {projectFilter ? (
          <Button variant={'white-red'} onClick={onClickToShowDeleteDialog}>
            このフィルタを削除
          </Button>
        ) : (
          <Box />
        )}
        <Button
          variant={'blue-fill'}
          type={'submit'}
          isDisabled={!!validationError}
          isLoading={formState.isSubmitting}
        >
          保存
        </Button>
      </HStack>
    </VStack>
  )
}

const DeleteProjectFilterConfirmationDialog: React.FC<{
  projectFilter: ProjectFilter
}> = ({ projectFilter }) => {
  const util = trpc.useContext()
  const toast = useToast()
  const deleteMutation = trpc.projectFilter.delete.useMutation({
    onSuccess: async () => {
      await util.projectFilter.list.invalidate()
      toast({
        title: 'フィルタを削除しました',
        status: 'success',
      })
    },
  })
  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            フィルタを削除
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          spacing={'16px'}
          px={'24px'}
          py={'8px'}
        >
          <VStack alignItems={'flex-start'} p={0}>
            <Text fontWeight={'bold'}>フィルタ名</Text>
            <Text>{projectFilter.name}</Text>
          </VStack>
          <Alert
            status={'warning'}
            w={'full'}
            variant={'subtle'}
            boxShadow={'base'}
            px={'16px'}
            py={'12px'}
          >
            このフィルタを使えるメンバー全員がこのフィルタを利用できなくなります。
          </Alert>
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              colorScheme={'red'}
              onClick={() =>
                deleteMutation.mutateAsync({ id: Number(projectFilter.id) })
              }
              isLoading={deleteMutation.isLoading}
            >
              削除を実行
            </Button>
          </HStack>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}></AlertDialogFooter>
    </AlertDialogContent>
  )
}
