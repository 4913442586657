import { RouterOutput, trpc } from '@/lib/trpc'
import { Spinner, VStack, Heading, StackDivider } from '@chakra-ui/react'
import React from 'react'
import type { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { YearMonth, YearMonthContextProvider } from '@/context/yearMonth'
import { toNextMonth, toPrevMonth } from '@app/shared'
import {
  ProjectMultiSelector,
  useSelectedProjectsAndFiltersQuery,
} from '../../ui/ProjectSelector/ProjectMultipleSelector'
import { Layout } from '../../Layout'
import { logger } from '@/lib/logger'
import { ErrorPage } from '@/pages/ErrorPage'
import { useSelfState } from '@/components/providers/SelfProvider'
import { EmptyProjectMessage } from './EmptyProject'
import { DashboardForPM } from './DashboardPM'
import { DashboardForMember } from './DashboardMember'
import { RefreshButton } from '@/components/ui/RefreshButton'

export const Dashboard: AuthenticatedComponent = () => {
  const { self } = useSelfState()
  const { isLoading, selectedFilter, selectedProjects } =
    useSelectedProjectsAndFiltersQuery({ urlParamSync: true })

  if (isLoading) {
    return <Spinner />
  }
  if (!self) {
    return <ErrorPage errorMessage={'ユーザー情報が取得できませんでした'} />
  }

  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        paddingBottom={'120px'}
        gap={'14'}
      >
        <RefreshButton />
        <VStack
          alignItems={'flex-start'}
          w={'full'}
          borderRadius={'4px'}
          bgColor={'white'}
          padding={'4'}
          boxShadow={'base'}
          divider={<StackDivider borderColor='gray.200' />}
          gap={'2'} // dividerがmargin上下8px取っているのを加味している
        >
          <Heading size={'lg'}>ダッシュボード</Heading>

          <ProjectMultiSelector />
        </VStack>
        <YearMonthContextProvider options={{ saveInUrl: false }}>
          {(yearMonth) => (
            <React.Suspense fallback={<Spinner />}>
              <DashboardBase
                projects={selectedProjects}
                yearMonth={yearMonth}
              />
            </React.Suspense>
          )}
        </YearMonthContextProvider>
      </VStack>
    </Layout>
  )
}
const DashboardBase: React.FC<{
  projects: RouterOutput['project']['list'] | undefined
  yearMonth: YearMonth
}> = ({ projects, yearMonth }) => {
  const projectIds = React.useMemo(
    () => projects?.map((p) => Number(p.id)) || [],
    [projects],
  )

  // イベントカレンダーは前後1ヶ月分
  const projectMonths = trpc.projectMonth.list.useQuery(
    {
      filter: {
        projectIds: projectIds,
        yearMonths: [toPrevMonth(yearMonth), yearMonth, toNextMonth(yearMonth)],
      },
    },
    {
      enabled: projectIds.length > 0,
    },
  )
  const { self } = useSelfState()

  logger.info(`DashboardBase`, {
    projects,
    self,
  })

  if (!self || projectMonths.isInitialLoading) {
    return <Spinner />
  }
  if (projectMonths.error) {
    return <ErrorPage errorMessage={projectMonths.error.message} />
  }
  if (!projects || projects.length === 0) {
    return <EmptyProjectMessage />
  }

  return (
    <VStack alignItems={'flex-start'} justifyContent={'flex-start'} w={'full'}>
      {/* <YearMonthSelector /> */}
      {self.role === 'member' ? (
        <DashboardForMember
          projects={projects}
          projectMonths={projectMonths.data || []}
        />
      ) : (
        <DashboardForPM
          projects={projects}
          projectMonths={projectMonths.data || []}
        />
      )}
    </VStack>
  )
}
