import { UseTableSort } from '@/components/ui/SortableTableHeader'
import { RouterOutput } from '@/lib/trpc'
import { yearMonthToDate } from '@app/shared'

export type TaskListSort = {
  field: 'deadlineBusinessDate' | 'startBusinessDate' | 'taskNumber' | undefined
  direction: 'asc' | 'desc' | undefined
}
type TaskCard = RouterOutput['taskCard']['list'][number]
export const taskCardComparator =
  (useSort: UseTableSort<TaskListSort>) => (t1: TaskCard, t2: TaskCard) => {
    const { field, direction } = useSort[0]
    if (!field) {
      return 0
    }
    const f1 = t1[field]
    const f2 = t2[field]
    if (f1 === f2) {
      return 0
    }
    if (f1 === null) {
      return 1
    }
    if (f2 === null) {
      return -1
    }

    if (field === 'startBusinessDate' || field === 'deadlineBusinessDate') {
      const t1YearMonth = yearMonthToDate(t1.projectMonth).getTime()
      const t2YearMonth = yearMonthToDate(t2.projectMonth).getTime()
      if (t1YearMonth !== t2YearMonth) {
        return direction === 'asc'
          ? t1YearMonth - t2YearMonth
          : t2YearMonth - t1YearMonth
      }
      if (field === 'startBusinessDate') {
        if (t1.startBusinessDate === t2.startBusinessDate) {
          if (t1.startTimeslotStart === t2.startTimeslotStart) {
            return direction === 'asc'
              ? (t1.startTimeslotEnd || '00:00').localeCompare(
                  t2.startTimeslotEnd || '00:00',
                )
              : (t2.startTimeslotEnd || '00:00').localeCompare(
                  t1.startTimeslotEnd || '00:00',
                )
          } else {
            return direction === 'asc'
              ? (t1.startTimeslotStart || '00:00').localeCompare(
                  t2.startTimeslotStart || '00:00',
                )
              : (t2.startTimeslotStart || '00:00').localeCompare(
                  t1.startTimeslotStart || '00:00',
                )
          }
        } else {
          return direction === 'asc'
            ? t1.startBusinessDate! - t2.startBusinessDate!
            : t2.startBusinessDate! - t1.startBusinessDate!
        }
      } else {
        if (t1.deadlineBusinessDate === t2.deadlineBusinessDate) {
          if (t1.deadlineTimeslotStart === t2.deadlineTimeslotStart) {
            return direction === 'asc'
              ? (t1.deadlineTimeslotEnd || '00:00').localeCompare(
                  t2.deadlineTimeslotEnd || '00:00',
                )
              : (t2.deadlineTimeslotEnd || '00:00').localeCompare(
                  t1.deadlineTimeslotEnd || '00:00',
                )
          } else {
            return direction === 'asc'
              ? (t1.deadlineTimeslotStart || '00:00').localeCompare(
                  t2.deadlineTimeslotStart || '00:00',
                )
              : (t2.deadlineTimeslotStart || '00:00').localeCompare(
                  t1.deadlineTimeslotStart || '00:00',
                )
          }
        } else {
          return direction === 'asc'
            ? t1.deadlineBusinessDate! - t2.deadlineBusinessDate!
            : t2.deadlineBusinessDate! - t1.deadlineBusinessDate!
        }
      }
    }
    if (typeof f1 === 'number' && typeof f2 === 'number') {
      return direction === 'asc' ? f1 - f2 : f2 - f1
    }
    if (typeof f1 === 'string' && typeof f2 === 'string') {
      return direction === 'asc' ? f1.localeCompare(f2) : f2.localeCompare(f1)
    }

    // TODO: データが無いときのsortが適当
    if (!f2) {
      return 1
    } else {
      return -1
    }
  }
