import React, { useTransition } from 'react'
import { addMonths } from 'date-fns'
import { atom, useAtom } from 'jotai'
import { locationAtom } from '@/helper/location'
import { atomWithLocation } from 'jotai-location'

export const yearMonthFromDate = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  } as YearMonth
}
export const yearMonthToDate = (yearMonth: YearMonth) => {
  return new Date(yearMonth.year, yearMonth.month - 1)
}

export type YearMonth = {
  year:
    | 2020
    | 2021
    | 2022
    | 2023
    | 2024
    | 2025
    | 2026
    | 2027
    | 2028
    | 2029
    | 2030
    | number
  month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number
}
// 面倒なのでcontextにdispatcherを持たせている
type YearMonthContextType = {
  yearMonth: YearMonth
  dispatchers: {
    set: (yearMonth: YearMonth) => void
    reset: () => void
    nextMonth: () => void
    prevMonth: () => void
  }
}

const stub = () => {
  throw new Error()
}
const YearMonthContext = React.createContext<YearMonthContextType>({
  yearMonth: yearMonthFromDate(new Date()),
  dispatchers: {
    set: stub,
    reset: stub,
    nextMonth: stub,
    prevMonth: stub,
  },
})

export const useYearMonthContext = () => React.useContext(YearMonthContext)

type YearMonthContextProviderOptions = {
  saveInUrl: boolean // default true
}
export const YearMonthContextProvider: React.FC<{
  // children: React.ReactNode
  children: (a: YearMonth) => React.ReactNode
  options?: YearMonthContextProviderOptions
}> = ({ children, options }) => {
  const { yearMonth, setYearMonth, setPrevMonth, setNextMonth } =
    useYearMonth(options)
  const reset = React.useCallback(() => {
    setYearMonth(yearMonthFromDate(new Date()))
  }, [setYearMonth])

  return (
    <YearMonthContext.Provider
      value={{
        yearMonth,
        dispatchers: {
          set: setYearMonth,
          reset,
          nextMonth: setNextMonth,
          prevMonth: setPrevMonth,
        },
      }}
    >
      {children(yearMonth)}
    </YearMonthContext.Provider>
  )
}

const yearMonthAtomWithoutLocation = atom<YearMonth>(
  yearMonthFromDate(new Date()),
)

const yearMonthAtomLocation = atom<YearMonth, YearMonth[], void>(
  (get) => {
    // const sp = new URLSearchParams(window.location.search)
    const sp = (() => {
      const sp = get(locationAtom).searchParams
      if (sp && sp.has('year') && sp.has('month')) {
        return sp
      }
      return new URLSearchParams(window.location.search)
    })()
    const ym = {
      year: sp?.get('year'),
      month: sp?.get('month'),
    }
    if (
      ym.year &&
      ym.month &&
      !(Number.isNaN(Number(ym.year)) || Number.isNaN(Number(ym.month)))
    ) {
      return {
        year: Number(ym.year),
        month: Number(ym.month),
      }
    } else {
      const ym = yearMonthFromDate(new Date())
      return ym
    }
  },
  (get, set, update) => {
    set(locationAtom, (prev) => {
      const searchParams = prev.searchParams || new URLSearchParams()
      searchParams.set('year', String(update.year))
      searchParams.set('month', String(update.month))
      return {
        ...prev,
        searchParams,
      }
    })
  },
)

const useYearMonth = (options?: { saveInUrl: boolean }) => {
  const saveInUrl = options?.saveInUrl !== false
  const [isPending, startTransition] = useTransition()
  const [location, setLocation] = useAtom(locationAtom)
  const setSearchParams = React.useCallback(
    (fn: (prev: URLSearchParams) => URLSearchParams) => {
      setLocation((prev) => {
        const searchParams = fn(prev.searchParams || new URLSearchParams())
        return {
          ...prev,
          searchParams,
        }
      })
    },
    [setLocation],
  )

  const fromUrlParams = React.useCallback((): YearMonth | undefined => {
    const urlParams = (() => {
      // locationAtomに残っている状態とブラウザの状態が異なる場合があるので、
      // その場合はブラウザの状態を優先する
      if (location.pathname !== window.location.pathname) {
        return new URLSearchParams(window.location.search)
      } else {
        return location.searchParams || new URLSearchParams()
      }
    })()
    if (urlParams.has('year') && urlParams.has('month')) {
      const ym = {
        year: Number(urlParams.get('year')),
        month: Number(urlParams.get('month')),
      }
      if (
        !Number.isNaN(ym.year) &&
        !Number.isNaN(ym.month) &&
        ym.year >= 2000 &&
        ym.year < 3000 &&
        ym.month >= 1 &&
        ym.month <= 12
      ) {
        return ym
      }
    }
  }, [location.pathname, location.searchParams])
  const stateAtom = React.useMemo(() => {
    return saveInUrl ? yearMonthAtomLocation : yearMonthAtomWithoutLocation
  }, [saveInUrl])
  const [yearMonth, setYearMonth] = useAtom(stateAtom)
  React.useEffect(() => {
    if (!saveInUrl) {
      return
    }
    const ym = fromUrlParams()
    if (!ym) {
      const x = yearMonthFromDate(new Date())
      startTransition(() => {
        setSearchParams((prev) => {
          prev.set('year', String(x.year))
          prev.set('month', String(x.month))
          return prev
        })
      })
    }
  }, [fromUrlParams, saveInUrl, setSearchParams])

  const setNextMonth = React.useCallback(() => {
    const next = addMonths(yearMonthToDate(yearMonth), 1)
    if (!saveInUrl) {
      setSearchParams((prev) => {
        prev.set('year', String(next.getFullYear()))
        prev.set('month', String(next.getMonth() + 1))
        return prev
      })
    }
    setYearMonth({
      year: next.getFullYear(),
      month: next.getMonth() + 1,
    })
  }, [saveInUrl, setSearchParams, setYearMonth, yearMonth])

  const setPrevMonth = React.useCallback(() => {
    const next = addMonths(yearMonthToDate(yearMonth), -1)
    if (!saveInUrl) {
      setSearchParams((prev) => {
        prev.set('year', String(next.getFullYear()))
        prev.set('month', String(next.getMonth() + 1))
        return prev
      })
    }
    setYearMonth({
      year: next.getFullYear(),
      month: next.getMonth() + 1,
    })
  }, [saveInUrl, setSearchParams, setYearMonth, yearMonth])

  React.useEffect(() => {
    // 戻るボタン等でURLが変わったときにstateとURLを同期する
    if (saveInUrl) {
      const ym = fromUrlParams()
      if (ym && (ym.year !== yearMonth.year || ym.month !== yearMonth.month)) {
        startTransition(() => {
          setYearMonth(ym)
        })
      }
    }
  }, [yearMonth, setSearchParams, fromUrlParams, setYearMonth, saveInUrl])

  return {
    yearMonth,
    setYearMonth,
    setNextMonth,
    setPrevMonth,
  }
}
