import { HStack, Text } from '@chakra-ui/react'
import { SidebarNavigationButton } from './NavigationButton'

export const SidebarLink: React.FC<{
  to: string
  icon: React.ReactElement
  text: string
}> = ({ to, icon, text }) => {
  return (
    <SidebarNavigationButton
      justifyContent={'flex-start'}
      w={'full'}
      to={to}
      p={0}
    >
      <HStack
        w={'full'}
        px={0}
        m={0}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        {icon}
        <Text>{text}</Text>
      </HStack>
    </SidebarNavigationButton>
  )
}
