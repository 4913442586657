import { Layout } from '@/components/Layout'
import { useSelfState } from '@/components/providers/SelfProvider'
import { useDialog } from '@/components/ui/Dialog'
import {
  ProjectSingleSelector,
  WithSingleProjectSelection,
} from '@/components/ui/ProjectSelector/ProjectSingleSelector'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { useTaskCardTableFilter } from '@/components/ui/TaskCardFilter'
import {
  TaskCardTable,
  useTaskCardSelection,
} from '@/components/ui/TaskCardTable'
import { YearMonthSelector } from '@/components/ui/YearMonthSelector'
import { trpc } from '@/lib/trpc'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { ErrorPage } from '@/pages/ErrorPage'
import type { TaskCardFilterSchema } from '@app/api/src/usecase/taskCard/predicates'
import { BusinessDates, YearMonth } from '@app/shared'
import {
  Text,
  VStack,
  Divider,
  Spinner,
  Heading,
  Box,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { DialogToChoiceHowToCreate } from './DialogToChoiceHowToCreate'
import { TaskCardBulkAssignChangeDialog } from './TaskCardBulkAssignChangeDialog'
import { User } from '@prisma/client'
import { RefreshButton } from '@/components/ui/RefreshButton'

export const TaskCardList: AuthenticatedComponent<{
  yearMonth: YearMonth
}> = ({ yearMonth }) => {
  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        w={'full'}
        borderRadius={'4px'}
        bgColor={'white'}
        padding={'16px'}
        boxShadow={'base'}
      >
        <RefreshButton />
        <Box w={'fit-content'}>
          <YearMonthSelector />
        </Box>
        <Heading size={'lg'} fontWeight={'bold'}>
          タスクカード一覧
        </Heading>
        <Divider />
        <ProjectSingleSelector />
      </VStack>
      <WithSingleProjectSelection>
        {(selectedProject) => {
          return (
            <TaskCardListBody project={selectedProject} yearMonth={yearMonth} />
          )
        }}
      </WithSingleProjectSelection>
    </Layout>
  )
}

const TaskCardListBody: React.FC<{
  project: SelectedProject
  yearMonth: YearMonth
}> = ({ project, yearMonth }) => {
  const { self } = useSelfState()
  const usersQuery = trpc.user.list.useQuery({
    filter: {
      accessibleProjectId: project.id,
      assign: { projectId: project.id, yearMonth },
    },
  })
  const accessibleUsersQuery = trpc.user.list.useQuery({
    filter: {
      accessibleProjectId: project.id,
    },
  })
  const { TaskCardFilterComponent, taskCardsFilter: taskCardsFilterRest } =
    useTaskCardTableFilter({
      userCandidates: usersQuery.data || [],
      enabled: {
        attribute: true,
        assignedToUserId: true,
        outputStatus: true,
        onlyDelayed: true,
      },
    })
  const taskCardsFilter = React.useMemo<TaskCardFilterSchema>(() => {
    return {
      projectIds: [project.id],
      yearMonths: [yearMonth],
      ...taskCardsFilterRest.condition,
    }
  }, [project.id, taskCardsFilterRest, yearMonth])

  const projectMonthQuery = trpc.projectMonth.show.useQuery({
    projectId: project.id,
    yearMonth,
  })

  const businessDatesPerProject = React.useMemo(() => {
    if (projectMonthQuery.isLoading) {
      return
    }
    const bd = (() => {
      if (projectMonthQuery.data) {
        return BusinessDates.of(projectMonthQuery.data)
      } else {
        return BusinessDates.fallback(yearMonth, project)
      }
    })()
    return {
      [project.id]: { [yearMonth.year]: { [yearMonth.month]: bd } },
    }
  }, [project, projectMonthQuery.data, projectMonthQuery.isLoading, yearMonth])

  const dialog = useDialog()
  const onClickToCreate = React.useCallback(() => {
    dialog.closeDialog()
    dialog.openDialog({
      size: 'lg',
      body: (
        <DialogToChoiceHowToCreate
          projectId={project.id}
          yearMonth={yearMonth}
        />
      ),
      closeOnOverlayClick: true,
    })
  }, [dialog, project.id, yearMonth])

  // タスクカードの担当者一括変更まわり
  const taskCardSelection = useTaskCardSelection()
  const toast = useToast()
  const util = trpc.useContext()
  const mutation = trpc.taskCard.bulkUpdate.useMutation({
    onSuccess: async (result) => {
      if (result.ok) {
        toast({
          title: '担当者を一括変更しました',
          status: 'success',
        })
        try {
          await util.taskCard.list.invalidate()
        } finally {
          taskCardSelection.clear()
          dialog.closeDialog()
        }
      } else {
        toast({
          title: `担当者の変更に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      toast({
        title: `担当者の変更に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  const onSubmit = React.useCallback(
    (newUser: User) => {
      return mutation.mutateAsync({
        projectId: project.id,
        taskCardIds: taskCardSelection.selectedTaskCards.map(
          (taskCard) => taskCard.id,
        ),
        assignedToUserId: newUser.id,
      })
    },
    [mutation, project.id, taskCardSelection.selectedTaskCards],
  )
  const onClickToBulkAssignChange = React.useCallback(() => {
    dialog.openDialog({
      size: 'lg',
      body: (
        <TaskCardBulkAssignChangeDialog
          project={project}
          selectedTaskCards={taskCardSelection.selectedTaskCards}
          users={accessibleUsersQuery.data || []}
          onSubmit={onSubmit}
        />
      ),
      closeOnOverlayClick: true,
    })
  }, [
    accessibleUsersQuery.data,
    dialog,
    onSubmit,
    project,
    taskCardSelection.selectedTaskCards,
  ])

  if (taskCardsFilter.projectIds[0] !== project.id) {
    return (
      <ErrorPage
        errorMessage={`予期しないエラーです. 選択したプロジェクトとデータ取得条件が異なります(${taskCardsFilter.projectIds[0]} !== ${project.id}`}
      />
    )
  }

  if (projectMonthQuery.isLoading) {
    return <Spinner />
  }
  if (!businessDatesPerProject) {
    return <Text>プロジェクトが見つかりません</Text>
  }

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      spacing={'24px'}
      mt={'40px'}
      padding={'16px'}
    >
      {self?.role !== 'member' && ( // PM以上でないと操作できない
        <HStack w={'full'} justifyContent={'flex-start'}>
          <Button variant={'blue-fill'} size={'sm'} onClick={onClickToCreate}>
            タスクカードの新規作成
          </Button>
          {taskCardSelection.selectedTaskCards.length > 0 ? (
            <>
              <Button
                size={'sm'}
                colorScheme='red'
                onClick={onClickToBulkAssignChange}
              >
                {taskCardSelection.selectedTaskCards.length}
                件の選択したタスクカードの担当者を変更する
              </Button>
              <Button
                size={'sm'}
                variant='white-red'
                onClick={taskCardSelection.clear}
              >
                選択をキャンセルする
              </Button>
            </>
          ) : (
            <Button size={'sm'} isDisabled colorScheme='gray'>
              選択したタスクカードの担当者変更
            </Button>
          )}
        </HStack>
      )}
      <TaskCardTable
        type={'filter'}
        paginationPosition='both'
        projects={[project]}
        businessDatesPerProject={businessDatesPerProject}
        taskCardsFilter={taskCardsFilter}
        taskCardFilterComponent={TaskCardFilterComponent}
        taskCardSelection={
          self?.role !== 'member' ? taskCardSelection : undefined
        }
      />
    </VStack>
  )
}
