import { PagePath } from '@/PagePath'
import { firebaseAuthDispatcher } from '@/lib/firebaseAuth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelfDispatcher } from '../providers/SelfProvider'

export const LogOut = () => {
  const navigate = useNavigate()
  const { clear } = useSelfDispatcher()
  React.useEffect(() => {
    clear()
    firebaseAuthDispatcher.logout().then(() => {
      navigate(PagePath.login)
    })
  }, [clear, navigate])
  return <></>
}
