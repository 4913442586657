import { logger } from '@/lib/logger'
import type { ProjectYearlyEventDates } from '@app/api/src/model/prisma'
import { ProjectCreateSchema } from '@app/api/src/usecase/project/createProject'
import { ProjectUpdateSchema } from '@app/api/src/usecase/project/updateProject'
import { dateTimeFormatter } from '@app/shared'
import {
  Text,
  UnorderedList,
  ListItem,
  HStack,
  Button,
  VStack,
  FormControl,
  Input,
} from '@chakra-ui/react'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { MdOutlineDelete, MdOutlineAdd } from 'react-icons/md'

export const ProjectYearlyEventsEditor: React.FC = () => {
  const form = useFormContext<ProjectUpdateSchema | ProjectCreateSchema>()

  const {
    fields: events,
    append,
    remove,
  } = useFieldArray({
    name: 'yearlyEventDates',
    control: form.control,
  })

  const body = React.useMemo(() => {
    if (events.length > 0) {
      return (
        <UnorderedList spacing={'12px'}>
          {events.map(({ date: dateString, name }, idx) => {
            const date = dateTimeFormatter.jst.fromString(dateString)
            return (
              <ListItem key={`yearly-event-${idx}`}>
                <HStack alignItems={'center'} justifyContent={'flex-start'}>
                  <Text fontWeight={'bold'}>{name}</Text>
                  <Text>{dateTimeFormatter.jst['YYYY/MM/DD(E)'](date)}</Text>
                  <Button
                    size={'sm'}
                    p={0}
                    // isLoading={mutation.isLoading}
                    onClick={() => {
                      remove(idx)
                      // return mutation.mutateAsync()
                    }}
                  >
                    <MdOutlineDelete size={'16px'} />
                  </Button>
                </HStack>
              </ListItem>
            )
          })}
        </UnorderedList>
      )
    } else {
      return <Text>未設定</Text>
    }
  }, [events, remove])

  const [input, setInput] = React.useState<
    ProjectYearlyEventDates[number] | undefined
  >()

  return (
    <VStack w={'full'} spacing={'16px'} alignItems={'flex-start'}>
      {body}
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        bgColor={'gray.50'}
        p={'16px'}
        spacing={'16px'}
      >
        <FormControl w={'fit-content'}>
          <Input
            type={'date'}
            placeholder={'日付を選択'}
            // w={'3em'}
            value={input?.date || ''}
            onChange={(e) => {
              const value = e.target.value
              setInput((prev) => {
                if (prev) {
                  return { ...prev, date: value }
                } else {
                  return { name: '', date: value }
                }
              })
            }}
          />
        </FormControl>
        <FormControl w={'full'}>
          <Input
            type={'text'}
            placeholder={'年次イベント名'}
            // w={'10em'}
            value={input?.name || ''}
            onChange={(e) => {
              const value = e.target.value
              setInput((prev) => {
                if (prev) {
                  return { ...prev, name: value }
                } else {
                  return { name: value, date: '' }
                }
              })
            }}
          />
        </FormControl>
        <Button
          size={'md'}
          variant={'blue-fill'}
          // p={0}
          isDisabled={Boolean(!input || !input.date || !input.name)}
          onClick={() => {
            logger.info(`onClick`, { input })
            if (input) {
              append(input)
              setInput(undefined)
            }
          }}
        >
          <HStack>
            <MdOutlineAdd size={'16px'} />
            <Text>追加</Text>
          </HStack>
        </Button>
      </HStack>
    </VStack>
  )
}
