/* eslint-disable no-console */

const caller = (e: Error) => {
  try {
    const x = e.stack?.split('at ')
    if (x) {
      return x[x.length - 1].split(' ')[0]
    }
  } catch (e) {}
  return 'unknown'
}

export const logger = {
  debug: (message: string, obj?: any) => {
    if (import.meta.env.DEV) {
      console.log({
        timestamp: new Date().toLocaleString('ja-JP'),
        caller: caller(new Error()),
        message,
        ...obj,
      })
    }
  },
  info: (message: string, obj?: any) => {
    console.info({
      timestamp: new Date().toLocaleString('ja-JP'),
      caller: caller(new Error()),
      message,
      ...obj,
    })
  },
  error: (message: string, error: any, obj?: any) => {
    console.error({
      timestamp: new Date().toLocaleString('ja-JP'),
      message,
      error,
      errorJson: JSON.stringify(error),
      stack: error.stack,
      ...obj,
    })
  },
}
