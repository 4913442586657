import { PagePathParams } from '@/PagePath'
import { TaskCardEdit } from '@/components/pages/taskcard/edit'
import { BackButton } from '@/components/ui/BackButton'
import { YearMonth, YearMonthContextProvider } from '@/context/yearMonth'
import { logger } from '@/lib/logger'
import { trpc } from '@/lib/trpc'
import {
  Authenticated,
  AuthenticatedComponent,
} from '@/pages/AuthenticatedPage'
import { Spinner, useToast } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ErrorPage } from '../ErrorPage'

export const TaskCardEditPage = () => {
  const { taskNumber, projectId } = useParams<PagePathParams['tasks']['show']>()
  if (!taskNumber || !projectId || Number.isNaN(Number(projectId))) {
    return <ErrorPage errorMessage='パラメータが不正です' />
  }

  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <YearMonthContextProvider>
            {(yearMonth) => (
              <TaskCardEditPageInternal
                {...{
                  projectId: Number(projectId),
                  taskNumber,
                  yearMonth,
                  firebaseUser,
                  self,
                }}
              ></TaskCardEditPageInternal>
            )}
          </YearMonthContextProvider>
        )
      }}
    </Authenticated>
  )
}

const TaskCardEditPageInternal: AuthenticatedComponent<{
  projectId: number
  taskNumber: string
  yearMonth: YearMonth
}> = ({ projectId, taskNumber, yearMonth, firebaseUser, self }) => {
  const toast = useToast()
  const query = trpc.taskCard.show.useQuery({
    projectId,
    yearMonth,
    taskNumber,
  })

  if (query.isLoading) {
    return <Spinner />
  }
  if (query.error) {
    return <ErrorPage errorMessage={query.error.message} />
  }
  if (!query.data) {
    return <ErrorPage errorMessage='データが取得できませんでした' />
  }

  return (
    <TaskCardEdit
      firebaseUser={firebaseUser}
      self={self}
      taskCard={query.data}
    />
  )
}
