import { useRouteError } from 'react-router-dom'
import { Box, Text, VStack } from '@chakra-ui/react'
import { logger } from '@/lib/logger'
import { useFirebaseAuthState } from '@/components/providers/FirebaseAuthProvider'
import { ErrorBoundaryProps } from 'react-error-boundary'
import { useSelfState } from '@/components/providers/SelfProvider'
import React from 'react'
import { BackButton } from '@/components/ui/BackButton'

export const ErrorBoundaryPage = ({ error }: { error: ErrorBoundaryProps }) => {
  // const error = useRouteError()
  const authState = useFirebaseAuthState()
  logger.error(`ErrorPage`, { error })

  return (
    <VStack w={'full'} alignItems={'flex-start'} gap={'16px'} padding={'16px'}>
      <h1>エラーが発生しました</h1>
      <Box>{JSON.stringify(authState)}</Box>
      <Box>{`${
        typeof error === 'object' ? JSON.stringify(error) : error
      }`}</Box>
    </VStack>
  )
}

export const ErrorCard: React.FC<{ errorMessage: string }> = ({
  errorMessage,
}) => {
  return (
    <VStack
      alignItems={'flex-start'}
      bgColor={'white'}
      gap={'16px'}
      padding={'32px'}
      boxShadow={'base'}
    >
      <Text fontSize={'xl'} fontWeight={'bold'}>
        エラー: {errorMessage}
      </Text>
    </VStack>
  )
}
export const ErrorPage: React.FC<{ errorMessage?: string }> = ({
  errorMessage,
}) => {
  const error = useRouteError()
  const authState = useFirebaseAuthState()
  const { self, status } = useSelfState()
  logger.error(`ErrorPage`, { error })

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      gap={'16px'}
      py={'54px'}
      px={'32px'}
    >
      {/* <Heading>エラーが発生しました</Heading> */}
      <VStack
        alignItems={'flex-start'}
        bgColor={'white'}
        gap={'16px'}
        padding={'32px'}
        boxShadow={'base'}
      >
        <Text fontSize={'xl'} fontWeight={'bold'}>
          エラーメッセージ: {errorMessage}
        </Text>
        <Box w={'full'}>
          <Text>
            ログイン中のユーザー:{' '}
            {self ? `${self.name}[${self.userAccount.email}]` : '未ログイン'}
          </Text>
          <Text>
            {String(error) !== 'undefined' &&
              `${typeof error === 'object' ? JSON.stringify(error) : error}`}
          </Text>
        </Box>
      </VStack>
      <BackButton />
    </VStack>
  )
}
