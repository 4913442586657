import { SidebarLayout } from '@/components/SidebarLayout'
import { Box, VStack } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export const Page404 = () => {
  return (
    <VStack marginTop={'30px'}>
      <Box fontSize='2xl' fontWeight='bold' textAlign='center'>
        404 NOT FOUND
      </Box>
    </VStack>
  )
}

export const AuthenticatedPage404 = () => {
  const location = useLocation()
  return (
    <SidebarLayout>
      <Box fontSize='2xl' fontWeight='bold' textAlign='left'>
        "{location.pathname}"にはページが存在しません
      </Box>
    </SidebarLayout>
  )
}
