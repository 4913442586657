import { RootLayout } from '@/components/RootLayout'
import { Invite } from '@/components/pages/Invite'
import { Authenticated } from './AuthenticatedPage'

export const InvitePage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => (
        <Invite self={self} firebaseUser={firebaseUser} />
      )}
    </Authenticated>
  )
}
