import { AdminAccounts } from '@/components/pages/admin/accounts'
import { Authenticated } from '../AuthenticatedPage'

export const AdminAccountsPage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => (
        <AdminAccounts self={self} firebaseUser={firebaseUser} />
      )}
    </Authenticated>
  )
}
