import type { AppRouter, RouterInput, RouterOutput } from '@app/api'
export type { AppRouter, RouterInput, RouterOutput } // 間違えにくいようにここでtype import & exportしておく
import superjson from 'superjson'

import {
  CreateTRPCReact,
  createTRPCReact,
  httpBatchLink,
} from '@trpc/react-query'
import { firebaseAuthDispatcher } from './firebaseAuth'
import { logger } from './logger'
import { sleep } from '@app/shared'

export const trpc: CreateTRPCReact<AppRouter, any, null> =
  createTRPCReact<AppRouter>()

const url =
  import.meta.env.VITE_API_TRPC_ENDPOINT || 'http://localhost:3333/trpc'
logger.info(`trpc endpoint is "${url}"`)

export const trpcClient = trpc.createClient({
  transformer: superjson,
  links: [
    httpBatchLink({
      url,
      headers: async () => {
        // await sleep(1000)
        if (firebaseAuthDispatcher.currentUser) {
          const token = await firebaseAuthDispatcher.getIdToken()
          return {
            Authorization: `Bearer ${token}`,
          }
        }
        return {}
      },
    }),
  ],
})
