import { User } from '@prisma/client'
import React from 'react'
import { useFirebaseAuthState } from './FirebaseAuthProvider'
import { RouterOutput, trpc } from '@/lib/trpc'
import { logger } from '@/lib/logger'

const stub = (): never => {
  throw new Error('stub')
}

const initialState: State = {
  status: 'uninitialized',
}
const initialDispatch: Dispatch = {
  refresh: stub,
  clear: stub,
}

type State = {
  status: 'uninitialized' | 'initialized'
  self?: RouterOutput['self']['get']['user']
}

type Dispatch = {
  refresh: () => Promise<void>
  clear: () => void
}

const StateContext = React.createContext<State>({ ...initialState })
const DispatchContext = React.createContext<Dispatch>({ ...initialDispatch })

export const useSelfState = () => React.useContext(StateContext)
export const useSelfDispatcher = () => React.useContext(DispatchContext)

export const SelfProviderContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { state, dispatcher } = useSelf()

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatcher}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useSelf = (): { state: State; dispatcher: Dispatch } => {
  const { initialized, firebaseUser } = useFirebaseAuthState()
  const [firstFetchDone, setFirstFetchDone] = React.useState(false)
  const [state, setState] = React.useState<State>({ ...initialState })
  const { error, remove, fetchStatus, refetch } = trpc.self.get.useQuery(
    undefined,
    {
      enabled: false, // firebase authが終わるのを待ってからfetchするため
    },
  )

  const clear = React.useCallback(() => {
    setState({ status: 'initialized', self: undefined })
    remove()
  }, [remove])

  const refresh = React.useCallback(async () => {
    if (fetchStatus === 'fetching') {
      return
    }
    clear()
    return refetch().then((res) => {
      setState({ status: 'initialized', self: res.data?.user || undefined })
    })
  }, [clear, fetchStatus, refetch])

  React.useEffect(() => {
    if (
      !initialized ||
      !firebaseUser ||
      firstFetchDone ||
      fetchStatus === 'fetching'
    ) {
      return
    }
    logger.info(`[self]fetching user`)
    refetch()
      .then((response) => {
        const { data, error } = response
        if (data?.user) {
          logger.info(`[self]succeeded`)
          setState({ status: 'initialized', self: data.user })
        } else {
          logger.error(`[self]failed`, { error, response })
          setState({ status: 'initialized', self: undefined })
        }
      })
      .catch((e) => {
        logger.error(`[useSelf]fetching user failed`, { e })
        setState({ status: 'initialized', self: undefined })
      })
      .finally(() => {
        setFirstFetchDone(true)
      })
  }, [fetchStatus, initialized, firebaseUser, refetch, firstFetchDone])

  return {
    state: state,
    dispatcher: {
      refresh,
      clear,
    },
  }
}
