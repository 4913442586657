import { PagePathParams } from '@/PagePath'
import { TaskCardTemplateCreate } from '@/components/pages/taskCardTemplate/new'
import { BackButton } from '@/components/ui/BackButton'
import { YearMonth, YearMonthContextProvider } from '@/context/yearMonth'
import { logger } from '@/lib/logger'
import { trpc } from '@/lib/trpc'
import {
  Authenticated,
  AuthenticatedComponent,
} from '@/pages/AuthenticatedPage'
import { Spinner, useToast } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'

export const TaskCardTemplateCreatePage = () => {
  const { projectId } = useParams<PagePathParams['templates']['new']>()
  if (!projectId || Number.isNaN(Number(projectId))) {
    return <>invalid params</>
  }

  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <TaskCardTemplateCreatePageInternal
            {...{
              projectId: Number(projectId),
              firebaseUser,
              self,
            }}
          ></TaskCardTemplateCreatePageInternal>
        )
      }}
    </Authenticated>
  )
}

const TaskCardTemplateCreatePageInternal: AuthenticatedComponent<{
  projectId: number
}> = ({ projectId, firebaseUser, self }) => {
  const toast = useToast()
  const query = trpc.project.show.useQuery({
    id: projectId,
  })
  React.useEffect(() => {
    if (query.error) {
      toast({
        title: `error ${query.error.message}`,
        status: 'error',
      })
    }
  }, [query.error, toast])

  if (query.isLoading) {
    return <Spinner />
  }
  logger.info(`query`, { data: query.data, error: query.error })
  if (query.error) {
    return (
      <>
        エラーが発生しました {query.error.message} <BackButton />
      </>
    )
  }
  if (!query.data) {
    return <>プロジェクトが見つかりませんでした</>
  }

  return (
    <TaskCardTemplateCreate
      firebaseUser={firebaseUser}
      self={self}
      project={query.data}
    />
  )
}
