import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import React from 'react'
import { TaskCardDetail } from '../types'
import { TaskCardEditMember } from './TaskCardEditMember'
import { TaskCardEditPM } from './TaskCardEditPM'

export const TaskCardEdit: AuthenticatedComponent<{
  taskCard: TaskCardDetail
}> = (props) => {
  if (props.self.role === 'member') {
    return <TaskCardEditMember {...props} />
  } else {
    return <TaskCardEditPM {...props} />
  }
}
