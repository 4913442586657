import { PagePath } from '@/PagePath'
import { SidebarLayout } from '@/components/SidebarLayout'
import { useFirebaseAuthState } from '@/components/providers/FirebaseAuthProvider'
import { useSelfState } from '@/components/providers/SelfProvider'
import { FirebaseUser } from '@/lib/firebaseAuth'
import { Box, HStack, Spinner, useToast } from '@chakra-ui/react'
import { User } from '@prisma/client'
import React from 'react'
import { Navigate } from 'react-router-dom'

export const Authenticated: React.FC<{
  children: <T>(a: AuthenticatedProps<T>) => React.ReactNode
}> = ({ children }) => {
  const { initialized, firebaseUser } = useFirebaseAuthState()
  const { self, status } = useSelfState()

  if (!initialized || (firebaseUser && status === 'uninitialized')) {
    return (
      <HStack>
        <Spinner />
      </HStack>
    )
  } else if (!firebaseUser || !self) {
    return <Navigate to={PagePath.login} />
  }
  const view = children({ firebaseUser, self })
  return <SidebarLayout>{view}</SidebarLayout>
}

type AuthenticatedProps<T> = T & { firebaseUser: FirebaseUser; self: User }
// eslint-disable-next-line @typescript-eslint/ban-types
export type AuthenticatedComponent<T extends {} = {}> = React.FC<
  AuthenticatedProps<T>
>
