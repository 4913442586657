import { TextWithLinks } from '@/components/ui/TextWithLInks'
import { RouterOutput } from '@/lib/trpc'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import {
  Flex,
  HStack,
  Heading,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { Layout } from '@/components/Layout'
import { ProjectMonthlyEventDates } from '@app/api/src/model/prisma'
import { ColorMark } from '@/components/ui/ColorMark'
import { colorLabel } from '@/helper/color'
import { MonthlyProjectCalendarView } from '@/components/ui/ProjectCalendar/view'
import { dateTimeFormatter } from '@app/shared'
import { LinkButton } from '@/components/ui/LinkButton'
import { PagePath } from '@/PagePath'
import { MdOutlineEdit } from 'react-icons/md'
import { YearMonthContextProvider } from '@/context/yearMonth'
import { YearMonthSelector } from '@/components/ui/YearMonthSelector'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'
import { RefreshButton } from '@/components/ui/RefreshButton'

type Project = NonNullable<RouterOutput['project']['show']>
export const ProjectConfigurationShow: AuthenticatedComponent<{
  project: Project
}> = ({ self, firebaseUser, project }) => {
  return (
    <Layout>
      <VStack spacing={'56px'} alignItems={'flex-start'}>
        <RefreshButton />
        <HStack w={'full'} justifyContent={'space-between'}>
          <Heading size={'xl'}>{project.name}</Heading>
          {self.role !== 'member' && (
            <LinkButton
              variant={'blue-fill'}
              size={'sm'}
              to={PagePath.project.edit(project.id)}
            >
              <HStack>
                <MdOutlineEdit />
                <Text>編集</Text>
              </HStack>
            </LinkButton>
          )}
        </HStack>

        <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            ヒアリングシートURL
          </Text>
          <Card>
            <TextWithLinks>{project.hearingSheetUrl}</TextWithLinks>
          </Card>
        </VStack>

        <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            月次イベント
          </Text>
          <Card>
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              divider={<StackDivider />}
              spacing={0}
            >
              {project.monthlyEventDates.length > 0 ? (
                project.monthlyEventDates
                  .sort((a, b) => a.businessDate - b.businessDate)
                  .map(({ name, businessDate }, index) => {
                    return (
                      <HStack
                        w={'full'}
                        key={`yearly-event-${index}`}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                        px={'16px'}
                        py={'12px'}
                      >
                        <Text fontWeight={'bold'}>第{businessDate}営業日</Text>
                        <Text>{name}</Text>
                      </HStack>
                    )
                  })
              ) : (
                <Text>未設定</Text>
              )}
            </VStack>
          </Card>
        </VStack>

        <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            年間イベント
          </Text>
          <Card>
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              divider={<StackDivider />}
            >
              {project.yearlyEventDates.length > 0 ? (
                project.yearlyEventDates
                  .sort((a, b) => a.date.localeCompare(b.date))
                  .map(({ name, date }, index) => {
                    const dateString = dateTimeFormatter.jst['YYYY/MM/DD(E)'](
                      dateTimeFormatter.jst.fromString(date),
                    )
                    return (
                      <VStack
                        w={'full'}
                        key={`yearly-event-${index}`}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                      >
                        <Text>{dateString}</Text>
                        <Text>{name}</Text>
                      </VStack>
                    )
                  })
              ) : (
                <Text>未設定</Text>
              )}
            </VStack>
          </Card>
        </VStack>

        <VStack
          w={'full'}
          spacing={'24px'}
          alignItems={'flex-start'}
          minW={'992px'}
        >
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            休業日設定
          </Text>
          <Card>
            <YearMonthContextProvider options={{ saveInUrl: false }}>
              {(yearMonth) => {
                return (
                  <Flex w={'full'} justifyContent={'center'}>
                    <VStack w={'fit-content'} alignItems={'center'}>
                      <YearMonthSelector bgColor={'gray.50'} />
                      <MonthlyProjectCalendarView
                        onClickCell={undefined}
                        yearMonth={yearMonth}
                        monthlyEvents={
                          project.monthlyEventDates as ProjectMonthlyEventDates
                        }
                        nonBusinessDates={project.nonBusinessDates}
                      />
                    </VStack>
                  </Flex>
                )
              }}
            </YearMonthContextProvider>
          </Card>
        </VStack>

        <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            担当PM
          </Text>
          <Card>
            <UserWithIcon user={project.assignedPmUser} />
          </Card>
        </VStack>

        <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            メンバー
          </Text>
          <Card>
            <ProjectAssignedMemberList project={project} />
          </Card>
        </VStack>

        <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            プロジェクトのカラー
          </Text>
          <Card>
            <HStack>
              <ColorMark color={project.color} />
              <Text>{colorLabel(project.color) || project.color}</Text>
            </HStack>
          </Card>
        </VStack>
      </VStack>
    </Layout>
  )
}

const Card: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <VStack
      alignItems={'flex-start'}
      bgColor={'white'}
      paddingX={'20px'}
      paddingY={'24px'}
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.200'}
    >
      {children}
    </VStack>
  )
}

const ProjectAssignedMemberList: React.FC<{ project: Project }> = ({
  project,
}) => {
  const members = React.useMemo(
    () => project.projectAssign.map((assign) => assign.user),
    [project.projectAssign],
  )
  return (
    <VStack w={'full'} alignItems={'flex-start'} divider={<StackDivider />}>
      {members.length > 0 ? (
        members.map((member) => {
          return (
            <HStack key={member.id} w={'full'} justifyContent={'space-between'}>
              <UserWithIcon user={member} />
            </HStack>
          )
        })
      ) : (
        <Text>担当メンバーがいません</Text>
      )}
    </VStack>
  )
}
