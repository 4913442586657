import { RouterOutput, trpc } from '@/lib/trpc'
import {
  Spinner,
  HStack,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
} from '@chakra-ui/react'
import React from 'react'
import { BusinessDatesPerProject } from '@app/shared'
import {
  SortableTableHeader,
  UseTableSort,
  useTableSort,
} from '../ui/SortableTableHeader'
import { atom, useAtom } from 'jotai'
import { PaginationIndicator, Paging } from '../ui/PaginationIndicator'
import type { TaskCardFilterSchema } from '@app/api/src/usecase/taskCard/predicates'
import {
  TaskCardTableColumnOptions,
  TaskCardTableRow,
} from './TaskCardTableRow'
import { PrimitiveAtom } from 'jotai/vanilla'
import { TaskListSort, taskCardComparator } from '@/helper/taskCard'
import { ErrorCard } from '@/pages/ErrorPage'

type TaskCard = RouterOutput['taskCard']['list'][number]
const taskListSortAtom = atom<TaskListSort>({
  field: 'deadlineBusinessDate',
  direction: 'asc',
})
taskListSortAtom.onMount = (set) => {
  set({
    field: 'deadlineBusinessDate',
    direction: 'asc',
  })
}

type TaskCardPagingAtomType = Paging & {
  isLoading: boolean
  total: number | undefined
}

type TaskCardTableProps = {
  projects: RouterOutput['project']['list']
  taskCardSelection?: UseTaskCardSelectionReturn // 一括選択して操作する用. useTaskCardSelectionの結果をいれる
  paginationPosition: 'top' | 'bottom' | 'both'
  taskCardFilterComponent: React.ReactElement // useTaskCardTableFilter().TaskCardFilterComponentを入れる
  columnOptions?: TaskCardTableColumnOptions // 特定のカラムの表示ON/OFFを切り替える
  businessDatesPerProject: BusinessDatesPerProject
} & (
  | {
      type: 'filter'
      taskCardsFilter: TaskCardFilterSchema
    }
  | {
      type: 'data'
      taskCards: NonNullable<RouterOutput['taskCard']['list']>
    }
)

export type UseTaskCardSelectionReturn = ReturnType<typeof useTaskCardSelection>
export const useTaskCardSelection = () => {
  const state = React.useMemo(() => atom<TaskCard[]>([]), [])
  const [selectedTaskCards, setSelectedTaskCardIds] = useAtom(state)
  const addSelection = React.useCallback(
    (taskCards: TaskCard[]) => {
      setSelectedTaskCardIds((prev) => {
        return prev.concat(taskCards).uniqBy((t) => t.id)
      })
    },
    [setSelectedTaskCardIds],
  )

  const removeSelection = React.useCallback(
    (taskCards: TaskCard[]) => {
      setSelectedTaskCardIds((prev) => {
        return prev.filter(({ id }) => !taskCards.find((t) => t.id === id))
      })
    },
    [setSelectedTaskCardIds],
  )

  const clear = React.useCallback(() => {
    setSelectedTaskCardIds([])
  }, [setSelectedTaskCardIds])

  const isSelected = React.useCallback(
    (taskCard: TaskCard) => {
      return !!selectedTaskCards.find((t) => t.id === taskCard.id)
    },
    [selectedTaskCards],
  )

  return {
    selectedTaskCards,
    clear,
    addSelection,
    removeSelection,
    isSelected,
  }
}

export const TaskCardTable: React.FC<TaskCardTableProps> = (props) => {
  // 単一ページに複数テーブル表示することがあるので内部でatomを初期化して引き回す
  const taskListPagingAtom = React.useMemo(
    () =>
      atom<TaskCardPagingAtomType>({
        total: undefined,
        isLoading: false,
        perPage: 20,
        page: 1,
      }),
    [],
  )
  const [paging, setPaging] = useAtom(taskListPagingAtom)
  const useSort = useTableSort(taskListSortAtom)
  const { projects, businessDatesPerProject, type } = props

  React.useEffect(() => {
    if (type === 'data') {
      setPaging((prev) => ({
        ...prev,
        total: props.taskCards.length,
      }))
    }
  }, [props, setPaging, type])

  const body = React.useMemo(() => {
    if (type === 'filter') {
      return (
        <React.Suspense fallback={<Spinner />}>
          <TaskCardTableWithFetching
            projects={projects}
            useSort={useSort}
            taskCardSelection={props.taskCardSelection}
            pagingAtom={taskListPagingAtom}
            businessDatesPerProject={businessDatesPerProject}
            taskCardsFilter={props.taskCardsFilter}
            columnOptions={props.columnOptions}
          />
        </React.Suspense>
      )
    }
    const taskCards = props.taskCards
      .sort(taskCardComparator(useSort))
      .slice((paging.page - 1) * paging.perPage, paging.page * paging.perPage)

    return (
      <TaskCardTableBody
        projects={projects}
        taskCardSelection={props.taskCardSelection}
        businessDatesPerProject={businessDatesPerProject}
        taskCards={taskCards}
        columnOptions={props.columnOptions}
        useSort={useSort}
      />
    )
  }, [
    businessDatesPerProject,
    paging.page,
    paging.perPage,
    projects,
    props,
    type,
    useSort,
    taskListPagingAtom,
  ])
  const pagination = React.useMemo(
    () => (
      <PaginationIndicator
        total={paging.total ?? 0}
        paging={paging}
        isLoading={!paging.total || paging.isLoading}
        onChangePage={(page) => {
          setPaging((prev) => ({
            ...prev,
            page,
            perPage: paging.perPage,
          }))
        }}
      />
    ),
    [paging, setPaging],
  )

  return (
    <Box w={'full'} rounded={'md'} p={0} m={0}>
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'space-between'}
        pb={'24px'}
        px={0}
        mx={0}
      >
        {props.taskCardFilterComponent}
        {props.paginationPosition !== 'bottom' && pagination}
      </HStack>
      <Box w={'full'} overflowX={'auto'} rounded={'md'} p={0} m={0}>
        {body}
      </Box>
      {props.paginationPosition !== 'top' && (
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          my={'12px'}
        >
          {pagination}
        </HStack>
      )}
    </Box>
  )
}

// データを取得しつつ表示するもの
export const TaskCardTableWithFetching: React.FC<{
  projects: RouterOutput['project']['list']
  useSort: UseTableSort<TaskListSort>
  pagingAtom: PrimitiveAtom<TaskCardPagingAtomType>
  taskCardSelection?: UseTaskCardSelectionReturn
  businessDatesPerProject: BusinessDatesPerProject
  taskCardsFilter: TaskCardFilterSchema
  columnOptions?: TaskCardTableColumnOptions
}> = ({
  projects,
  useSort,
  pagingAtom,
  businessDatesPerProject,
  taskCardsFilter,
  columnOptions,
  taskCardSelection,
}) => {
  const [paging, setPaging] = useAtom(pagingAtom)

  const tasksCountQuery = trpc.taskCard.count.useQuery({
    filter: taskCardsFilter,
  })
  const tasksQuery = trpc.taskCard.list.useQuery(
    {
      filter: taskCardsFilter,
      pagination: {
        perPage: paging.perPage,
        page: paging.page,
        sort: {
          field: useSort[0].field || 'createdAt',
          direction: useSort[0].direction || 'desc',
        },
      },
    },
    {
      enabled: projects.length > 0,
    },
  )
  React.useEffect(() => {
    setPaging((prev) => ({
      ...prev,
      total: tasksCountQuery.data || 0,
    }))
  }, [setPaging, tasksCountQuery.data])

  if (tasksQuery.isLoading || tasksCountQuery.isLoading) {
    return <Spinner />
  }
  if (tasksQuery.error || tasksCountQuery.error) {
    return (
      <ErrorCard
        errorMessage={`タスクカードの取得に失敗しました。${(
          tasksQuery.error || tasksCountQuery.error
        )?.message}`}
      />
    )
  }

  return (
    <TaskCardTableBody
      taskCards={tasksQuery.data || []}
      businessDatesPerProject={businessDatesPerProject}
      projects={projects}
      columnOptions={columnOptions}
      taskCardSelection={taskCardSelection}
      useSort={useSort}
    />
  )
}

type ProjectPerId = {
  [projectId: number]: RouterOutput['project']['list'][number]
}
const TaskCardTableBody: React.FC<{
  taskCards: RouterOutput['taskCard']['list']
  businessDatesPerProject: BusinessDatesPerProject
  projects: RouterOutput['project']['list']
  columnOptions?: TaskCardTableColumnOptions
  taskCardSelection?: UseTaskCardSelectionReturn
  useSort: UseTableSort<TaskListSort>
}> = ({
  taskCards,
  taskCardSelection,
  businessDatesPerProject,
  projects,
  columnOptions,
  useSort,
}) => {
  const projectPerId = React.useMemo(() => {
    return projects.reduce<ProjectPerId>((acc, project) => {
      acc[Number(project.id)] = project
      return acc
    }, {})
  }, [projects])
  const isAllChecked = React.useMemo(() => {
    return (
      taskCardSelection &&
      taskCardSelection.selectedTaskCards.length > 0 &&
      taskCards.every(taskCardSelection.isSelected)
    )
  }, [taskCardSelection, taskCards])
  const isIndeterminate = React.useMemo(() => {
    return (
      !isAllChecked &&
      taskCardSelection &&
      taskCardSelection.selectedTaskCards.length > 0
    )
  }, [isAllChecked, taskCardSelection])

  return (
    <Table
      variant='striped'
      layout={'auto'}
      w={'full'}
      rounded={'md'}
      fontSize={'xs'}
    >
      <Thead w={'full'}>
        <Tr height={'3rem'} paddingY={'10px'} whiteSpace={'nowrap'}>
          {taskCardSelection && (
            <Th textAlign={'center'} px={0} mx={0}>
              <Checkbox
                bg={'white'}
                isChecked={isAllChecked}
                isIndeterminate={isIndeterminate}
                onChange={(e) => {
                  if (e.target.checked) {
                    // 未選択→選択
                    taskCardSelection.addSelection(taskCards)
                  } else {
                    // 選択→未選択
                    taskCardSelection.clear()
                  }
                }}
              />
            </Th>
          )}
          <Th w={'fit-content'}>ステータス</Th>
          <Th w={'fit-content'} whiteSpace={'nowrap'}>
            プロジェクト名
          </Th>
          <Th w={'fit-content'}>属性</Th>
          <Th w={'fit-content'} whiteSpace={'nowrap'}>
            No.
          </Th>
          <Th w={'fit-content'}>タスク名</Th>
          {columnOptions?.showAssignedUser !== false && (
            <Th w={'fit-content'}>担当者</Th>
          )}
          <SortableTableHeader field='startBusinessDate' useSort={useSort}>
            実施予定日
          </SortableTableHeader>
          <SortableTableHeader field='deadlineBusinessDate' useSort={useSort}>
            期限
          </SortableTableHeader>
        </Tr>
      </Thead>
      <Tbody borderRadius={'8px'}>
        {taskCards && taskCards.length > 0 ? (
          taskCards.map((taskCard) => {
            return (
              <TaskCardTableRow
                key={taskCard.id}
                project={projectPerId[Number(taskCard.projectId)]}
                task={taskCard}
                columnOptions={columnOptions}
                taskCardSelection={taskCardSelection}
                businessDates={
                  businessDatesPerProject[Number(taskCard.projectId)]?.[
                    taskCard.projectMonth.year
                  ]?.[taskCard.projectMonth.month]
                }
              />
            )
          })
        ) : (
          <Tr>
            <Td colSpan={9}>タスクはありません</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  )
}
