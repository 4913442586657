import { HStack, Heading, StyleProps } from '@chakra-ui/react'

export const StickyHeader: React.FC<
  {
    title: string
    children: React.ReactNode
  } & StyleProps
> = ({ title, children, ...props }) => {
  return (
    <HStack
      // position={'sticky'}
      position={'fixed'}
      top={0}
      m={0}
      w={'80%'}
      bgColor={'yellow.100'}
      justifyContent={'space-between'}
      px={'24px'}
      py={'12px'}
      boxShadow={'base'}
      h={'56px'}
      zIndex={999}
      {...props}
    >
      <Heading
        size={'md'}
        fontWeight={'bold'}
        color={props.color || 'yellow.800'}
        whiteSpace={'nowrap'}
      >
        {title}
      </Heading>
      {children}
    </HStack>
  )
}
