import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { YearMonth, dateTimeFormatter } from '@app/shared'
import {
  Text,
  HStack,
  VStack,
  Heading,
  StackDivider,
  useToast,
  Button,
  Divider,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormLabel,
  Textarea,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Box,
} from '@chakra-ui/react'
import { User } from '@prisma/client'
import React from 'react'
import { BusinessDates } from '@app/shared'
import { logger } from '@/lib/logger'
import { RouterOutput, trpc } from '@/lib/trpc'
import { TaskAttributeSelect } from '../../../ui/TaskCardAttribute'
import { TimeSlotSelect } from '../../../ui/TimeSlot'
import { LinkButton } from '@/components/ui/LinkButton'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import type { TaskCardCreateSchema } from '@app/api/src/usecase/taskCard/createTask'
import { ColorMark } from '@/components/ui/ColorMark'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { StickyHeader } from '@/components/ui/StickyHeader'
import { UserAssignSelect } from '@/components/ui/UserAssignSelect'
import { NamedLinkArrayInput } from '@/components/ui/NamedLinkArrayInput'
import { useNavigate } from 'react-router-dom'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import {
  TaskCardDeadlineInput,
  TaskCardStartInput,
} from '@/components/ui/TaskCardDateInput'
import { locationFromToPath, useLocationFrom } from '@/helper/locationState'

type Project = NonNullable<RouterOutput['project']['show']>
export const TaskCardCreate: AuthenticatedComponent<{
  project: Project
  yearMonth: YearMonth
}> = ({ self, firebaseUser, project, yearMonth }) => {
  const businessDates = React.useMemo(() => {
    if (project.projectMonths && project.projectMonths.length > 0) {
      return BusinessDates.of(project.projectMonths[0])
    } else {
      undefined
    }
  }, [project.projectMonths])

  const form = useForm<TaskCardCreateSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      projectId: Number(project.id),
      yearMonth,
      assignedToUserId: project.assignedPmUserId,
      startBusinessDate: 1,
      startTimeslotStart: '09:00',
      startTimeslotEnd: '12:00',
      deadlineBusinessDate: 1,
      deadlineTimeslotStart: '09:00',
      deadlineTimeslotEnd: '12:00',
    },
  })
  const toast = useToast()
  const mutation = trpc.taskCard.create.useMutation({
    onError: (e) => {
      logger.error('TaskCardCreate', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const util = trpc.useContext()
  const navigate = useNavigate()
  const { setError } = form
  const locationFrom = useLocationFrom()
  const onSubmitToCreate = React.useCallback(
    async (data: TaskCardCreateSchema) => {
      if (
        data.startBusinessDate &&
        data.deadlineBusinessDate &&
        (data.startBusinessDate > data.deadlineBusinessDate ||
          (data.startTimeslotStart &&
            data.deadlineTimeslotStart &&
            data.startBusinessDate === data.deadlineBusinessDate &&
            data.startTimeslotStart > data.deadlineTimeslotStart))
      ) {
        setError('deadlineBusinessDate', {
          type: 'custom',
          message: '実施予定日よりも後の日付を入力してください',
        })
        return false
      }

      mutation
        .mutateAsync(data)
        .then(async (result) => {
          toast.closeAll()
          if (result.ok) {
            await util.taskCard.list.invalidate()
            toast({
              title: '作成しました',
              status: 'success',
            })
            navigate(
              PagePath.taskCard.show(
                result.result.projectId,
                result.result.taskNumber,
                yearMonth,
              ),
              {
                state: locationFrom,
              },
            )
          } else {
            logger.error('TaskCardCreate', {
              projectId: project.id,
              result,
            })
            toast({
              title: `作成に失敗しました ${result.errorMessage}`,
              status: 'error',
            })
          }
        })
        .catch((e) => {
          logger.error('TaskCardCreate', {
            projectId: project.id,
            error: e,
          })
          toast({
            title: `作成に失敗しました ${e}`,
            status: 'error',
          })
        })
    },
    [
      locationFrom,
      mutation,
      navigate,
      project.id,
      setError,
      toast,
      util.taskCard.list,
      yearMonth,
    ],
  )

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(onSubmitToCreate)}
      >
        <TaskCardCreateStickyHeader
          project={project}
          yearMonth={yearMonth}
          isLoading={mutation.isLoading}
        />
        <LayoutWithStickyHeader>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'56px'}
            paddingBottom={'120px'}
          >
            <TaskHeadingContent
              project={project}
              businessDates={businessDates}
              yearMonth={yearMonth}
            />
            <TaskBasicInformationCreate />
          </VStack>
        </LayoutWithStickyHeader>
      </form>
    </FormProvider>
  )
}

const TaskCardCreateStickyHeader: React.FC<{
  project: SelectedProject
  yearMonth: YearMonth
  isLoading: boolean
}> = ({ project, yearMonth, isLoading }) => {
  const {
    formState: { isValid },
  } = useFormContext()
  const locationFrom = useLocationFrom()
  return (
    <StickyHeader title={'タスクカード作成'}>
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          size={'sm'}
          variant={'white-blue'}
          to={
            locationFromToPath(locationFrom) ||
            PagePath.taskCard.list(project.id, yearMonth)
          }
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>やめる</Text>
          </HStack>
        </LinkButton>
        <Button
          type={'submit'}
          size={'sm'}
          variant={'blue-fill'}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>保存して終了</Text>
          </HStack>
        </Button>
      </HStack>
    </StickyHeader>
  )
}

const TaskHeadingContent = ({
  project,
  businessDates,
  yearMonth,
}: {
  project: Project
  businessDates: BusinessDates | undefined
  yearMonth: YearMonth
}) => {
  const util = trpc.useContext()
  const toast = useToast()
  const {
    register,
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<TaskCardCreateSchema>()

  const [startBusinessDateNum, deadlineBusinessDateNum] = useWatch({
    name: ['startBusinessDate', 'deadlineBusinessDate'],
    control,
  })
  const { startBusinessDate, deadlineBusinessDate } = React.useMemo(() => {
    if (!businessDates) {
      return { startBusinessDate: undefined, deadlineBusinessDate: undefined }
    }
    return {
      startBusinessDate: startBusinessDateNum
        ? businessDates.toDate(startBusinessDateNum)
        : undefined,
      deadlineBusinessDate: deadlineBusinessDateNum
        ? businessDates.toDate(deadlineBusinessDateNum)
        : undefined,
    }
  }, [businessDates, deadlineBusinessDateNum, startBusinessDateNum])

  return (
    <VStack
      w={'full'}
      pt={'16px'}
      pb={'24px'}
      px={'24px'}
      spacing={'16px'}
      bgColor={'white'}
      boxShadow={'base'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          プロジェクト名
        </Text>
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={project.color} />
          <Text fontWeight={'bold'}>{project.name}</Text>
        </HStack>
      </HStack>
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          対象月
        </Text>
        <Text fontWeight={'bold'}>
          {yearMonth.year}年{yearMonth.month}月
        </Text>
      </HStack>
      <FormControl w={'full'} isRequired isInvalid={!!errors?.taskNumber}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            No.
          </FormLabel>
          <Input
            {...register('taskNumber', {
              required: true,
              validate: (v) => {
                logger.info(`validate!!! ${v}`)
                if (v.includes(' ') || v.includes('　')) {
                  setError('taskNumber', {
                    type: 'custom',
                    message: '空白は含めないでください',
                  })
                  return false
                }
                clearErrors('taskNumber')
                return true
              },
            })}
            type={'text'}
            placeholder='例: 92-2'
            w={'10em'}
          />
        </HStack>
      </FormControl>
      <Divider />
      <FormControl w={'full'} isRequired isInvalid={!!errors?.attribute}>
        <HStack
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            属性
          </FormLabel>
          <Box p={0} m={0} w={'20em'}>
            <TaskAttributeSelect
              defaultValue={undefined}
              onChange={(attribute) =>
                attribute && setValue('attribute', attribute)
              }
            />
          </Box>
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!errors?.title}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            タスクカード名
          </FormLabel>
          <Input
            w={'32em'}
            {...register('title', { required: true })}
            type={'text'}
          />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!errors?.startBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            実施予定
          </FormLabel>
          <TaskCardStartInput />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!errors?.deadlineBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            期限日
          </FormLabel>
          <TaskCardDeadlineInput />
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!errors?.assignedToUserId}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            担当者
          </FormLabel>
          <Box w={'20em'}>
            <UserAssignSelect
              projectId={project.id}
              defaultValue={project.assignedPmUser}
              onChange={(user: User) => {
                setValue('assignedToUserId', user.id)
              }}
            />
          </Box>
        </HStack>
      </FormControl>
    </VStack>
  )
}

const TaskBasicInformationCreate = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TaskCardCreateSchema>()
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              input
            </Text>
            {/* <Button variant={'transparent'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <MdCreate />
                <Text>編集</Text>
              </HStack>
            </Button> */}
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('inputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'inputLinks'} />
          </VStack>
        </HStack>
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              作業内容
            </Text>
          </VStack>
          <NamedLinkArrayInput name={'description'} />
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              output
            </Text>
            {/* <Button variant={'transparent'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <MdCreate />
                <Text>編集</Text>
              </HStack>
            </Button> */}
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!errors.outputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('outputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'outputLinks'} />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
