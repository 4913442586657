import { addDays, startOfDay, endOfDay } from 'date-fns'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { useTaskCardTableFilter } from '@/components/ui/TaskCardFilter'
import { TaskCardTable } from '@/components/ui/TaskCardTable'
import { RouterOutput } from '@/lib/trpc'
import {
  BusinessDatesPerProject,
  notUndefinedOrNull,
  jstNow,
  yearMonthFromDate,
  dateTimeFormatter,
  taskCardDerivedStatusDelayedList,
} from '@app/shared'
import {
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Divider,
} from '@chakra-ui/react'
import React from 'react'
import { useSelfState } from '@/components/providers/SelfProvider'

// 近日中のタスクカード. PM/Memberで差異がある
export const RecentTaskCardList: React.FC<{
  projects: SelectedProject[]
  businessDatesPerProject: BusinessDatesPerProject
  taskCards: RouterOutput['taskCard']['list']
}> = ({ projects, businessDatesPerProject, taskCards }) => {
  const { self } = useSelfState()
  const {
    thisWeekLabel,
    todayFilter,
    thisWeekFilter,
    taskCardsForToday,
    taskCardsForThisWeek,
  } = useRecentTaskCardList(projects, businessDatesPerProject, taskCards)

  return (
    <Tabs w={'full'} position='relative' variant='unstyled'>
      <TabList>
        <Tab>今日</Tab>
        <Tab>今週 ({thisWeekLabel})</Tab>
      </TabList>
      <TabIndicator
        mt='-1px'
        height='2px'
        bg='blue.500'
        w={'full'}
        borderRadius='1px'
        zIndex={2}
      />
      <Divider
        borderColor={'gray.200'}
        borderWidth={'2px'}
        w={'full'}
        zIndex={1}
        mt={'-2px'}
        mb={'16px'}
      />
      <TabPanels>
        <TabPanel p={0}>
          <TaskCardTable
            type={'data'}
            paginationPosition='bottom'
            projects={projects}
            businessDatesPerProject={businessDatesPerProject}
            taskCards={taskCardsForToday}
            taskCardFilterComponent={todayFilter.TaskCardFilterComponent}
            columnOptions={
              self?.role === 'member' ? { showAssignedUser: false } : undefined
            }
          />
        </TabPanel>
        <TabPanel p={0}>
          <TaskCardTable
            type={'data'}
            paginationPosition='bottom'
            projects={projects}
            businessDatesPerProject={businessDatesPerProject}
            taskCards={taskCardsForThisWeek}
            taskCardFilterComponent={thisWeekFilter.TaskCardFilterComponent}
            columnOptions={
              self?.role === 'member' ? { showAssignedUser: false } : undefined
            }
          />
        </TabPanel>
        {/* <TabPanel>
      </TabPanel> */}
      </TabPanels>
    </Tabs>
  )
}

const useRecentTaskCardList = (
  projects: SelectedProject[],
  businessDatesPerProject: BusinessDatesPerProject,
  taskCards: RouterOutput['taskCard']['list'],
) => {
  // const [duration, setDuration] = React.useState<'today' | 'thisWeek'>('today')
  const userCandidates = React.useMemo(
    () => taskCards.map((t) => t.assignedTo).filter(notUndefinedOrNull),
    [taskCards],
  )
  const { self } = useSelfState()
  const enabledFilter = React.useMemo(() => {
    if (self?.role !== 'member') {
      return {
        attribute: true,
        assignedToUserId: true,
        outputStatus: true,
        onlyDelayed: true,
      }
    }
    return {
      attribute: false,
      assignedToUserId: false,
      outputStatus: false,
      onlyDelayed: false,
    }
  }, [self?.role])

  const todayFilter = useTaskCardTableFilter({
    userCandidates,
    enabled: enabledFilter,
  })

  const thisWeekFilter = useTaskCardTableFilter({
    userCandidates,
    enabled: enabledFilter,
  })

  const now = React.useMemo(() => jstNow(), [])
  const yearMonth = React.useMemo(() => yearMonthFromDate(now), [now])
  const todayBusinessDates = React.useMemo(() => {
    return Object.entries(businessDatesPerProject).reduce(
      (acc, [projectId, bds]) => {
        const bd = bds[yearMonth.year][yearMonth.month].fromDateAtLeast(now)
        acc[Number(projectId)] = bd
        return acc
      },
      {} as { [projectId: number]: number },
    )
  }, [businessDatesPerProject, now, yearMonth])

  const taskCardsForToday = React.useMemo(() => {
    return taskCards.filter((task) => {
      if (!todayFilter.taskCardsFilter.apply(task)) {
        return false
      }
      if (taskCardDerivedStatusDelayedList.includes(task.derivedStatus)) {
        // 遅れているものは今日やらないといけない
        return true
      }

      const { year, month } = task.projectMonth
      if (year === yearMonth.year && month === yearMonth.month) {
        // 今月のタスク
        const today = todayBusinessDates[Number(task.projectId)]
        if (task.startBusinessDate && task.startBusinessDate > today) {
          // 実施予定日が未来のものは除外
          return false
        }
        if (
          task.outputStatus === 'done' &&
          (!task.startBusinessDate || task.startBusinessDate < today)
        ) {
          // 完了済み かつ 実施予定日が今日より前のものは除外
          return false
        }
        // 実施予定日が今日で完了している or 実施予定日が今日以前で未完了のもの
        return true
      }

      // 今月以外のものは遅延しているかどうかだけで判定可能
      return false
    })
  }, [
    taskCards,
    todayBusinessDates,
    todayFilter.taskCardsFilter,
    yearMonth.month,
    yearMonth.year,
  ])

  const startOfThisWeek = React.useMemo(() => startOfDay(now), [now])
  const endOfThisWeek = React.useMemo(() => endOfDay(addDays(now, 6)), [now]) // 「今週」の終わり

  const thisWeekLabel = React.useMemo(() => {
    const start = dateTimeFormatter.jst['M/D'](startOfThisWeek)
    const end = dateTimeFormatter.jst['M/D'](endOfThisWeek)
    return `${start}-${end}`
  }, [endOfThisWeek, startOfThisWeek])

  const taskCardsForThisWeek = React.useMemo(() => {
    return taskCards.filter((task) => {
      if (!thisWeekFilter.taskCardsFilter.apply(task)) {
        return false
      }
      if (taskCardDerivedStatusDelayedList.includes(task.derivedStatus)) {
        // 遅れているものは今週やらないといけない
        return true
      }

      const { year, month } = task.projectMonth
      const taskBusinessDates =
        businessDatesPerProject[Number(task.projectId)][year][month].task(task)

      if (year === yearMonth.year && month === yearMonth.month) {
        // 今月のタスク
        if (
          (task.startBusinessDate && !taskBusinessDates.startDate?.start) || // 営業日設定がおかしくて実施予定日が月の日数を超えている、みたいな場合のタスクカードを除去する
          (taskBusinessDates.startDate?.start &&
            taskBusinessDates.startDate.start > endOfThisWeek)
        ) {
          // 実施予定日が未来のものは除外
          return false
        }
        if (
          task.outputStatus === 'done' &&
          (!taskBusinessDates.startDate?.start ||
            taskBusinessDates.startDate.start < startOfThisWeek ||
            taskBusinessDates.startDate.start > endOfThisWeek)
        ) {
          // 完了済み かつ 実施予定日が今週でないものは除外
          return false
        }

        // 実施予定日が今週で完了している or 実施予定日が今週以前で未完了のもの
        return true
      }

      // 今月以外のものは遅延しているかどうかだけで判定可能
      return false
    })
  }, [
    businessDatesPerProject,
    endOfThisWeek,
    startOfThisWeek,
    taskCards,
    thisWeekFilter.taskCardsFilter,
    yearMonth.month,
    yearMonth.year,
  ])

  return {
    thisWeekLabel,
    todayFilter,
    thisWeekFilter,
    taskCardsForToday,
    taskCardsForThisWeek,
  }
}
