import React from 'react'
import { Authenticated } from '../AuthenticatedPage'
import { ProjectCreate } from '@/components/pages/project/ProjectCreate'

export const ProjectCreatePage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return <ProjectCreate firebaseUser={firebaseUser} self={self} />
      }}
    </Authenticated>
  )
}
