import { dateTimeFormatter } from '@app/shared'
import {
  AlertDialogContent,
  Text,
  AlertDialogHeader,
  VStack,
  AlertDialogCloseButton,
  AlertDialogBody,
  HStack,
  Select,
  Input,
  Textarea,
  AlertDialogFooter,
  Button,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TaskCardDetail } from '../types'
import { useMutation } from '@tanstack/react-query'
import { taskCardCommentLabel } from '@/constants/taskCardCommentType'

export type QuestionToClientInput = {
  dateTime: Date
  text: string
}
type QuestionToClientInputForm = {
  date: string
  time: string
  text: string
}
export const TaskCardQuestionToClientAddDialog: React.FC<{
  task: TaskCardDetail
  onSubmit: (data: QuestionToClientInput) => Promise<void>
}> = ({ task, onSubmit }) => {
  const now = React.useMemo(() => new Date(), [])
  const form = useForm<QuestionToClientInputForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      date: dateTimeFormatter.jst['YYYY-MM-DD'](now),
      time: dateTimeFormatter.jst['hh:mm'](now),
    },
  })
  const mutation = useMutation({
    mutationFn: async () => {
      const input = form.getValues()
      const data = {
        type: 'question_client',
        text: input.text,
        dateTime: dateTimeFormatter.jst.fromString(input.date, input.time),
      }
      return onSubmit(data)
    },
  })
  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            クライアントへの質問の投稿
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <form onSubmit={form.handleSubmit((data) => mutation.mutateAsync())}>
          <VStack
            w={'full'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            spacing={'10px'}
          >
            {/* <HStack alignItems={'center'} justifyContent={'flex-start'}>
              <FormControl isRequired>
                <Input {...form.register('date')} type={'date'} />
                <FormErrorMessage>
                  {form.formState.errors?.date?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                <Input {...form.register('time')} type={'time'} />
                <FormErrorMessage>
                  {form.formState.errors?.time?.message}
                </FormErrorMessage>
              </FormControl>
            </HStack> */}

            <FormControl isRequired>
              <Textarea
                {...form.register('text')}
                isDisabled={mutation.isLoading}
                height={'6em'}
                placeholder={
                  '例）領収書の添付漏れがあり経理部の佐藤さんに確認中です。関連スレッドは以降をご確認ください。https://example.com/testpage'
                }
              />
            </FormControl>
            <HStack w={'full'} justifyContent={'flex-end'}>
              <Button
                type={'submit'}
                isLoading={mutation.isLoading}
                w={'fit-content'}
                variant={'blue-fill'}
                borderRadius={'6px'}
              >
                投稿
              </Button>
            </HStack>
          </VStack>
        </form>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}>
        {/* <Button
          onClick={() => mutation.mutateAsync()}
          isLoading={mutation.isLoading}
          w={'fit-content'}
          variant={'blue-fill'}
          borderRadius={'6px'}
        >
          投稿する
        </Button> */}
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
