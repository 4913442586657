import { logger } from '@/lib/logger'
import type { ProjectMonthlyEventDates } from '@app/api/src/model/prisma'
import { ProjectCreateSchema } from '@app/api/src/usecase/project/createProject'
import { ProjectUpdateSchema } from '@app/api/src/usecase/project/updateProject'
import {
  Text,
  UnorderedList,
  ListItem,
  HStack,
  Button,
  VStack,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputStepper,
  StackDivider,
  NumberInputField,
} from '@chakra-ui/react'
import { Project } from '@prisma/client'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useForm, useFieldArray, useFormContext } from 'react-hook-form'
import { MdOutlineDelete, MdOutlineAdd } from 'react-icons/md'

export const ProjectMonthlyEvents: React.FC = () => {
  const form = useFormContext<ProjectUpdateSchema | ProjectCreateSchema>()
  const {
    fields: events,
    append,
    remove,
  } = useFieldArray({
    name: 'monthlyEventDates',
    control: form.control,
  })

  const body = React.useMemo(() => {
    if (events.length > 0) {
      return (
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          divider={<StackDivider />}
        >
          {events.map(({ businessDate, name }, idx) => {
            return (
              <HStack
                key={`monthly-event-${idx}`}
                w={'full'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <HStack
                  w={'full'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  <Text fontWeight={'bold'}>{name}</Text>
                  <Text>: 第{businessDate}営業日</Text>
                </HStack>
                <Button
                  size={'sm'}
                  p={0}
                  onClick={() => {
                    remove(idx)
                  }}
                >
                  <MdOutlineDelete size={'16px'} />
                </Button>
              </HStack>
            )
          })}
        </VStack>
      )
    } else {
      return <Text>未設定</Text>
    }
  }, [events, remove])

  const [input, setInput] = React.useState<
    ProjectMonthlyEventDates[number] | undefined
  >(undefined)

  return (
    <VStack w={'full'} spacing={'16px'} alignItems={'flex-start'}>
      {body}
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        bgColor={'gray.50'}
        p={'16px'}
        spacing={'16px'}
      >
        <HStack w={'full'}>
          <FormControl w={'fit-content'}>
            <InputGroup>
              <InputLeftAddon>第</InputLeftAddon>
              <NumberInput
                min={1}
                max={31}
                keepWithinRange={false}
                clampValueOnBlur={false}
                value={input?.businessDate || 0}
                onChange={(e, value) => {
                  // const value = Number(e.target.value)
                  setInput((prev) => {
                    if (prev) {
                      return { ...prev, businessDate: value }
                    } else {
                      return { name: '', businessDate: value }
                    }
                  })
                }}
              >
                <NumberInputField borderRadius={0} w={'5em'} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <InputRightAddon>営業日</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl w={'full'}>
            <Input
              w={'full'}
              type={'text'}
              placeholder={'月次イベント名'}
              value={input?.name || ''}
              onChange={(e) => {
                const value = e.target.value
                setInput((prev) => {
                  if (prev) {
                    return { ...prev, name: value }
                  } else {
                    return { name: value, businessDate: 0 }
                  }
                })
              }}
              // w={'10em'}
            />
          </FormControl>
        </HStack>
        <Button
          size={'sm'}
          // p={0}
          variant={'blue-fill'}
          isDisabled={Boolean(!input || !input.businessDate || !input.name)}
          onClick={() => {
            logger.info(`onClick`, { input })
            if (input) {
              append(input)
              setInput(undefined)
            }
          }}
        >
          <MdOutlineAdd size={'16px'} />
          <Text>追加</Text>
        </Button>
      </HStack>
    </VStack>
  )
}
