import { NamedLinkSchema } from '@app/api/src/routers/trpc/schema'
import { Image, SimpleGrid, HStack, Link } from '@chakra-ui/react'
import { JsonValue } from '@prisma/client/runtime/library'
import React from 'react'
import { DocumentLinkIcon } from './LinkIcon'

export const DescriptionLinkGrid = ({ links }: { links: JsonValue }) => {
  const _links = React.useMemo(() => {
    if (Array.isArray(links)) {
      return links.filter((link: any) => link.url !== '' && link.name !== '')
    } else if (typeof links === 'object') {
      return []
    }
  }, [links])

  return (
    <SimpleGrid w={'full'} columns={2} spacing={4}>
      {((_links || []) as NamedLinkSchema[]).map(({ name, url }, i) => {
        return (
          <HStack
            key={`${url}-${i}`}
            alignItems={'center'}
            w={'full'}
            justifyContent={'flex-start'}
            px={'8px'}
            py={'6px'}
            spacing={'10px'}
            bgColor={'gray.50'}
            borderRadius={'4px'}
          >
            <DocumentLinkIcon />
            <Link
              href={url}
              target={'_blank'}
              rel={'noreferrer noopener'}
              color={'blue.600'}
              textDecoration={'underline'}
            >
              {name}
            </Link>
          </HStack>
        )
      })}
    </SimpleGrid>
  )
}
