import { RouterOutput, trpc } from '@/lib/trpc'
import {
  Button,
  HStack,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ProjectUpdateSchema } from '@app/api/src/usecase/project/updateProject'
import { UserSelect } from '@/components/ui/UserSelect'
import { MdOutlineDelete } from 'react-icons/md'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'
import { User } from '@prisma/client'

type ProjectAssignList = NonNullable<
  RouterOutput['project']['show']
>['projectAssign']
export const ProjectAssignedMemberListInput: React.FC<{
  projectAssignList: ProjectAssignList
}> = ({ projectAssignList }) => {
  const { setValue } = useFormContext<ProjectUpdateSchema>()
  const [members, setMembers] = React.useState<User[]>(
    projectAssignList.map((assign) => assign.user),
  )

  const onClickToAdd = React.useCallback(
    async (user: User | null) => {
      if (!user) {
        return
      }
      const _members = [...members, user]
      setMembers(_members)
      setValue(
        'assignedMemberIds',
        _members.map((u) => u.id),
        {
          shouldDirty: true,
        },
      )
    },
    [members, setValue],
  )
  const onClickToRemove = React.useCallback(
    async (user: User) => {
      const _members = members.filter((u) => u.id !== user.id)
      setMembers(_members)
      setValue(
        'assignedMemberIds',
        _members.map((u) => u.id),
        {
          shouldDirty: true,
        },
      )
    },
    [members, setValue],
  )

  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <VStack w={'full'} alignItems={'flex-start'} divider={<StackDivider />}>
        {members.length > 0 ? (
          members.map((member) => {
            return (
              <HStack
                key={member.id}
                w={'full'}
                justifyContent={'space-between'}
              >
                <UserWithIcon user={member} />
                <Button
                  bgColor={'transparent'}
                  onClick={() => onClickToRemove(member)}
                >
                  <MdOutlineDelete />
                </Button>
              </HStack>
            )
          })
        ) : (
          <Text>担当メンバーがいません</Text>
        )}
      </VStack>
      <ProjectUserAssignSelect
        projectAssignList={projectAssignList}
        onChange={onClickToAdd}
      />
    </VStack>
  )
}

const ProjectUserAssignSelect: React.FC<{
  projectAssignList: ProjectAssignList
  onChange: (user: User | null) => void
}> = ({ projectAssignList, onChange }) => {
  const query = trpc.user.list.useQuery({
    filter: {
      // accessibleProjectId: project.id,
      // role: ['member'],
    },
  })
  const users = React.useMemo(() => {
    return (query.data || []).filter((user) => {
      return !projectAssignList.some((assign) => assign.userId === user.id)
    })
  }, [projectAssignList, query.data])
  return query.isLoading ? (
    <Spinner />
  ) : query.data ? (
    <UserSelect
      type={'input'}
      candidates={users}
      placeholder={'追加するメンバー名を入力...'}
      defaultValue={undefined}
      onChange={onChange}
    />
  ) : (
    <Spinner />
  )
}
