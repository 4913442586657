import { Text, HStack, Spinner } from '@chakra-ui/react'

export const TextSpinner = () => {
  return (
    <HStack h={'full'} spacing={'2'}>
      {/* できれば他のSpinner含めて、SpinnerとTextをcolor={'gray.400'}したい */}
      <Spinner bgSize={'18'} />
      <Text>loading...</Text>
    </HStack>
  )
}
