import React from 'react'
import { Dashboard } from '@/components/pages/dashboard/Dashboard'
import { Authenticated } from './AuthenticatedPage'
import { Spinner } from '@chakra-ui/react'
import { YearMonthContextProvider } from '@/context/yearMonth'

export const DashboardPage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return <Dashboard self={self} firebaseUser={firebaseUser} />
      }}
    </Authenticated>
  )
}
