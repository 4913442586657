import { useSelfState } from '@/components/providers/SelfProvider'
import {
  Spinner,
  HStack,
  VStack,
  Text,
  Divider,
  Button,
} from '@chakra-ui/react'
import { RouterOutput, trpc } from '@/lib/trpc'
import React from 'react'
import { useDialog } from '../Dialog'
import { DialogToUpsertProjectFilter } from './ProjectFilterDialog'
import { TextEllipsis } from '../TextEllipsis'

export const ProjectFilterList = () => {
  const { self } = useSelfState()
  const [isFolded, setIsFolded] = React.useState(true)
  const dialog = useDialog()
  const onClickToShowDialog = React.useCallback(
    (projectFilter?: RouterOutput['projectFilter']['list'][number]) => {
      dialog.closeDialog()
      dialog.openDialog({
        size: '2xl',
        closeOnOverlayClick: true,
        body: <DialogToUpsertProjectFilter projectFilter={projectFilter} />,
      })
    },
    [dialog],
  )

  const projectFiltersQuery = trpc.projectFilter.list.useQuery(
    {},
    {
      enabled: !!self,
    },
  )

  const list = React.useMemo(() => {
    const FILTER_SHOW_NUM = 5
    const { filters, hasMore } = (() => {
      const filters = projectFiltersQuery.data || []
      if (isFolded) {
        return {
          filters: filters.slice(0, FILTER_SHOW_NUM),
          hasMore: filters.length > FILTER_SHOW_NUM,
        }
      } else {
        return { filters, hasMore: false }
      }
    })()

    return (
      <VStack
        w={'full'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        spacing={'0'}
      >
        {filters.map((projectFilter) => (
          <Button
            key={`filter-${projectFilter.id}`}
            w={'full'}
            textAlign={'left'}
            justifyContent={'flex-start'}
            variant={'sidebar'}
            onClick={() => onClickToShowDialog(projectFilter)}
          >
            <TextEllipsis fontSize={'sm'} fontWeight={'normal'}>
              {projectFilter.name}
            </TextEllipsis>
          </Button>
        ))}
        {hasMore && (
          <Button
            variant={'transparent-clickable'}
            onClick={() => setIsFolded(!isFolded)}
          >
            <Text color={'gray.600'} textDecoration={'underline'}>
              ...すべてみる
            </Text>
          </Button>
        )}
      </VStack>
    )
  }, [isFolded, onClickToShowDialog, projectFiltersQuery.data])

  if (projectFiltersQuery.isLoading) {
    return <Spinner />
  }

  return (
    <VStack
      w={'full'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      spacing={'4px'}
    >
      <HStack w={'full'} alignItems={'center'} justifyContent={'space-between'}>
        <Text whiteSpace={'nowrap'} flexGrow={4}>
          フィルタ
        </Text>
        {self && self.role !== 'member' && (
          <Button
            w={'fit-content'}
            flexGrow={0}
            variant={'outline'}
            colorScheme={'gray'}
            color={'gray.400'}
            size={'xs'}
            onClick={() => onClickToShowDialog()}
          >
            {/* <MdOutlineAdd /> */}
            <Text>+ 追加</Text>
          </Button>
        )}
      </HStack>
      <Divider />
      {(projectFiltersQuery.data || []).length > 0 ? list : <br />}
    </VStack>
  )
}
