import React from 'react'
import { Badge, Select, Tag } from '@chakra-ui/react'
import { TaskAttribute, TaskCard } from '@prisma/client'
import { taskAttributeLabel } from '@/constants/taskAttribute'

export const TaskAttributeTag = ({
  attribute,
}: {
  attribute: TaskCard['attribute']
}) => {
  const { label, bgColor, color } = taskAttributeLabel[attribute]
  return (
    <Badge
      borderRadius={'2px'}
      paddingX={'4px'}
      paddingY={'2px'}
      bgColor={bgColor || 'gray.100'}
      color={color || 'black'}
      whiteSpace={'nowrap'}
      w={'fit-content'}
    >
      {label}
    </Badge>
  )
}

export const TaskAttributeSelect: React.FC<{
  defaultValue?: TaskAttribute
  onChange: (attribute: TaskAttribute | null) => void
}> = ({ defaultValue, onChange }) => {
  const options = React.useMemo(() => {
    return Object.entries(taskAttributeLabel).map(([key, { label }]) => (
      <option key={key} value={key}>
        {label}
      </option>
    ))
  }, [])
  const [value, setValue] = React.useState(defaultValue)

  return (
    <Select
      color={value ? 'gray.700' : 'gray.500'}
      onChange={async (v) => {
        if (v.target.value === '') {
          setValue(undefined)
          onChange(null)
        } else {
          const attribute = v.target.value as TaskAttribute
          setValue(attribute)
          onChange(attribute)
        }
      }}
      defaultValue={defaultValue || ''}
      bgColor={'white'}
      w={'fit-content'}
      size={'md'}
      h={'36px'}
      borderRadius={'6px'}
    >
      <option value={''}>属性を選択</option>
      {options}
    </Select>
  )
}
