import { dayOfWeeks } from '@/constants/dayOfWeek'
import { YearMonth } from '@/context/yearMonth'
import { calendarDates } from '@/helper/dates'
import { ProjectMonthlyEventDates } from '@app/api/src/model/prisma'
import { dateTimeFormatter } from '@app/shared'
import {
  Text,
  VStack,
  HStack,
  Box,
  Button,
  Flex,
  StyleProps,
} from '@chakra-ui/react'
import { addDays } from 'date-fns'
import React from 'react'

export const MonthlyProjectCalendarView: React.FC<{
  yearMonth: YearMonth
  monthlyEvents: ProjectMonthlyEventDates
  nonBusinessDates: string[]
  onClickCell?: (date: Date, excluded: boolean) => boolean
}> = ({ yearMonth, monthlyEvents, nonBusinessDates, onClickCell }) => {
  const { year, month } = yearMonth
  const { monthStart, monthEnd, monthDates } = calendarDates(yearMonth)

  const excludedDates: Set<string> = React.useMemo(() => {
    return new Set(nonBusinessDates)
  }, [nonBusinessDates])

  // 1日までの空白(1日が日曜日なら0でよい)
  const startFillers = React.useMemo(() => {
    const d = monthStart.getDay()
    return Array.from(Array(d).keys()).map((i) => {
      return (
        <CalendarFiller
          key={`startFiller-${year}-${month}-${i}`}
          date={addDays(monthStart, -d + i)}
        />
      )
    })
  }, [monthStart, year, month])

  // 月初から月末
  const dayElements = React.useMemo(() => {
    let businessDate: number = 0
    return monthDates.map((date: Date, idx) => {
      const excluded = excludedDates.has(
        dateTimeFormatter.jst['YYYY-MM-DD'](date),
      )
      if (!excluded) {
        businessDate++
      }
      const events = monthlyEvents.filter(
        ({ businessDate: bd }) => bd === businessDate,
      )
      return (
        <CalendarCell
          key={`dayElements-${year}-${month}-${idx}-${excludedDates.size}`} // useEffectで追加された後に再描画されるように
          date={date}
          onClick={onClickCell}
          excluded={excluded}
        >
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
            spacing={'8px'}
            fontSize={'12px'}
            fontWeight={'bold'}
          >
            {events.map((event, i) => {
              return (
                <Box whiteSpace={'normal'} key={`${event.businessDate}-${i}`}>
                  {event.name}
                </Box>
              )
            })}
          </VStack>
        </CalendarCell>
      )
    })
  }, [excludedDates, month, monthDates, monthlyEvents, onClickCell, year])

  // 月末以降の空白(トータルで最大6週間表示する)
  const endFillers = React.useMemo(() => {
    const count = (() => {
      const uptoMonthEnd = startFillers.length + dayElements.length
      if (7 * 5 > uptoMonthEnd) {
        return 7 * 5 - uptoMonthEnd
      } else {
        return 7 * 6 - uptoMonthEnd
      }
    })()
    return Array.from(Array(count).keys()).map((i) => {
      return (
        <CalendarFiller
          key={`endFiller-${year}-${month}-${i}`}
          date={addDays(monthEnd, i + 1)}
        />
      )
    })
  }, [dayElements.length, month, monthEnd, startFillers.length, year])

  // 全部をくっつけて7日ごとに区切る
  const lineElements = chunk(
    [...headers, ...startFillers, ...dayElements, ...endFillers],
    7,
  )

  return (
    <VStack justifyContent={'center'} alignItems={'center'} w={'950px'}>
      {lineElements.map((line, index) => {
        return (
          <HStack
            w={'full'}
            key={`calendar-line-${index}`}
            alignItems={'center'}
            justifyContent={'center'}
            h={'max-content'}
          >
            {line}
          </HStack>
        )
      })}
    </VStack>
  )
}

const CalendarFiller: React.FC<{ date: Date }> = ({ date }) => {
  return (
    <Box {...calendarElementStyle}>
      <Button
        w={'full'}
        h={'full'}
        borderRadius={'4px'}
        bgColor={'gray.100'}
        _active={{ bgColor: 'gray.100' }}
        _hover={{ bgColor: 'gray.100' }}
        cursor={'default'}
      >
        <VStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          w={'full'}
          borderRadius={'4px'}
          p={'14px'}
          h={'full'}
        >
          <Text color={'gray.400'} fontSize={'14px'}>
            {date.getDate()}
          </Text>
          <Box
            maxH={'352px'}
            minH={'52px'}
            textAlign={'left'}
            h={'full'}
            overflow={'auto'}
          >
            {'　'}
          </Box>
        </VStack>
      </Button>
    </Box>
  )
}

const dayOfWeekMap = new Map(dayOfWeeks.map((obj) => [obj.day, obj]))
const calendarElementStyle: StyleProps = {
  borderRadius: '4px',
  h: 'full',
  w: '128px',
  maxH: '352px',
  minH: '52px',
}

const CalendarCell: React.FC<{
  onClick?: (date: Date, newExcluded: boolean) => boolean
  excluded: boolean
  date: Date
  children?: React.ReactNode
}> = ({ onClick, excluded, date, children }) => {
  const [value, setValue] = React.useState(excluded)
  const style: StyleProps = React.useMemo(() => {
    if (onClick) {
      return {
        cursor: 'pointer',
        _hover: {
          bgColor: value ? 'gray.400' : 'gray.200',
        },
        _active: {
          bgColor: value ? 'gray.300' : 'gray.300',
        },
      }
    } else {
      return {
        cursor: 'default',
        _hover: {
          bgColor: value ? 'gray.500' : 'gray.100',
        },
        _active: {
          bgColor: value ? 'gray.500' : 'gray.100',
        },
      }
    }
  }, [onClick, value])

  return (
    <Box {...calendarElementStyle} bgColor={value ? 'gray.500' : 'gray.100'}>
      <Button
        bgColor={value ? 'blue.400' : 'gray.100'}
        {...style}
        onClick={() => {
          if (onClick && onClick(date, !value)) {
            setValue(!value)
          }
        }}
        w={'full'}
        h={'full'}
        borderRadius={'4px'}
        padding={'16px'}
      >
        <VStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          w={'full'}
          h={'full'}
          p={'0'}
        >
          <Text color={value ? 'white' : 'gray.700'} fontSize={'14px'}>
            {date.getDate()}
          </Text>
          <Box
            color={value ? 'white' : 'gray.500'}
            // maxH={'152px'}
            minH={'52px'}
            textAlign={'left'}
            h={'full'}
            overflowY={'auto'}
            fontSize={'xs'}
            fontWeight={'bold'}
          >
            {value ? '休業日' : children}
          </Box>
        </VStack>
      </Button>
    </Box>
  )
}

const chunk = <T,>(arr: T[], size: number) => {
  return arr.reduce(
    (newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]),
    [] as T[][],
  )
}

const CalendarHeaderElement: React.FC<{ text: string; color: string }> = ({
  text,
  color,
}) => {
  return (
    <Flex
      {...calendarElementStyle}
      h={'36px'}
      justifyContent={'center'}
      alignItems={'center'}
      color={color}
      fontWeight={'medium'}
    >
      <Text>{text}</Text>
    </Flex>
  )
}
const headers = dayOfWeeks
  .slice()
  .sort((a, b) => a.day - b.day) // 日曜日始まり
  .map((d) => {
    const color = d.day === 0 ? 'red.500' : d.day === 6 ? 'blue.500' : 'black'
    return (
      <CalendarHeaderElement
        key={`calendar-header-${d.id}`}
        text={d.name}
        color={color}
      />
    )
  })
