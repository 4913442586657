import { PagePathParams } from '@/PagePath'
import { Spinner } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Authenticated, AuthenticatedComponent } from '../AuthenticatedPage'
import { trpc } from '@/lib/trpc'
import { ProjectConfigurationShow } from '@/components/pages/project/configuration/ProjectConfigurationShow'
import { ErrorPage } from '../ErrorPage'

export const ProjectConfigurationShowPage = () => {
  const { projectId } = useParams<PagePathParams['projects']['show']>()
  if (!projectId || Number.isNaN(Number(projectId))) {
    return <>invalid params</>
  }

  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <ProjectConfigurationPageInternal
            {...{
              projectId: Number(projectId),
              firebaseUser,
              self,
            }}
          />
        )
      }}
    </Authenticated>
  )
}

const ProjectConfigurationPageInternal: AuthenticatedComponent<{
  projectId: number
}> = ({ projectId, firebaseUser, self }) => {
  const query = trpc.project.show.useQuery({
    id: projectId,
    yearMonth: undefined,
  })

  if (query.isLoading) {
    return <Spinner />
  }
  if (query.error) {
    return <ErrorPage errorMessage={query.error.message} />
  }
  if (!query.data) {
    return <ErrorPage errorMessage='データが取得できませんでした' />
  }

  return (
    <ProjectConfigurationShow
      firebaseUser={firebaseUser}
      self={self}
      project={query.data}
    />
  )
}
