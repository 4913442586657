import { TextWithLinks } from '@/components/ui/TextWithLInks'
import { Picture } from '@/components/ui/user/picture'
import { addDays, startOfDay } from 'date-fns'
import {
  toPrevMonth,
  BusinessDatesPerProject,
  BusinessDates,
  jstNow,
  yearMonthFromDate,
} from '@app/shared'
import {
  Text,
  VStack,
  HStack,
  Spinner,
  StackDivider,
  Badge,
} from '@chakra-ui/react'
import React from 'react'
import { RouterOutput, trpc } from '@/lib/trpc'
import { ErrorCard } from '@/pages/ErrorPage'
import { DateTimeWithBusinessDate } from '@/components/ui/DateWithBusinessDate'
import { TaskCardBanner } from '@/components/ui/TaskCardBanner'
import { TaskCommentFilterSchema } from '@app/api/src/routers/trpc/taskComment/list'
import {
  PaginationIndicator,
  Paging,
} from '@/components/ui/PaginationIndicator'
import { atom, useAtom } from 'jotai'

type ProjectPerId = {
  [projectId: number]: RouterOutput['project']['list'][number]
}
const pagingAtom = atom<
  Paging & { isLoading: boolean; total: number | undefined }
>({
  total: undefined,
  isLoading: false,
  perPage: 20,
  page: 1,
})

export const NewRuleComments: React.FC<{
  projects: RouterOutput['project']['list']
  businessDatesPerProject: BusinessDatesPerProject
}> = ({ projects, businessDatesPerProject }) => {
  const projectPerId = React.useMemo(() => {
    return projects.reduce<ProjectPerId>((acc, project) => {
      acc[Number(project.id)] = project
      return acc
    }, {})
  }, [projects])
  const projectIds = React.useMemo(() => projects.map((p) => p.id), [projects])
  const commentsFilter: TaskCommentFilterSchema = React.useMemo(() => {
    const now = jstNow()
    const yearMonth = yearMonthFromDate(now)
    return {
      yearMonths: [toPrevMonth(yearMonth), yearMonth],
      timestamp: {
        after: startOfDay(addDays(jstNow(), -30)), // startOfDayを入れて固定しないと無限にクエリが投げられる
      },
      category: {
        in: ['report_new_rule'],
      },
      projectIds,
    }
  }, [projectIds])

  const newRuleCommentsQuery = trpc.taskComment.list.useQuery(
    {
      filter: commentsFilter,
      pagination: {
        sort: {
          field: 'timestamp',
          direction: 'desc',
        },
      },
    },
    {
      enabled: projectIds.length > 0,
    },
  )
  const comments = React.useMemo(
    () => (newRuleCommentsQuery.data || []).filter((c) => Boolean(c.taskCard)),
    [newRuleCommentsQuery.data],
  )
  const [paging, setPaging] = useAtom(pagingAtom)
  React.useEffect(() => {
    if (comments) {
      setPaging((prev) => ({
        ...prev,
        total: comments.length,
      }))
    }
  }, [comments, setPaging])

  if (newRuleCommentsQuery.isInitialLoading) {
    return <Spinner />
  }
  if (newRuleCommentsQuery.error) {
    return <ErrorCard errorMessage={newRuleCommentsQuery.error.message} />
  }
  return (
    <VStack alignItems={'flex-start'} w={'full'} borderRadius={'4px'}>
      <VStack
        alignItems={'flex-start'}
        w={'full'}
        borderRadius={'4px'}
        divider={<StackDivider />}
      >
        {comments.length > 0 ? (
          comments
            .slice(
              (paging.page - 1) * paging.perPage,
              paging.page * paging.perPage,
            )
            .map((comment) => {
              const taskCard = comment.taskCard
              if (!taskCard) {
                // すでにfilterしてあるので安全
                return <></>
              }
              return (
                <NewRuleComment
                  key={`comment-${comment.id}`}
                  project={projectPerId[Number(comment.projectId)]}
                  comment={comment}
                  businessDates={
                    businessDatesPerProject[Number(comment.projectId)][
                      taskCard.projectMonth.year
                    ][taskCard.projectMonth.month]
                  }
                />
              )
            })
        ) : (
          <Text>新しいルールの通知はありません</Text>
        )}
      </VStack>
      <HStack w={'full'} justifyContent={'flex-end'} mt={'12px'}>
        {/* <Text>{comments.length}件</Text> */}
        <PaginationIndicator
          total={paging.total ?? 0}
          paging={paging}
          isLoading={!paging.total || paging.isLoading}
          onChangePage={(page) => {
            setPaging((prev) => ({
              ...prev,
              page,
              perPage: paging.perPage,
            }))
          }}
        />
      </HStack>
    </VStack>
  )
}

const NewRuleComment: React.FC<{
  project: RouterOutput['project']['list'][number]
  comment: RouterOutput['taskComment']['list'][number]
  businessDates: BusinessDates
}> = ({ project, comment, businessDates }) => {
  const businessDate = React.useMemo(
    () => businessDates.fromDate(comment.timestamp),
    [businessDates, comment.timestamp],
  )
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      bgColor={'white'}
      px={'20px'}
      py={'16px'}
      spacing={'10px'}
      borderRadius={'8px'}
    >
      <TaskCardBanner project={project} taskCard={comment.taskCard} />
      <HStack
        spacing={'16px'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        w={'full'}
      >
        <Picture user={comment.user} />
        <Text>{comment.user.name}</Text>
        <Badge
          variant={'outline'}
          borderRadius={'2px'}
          borderWidth={'1px'}
          borderColor={'gray.400'}
          color={'gray.400'}
          px={'4px'}
          py={'2px'}
        >
          投稿日時
        </Badge>
        <DateTimeWithBusinessDate
          timestamp={comment.timestamp}
          businessDate={businessDate}
        />
      </HStack>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <TextWithLinks w={'80%'}>{comment.body}</TextWithLinks>
      </HStack>
    </VStack>
  )
}
