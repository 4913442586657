import { ThemeComponents } from '@chakra-ui/react'

import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const striped = definePartsStyle((props) => {
  const { colorScheme: c, colorMode } = props
  return {
    baseStyle: {},
    thead: {
      verticalAlign: 'middle',
      borderColor: 'gray.200',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    th: {
      backgroundColor: `gray.100`,
      verticalAlign: 'middle',
      fontWeight: 'bold',
      borderBottomWidth: '1px',
      borderColor: 'gray.200',
      color: 'gray.500',
    },
    td: {
      verticalAlign: 'middle',
      // minWidth: '100px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderColor: 'gray.200',
    },
    tr: {
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      borderColor: 'gray.200',
      verticalAlign: 'middle',
      _even: {
        backgroundColor: 'gray.10',
      },
      _odd: {
        backgroundColor: 'white',
      },
    },
  }
})
export const tableTheme = defineMultiStyleConfig({
  variants: { striped },
})

export const tableStyle: ThemeComponents = {
  TableContainer: {
    baseStyle: {
      width: '100%',
    },
  },
  Table: {
    parts: ['thead', 'th', 'tr', 'td'],
    baseStyle: {
      table: {},
    },
    variants: {
      striped: {
        thead: {
          verticalAlign: 'middle',
          borderColor: 'gray.200',
          borderWidth: '1px',
        },
        th: {
          backgroundColor: 'gray.100',
          verticalAlign: 'middle',
          fontWeight: 'bold',
          borderBottomWidth: '1px',
          borderColor: 'gray.200',
          color: 'gray.500',
          paddingX: '4px',
          _first: {
            paddingX: '12px',
          },
          _last: {
            paddingX: '12px',
          },
        },
        td: {
          _first: {
            paddingX: '12px',
          },
          _last: {
            paddingX: '12px',
          },
          verticalAlign: 'middle',
          minWidth: '36px',
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderColor: 'gray.200',
          paddingX: '6px',
        },
        tr: {
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderColor: 'gray.200',
          verticalAlign: 'middle',
          _even: {
            backgroundColor: 'gray.10',
          },
          _odd: {
            backgroundColor: 'white',
          },
          // _last: {
          //   td: {
          //     _last: {
          //       borderBottomRadius: '8px',
          //     },
          //   },
          // },
        },
      },
    },
    defaultProps: {
      size: 'sm',
    },
  },
  Th: {
    variants: {
      sortable: {
        cursor: 'pointer',
        _hover: {
          bg: 'gray.100',
        },
      },
    },
  },
}
