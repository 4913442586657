import { waitUntil } from '@app/shared'
import { Box, Button } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { MdOutlineRefresh } from 'react-icons/md'

export const RefreshButton = () => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = React.useState(false)
  const onClick = React.useCallback(async () => {
    setIsLoading(true)
    queryClient.invalidateQueries()
    return waitUntil(() => queryClient.isFetching() === 0, 3000).finally(() =>
      setIsLoading(false),
    )
  }, [queryClient])

  return (
    <Box position={'absolute'} top={'0px'} right={'0px'}>
      <Button
        variant={'transparent-clickable'}
        onClick={onClick}
        isLoading={isLoading}
      >
        <MdOutlineRefresh size={'20px'} />
      </Button>
    </Box>
  )
}
