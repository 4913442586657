import { User } from '@prisma/client'
import React from 'react'
import { AutoCompleteInput, Choice } from './AutoCompleteInput'
import { UserWithIcon } from './user/UserWithIcon'

export const UserSelect: React.FC<{
  type?: 'input' | 'select' | 'mustSelect'
  candidates: User[]
  defaultValue: User | undefined
  placeholder?: string
  onChange: (user: User | null) => void
}> = ({ type, candidates, placeholder, defaultValue, onChange }) => {
  const choices: Choice[] = React.useMemo(() => {
    return candidates.map(
      (user) =>
        ({
          id: user.id,
          element: <UserWithIcon user={user} />,
          text: user.name,
        }) as Choice,
    )
  }, [candidates])
  const _defaultValue = React.useMemo(() => {
    return defaultValue
      ? choices.find((c) => c.id === defaultValue.id)
      : undefined
  }, [defaultValue, choices])

  if (type === 'mustSelect') {
    return (
      <AutoCompleteInput
        type={'mustSelect'}
        placeholder={placeholder || 'ユーザーを選択'}
        emptyText='候補がありません'
        choices={choices}
        onChange={(v: Choice) => {
          const user = candidates.find((u) => u.id === v.id)
          if (user) {
            return onChange(user)
          }
        }}
        defaultValue={_defaultValue}
      />
    )
  } else {
    return (
      <AutoCompleteInput
        type={type || 'select'}
        placeholder={placeholder || 'ユーザーを選択'}
        emptyText='候補がありません'
        choices={choices}
        onChange={(v: Choice | null) => {
          if (!v) {
            onChange(v)
            return
          }
          const user = candidates.find((u) => u.id === v.id)
          if (user) {
            return onChange(user)
          }
        }}
        defaultValue={_defaultValue}
      />
    )
  }
}
