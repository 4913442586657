import React from 'react'
import {
  HStack,
  VStack,
  Spinner,
  Divider,
  Text,
  Heading,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  StackDivider,
  Badge,
} from '@chakra-ui/react'
import { RouterOutput, trpc } from '@/lib/trpc'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { YearMonth } from '@/context/yearMonth'
import { atom, useAtom } from 'jotai'
import { PaginationIndicator, Paging } from '../ui/PaginationIndicator'
import { Layout } from '../Layout'
import { YearMonthSelector } from '../ui/YearMonthSelector'
import { PagePath } from '@/PagePath'
import { BusinessDates, dateTimeFormatter } from '@app/shared'
import { FaExternalLinkAlt } from 'react-icons/fa'
import {
  useTableSort,
  UseTableSort,
  SortableTableHeader,
} from '../ui/SortableTableHeader'
import { TaskCardFilterSchema } from '@app/api/src/usecase/taskCard/predicates'
import { TaskInputStatusTag } from './taskcard/TaskCardInputStatus'
import { TaskAttributeTag } from '../ui/TaskCardAttribute'
import { LinkButton } from '../ui/LinkButton'
import {
  ProjectSingleSelector,
  WithSingleProjectSelection,
} from '../ui/ProjectSelector/ProjectSingleSelector'
import { SelectedProject } from '../ui/ProjectSelector/type'
import { ErrorCard, ErrorPage } from '@/pages/ErrorPage'
import { TextEllipsis } from '../ui/TextEllipsis'
import { taskCardComparator } from '@/helper/taskCard'
import { ProjectWithColor } from '../ui/ProjectWithColor'
import { TimeSlot } from '../ui/TimeSlot'
import { DateForTable } from '../ui/DateForTable'
import { RefreshButton } from '../ui/RefreshButton'

type Project = NonNullable<RouterOutput['project']['show']>
type TaskCard = RouterOutput['taskCard']['list'][number]

type InputStatusListSort = {
  field: 'startBusinessDate' | 'deadlineBusinessDate'
  direction: 'asc' | 'desc'
}
const inputStatusListSortAtom = atom<InputStatusListSort>({
  field: 'startBusinessDate',
  direction: 'asc',
})

// データの取得はpagingせず全件取得するのでUI用
export const InputStatusList: AuthenticatedComponent<{
  yearMonth: YearMonth
}> = ({ self, yearMonth }) => {
  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        w={'full'}
        borderRadius={'4px'}
        bgColor={'white'}
        padding={'16px'}
        boxShadow={'base'}
      >
        <RefreshButton />
        <Box w={'fit-content'}>
          <YearMonthSelector />
        </Box>

        <Heading size={'lg'}>input資料進捗表</Heading>

        <StackDivider />

        <ProjectSingleSelector />
      </VStack>

      <Divider />
      <WithSingleProjectSelection>
        {(selectedProject) => {
          return (
            <InputStatusListBody
              project={selectedProject}
              yearMonth={yearMonth}
            />
          )
        }}
      </WithSingleProjectSelection>
    </Layout>
  )
}

const InputStatusListBody: React.FC<{
  project: SelectedProject
  yearMonth: YearMonth
}> = ({ project, yearMonth }) => {
  const useSort = useTableSort(inputStatusListSortAtom)

  const projectMonthQuery = trpc.projectMonth.show.useQuery({
    projectId: project.id,
    yearMonth,
  })

  const businessDates = React.useMemo(() => {
    if (projectMonthQuery.data) {
      return BusinessDates.of(projectMonthQuery.data)
    } else {
      return BusinessDates.fallback(yearMonth, project)
    }
  }, [project, projectMonthQuery.data, yearMonth])

  if (projectMonthQuery.isLoading) {
    return <Spinner />
  }
  if (!businessDates) {
    return <ErrorPage errorMessage={'データがありません'} />
  }

  if (projectMonthQuery.error) {
    return <ErrorPage errorMessage={projectMonthQuery.error.message} />
  }

  return (
    <VStack spacing={'56px'} w={'full'} p={0} mt={'56px'}>
      <VStack
        w={'full'}
        spacing={'24px'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <Heading size={'md'}>期限切れ</Heading>
        <React.Suspense fallback={<Spinner />}>
          <InputStatusListTableBody
            project={project}
            useSort={useSort}
            businessDates={businessDates}
            yearMonth={yearMonth}
            type={'expired'}
          />
        </React.Suspense>
      </VStack>
      <VStack
        w={'full'}
        spacing={'24px'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <Heading size={'md'}>実施予定日を遅延</Heading>
        <React.Suspense fallback={<Spinner />}>
          <InputStatusListTableBody
            project={project}
            useSort={useSort}
            businessDates={businessDates}
            yearMonth={yearMonth}
            type={'delayed'}
          />
        </React.Suspense>
      </VStack>
      <VStack
        w={'full'}
        spacing={'24px'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <Heading size={'md'}>その他</Heading>
        <React.Suspense fallback={<Spinner />}>
          <InputStatusListTableBody
            project={project}
            useSort={useSort}
            businessDates={businessDates}
            yearMonth={yearMonth}
            type={'others'}
          />
        </React.Suspense>
      </VStack>
    </VStack>
  )
}

const InputStatusListTableBody: React.FC<{
  project: SelectedProject
  useSort: UseTableSort<InputStatusListSort>
  businessDates: BusinessDates
  yearMonth: YearMonth
  type: 'expired' | 'delayed' | 'others'
}> = ({ project, yearMonth, useSort, businessDates, type }) => {
  const filter: TaskCardFilterSchema = React.useMemo(() => {
    return {
      yearMonths: [yearMonth],
      projectIds: [project.id],
      extendedInputStatus: type,
    } as TaskCardFilterSchema
  }, [project.id, type, yearMonth])

  const inputStatusListPagingAtom = React.useMemo(
    () =>
      atom<Paging & { isLoading: boolean; total: number | undefined }>({
        total: undefined,
        isLoading: false,
        perPage: 15,
        page: 1,
      }),
    [],
  )
  const [paging, setPaging] = useAtom(inputStatusListPagingAtom)

  const countQuery = trpc.taskCard.count.useQuery({
    filter,
  })
  const taskCardsQuery = trpc.taskCard.list.useQuery({
    filter,
    pagination: {
      perPage: paging.perPage,
      page: paging.page,
      sort: {
        field: useSort[0].field || 'startBusinessDate',
        direction: useSort[0].direction || 'asc',
      },
    },
  })

  React.useEffect(() => {
    if (countQuery.data) {
      setPaging((prev) => ({
        ...prev,
        total: countQuery.data,
        isLoading: false,
      }))
    }
  }, [countQuery.data, setPaging])

  if (taskCardsQuery.isInitialLoading || countQuery.isInitialLoading) {
    return <Spinner />
  }
  if (taskCardsQuery.error || countQuery.error) {
    return (
      <ErrorCard
        errorMessage={
          (countQuery.error || taskCardsQuery.error)?.message || 'エラー'
        }
      />
    )
  }

  return (
    <VStack spacing={0} w={'full'} p={0}>
      <Box w={'full'}>
        <Table variant='striped' layout={'auto'} w={'full'}>
          <Thead w={'full'}>
            <Tr height={'3rem'} paddingY={'10px'} whiteSpace={'nowrap'}>
              <SortableTableHeader
                useSort={useSort}
                field={'startBusinessDate'}
              >
                実施予定日
              </SortableTableHeader>
              <SortableTableHeader
                useSort={useSort}
                field={'deadlineBusinessDate'}
              >
                期限
              </SortableTableHeader>
              <Th w={'fit-content'}>プロジェクト名</Th>
              <Th w={'fit-content'}>属性</Th>
              <Th w={'fit-content'}>No.</Th>
              <Th w={'fit-content'}>タスクカード名</Th>
              <Th w={'fit-content'}>input資料受領状況</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {taskCardsQuery.data && taskCardsQuery.data.length > 0 ? (
              taskCardsQuery.data.map((taskCard) => {
                return (
                  <InputStatusListTableRow
                    key={taskCard.id}
                    taskCard={taskCard}
                    project={project}
                    businessDates={businessDates}
                  />
                )
              })
            ) : (
              <Tr>
                <Td colSpan={8}>該当するタスクカードがありません</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <HStack w={'full'} justifyContent={'flex-end'} mt={'12px'}>
        <PaginationIndicator
          total={paging.total ?? 0}
          paging={paging}
          isLoading={!paging.total || paging.isLoading}
          onChangePage={(page) => {
            setPaging((prev) => ({
              ...prev,
              page,
              perPage: paging.perPage,
            }))
          }}
        />
      </HStack>
    </VStack>
  )
}

const InputStatusListTableRow: React.FC<{
  project: SelectedProject
  taskCard: TaskCard
  businessDates: BusinessDates
}> = ({ project, taskCard, businessDates }) => {
  const { startBusinessDate, deadlineBusinessDate } = React.useMemo(() => {
    return {
      startBusinessDate: taskCard.startBusinessDate
        ? businessDates.toDate(taskCard.startBusinessDate)
        : undefined,
      deadlineBusinessDate: taskCard.deadlineBusinessDate
        ? businessDates.toDate(taskCard.deadlineBusinessDate)
        : undefined,
    }
  }, [businessDates, taskCard.deadlineBusinessDate, taskCard.startBusinessDate])

  const { startDateColor, deadlineDateColor } = React.useMemo(() => {
    switch (taskCard.derivedStatus) {
      case 'delayed/client_matter':
      case 'delayed/self_matter':
        return { startDateColor: 'red.500', deadlineDateColor: 'red.500' }
      case 'should_start/client_matter':
      case 'should_start/self_matter':
        return { startDateColor: 'red.500', deadlineDateColor: 'gray.700' }
      default:
        return { startDateColor: undefined, deadlineDateColor: undefined }
    }
  }, [taskCard.derivedStatus])

  return (
    <Tr maxW={'full'} justifyContent={'center'} alignSelf={'center'}>
      <Td verticalAlign={'middle'}>
        <DateForTable
          date={startBusinessDate}
          businessDate={undefined}
          timeslotStart={taskCard.startTimeslotStart}
          timeslotEnd={taskCard.startTimeslotEnd}
          textColor={startDateColor}
        />
      </Td>
      <Td verticalAlign={'middle'}>
        <DateForTable
          date={deadlineBusinessDate}
          businessDate={undefined}
          timeslotStart={taskCard.deadlineTimeslotStart}
          timeslotEnd={taskCard.deadlineTimeslotEnd}
          textColor={deadlineDateColor}
        />
      </Td>
      <Td whiteSpace={'nowrap'} verticalAlign={'middle'}>
        <ProjectWithColor project={project} />
      </Td>
      <Td verticalAlign={'middle'}>
        <TaskAttributeTag attribute={taskCard.attribute} />
      </Td>
      <Td verticalAlign={'middle'}>{taskCard.taskNumber}</Td>
      <Td verticalAlign={'middle'}>
        <TextEllipsis>{taskCard.title}</TextEllipsis>
      </Td>
      <Td textAlign={'center'} minWidth={'fit-content'}>
        <TaskInputStatusTag inputStatus={taskCard.inputStatus} />
      </Td>
      <Td verticalAlign={'middle'}>
        <HStack>
          <LinkButton
            to={PagePath.taskCard.show(
              taskCard.projectId,
              taskCard.taskNumber,
              taskCard.projectMonth,
            )}
            variant={'transparent-clickable'}
            color={'gray.500'}
            size={'sm'}
          >
            <FaExternalLinkAlt />
          </LinkButton>
        </HStack>
      </Td>
    </Tr>
  )
}
