import { Text, Th, HStack } from '@chakra-ui/react'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import React from 'react'
import { MdOutlineArrowUpward, MdOutlineArrowDownward } from 'react-icons/md'

type TableSort = {
  field: string | undefined
  direction: 'asc' | 'desc' | undefined
}
type TableSortValue = {
  [field: string]: 'asc' | 'desc' | undefined
}
type TableSortAtom<T extends TableSort> = ReturnType<typeof atom<T>>
const readTableSort = <T extends TableSort>(tableSortAtom: TableSortAtom<T>) =>
  atom<[T, TableSortValue]>((get) => {
    const sort = get(tableSortAtom)
    return sort.field
      ? [
          sort,
          {
            [sort.field]: sort.direction,
          },
        ]
      : [sort, {}]
  })
const changeTableSort = <T extends TableSort>(
  tableSortAtom: TableSortAtom<T>,
) =>
  atom<null, TableSort['field'][], T>(null, (get, set, field) => {
    const prev = get(tableSortAtom)
    const newValue = {
      field: field,
      direction:
        prev.field === field
          ? prev.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'desc',
    } as T
    set(tableSortAtom, newValue)
    return newValue
  })

export const useTableSort = <T extends TableSort>(
  tableSortAtom: TableSortAtom<T>,
) => {
  const readAtom = React.useMemo(
    () => readTableSort(tableSortAtom),
    [tableSortAtom],
  )
  const [sort, sortValue] = useAtomValue(readAtom)
  const writeAtom = React.useMemo(
    () => changeTableSort(tableSortAtom),
    [tableSortAtom],
  )
  const setSort = useSetAtom(writeAtom)
  return [sort, sortValue, setSort] as UseTableSort<T>
}
export type UseTableSort<T extends TableSort> = [
  T,
  TableSortValue,
  (...field: string[]) => void,
]

export const SortableTableHeader = <T extends TableSort>({
  field,
  useSort,
  children,
}: {
  field: string
  useSort: UseTableSort<T>
  children: React.ReactNode
}) => {
  const [, sort, setSort] = useSort
  const onClick = React.useCallback(() => {
    setSort(field)
  }, [field, setSort])

  return (
    <Th cursor={'pointer'} onClick={onClick}>
      <HStack>
        <Text>{children}</Text>
        {sort[field] ? (
          sort[field] === 'asc' ? (
            <AscIcon />
          ) : (
            <DescIcon />
          )
        ) : (
          <NeutralIcon />
        )}
      </HStack>
    </Th>
  )
}

const NeutralIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.90267 7.59554L5.80076 8.49363L9.36127 4.93949L9.99821 4.31926L10.6352 4.93949L14.1893 8.5L15.0938 7.59554L9.99821 2.5L4.90267 7.59554Z'
        fill='#718096'
      />
      <path
        d='M15.0934 12.4045L14.1953 11.5064L10.6348 15.0605L9.99789 15.6807L9.36094 15.0605L5.8068 11.5L4.90234 12.4045L9.99789 17.5L15.0934 12.4045Z'
        fill='#718096'
      />
    </svg>
  )
}

const AscIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.16797 10.0003L6.10797 10.9403L9.83464 7.22033V15.3337H11.168V7.22033L14.888 10.947L15.8346 10.0003L10.5013 4.66699L5.16797 10.0003Z'
        fill='#718096'
      />
    </svg>
  )
}
const DescIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3346 10.0003L14.3946 9.06033L10.668 12.7803V4.66699H9.33464V12.7803L5.61464 9.05366L4.66797 10.0003L10.0013 15.3337L15.3346 10.0003Z'
        fill='#718096'
      />
    </svg>
  )
}
