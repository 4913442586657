import React from 'react'
import { createBrowserRouter, Route, Routes } from 'react-router-dom'
import { ErrorPage } from '../pages/ErrorPage'
import { LoginPage } from '../pages/LoginPage'
import { RouterPath } from '../PagePath'
import { LogOutPage } from '../pages/LogOutPage'
import { AuthenticatedRouter } from './authenticatedRouter'
import { ProvidersInSideRouter } from '@/Providers'

const Root = () => {
  return (
    <ProvidersInSideRouter>
      <Routes>
        <Route path={RouterPath.login} element={<LoginPage />} />
        <Route path={RouterPath.logout} element={<LogOutPage />} />

        {/* それ以外は認証前提のroute */}
        <Route path='*' element={<AuthenticatedRouter />} />
      </Routes>
    </ProvidersInSideRouter>
  )
}

export const router = createBrowserRouter([
  {
    path: '*',
    Component: Root,
    errorElement: <ErrorPage />,
  },
])
