import { TaskCardList } from '@/components/pages/taskcard/list'
import { YearMonthContextProvider } from '@/context/yearMonth'
import { Authenticated } from '@/pages/AuthenticatedPage'
import React from 'react'

export const TaskCardListPage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <YearMonthContextProvider>
            {(yearMonth) => (
              <TaskCardList
                {...{
                  yearMonth,
                  firebaseUser,
                  self,
                }}
              ></TaskCardList>
            )}
          </YearMonthContextProvider>
        )
      }}
    </Authenticated>
  )
}
