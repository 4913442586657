import { YearMonth } from '@app/shared'
import { addDays, differenceInDays, startOfMonth, endOfMonth } from 'date-fns'

export const isSunday = (date: Date) => date.getDay() === 0
export const isSaturday = (date: Date) => date.getDay() === 6
export const calendarDates = (yearMonth: YearMonth) => {
  const base = new Date(yearMonth.year, yearMonth.month - 1, 1, 12, 0, 0) // JSTでも大丈夫なように
  const monthStart = startOfMonth(base)
  const monthEnd = endOfMonth(base)
  const monthDates = Array.from(
    Array(1 + differenceInDays(monthEnd, monthStart)).keys(),
  ).map((day: number) => {
    return addDays(monthStart, day)
  })
  return {
    base,
    monthStart,
    monthEnd,
    monthDates,
  }
}
