import React from 'react'
import { logger } from '@/lib/logger'
import {
  Text,
  Box,
  HStack,
  VStack,
  useToast,
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Heading,
  Select,
  Spacer,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { RouterOutput, trpc } from '@/lib/trpc'
import {
  MdArrowForwardIos,
  MdOutlineCancel,
  MdOutlineEdit,
  MdOutlineSave,
} from 'react-icons/md'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { SortableTableHeader, useTableSort } from '../../ui/SortableTableHeader'
import { dateTimeFormatter } from '@app/shared'
import { Picture } from '../../ui/user/picture'
import { UserRoleSelect, UserRoleTag } from '../../ui/user/role'
import { UserRole } from '@prisma/client'
import { atom } from 'jotai'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'
import { Navigate } from 'react-router-dom'
import { ErrorPage } from '@/pages/ErrorPage'
import { PagePath } from '@/PagePath'
import { Layout } from '@/components/Layout'
import { RefreshButton } from '@/components/ui/RefreshButton'

type AccountListSort = {
  field: 'name' | 'lastLoginAt' | undefined
  direction: 'asc' | 'desc' | undefined
}
const accountListSortAtom = atom<AccountListSort>({
  field: 'name',
  direction: 'asc',
})
export const AdminAccounts: AuthenticatedComponent = ({
  self,
  firebaseUser,
}) => {
  const query = trpc.user.list.useQuery(
    {},
    {
      enabled: self.role === 'admin',
    },
  )
  const toast = useToast()

  if (self.role !== 'admin') {
    return <ErrorPage errorMessage={`アカウントを管理する権限がありません`} />
  }
  if (query.error) {
    return <ErrorPage errorMessage={query.error.message} />
  }
  if (!query.data) {
    return <ErrorPage errorMessage='データが取得できませんでした' />
  }

  return (
    <Layout>
      <VStack
        justifyContent={'center'}
        alignItems={'flex-start'}
        w={'full'}
        textAlign={'center'}
        spacing={'0'}
      >
        <RefreshButton />
        <Heading size={'lg'}>アカウント管理</Heading>
        <Spacer mt={'14'} />
        {query.isLoading ? (
          <Spinner />
        ) : (
          <UsersTable users={query.data ?? []} />
        )}
      </VStack>
    </Layout>
  )
}

const UsersTable: React.FC<{ users: RouterOutput['user']['list'] }> = ({
  users,
}) => {
  const useSort = useTableSort(accountListSortAtom)
  const { field, direction } = useSort[0]
  return (
    <Box w={'full'}>
      <Table variant='striped' layout={'auto'} w={'full'}>
        <Thead w={'full'}>
          <Tr height={'3rem'} paddingY={'10px'}>
            <SortableTableHeader field={'name'} useSort={useSort}>
              名前
            </SortableTableHeader>
            <Th w={'fit-content'} whiteSpace={'nowrap'}>
              メールアドレス
            </Th>
            <Th w={'fit-content'}>権限</Th>
            <SortableTableHeader field={'lastLoginAt'} useSort={useSort}>
              最終ログイン
            </SortableTableHeader>
          </Tr>
        </Thead>
        <Tbody>
          {users.length > 0 ? (
            users
              .sort((u1, u2) => {
                const _field = field || 'name'
                const f1 = u1[_field]
                const f2 = u2[_field]
                if (f1 === f2) {
                  return 0
                }
                if (typeof f1 === 'string' && typeof f2 === 'string') {
                  return direction === 'asc'
                    ? f1.localeCompare(f2)
                    : f2.localeCompare(f1)
                }
                return direction === 'asc'
                  ? (f1 as Date).getTime() - (f2 as Date).getTime()
                  : (f2 as Date).getTime() - (f1 as Date).getTime()
              })
              .map((user) => {
                return <UsersTableRow key={user.id} user={user} />
              })
          ) : (
            <Tr>
              <Td colSpan={4}>アカウントがありません</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}

const UsersTableRow = ({
  user,
}: {
  user: RouterOutput['user']['list'][number]
}) => {
  const [editing, setEditing] = React.useState(false)
  const [selectedRole, setSelectedRole] = React.useState<UserRole | undefined>(
    undefined,
  )
  const toast = useToast()
  const trpcUtil = trpc.useContext()
  const mutation = trpc.user.update.useMutation({
    onSuccess: (result) => {
      if (result.ok) {
        logger.info(`userUpdate succeeded`, { result })
        toast.closeAll()
        toast({
          title: `ユーザ情報を更新しました`,
          status: 'success',
        })
        setSelectedRole(undefined)
        setEditing(false)
        trpcUtil.user.list.invalidate()
      } else {
        logger.info(`userUpdate failed`, {
          error: result.errorMessage,
        })
        toast({
          title: `ユーザ情報の更新に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.info(`userUpdate failed`, { error })
      toast({
        title: `ユーザ情報の更新に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  return (
    <Tr maxW={'full'} justifyContent={'center'} alignSelf={'center'}>
      <Td verticalAlign={'middle'}>
        <UserWithIcon user={user} />
      </Td>
      <Td verticalAlign={'middle'}>{user.userAccounts[0]?.email}</Td>
      <Td verticalAlign={'middle'} whiteSpace={'nowrap'} w={'15em'}>
        {editing ? (
          <HStack alignItems={'center'} justifyContent={'flex-end'}>
            <UserRoleSelect
              w={'fit-content'}
              onChange={(e) => {
                const newRole = e.target.value as UserRole
                // setSelectedRole(newRole)
                mutation.mutateAsync({ id: user.id, role: newRole })
              }}
              isDisabled={mutation.isLoading}
            />
            {/* {mutation.isLoading && <Spinner />} */}
            <Button
              isLoading={mutation.isLoading}
              variant={'transparent-clickable'}
              size={'sm'}
              onClick={() => setEditing(false)}
            >
              <MdOutlineCancel />
            </Button>
          </HStack>
        ) : (
          <HStack alignItems={'center'} justifyContent={'flex-end'} spacing={0}>
            <UserRoleTag role={user.role} />
            <Button
              variant={'transparent-clickable'}
              size={'sm'}
              onClick={() => setEditing(true)}
              // visibility={user.role !== 'admin' ? 'visible' : 'hidden'}
              // isDisabled={user.role === 'admin'}
            >
              <Box w={'12px'} style={{ rotate: '90deg' }}>
                <MdArrowForwardIos />
              </Box>
            </Button>
          </HStack>
        )}
      </Td>
      <Td whiteSpace={'nowrap'} verticalAlign={'middle'}>
        {dateTimeFormatter.jst['YYYY/MM/DD(E) HH:mm'](user.lastLoginAt)}
      </Td>
    </Tr>
  )
}
