import { Box } from '@chakra-ui/react'

export const LayoutWithStickyHeader: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <Box w={'full'} minW={'992px'} paddingY={'80px'} paddingX={'32px'}>
      {children}
    </Box>
  )
}

export const Layout: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <Box w={'full'} minW={'992px'} paddingY={'56px'} paddingX={'32px'}>
      {children}
    </Box>
  )
}
