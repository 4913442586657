import { trpc } from '@/lib/trpc'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { logger } from '@/lib/logger'
import { ProjectMonthlyEvents } from './ProjectMonthlyEvents'
import { ProjectYearlyEventsEditor } from './ProjectYearlyEvents'
import { BusinessDateMonthlyCalendarEditView } from '../../ui/ProjectCalendar'
import { FormProvider, useForm } from 'react-hook-form'
import type { ProjectCreateSchema } from '@app/api/src/usecase/project/createProject'
import { useNavigate } from 'react-router-dom'
import { PagePath } from '@/PagePath'
import { ColorPalette } from './ColorPalette'
import { Layout } from '@/components/Layout'
import { Link } from '@/components/ui/LinkButton'
import { MdOutlineInfo } from 'react-icons/md'
import { UserSelect } from '@/components/ui/UserSelect'
import { ProjectAssignedMemberListInput } from './ProjectAssignedMemberListInput'

export const ProjectCreate: AuthenticatedComponent = ({
  self,
  firebaseUser,
}) => {
  const pmUsers = trpc.user.list.useQuery({
    filter: {
      role: ['pm', 'admin'], // 'admin'は必要？
    },
  })
  const toast = useToast()
  const util = trpc.useContext()
  const navigate = useNavigate()
  const mutation = trpc.project.create.useMutation({
    onSuccess: async (res) => {
      if (res.ok) {
        await util.project.invalidate()
        toast({
          title: '保存しました',
          status: 'success',
        })
        navigate(PagePath.project.show(Number(res.result.id)))
      } else {
        logger.error('ProjectCreation updateMutation', { res })
        toast({
          title: `保存に失敗しました ${res.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.error('ProjectCreation updateMutation', error)
      toast({
        title: `保存に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  const onSubmit = React.useCallback(() => {}, [])
  const form = useForm<ProjectCreateSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  return (
    <Layout>
      <FormProvider {...form}>
        <form
          style={{ width: '100%' }}
          onSubmit={form.handleSubmit((input) => mutation.mutateAsync(input))}
        >
          <VStack spacing={'56px'} alignItems={'flex-start'}>
            <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                クライアント情報
              </Text>
              <Card>
                <FormControl
                  isRequired
                  isInvalid={!!form.formState.errors?.name}
                >
                  <VStack
                    w={'full'}
                    spacing={'10px'}
                    px={'20px'}
                    py={'24px'}
                    alignItems={'flex-start'}
                  >
                    <FormLabel fontSize={'xl'} fontWeight={'bold'}>
                      プロジェクト名
                    </FormLabel>
                    <Input {...form.register('name')} />
                    <FormErrorMessage>
                      {form.formState.errors?.name?.message}
                    </FormErrorMessage>
                  </VStack>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={!!form.formState.errors?.hearingSheetUrl}
                >
                  <VStack
                    w={'full'}
                    spacing={'10px'}
                    px={'20px'}
                    py={'24px'}
                    alignItems={'flex-start'}
                  >
                    <FormLabel fontSize={'xl'} fontWeight={'bold'}>
                      ヒアリングシートURL
                    </FormLabel>
                    <Input
                      {...form.register('hearingSheetUrl', {
                        validate: (value) => {
                          if (value && !value.match(/^http[s]?:\/\//)) {
                            return 'URLを指定してください'
                          }
                          return true
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {form.formState.errors?.hearingSheetUrl?.message}
                    </FormErrorMessage>
                  </VStack>
                </FormControl>
              </Card>
            </VStack>

            <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                月次イベント
              </Text>
              <Card>
                <ProjectMonthlyEvents />
              </Card>
            </VStack>

            <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                年間イベント
              </Text>
              <Card>
                <ProjectYearlyEventsEditor />
              </Card>
            </VStack>

            <VStack
              w={'full'}
              minW={'992px'}
              spacing={'24px'}
              alignItems={'flex-start'}
            >
              <HStack spacing={'24px'} alignItems={'center'}>
                <Text fontSize={'2xl'} fontWeight={'bold'}>
                  休業日
                </Text>
                <Link
                  to={'https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html'}
                  textDecoration={'underline'}
                  target='_blank'
                >
                  <HStack alignItems={'center'}>
                    <Box w={'24px'} h={'24px'} color={'gray.500'}>
                      <MdOutlineInfo size={'24px'} />
                    </Box>
                    <Text>日本の祝日</Text>
                  </HStack>
                </Link>
              </HStack>
              <Card>
                <BusinessDateMonthlyCalendarEditView
                  mode='new'
                  onChange={async (nonBusinessDates) => {
                    form.setValue('nonBusinessDates', nonBusinessDates, {
                      shouldDirty: true,
                    })
                  }}
                  monthlyEvents={[]}
                  defaultNonBusinessDates={[]}
                />
              </Card>
            </VStack>

            <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                担当PM
              </Text>
              <Card>
                {/* <Text>{project.assignedPmUser.name}</Text> */}
                <FormControl isRequired>
                  <UserSelect
                    type={'mustSelect'}
                    candidates={pmUsers.data || []}
                    defaultValue={undefined}
                    onChange={(user) => {
                      if (user) {
                        form.setValue('assignedPmUserId', user.id, {
                          shouldDirty: true,
                        })
                      }
                    }}
                  />
                  <FormErrorMessage>
                    {form.formState.errors?.assignedPmUserId?.message}
                  </FormErrorMessage>
                </FormControl>
              </Card>
            </VStack>

            <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                メンバー
              </Text>
              <Card>
                <ProjectAssignedMemberListInput projectAssignList={[]} />
              </Card>
            </VStack>

            <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                プロジェクトのカラー
              </Text>
              <Card>
                <FormControl isRequired>
                  <ColorPalette
                    onChange={(color) => {
                      form.setValue('color', color)
                    }}
                  />
                  <FormErrorMessage>
                    {form.formState.errors?.color?.message}
                  </FormErrorMessage>
                </FormControl>
              </Card>
            </VStack>

            <HStack w={'full'} justifyContent={'flex-end'}>
              <Button
                variant={'green-fill'}
                type={'submit'}
                isLoading={mutation.isLoading}
                // isDisabled={!form.formState.isValid}
              >
                保存
              </Button>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </Layout>
  )
}

const Card: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <VStack
      alignItems={'flex-start'}
      bgColor={'white'}
      paddingX={'16px'}
      paddingY={'16px'}
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.200'}
    >
      {children}
    </VStack>
  )
}
