import { initializeApp } from 'firebase/app'

import {
  User as FirebaseUser,
  getAdditionalUserInfo,
  signInWithPopup,
  GoogleAuthProvider,
  getAuth,
  updateProfile,
} from 'firebase/auth'
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { logger } from './logger'
export type { User as FirebaseUser } from 'firebase/auth'

const app = initializeApp({
  projectId: import.meta.env.VITE_GOOGLE_CLOUD_PROJECT,
  apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASEURL,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENTID,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGEBUCKET,
})

logger.info(`initialized firebase app`, {
  mode: import.meta.env.MODE,
  env: import.meta.env.NODE_ENV,
  project: app.options.projectId,
})

// undefinedのときにエラーを吐かない設定。型を完璧にできているのなら、nullを入れずにやれるのでめちゃくちゃ良いが、ゆるく使ってるなら、意図しないundefinedが通ってしまうのでやめる
// _db.settings({ ignoreUndefinedProperties: true })

const auth = getAuth(app)
// auth.setPersistence(Auth.Persistence.SESSION)

const analytics = getAnalytics(app)
setAnalyticsCollectionEnabled(analytics, false)

// ローカルで実行中の場合は、エミュレータを使う
// if (GLOBAL_CONFIG.USE_EMULATOR) {
//   console.log(`*** Use Firebase emulator ***`);
//   db.useEmulator('localhost', 8888);
//   myAuth.useEmulator('http://localhost:8887');
// }

export const firebaseAuthDispatcher = {
  underlying: auth,
  buildErrorMessage: (e: { code: string }): string => {
    // https://firebase.google.com/docs/auth/admin/errors?hl=ja
    switch (e.code) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
      case 'auth/invalid-login-credentials':
        return 'メールアドレスまたはパスワードが間違っています'
      case 'auth/invalid-email':
        return 'メールアドレスの形式が正しくありません'
      case 'auth/email-already-in-use':
        return '既に登録されているメールアドレスです'
      case 'auth/weak-password':
        return 'パスワードが簡単すぎます'
      case 'auth/too-many-requests':
        return 'ログイン試行回数が多すぎます。時間を空けて再度お試しください'
      case 'auth/requires-recent-login':
        return '画面をリロードして再度お試しください'
      default:
        return e.code
    }
  },
  loginWithGoogle: async (): Promise<FirebaseUser> => {
    const provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
    auth.useDeviceLanguage()
    provider.setCustomParameters({
      login_hint: 'hogehoge@example.com',
    })

    return signInWithPopup(auth, provider)
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error)
        logger.error(`loginWithGoogle`, error, { credential })
        return Promise.reject(`failed due to ${error}`)
      })
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const info = getAdditionalUserInfo(result)
        logger.info(`loginWithGoogle`, { result, credential, info })
        if (credential) {
          if (
            result.user.displayName !== String(info?.profile?.name) ||
            result.user.photoURL !== String(info?.profile?.picture)
          ) {
            await updateProfile(result.user, {
              displayName:
                String(info?.profile?.name) || result.user.displayName,
              photoURL: String(info?.profile?.picture) || result.user.photoURL,
            })
          }
          await auth.updateCurrentUser(result.user)
          await firebaseAuthDispatcher.getIdToken()
          return result.user
        } else {
          return Promise.reject(
            'failed to loginWithGoogle credential is missing',
          )
        }
      })
  },
  logout: async () => {
    return auth.signOut().then(() => auth.updateCurrentUser(null))
  },
  getIdToken: async () => {
    return auth.currentUser?.getIdToken() //.then((token) => token.trim())
  },
  get currentUser() {
    return auth.currentUser
  },
}
