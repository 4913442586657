import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

export const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://ce76cc1ec1c66cd97cca34e16df4ccdc@o1126296.ingest.us.sentry.io/4506986908352512',
    environment: import.meta.env.MODE,

    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.01,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/pt-dig-nest-web-dev.web\.app\/api/,
      /^https:\/\/dig-nest-tool\.net\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.05,
    // replaysOnErrorSampleRate: 1.0,
  })
}
