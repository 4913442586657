import { useDialog } from '@/components/ui/Dialog'
import { TextWithLinks } from '@/components/ui/TextWithLInks'
import { Picture } from '@/components/ui/user/picture'
import { BusinessDates } from '@app/shared'
import { logger } from '@/lib/logger'
import { dateTimeFormatter } from '@app/shared'
import {
  Text,
  useToast,
  VStack,
  HStack,
  Heading,
  Button,
  StackDivider,
  Textarea,
  FormControl,
  FormErrorMessage,
  Input,
  Badge,
  Box,
} from '@chakra-ui/react'
import { MdOutlineAdd, MdOutlineRefresh } from 'react-icons/md'
import { TaskCardDetail, TaskCardDetailComment } from '../types'
import React from 'react'
import { trpc } from '@/lib/trpc'
import {
  QuestionToClientInput,
  TaskCardQuestionToClientAddDialog,
} from './ClientQuestionDialog'
import { useForm } from 'react-hook-form'
import { QuestionToClientStatus } from '@/components/ui/ClientQuestionStatus'
import { DateTimeWithBusinessDate } from '@/components/ui/DateWithBusinessDate'
import { QuestionToClient, isClientQuestion } from '@/helper/comments'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'

type QuestionToClientWithReplies = {
  question: QuestionToClient
  replies: QuestionToClient[]
}

export const TaskCardClientQuestions: React.FC<{
  task: TaskCardDetail
  businessDates: BusinessDates
}> = ({ task, businessDates }) => {
  const questions: QuestionToClientWithReplies[] = React.useMemo(() => {
    return (task.comments || [])
      .filter(isClientQuestion)
      .sort((a, b) => {
        // 投稿日時の降順
        // 返信のtimestampは親よりもあとに来ることを前提とする(usecaseで保証している)
        const cmp = a.timestamp.getTime() - b.timestamp.getTime()
        if (cmp !== 0) {
          return cmp
        } else {
          return Number(a.id - b.id)
        }
      })
      .reduce((acc, comment) => {
        // isClientQuestionで保証しているのにTSが理解できないのでキャストする
        const q = comment as QuestionToClient
        if (comment.replyToCommentId) {
          const target = acc.find(
            (item) => item.question.id === comment.replyToCommentId,
          )
          if (target) {
            target.replies.push(q)
          }
        } else {
          acc.push({ question: q, replies: [] })
        }
        return acc
      }, [] as QuestionToClientWithReplies[])
  }, [task.comments])

  const { isOpen: dialogIsOpen, openDialog, closeDialog } = useDialog()
  const utils = trpc.useContext()
  const createQuestionMutation = trpc.taskComment.create.useMutation({
    onSuccess: async (data) => {
      logger.info('createQuestionMutation.onSuccess', { data })
      if (data.ok) {
        await utils.taskCard.show.invalidate({
          taskNumber: task.taskNumber,
        })
      } else {
        logger.info(`createQuestionMutation failed`, {
          data,
          task,
        })
        toast({
          title: `状況の追加に失敗しました ${data.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.info(`createQuestionMutation failed`, {
        task,
        error,
      })
      toast({
        title: `状況の追加に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  const toast = useToast()

  const onClickAdd = React.useCallback(() => {
    if (!dialogIsOpen) {
      openDialog({
        body: (
          <TaskCardQuestionToClientAddDialog
            task={task}
            onSubmit={async (data: QuestionToClientInput) => {
              logger.info('onSubmit', { data })
              const input: Parameters<
                typeof createQuestionMutation.mutateAsync
              >[0] = {
                taskCardId: Number(task.id),
                text: data.text,
                timestamp: data.dateTime,
                category: 'question_client',
              }
              const result = await createQuestionMutation.mutateAsync(input)
              if (result.ok) {
                toast({
                  title: 'クライアントへの質問を追加しました',
                  status: 'success',
                })
                closeDialog()
              } else {
                toast({
                  title: `クライアントへの質問の追加に失敗しました ${result.errorCode} ${result.errorMessage}`,
                  status: 'error',
                })
              }
            }}
          />
        ),
        size: '2xl',
      })
    }
  }, [
    closeDialog,
    createQuestionMutation,
    dialogIsOpen,
    openDialog,
    task,
    toast,
  ])

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      spacing={'24px'}
      color={'gray.700'}
    >
      <HStack spacing={'24px'} alignItems={'center'}>
        <Heading size={'lg'}>クライアントへの質問</Heading>
        <Button
          size={'sm'}
          color={'white'}
          variant={'blue-fill'}
          onClick={onClickAdd}
        >
          <HStack alignItems={'center'} justifyContent={'center'}>
            <MdOutlineAdd />
            <Text>投稿</Text>
          </HStack>
        </Button>
      </HStack>

      <QuestionToClientElement
        task={task}
        businessDates={businessDates}
        questions={questions || []}
      />
    </VStack>
  )
}

const QuestionToClientElement: React.FC<{
  task: TaskCardDetail
  businessDates: BusinessDates
  questions: QuestionToClientWithReplies[]
  // businessDates: TaskBusinessDates
}> = ({ task, businessDates, questions }) => {
  if (questions.length === 0) {
    return (
      <Text px={'12px'} py={'12px'}>
        クライアントへの質問はありません
      </Text>
    )
  }

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      w={'full'}
      spacing={0}
      bgColor={'white'}
      borderRadius={'8px'}
      boxShadow={'base'}
      divider={<StackDivider />}
    >
      {questions.map((question) => {
        return (
          <TaskCardQuestionToClient
            key={question.question.id}
            task={task}
            businessDates={businessDates}
            question={question}
          />
        )
      })}
    </VStack>
  )
}

const TaskCardQuestionToClient: React.FC<{
  task: TaskCardDetail
  businessDates: BusinessDates
  question: QuestionToClientWithReplies
}> = ({ task, businessDates, question: { question, replies } }) => {
  const [isAddingComment, setIsAddingComment] = React.useState(false)
  const businessDate = React.useMemo(
    () => businessDates.fromDate(question.timestamp),
    [businessDates, question.timestamp],
  )
  const resolvedAtBusinessDate = React.useMemo(
    () =>
      question.resolvedAt
        ? businessDates.fromDate(question.timestamp)
        : undefined,
    [businessDates, question.resolvedAt, question.timestamp],
  )
  const toast = useToast()
  const util = trpc.useContext()
  const onClickToResolve = trpc.taskComment.updateStatus.useMutation({
    onSuccess: async (data) => {
      if (data.ok) {
        await util.taskComment.list.invalidate({
          filter: { projectIds: [Number(task.projectId)] },
        })
        await util.taskCard.show.invalidate({
          taskNumber: task.taskNumber,
        })
        toast({
          title: '解決済みに変更しました',
          status: 'success',
        })
      } else {
        logger.info(`onClickToResolve failed`, {
          task,
          data,
        })
        toast({
          title: `リクエストに失敗しました ${data.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.info(`onClickToResolve failed`, {
        task,
        error,
      })
      toast({
        title: `リクエストに失敗しました ${error}`,
        status: 'error',
      })
    },
  })

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      bgColor={'white'}
      px={'20px'}
      py={'16px'}
      spacing={'12px'}
      borderRadius={'8px'}
    >
      <HStack
        spacing={'16px'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        w={'full'}
      >
        <Box>
          <UserWithIcon user={question.user} />
        </Box>
        <Badge
          variant={'outline'}
          borderRadius={'2px'}
          borderWidth={'1px'}
          borderColor={'gray.400'}
          color={'gray.400'}
          px={'4px'}
          py={'2px'}
        >
          投稿日時
        </Badge>
        <DateTimeWithBusinessDate
          timestamp={question.timestamp}
          businessDate={businessDate}
        />
        <QuestionToClientStatus status={question.status} />
        {question.resolvedAt && (
          <DateTimeWithBusinessDate
            timestamp={question.resolvedAt}
            businessDate={resolvedAtBusinessDate}
          />
        )}
      </HStack>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <TextWithLinks w={'80%'}>{question.body}</TextWithLinks>
        <VStack w={'10em'} alignItems={'flex-start'} justifyContent={'center'}>
          <Button
            size={'xs'}
            w={'full'}
            variant={'white-blue'}
            onClick={() => setIsAddingComment(!isAddingComment)}
          >
            <HStack justifyContent={'center'}>
              <MdOutlineAdd />
              <Text> 状況を追加</Text>
            </HStack>
          </Button>
          {question.status === 'resolved' ? (
            <Button size={'xs'} w={'full'} colorScheme={'gray'} isDisabled>
              <HStack justifyContent={'center'}>
                <MdOutlineAdd />
                <Text>解決済み</Text>
              </HStack>
            </Button>
          ) : (
            <Button
              w={'full'}
              size={'xs'}
              isLoading={onClickToResolve.isLoading}
              variant={'blue-fill'}
              onClick={() =>
                onClickToResolve.mutateAsync({
                  id: Number(question.id),
                  status: 'resolved',
                  timestamp: new Date(),
                })
              }
            >
              <HStack justifyContent={'center'}>
                <MdOutlineRefresh />
                <Text>解決済みに変更</Text>
              </HStack>
            </Button>
          )}
        </VStack>
      </HStack>
      <ReplyToQuestionForm
        isVisible={isAddingComment}
        taskCard={task}
        parentComment={question}
        onSubmit={() => setIsAddingComment(false)}
      />
      <QuestionToClientReplies
        businessDates={businessDates}
        replies={replies}
      />
    </VStack>
  )
}
const QuestionToClientReplies: React.FC<{
  businessDates: BusinessDates
  replies: QuestionToClient[]
}> = ({ businessDates, replies }) => {
  if (replies.length === 0) {
    return <></>
  }

  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'flex-start'}>
      {replies.map((reply) => {
        const businessDate = businessDates.fromDate(reply.timestamp)
        return (
          <VStack
            key={`reply-${reply.replyToCommentId}-${reply.id}`}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            bgColor={'gray.50'}
            px={'20px'}
            py={'16px'}
            borderRadius={'8px'}
            boxShadow={'base'}
            w={'full'}
          >
            <HStack
              spacing={'16px'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <Picture user={reply.user} />
              <Text>{reply.user.name}</Text>

              <DateTimeWithBusinessDate
                timestamp={reply.timestamp}
                businessDate={businessDate}
              />
            </HStack>
            <TextWithLinks w={'full'}>{reply.body}</TextWithLinks>
          </VStack>
        )
      })}
    </VStack>
  )
}

type ReplyToQuestionFormInput = {
  date: string
  time: string
  text: string
}
const ReplyToQuestionForm: React.FC<{
  isVisible: boolean
  taskCard: TaskCardDetail
  parentComment: TaskCardDetailComment
  onSubmit: () => void
}> = ({ isVisible, taskCard, parentComment, onSubmit }) => {
  const now = React.useMemo(() => new Date(), [])
  const form = useForm<ReplyToQuestionFormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      date: dateTimeFormatter.jst['YYYY-MM-DD'](now),
      time: dateTimeFormatter.jst['hh:mm'](now),
    },
  })
  const toast = useToast()
  const util = trpc.useContext()
  const onClickToAddReply = trpc.taskComment.create.useMutation({
    onSuccess: async (data) => {
      if (data.ok) {
        await util.taskCard.show.invalidate({
          taskNumber: taskCard.taskNumber,
        })

        onSubmit()
        toast.closeAll()
        form.reset()
        toast({
          title: '状況を追加しました',
          status: 'success',
        })
      } else {
        logger.info(`onClickToAddReply failed`, {
          values: form.getValues(),
          data,
        })
        toast({
          title: `状況の追加に失敗しました ${data.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.info(`onClickToAddReply failed`, {
        values: form.getValues(),
        error,
      })
      toast({
        title: `状況の追加に失敗しました ${error}`,
        status: 'error',
      })
    },
  })

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={form.handleSubmit((data) =>
        onClickToAddReply.mutateAsync({
          taskCardId: Number(taskCard.id),
          text: data.text,
          timestamp: dateTimeFormatter.jst.fromString(data.date, data.time),
          category: 'question_client',
          replyToCommentId: Number(parentComment.id),
        }),
      )}
    >
      <VStack
        backgroundColor={'gray.50'}
        px={'20px'}
        py={'16px'}
        spacing={'12px'}
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        display={isVisible ? 'flex' : 'none'}
      >
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.time?.message}
        >
          <Textarea
            w={'full'}
            bgColor={'white'}
            height={'6em'}
            placeholder='内容を入力'
            isDisabled={onClickToAddReply.isLoading}
            {...form.register('text', {
              validate: (value) => {
                return value.trim().length > 0 ? true : '入力してください'
              },
            })}
          />
          <FormErrorMessage>
            {form.formState.errors?.time?.message}
          </FormErrorMessage>
        </FormControl>
        <HStack w={'full'} alignItems={'center'} justifyContent={'flex-end'}>
          <Input w={'fit-content'} type={'date'} {...form.register('date')} />
          <Input w={'fit-content'} type={'time'} {...form.register('time')} />
          <Button
            variant={'blue-fill'}
            type={'submit'}
            size={'sm'}
            borderRadius={'6px'}
            isLoading={onClickToAddReply.isLoading}
          >
            追加
          </Button>
        </HStack>
      </VStack>
    </form>
  )
}
