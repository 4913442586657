import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { dateTimeFormatter } from '@app/shared'
import {
  Text,
  HStack,
  VStack,
  Heading,
  StackDivider,
  useToast,
  Button,
  Divider,
} from '@chakra-ui/react'
import React from 'react'
import { BusinessDates } from '@app/shared'
import { TaskCardDetail } from '../types'
import { logger } from '@/lib/logger'
import { trpc } from '@/lib/trpc'
import { TaskAttributeTag } from '../../../ui/TaskCardAttribute'
import { TimeSlot } from '../../../ui/TimeSlot'
import { LinkButton } from '@/components/ui/LinkButton'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import type { TaskCardUpdateDetailByMemberSchema } from '@app/api/src/usecase/taskCard/updateTask'
import { ColorMark } from '@/components/ui/ColorMark'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { StickyHeader } from '@/components/ui/StickyHeader'
import type { NamedLinkSchema } from '@app/api/src/routers/trpc/schema'
import { AssignedUser } from '@/components/ui/AssignedUser'
import { DescriptionLinkGrid } from '@/components/ui/DescriptionLinkGrid'
import { NamedLinkArrayInput } from '@/components/ui/NamedLinkArrayInput'
import { useNavigate } from 'react-router-dom'

export const TaskCardEditMember: AuthenticatedComponent<{
  taskCard: TaskCardDetail
}> = ({ self, firebaseUser, taskCard }) => {
  const businessDates = BusinessDates.of(taskCard.projectMonth)
  const form = useForm<TaskCardUpdateDetailByMemberSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      taskCardId: Number(taskCard.id),
      type: 'basicInformation.member',
      description: (taskCard.description as NamedLinkSchema[]) || [],
    },
  })
  const mutation = trpc.taskCard.update.useMutation({
    onError: (e) => {
      logger.error('TaskCardEditMember', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const util = trpc.useContext()
  const toast = useToast()
  const navigate = useNavigate()
  const onSubmitToUpdate = React.useCallback(
    async (data: TaskCardUpdateDetailByMemberSchema) => {
      const result = await mutation.mutateAsync(data)
      toast.closeAll()
      if (result.ok) {
        await util.taskCard.show.invalidate({
          projectId: Number(taskCard.projectId),
          taskNumber: taskCard.taskNumber,
          yearMonth: {
            year: taskCard.projectMonth.year,
            month: taskCard.projectMonth.month,
          },
        })
        toast({
          title: '保存しました',
          status: 'success',
        })
        navigate(
          PagePath.taskCard.show(
            taskCard.projectId,
            taskCard.taskNumber,
            taskCard.projectMonth,
          ),
        )
      } else {
        logger.error('TaskCardHeadingEdit', {
          taskCard: Number(taskCard.id),
          result,
        })
        toast({
          title: `保存に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    [
      mutation,
      navigate,
      taskCard.id,
      taskCard.projectId,
      taskCard.projectMonth,
      taskCard.taskNumber,
      toast,
      util.taskCard.show,
    ],
  )

  if (self.role !== 'member') {
    return <>権限不正</>
  }

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(onSubmitToUpdate)}
      >
        <TaskCardEditStickyHeader
          taskCard={taskCard}
          isLoading={mutation.isLoading}
        />
        <LayoutWithStickyHeader>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'56px'}
            paddingBottom={'120px'}
          >
            <TaskHeadingContent
              taskCard={taskCard}
              businessDates={businessDates}
            />
            <TaskBasicInformationEdit taskCard={taskCard} />
          </VStack>
        </LayoutWithStickyHeader>
      </form>
    </FormProvider>
  )
}

const TaskCardEditStickyHeader = ({
  taskCard,
  isLoading,
}: {
  taskCard: TaskCardDetail
  isLoading: boolean
}) => {
  return (
    <StickyHeader title={'タスクカード編集'}>
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          size={'sm'}
          variant={'white-blue'}
          to={PagePath.taskCard.show(
            taskCard.projectId,
            taskCard.taskNumber,
            taskCard.projectMonth,
          )}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>やめる</Text>
          </HStack>
        </LinkButton>
        <Button
          type={'submit'}
          size={'sm'}
          variant={'blue-fill'}
          isLoading={isLoading}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>保存して終了</Text>
          </HStack>
        </Button>
      </HStack>
    </StickyHeader>
  )
}

const TaskHeadingContent = ({
  taskCard,
  businessDates,
}: {
  taskCard: TaskCardDetail
  businessDates: BusinessDates
}) => {
  const { startBusinessDate, deadlineBusinessDate } = React.useMemo(() => {
    return {
      startBusinessDate: taskCard.startBusinessDate
        ? businessDates.toDate(taskCard.startBusinessDate)
        : undefined,
      deadlineBusinessDate: taskCard.deadlineBusinessDate
        ? businessDates.toDate(taskCard.deadlineBusinessDate)
        : undefined,
    }
  }, [businessDates, taskCard.deadlineBusinessDate, taskCard.startBusinessDate])
  return (
    <VStack
      w={'full'}
      pt={'16px'}
      pb={'24px'}
      px={'24px'}
      spacing={'16px'}
      bgColor={'white'}
      boxShadow={'base'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          プロジェクト名
        </Text>
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={taskCard.project.color} />
          <Text fontWeight={'bold'}>{taskCard.project.name}</Text>
        </HStack>
      </HStack>
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          No.
        </Text>
        <Text fontWeight={'bold'}>{taskCard.taskNumber}</Text>
      </HStack>
      <Divider />

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          属性
        </Text>
        <AssignedUser user={taskCard.assignedTo} />
        <TaskAttributeTag attribute={taskCard.attribute} />
      </HStack>

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          タスクカード名
        </Text>
        <Text fontWeight={'bold'}>{taskCard.title}</Text>
      </HStack>

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          実施予定
        </Text>
        <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
          <HStack
            spacing={0}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Text>第{taskCard.startBusinessDate}営業日</Text>
            <TimeSlot
              start={taskCard.startTimeslotStart}
              end={taskCard.startTimeslotEnd}
            />
          </HStack>
          <Text fontSize={'sm'} color={'gray.500'}>
            {startBusinessDate &&
              dateTimeFormatter.jst['YYYY/MM/DD(E)'](startBusinessDate)}
          </Text>
        </VStack>
      </HStack>

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          期限日
        </Text>
        <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
          <HStack
            spacing={0}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Text>第{taskCard.deadlineBusinessDate}営業日</Text>
            <TimeSlot
              start={taskCard.deadlineTimeslotStart}
              end={taskCard.deadlineTimeslotEnd}
            />
          </HStack>
          <Text fontSize={'sm'} color={'gray.500'}>
            {deadlineBusinessDate &&
              dateTimeFormatter.jst['YYYY/MM/DD(E)'](deadlineBusinessDate)}
          </Text>
        </VStack>
      </HStack>

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          担当者
        </Text>
        <AssignedUser user={taskCard.assignedTo} />
      </HStack>
    </VStack>
  )
}

const TaskBasicInformationEdit = ({
  taskCard,
}: {
  taskCard: TaskCardDetail
}) => {
  const { register, formState } =
    useFormContext<TaskCardUpdateDetailByMemberSchema>()
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              input
            </Text>
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <Text>{taskCard.inputDescription}</Text>
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              borderColor={'gray.200'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              spacing={'8px'}
              px={'20px'}
              py={'16px'}
              w={'full'}
            >
              <Text fontSize={'sm'} fontWeight={'bold'}>
                参考情報
              </Text>
              <DescriptionLinkGrid links={taskCard.inputLinks} />
            </VStack>
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              作業内容
            </Text>
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <NamedLinkArrayInput name={'description'} />
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              output
            </Text>
            {/* <Button variant={'transparent'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <MdEdit />
                <Text>編集</Text>
              </HStack>
            </Button> */}
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <Text>{taskCard.outputDescription}</Text>
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              borderColor={'gray.200'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              spacing={'8px'}
              px={'20px'}
              py={'16px'}
              w={'full'}
            >
              <Text fontSize={'sm'} fontWeight={'bold'}>
                参考情報
              </Text>
              <DescriptionLinkGrid links={taskCard.outputLinks} />
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
