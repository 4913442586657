import {
  Box,
  Button,
  Circle,
  HStack,
  Spinner,
  Text,
  useStyleConfig,
} from '@chakra-ui/react'
import React from 'react'
import { LeftArrowButton, RightArrowButton } from './ArrowButton'

export type Paging = { perPage: number; page: number }

export const PaginationIndicator: React.FC<{
  total: number
  paging: Paging
  isLoading: boolean
  onChangePage: (page: number) => void
}> = ({ total, paging: { perPage, page }, isLoading, onChangePage }) => {
  if (total === 0) {
    return (
      <Box textAlign={'center'}>{/* <Text>データがありません</Text> */}</Box>
    )
  }

  const pageCount = Math.ceil(total / perPage)

  return (
    <HStack alignItems={'center'} justifyContent={'flex-start'}>
      <LeftArrowButton
        isDisabled={page === 1}
        onClick={() => {
          onChangePage(page - 1)
        }}
      />

      <Text>
        {page}ページ/{pageCount}ページ
      </Text>
      <RightArrowButton
        isDisabled={page === pageCount}
        onClick={() => {
          onChangePage(page + 1)
        }}
      />
    </HStack>
  )
}

const PageNumber: React.FC<{
  page: number
  element?: React.ReactElement
  isSelected: boolean
  isDisabled: boolean
  isLoading: boolean
  onClick: () => void
}> = ({ page, element, isSelected, isLoading, isDisabled, onClick }) => {
  const styles = useStyleConfig('Box', {
    variant: isDisabled
      ? 'circle.disabled'
      : isSelected
      ? 'circle.selected'
      : 'circle.default',
  })
  return (
    <Circle minW={'26px'} size={'fit-content'}>
      <Button
        __css={styles}
        onClick={onClick}
        justifyContent={'center'}
        alignItems={'center'}
        isDisabled={isDisabled}
      >
        {isLoading ? <Spinner size={'xs'} /> : element || page}
      </Button>
    </Circle>
  )
}
