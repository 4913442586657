import { TaskCommentCategory } from '@prisma/client'
import { taskCardCommentLabel } from '@/constants/taskCardCommentType'
import { Badge } from '@chakra-ui/react'

export const TaskCardCommentCategoryTag: React.FC<{
  category: TaskCommentCategory | 'issue'
}> = ({ category }) => {
  const { label, color, bgColor } = taskCardCommentLabel[category]
  return (
    <Badge
      borderRadius={'2px'}
      paddingX={'6px'}
      paddingY={'2px'}
      bgColor={bgColor}
      color={color}
      fontWeight={'bold'}
    >
      {label}
    </Badge>
  )
}
