import React from 'react'
import {
  HStack,
  VStack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  Select,
  Divider,
  Text,
  Checkbox,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Box,
  Heading,
  StackDivider,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { RouterOutput, trpc } from '@/lib/trpc'
import { MdOutlineFileOpen } from 'react-icons/md'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { YearMonth } from '@/context/yearMonth'
import { YearMonthSelector } from '../../ui/YearMonthSelector'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  SortableTableHeader,
  UseTableSort,
  useTableSort,
} from '../../ui/SortableTableHeader'
import { Picture } from '../../ui/user/picture'
import { dateTimeFormatter } from '@app/shared'
import { taskCardCommentLabel } from '@/constants/taskCardCommentType'
import { SidebarNavigationButton } from '../../ui/Sidebar/NavigationButton'
import { PagePath } from '@/PagePath'
import { TextWithLinks } from '../../ui/TextWithLInks'
import { PaginationIndicator, Paging } from '../../ui/PaginationIndicator'
import type { TaskCommentFilterSchema } from '@app/api/src/routers/trpc/taskComment/list'
import { Layout } from '../../Layout'
import { logger } from '@/lib/logger'
import { TaskCommentCategory } from '@prisma/client'
import { TaskCardCommentCategoryTag } from '../../ui/TaskCardCommentCategory'
import {
  ProjectSingleSelector,
  WithSingleProjectSelection,
} from '../../ui/ProjectSelector/ProjectSingleSelector'
import { SelectedProject } from '../../ui/ProjectSelector/type'
import { UserWithIcon } from '../../ui/user/UserWithIcon'
import { ErrorPage } from '@/pages/ErrorPage'
import { UserSelect } from '../../ui/UserSelect'
import { Link } from '../../ui/LinkButton'
import { useDialog } from '@/components/ui/Dialog'
import { TaskCommentPreviewDialog } from './TaskCommentPreviewDialog'
import { TextEllipsis } from '@/components/ui/TextEllipsis'
import { RefreshButton } from '@/components/ui/RefreshButton'

type User = RouterOutput['user']['list'][number]
type Comment = RouterOutput['taskComment']['list'][number]
type CommentListSort = {
  field: 'timestamp'
  direction: 'asc' | 'desc'
}
const commentFilterAtom = atom<Pick<TaskCommentFilterSchema, 'userIds'>>({
  userIds: undefined,
})
const commentListSortAtom = atom<CommentListSort>({
  field: 'timestamp',
  direction: 'desc',
})
const commentListPagingAtom = atom<
  Paging & { isLoading: boolean; total: number | undefined }
>({
  total: undefined,
  isLoading: false,
  perPage: 20,
  page: 1,
})

const selectedCommentTabAtom = atom<Comment['category'] | 'issue' | 'all'>(
  'all',
)
const selectedCommentIdsAtom = atom<number[]>([])

export const Comments: AuthenticatedComponent<{ yearMonth: YearMonth }> = ({
  self,
  yearMonth,
}) => {
  return (
    <Layout>
      <VStack
        alignItems={'flex-start'}
        w={'full'}
        borderRadius={'4px'}
        bgColor={'white'}
        padding={'16px'}
        boxShadow={'base'}
      >
        <RefreshButton />
        <Box w={'fit-content'}>
          <YearMonthSelector />
        </Box>

        <Heading size={'lg'}>共有事項</Heading>

        <StackDivider />

        <ProjectSingleSelector />
      </VStack>

      {/* <Divider /> */}
      <WithSingleProjectSelection>
        {(selectedProject) => {
          return (
            <CommentsTableBody
              project={selectedProject}
              yearMonth={yearMonth}
            />
          )
        }}
      </WithSingleProjectSelection>
    </Layout>
  )
}

const CommentsTableBody: React.FC<{
  project: SelectedProject
  yearMonth: YearMonth
}> = ({ project, yearMonth }) => {
  const commentFilter = useAtomValue(commentFilterAtom)
  const [selectedCommentType] = useAtom(selectedCommentTabAtom)
  const useSort = useTableSort(commentListSortAtom)
  const [paging, setPaging] = useAtom(commentListPagingAtom)
  const taskCommentFilter: TaskCommentFilterSchema = React.useMemo(() => {
    return {
      yearMonths: [yearMonth],
      projectIds: [project.id],
      category: { notIn: ['question_client'] },
      ...commentFilter,
    }
  }, [commentFilter, project.id, yearMonth])

  const countQuery = trpc.taskComment.count.useQuery(taskCommentFilter)
  const commentCounts = React.useMemo(() => {
    if (countQuery.data) {
      return {
        ...countQuery.data,
        issue:
          (countQuery.data.issue_data_processing || 0) +
          (countQuery.data.issue_input_delay || 0) +
          (countQuery.data.issue_input_quality || 0) +
          (countQuery.data.issue_other || 0),
      }
    } else {
      return undefined
    }
  }, [countQuery.data])

  const commentsQuery = trpc.taskComment.list.useQuery({
    filter: taskCommentFilter,
    pagination: {
      perPage: 10000, // 全件取得する
      page: 1,
      sort: {
        field: useSort[0].field || 'timestamp',
        direction: useSort[0].direction,
      },
    },
  })

  const usersQuery = trpc.user.list.useQuery({
    filter: {
      accessibleProjectId: Number(project.id),
      assign: { projectId: project.id, yearMonth },
    },
  })

  React.useEffect(() => {
    if (commentCounts) {
      setPaging((prev) => ({
        ...prev,
        total: commentCounts[selectedCommentType],
      }))
    }
  }, [commentCounts, selectedCommentType, setPaging])

  if (commentsQuery.isLoading || usersQuery.isLoading) {
    return <Spinner />
  }

  if (commentsQuery.error || usersQuery.error) {
    return (
      <ErrorPage
        errorMessage={(commentsQuery.error || usersQuery.error)?.message}
      />
    )
  }

  return (
    <CommentsTableTab
      project={project}
      useSort={useSort}
      allComments={commentsQuery.data || []}
      userCandidates={usersQuery.data || []}
    />
  )
}

const CommentsTableTab: React.FC<{
  project: SelectedProject
  useSort: UseTableSort<CommentListSort>
  allComments: Comment[]
  userCandidates: User[]
}> = ({ project, useSort, allComments, userCandidates }) => {
  const setSelectedCommentCategory = useSetAtom(selectedCommentTabAtom)
  const setPaging = useSetAtom(commentListPagingAtom)
  const onClickTab = React.useCallback(
    (category: Comment['category'] | 'issue' | 'all') => {
      setSelectedCommentCategory(category)
      setPaging((prev) => {
        return {
          ...prev,
          page: 1,
        }
      })
    },
    [setPaging, setSelectedCommentCategory],
  )

  const comments = React.useMemo(() => {
    return allComments.reduce(
      (acc, comment) => {
        const category = comment.category.startsWith('issue_')
          ? 'issue'
          : comment.category
        acc[category] ||= [] as Comment[]
        acc[category].push(comment)
        return acc
      },
      {} as Record<Comment['category'] | 'issue', Comment[]>,
    )
  }, [allComments])

  const [filter, setFilter] = useAtom(commentFilterAtom)
  const filterComponent = React.useMemo(() => {
    const defaultValue = filter.userIds
      ? (userCandidates || []).find((u) => u.id === filter.userIds?.[0])
      : undefined
    return (
      <Box key={'filter'} w={'fit-content'}>
        <UserSelect
          type={'select'}
          candidates={userCandidates || []}
          defaultValue={defaultValue}
          placeholder='起票者を選択'
          onChange={(user) => {
            setFilter((prev) => ({
              ...prev,
              userIds: user ? [user.id] : undefined,
            }))
          }}
        />
      </Box>
    )
  }, [filter.userIds, setFilter, userCandidates])
  const dialog = useDialog()
  const showDialog = React.useCallback(
    (taskComment: Comment) => {
      dialog.openDialog({
        size: 'lg',
        closeOnOverlayClick: true,
        body: (
          <TaskCommentPreviewDialog
            project={project}
            taskComment={taskComment}
          />
        ),
      })
    },
    [dialog, project],
  )

  return (
    <VStack
      justifyContent={'center'}
      alignItems={'center'}
      w={'full'}
      textAlign={'center'}
      px={'32px'}
      py={'16px'}
      spacing={'10px'}
    >
      <Tabs w={'full'} position='relative' variant='unstyled'>
        <TabList>
          <Tab onClick={() => onClickTab('all')}>すべて</Tab>
          <Tab onClick={() => onClickTab('report_new_rule')}>新ルール</Tab>
          <Tab onClick={() => onClickTab('report_work')}>作業内容</Tab>
          <Tab onClick={() => onClickTab('report_miss')}>ミス</Tab>
          <Tab onClick={() => onClickTab('issue')}>課題点</Tab>
          <Tab onClick={() => onClickTab('request_task_modification')}>
            タスクカード修正依頼
          </Tab>
          <Tab onClick={() => onClickTab('report_other')}>その他</Tab>
        </TabList>
        <TabIndicator
          mt='-1px'
          height='2px'
          bg='blue.500'
          w={'full'}
          borderRadius='1px'
          zIndex={2}
        />
        <Divider
          borderColor={'gray.200'}
          borderWidth={'2px'}
          w={'full'}
          zIndex={1}
          mt={'-2px'}
          mb={'16px'}
        />
        <TabPanels>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={allComments}
              commentCategory='all'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={comments['report_new_rule'] || []}
              commentCategory='report_new_rule'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={comments['report_work'] || []}
              commentCategory='report_work'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={comments['report_miss'] || []}
              commentCategory='report_miss'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={comments['issue'] || []}
              commentCategory='issue'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={comments['request_task_modification'] || []}
              commentCategory='request_task_modification'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CommentsTable
              useSort={useSort}
              comments={comments['report_other'] || []}
              commentCategory='report_other'
              filterComponent={filterComponent}
              showDialog={showDialog}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

const CommentsTable: React.FC<{
  comments: Comment[]
  filterComponent: React.ReactElement
  useSort: UseTableSort<CommentListSort>
  commentCategory: Comment['category'] | 'all' | 'issue'
  showDialog: (taskComment: Comment) => void
}> = ({
  comments: _comments,
  useSort,
  commentCategory,
  filterComponent,
  showDialog,
}) => {
  const [paging, setPaging] = useAtom(commentListPagingAtom)
  const comments = React.useMemo(() => {
    return _comments.slice(
      paging.perPage * (paging.page - 1),
      paging.perPage * paging.page,
    )
  }, [_comments, paging.page, paging.perPage])
  logger.info(`comments`, { commentCategory, _comments, comments, paging })

  return (
    <VStack spacing={0} w={'full'} p={0}>
      <Box w={'full'}>
        <HStack w={'full'} justifyContent={'space-between'} my={'12px'}>
          {filterComponent}
          <PaginationIndicator
            total={paging.total ?? 0}
            paging={paging}
            isLoading={!paging.total || paging.isLoading}
            onChangePage={(page) => {
              setPaging((prev) => ({
                ...prev,
                page,
                perPage: paging.perPage,
              }))
            }}
          />
        </HStack>
        <Table variant='striped' layout={'auto'} w={'full'}>
          <Thead w={'full'}>
            <Tr whiteSpace={'nowrap'} height={'3rem'} paddingY={'10px'}>
              {/* <Th textAlign={'center'}>
                <Checkbox bg={'white'} onChange={onCheckboxClicked} />
              </Th> */}
              <Th w={'fit-content'}>カテゴリ</Th>
              <Th w={'fit-content'}>起票者</Th>
              <Th w={'fit-content'}>対象のタスクカード</Th>
              <Th w={'fit-content'}>対象月</Th>
              <Th w={'fit-content'}>内容</Th>
              <SortableTableHeader useSort={useSort} field={'timestamp'}>
                追加日
              </SortableTableHeader>
              {/* <Th w={'fit-content'}></Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {comments.length > 0 ? (
              comments.map((comment) => {
                return (
                  <CommentsTableRow
                    key={comment.id}
                    comment={comment}
                    showDialog={showDialog}
                  />
                )
              })
            ) : (
              <Tr>
                <Td colSpan={7}>共有事項はありません</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <HStack w={'full'} justifyContent={'flex-end'} mt={'12px'}>
        <PaginationIndicator
          total={paging.total ?? 0}
          paging={paging}
          isLoading={!paging.total || paging.isLoading}
          onChangePage={(page) => {
            setPaging((prev) => ({
              ...prev,
              page,
              perPage: paging.perPage,
            }))
          }}
        />
      </HStack>
    </VStack>
  )
}

const CommentsTableRow: React.FC<{
  showDialog: (taskComment: Comment) => void
  comment: Comment
}> = ({ showDialog, comment }) => {
  if (!comment.taskCard) {
    return <></>
  }
  return (
    <Tr maxW={'full'} justifyContent={'center'} alignSelf={'center'}>
      {/* <Td textAlign={'center'} minWidth={'fit-content'}>
        <Checkbox bg={'white'} onChange={() => {}} isChecked={false} />
      </Td> */}
      <Td whiteSpace={'nowrap'} verticalAlign={'middle'}>
        <TaskCardCommentCategoryTag category={comment.category} />
      </Td>
      <Td verticalAlign={'middle'}>
        <UserWithIcon user={comment.user} />
      </Td>
      <Td verticalAlign={'middle'}>
        <Link
          color={'blue.500'}
          textDecoration={'underline'}
          to={PagePath.taskCard.show(
            comment.taskCard.projectId,
            comment.taskCard.taskNumber,
            comment.taskCard.projectMonth,
          )}
        >
          <TextEllipsis>{comment.taskCard.title}</TextEllipsis>
        </Link>
      </Td>
      <Td verticalAlign={'middle'} whiteSpace={'nowrap'}>
        {comment.taskCard?.projectMonth.year}/
        {comment.taskCard?.projectMonth.month}
      </Td>
      <Td verticalAlign={'middle'} overflow={'auto'}>
        <Button
          onClick={() => showDialog(comment)}
          variant={'transparent'}
          fontWeight={'normal'}
          fontSize={'14px'}
          textAlign={'left'}
        >
          <TextEllipsis>
            <Text color={'blue.500'} textDecoration={'underline'}>
              {comment.body.slice(0, 100)}
              {comment.body.length > 100 ? '...' : ''}
            </Text>
          </TextEllipsis>
        </Button>
      </Td>
      <Td whiteSpace={'nowrap'} verticalAlign={'middle'}>
        {dateTimeFormatter.jst['YYYY/MM/DD'](comment.createdAt)}
      </Td>
      {/* <Td verticalAlign={'middle'}>
        <SidebarNavigationButton
          to={
            comment.taskCard
              ? PagePath.taskCard.show(
                  comment.taskCard.projectId,
                  comment.taskCard.taskNumber,
                  comment.taskCard.projectMonth,
                )
              : '#'
          }
          variant={'white-blue'}
          isDisabled={!comment.taskCard}
          size={'sm'}
        >
          <FaExternalLinkAlt />
        </SidebarNavigationButton>
      </Td> */}
    </Tr>
  )
}
