import { Badge, Select, Tag, TagProps, Text, Tooltip } from '@chakra-ui/react'
import { TaskCard, TaskInputStatus, TaskOutputStatus } from '@prisma/client'
import React, { ChangeEvent } from 'react'
import {
  TaskCardOutputStatusTag,
  taskCardOutputStatusOptions,
} from './TaskCardOutputStatus'
import { TaskCardDerivedStatus, taskCardDerivedStatus } from '@app/shared'
import { taskCardInputStatusOptions } from './TaskCardInputStatus'

export const taskCardDerivedStatusOptions = {
  'ok/not_started': {
    label: '遅延なし未実施',
    labelShort: '遅延なし未実施',
    color: 'green.500',
  },
  'ok/in_progress': {
    label: '遅延なし進行中',
    labelShort: '遅延なし進行中',
    color: 'green.500',
  },
  'ok/done': {
    label: '遅延なし完了',
    labelShort: '遅延なし完了',
    color: 'green.500',
  },
  'not_started/input_delayed': {
    label: '資料受領遅延',
    labelShort: '資料受領遅延',
    color: 'orange.500',
  },
  'should_start/client_matter': {
    label: '実施予定遅延/先方都合',
    labelShort: '実施予定遅延',
    color: 'orange.500',
  },
  'should_start/self_matter': {
    label: '実施予定遅延/当方都合',
    labelShort: '実施予定遅延',
    color: 'red.500',
  },
  'delayed/self_matter': {
    label: '実施予定遅延(作業期間終了)/当方都合',
    labelShort: '作業期間終了',
    color: 'red.500',
  },
  'delayed/client_matter': {
    label: '未実施/先方都合',
    labelShort: '未実施',
    color: 'orange.500',
  },
} as const

export const TaskDerivedStatusTag = ({
  derivedStatus,
  inputStatus,
  outputStatus,
}: {
  derivedStatus: TaskCardDerivedStatus
  inputStatus: TaskInputStatus
  outputStatus: TaskOutputStatus
}) => {
  const label = React.useMemo(() => {
    const input = taskCardInputStatusOptions[inputStatus]
    const output = taskCardOutputStatusOptions[outputStatus]

    return (
      <Text>
        ・資料受領状況：{input.label}
        <br />
        ・ステータス：{output.label}
      </Text>
    )
  }, [inputStatus, outputStatus])
  const option = taskCardDerivedStatusOptions[derivedStatus]
  return (
    <Tooltip label={label}>
      <Inner bgColor={option.color}>{option.label}</Inner>
    </Tooltip>
  )
}

const Inner: React.FC<TagProps> = React.forwardRef(
  ({ children, ...rest }, ref) => (
    <Tag
      variant={'solid'}
      px={'8px'}
      py={'4px'}
      size={'sm'}
      borderRadius={'6px'}
      whiteSpace={'nowrap'}
      ref={ref}
      {...rest}
    >
      {children}
    </Tag>
  ),
)
