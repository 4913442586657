import { Comments } from '@/components/pages/taskComment/list'
import { Authenticated } from './AuthenticatedPage'
import { YearMonthContextProvider } from '@/context/yearMonth'
import { ClientQuestions } from '@/components/pages/ClientQuestions'

export const ClientQuestionsPage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <YearMonthContextProvider>
            {(yearMonth) => (
              <ClientQuestions
                {...{
                  firebaseUser,
                  self,
                  yearMonth,
                }}
              ></ClientQuestions>
            )}
          </YearMonthContextProvider>
        )
      }}
    </Authenticated>
  )
}
