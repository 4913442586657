import { RouterOutput } from '@/lib/trpc'
import { Box, Checkbox, HStack, Td, Text, Tr } from '@chakra-ui/react'
import React from 'react'
import { BusinessDates, dateTimeFormatter } from '@app/shared'
import { PagePath } from '@/PagePath'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { TaskAttributeTag } from './TaskCardAttribute'
import { UseTaskCardSelectionReturn } from './TaskCardTable'
import { ColorMark } from './ColorMark'
import { UserWithIcon } from './user/UserWithIcon'
import { TaskCardOutputStatusTag } from '../pages/taskcard/TaskCardOutputStatus'
import { Link, LinkButton } from './LinkButton'
import { TextEllipsis } from './TextEllipsis'
import { TimeSlot } from './TimeSlot'
import { DateForTable } from './DateForTable'

type TaskCardWithDerivedStatus = RouterOutput['taskCard']['list'][number]
export type TaskCardTableColumnOptions = {
  showAssignedUser?: boolean
}
export const TaskCardTableRow: React.FC<{
  businessDates: BusinessDates | undefined
  project: NonNullable<RouterOutput['project']['list'][number]>
  task: TaskCardWithDerivedStatus
  columnOptions?: TaskCardTableColumnOptions
  taskCardSelection?: UseTaskCardSelectionReturn
}> = ({ businessDates, project, task, columnOptions, taskCardSelection }) => {
  const dates = React.useMemo(
    () => businessDates?.task(task),
    [businessDates, task],
  )
  const { startDateColor, deadlineDateColor } = React.useMemo(() => {
    switch (task.derivedStatus) {
      case 'delayed/client_matter':
      case 'delayed/self_matter':
        return { startDateColor: 'red.500', deadlineDateColor: 'red.500' }
      case 'should_start/client_matter':
      case 'should_start/self_matter':
        return { startDateColor: 'red.500', deadlineDateColor: 'gray.700' }
      default:
        return { startDateColor: undefined, deadlineDateColor: undefined }
    }
  }, [task.derivedStatus])
  return (
    <Tr maxW={'full'} justifyContent={'center'} alignSelf={'center'}>
      {taskCardSelection && (
        <Td textAlign={'center'} minWidth={'fit-content'}>
          <Checkbox
            bg={'white'}
            isChecked={taskCardSelection.isSelected(task)}
            onChange={(e) => {
              if (e.target.checked) {
                taskCardSelection.addSelection([task])
              } else {
                taskCardSelection.removeSelection([task])
              }
            }}
          />
        </Td>
      )}
      {/* <Td verticalAlign={'middle'}>
        <LinkButton
          to={PagePath.taskCard.show(
            task.projectId,
            task.taskNumber,
            task.projectMonth,
          )}
          variant={'transparent-clickable'}
          color={'gray.500'}
          size={'sm'}
        >
          <FaExternalLinkAlt />
        </LinkButton>
      </Td> */}
      <Td verticalAlign={'middle'}>
        <TaskCardOutputStatusTag outputStatus={task.outputStatus} />
      </Td>

      <Td verticalAlign={'middle'} minW={'120px'} overflow={'auto'}>
        <HStack>
          <ColorMark color={project.color} />
          <TextEllipsis fontSize={'15px'}>{project.name}</TextEllipsis>
        </HStack>
      </Td>
      <Td whiteSpace={'nowrap'} verticalAlign={'middle'}>
        <TaskAttributeTag attribute={task.attribute} />
      </Td>
      <Td
        verticalAlign={'middle'}
        minW={'80px'}
        maxW={'10em'}
        overflow={'auto'}
      >
        <TextEllipsis fontSize={'13px'}>{task.taskNumber}</TextEllipsis>
      </Td>
      <Td
        verticalAlign={'middle'}
        minW={'144px'}
        maxW={'30em'}
        overflow={'auto'}
      >
        <Box w={'fit-content'}>
          <Link
            to={PagePath.taskCard.show(
              task.projectId,
              task.taskNumber,
              task.projectMonth,
            )}
            textDecoration={'underline'}
            color={'blue.500'}
            _hover={{
              color: 'blue.300',
            }}
            w={'fit-content'}
          >
            <TextEllipsis
              pl={'0.5em'}
              pr={'1em'}
              fontSize={'13px'}
              fontWeight={'bold'}
            >
              {task.title}
            </TextEllipsis>
          </Link>
        </Box>
      </Td>

      {columnOptions?.showAssignedUser !== false && (
        <Td minW={'88px'} maxW={'fit-content'} overflow={'auto'}>
          {task.assignedTo ? (
            <UserWithIcon user={task.assignedTo} fontSize={'13px'} />
          ) : (
            <Text>未設定</Text>
          )}
        </Td>
      )}
      <Td verticalAlign={'middle'}>
        <DateForTable
          date={dates?.startDate?.start}
          businessDate={task.startBusinessDate || undefined}
          timeslotStart={task.startTimeslotStart}
          timeslotEnd={task.startTimeslotEnd}
          textColor={startDateColor}
        />
      </Td>
      <Td verticalAlign={'middle'}>
        <DateForTable
          date={dates?.deadlineDate?.end}
          businessDate={task.deadlineBusinessDate || undefined}
          timeslotStart={task.deadlineTimeslotStart}
          timeslotEnd={task.deadlineTimeslotEnd}
          textColor={deadlineDateColor}
        />
      </Td>
    </Tr>
  )
}
