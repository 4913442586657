import { Text, HStack } from '@chakra-ui/react'
import { User } from '@prisma/client'
import { UserWithIcon } from './user/UserWithIcon'
import { dateTimeFormatter } from '@app/shared'
import { TimeSlot } from './TimeSlot'

export const AssignedUser: React.FC<{ user: User | null }> = ({ user }) => {
  return user ? <UserWithIcon user={user} /> : <Text>未割り当て</Text>
}

export const DateForTable: React.FC<{
  date: Date | undefined
  businessDate: number | undefined | null
  timeslotStart: string | undefined | null
  timeslotEnd: string | undefined | null
  textColor?: string
}> = ({ date, businessDate, timeslotEnd, timeslotStart, textColor }) => {
  return (
    <HStack
      alignItems={'center'}
      color={textColor}
      whiteSpace={'nowrap'}
      fontSize={'13px'}
    >
      <Text color={'gray.600'}>
        {date ? dateTimeFormatter.jst['M/D(E)'](new Date(date)) : '-'}
      </Text>
      <TimeSlot
        textColor={'gray.500'}
        start={timeslotStart}
        end={timeslotEnd}
      />
      {/* <Text>{`${date ? `[第${businessDate}営業日]` : '[第-営業日]'}`}</Text> */}
    </HStack>
  )
}
