import React from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogProps,
} from '@chakra-ui/react'

import { logger } from '@/lib/logger'

export type OpenDialogParams = {
  size?: AlertDialogProps['size']
  body: React.ReactElement
  closeOnOverlayClick?: boolean
}
type DialogState = {
  isOpen: boolean
  params?: OpenDialogParams
}
type DialogDispatch = {
  openDialog: (params: OpenDialogParams) => void
  closeDialog: () => void
}
const initialState: DialogState & DialogDispatch = {
  isOpen: false,
  openDialog: (params: OpenDialogParams) => {
    throw 'openDialog is not implemented'
  },
  closeDialog: () => {
    throw 'closeDialog is not implemented'
  },
  params: undefined,
}

const DialogStateContext = React.createContext<DialogState & DialogDispatch>({
  ...initialState,
})
export const useDialog = () => React.useContext(DialogStateContext)

const useDialogDispatcherInternal = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const [state, setState] = React.useState<DialogState>({
    ...initialState,
  })
  const openDialog = React.useCallback(
    (params: OpenDialogParams) => {
      logger.info(`[openDialog]called`, { params, state, isOpen })
      setIsOpen(true)
      setState({
        ...state,
        isOpen: true,
        params,
      })
    },
    [isOpen, state],
  )
  const closeDialog = React.useCallback(() => {
    setIsOpen(false)
    setState({
      ...state,
      isOpen: false,
      params: undefined,
    })
  }, [state])
  return {
    state: {
      ...state,
      isOpen,
    },
    dispatcher: {
      setIsOpen,
      openDialog,
      closeDialog,
    },
  }
}

export const DialogContainer: React.FC<{
  children: React.ReactNode
}> = (props) => {
  const { state, dispatcher } = useDialogDispatcherInternal()
  const cancelRef = React.useRef(null)
  logger.info(`[DialogContainer]`, {
    isOpen: state.isOpen,
    body: state.params?.body,
  })

  return (
    <DialogStateContext.Provider value={{ ...state, ...dispatcher }}>
      <AlertDialog
        size={state.params?.size || 'md'}
        isOpen={state.isOpen}
        onClose={dispatcher.closeDialog}
        leastDestructiveRef={cancelRef}
        isCentered={true}
        autoFocus={false}
        closeOnEsc={state.params?.closeOnOverlayClick !== false}
        closeOnOverlayClick={state.params?.closeOnOverlayClick !== false}
      >
        <AlertDialogOverlay>{state.params?.body}</AlertDialogOverlay>
      </AlertDialog>

      {props.children}
    </DialogStateContext.Provider>
  )
}
