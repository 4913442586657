import { trpc } from '@/lib/trpc'
import React from 'react'
import {
  jstNow,
  toNextMonth,
  toPrevMonth,
  yearMonthFromDate,
} from '@app/shared'
import { useSelfState } from '@/components/providers/SelfProvider'
export const useDashboardTaskCardsQuery = ({
  projectIds,
}: {
  projectIds: number[]
}) => {
  const { self } = useSelfState()
  const assignedToUserId = self?.role === 'member' ? self.id : undefined
  const yearMonth = React.useMemo(() => yearMonthFromDate(jstNow()), [])

  const prevMonthQuery = trpc.taskCard.list.useQuery(
    {
      filter: {
        // 先月分は何かしら遅延しているものだけを含む
        projectIds,
        yearMonths: [toPrevMonth(yearMonth)],
        outputStatus: ['not_started', 'in_progress'],
        derivedStatus: [
          // 完了しているものは含めない
          'delayed/client_matter',
          'delayed/client_matter',
          'should_start/client_matter',
          'should_start/self_matter',
          'not_started/input_delayed',
          'ok/not_started',
          'ok/in_progress',
        ],
        assignedToUserId,
      },
    },
    {
      enabled: Boolean(self),
    },
  )
  const thisMonthQuery = trpc.taskCard.list.useQuery(
    {
      filter: {
        // 今月分はすべて
        projectIds,
        yearMonths: [yearMonth],
        outputStatus: ['not_started', 'in_progress', 'done'],
        assignedToUserId,
      },
    },
    {
      enabled: Boolean(self),
    },
  )
  const nextMonthQuery = trpc.taskCard.list.useQuery(
    {
      filter: {
        // 翌月分は資料受領遅延になっているものだけ
        projectIds,
        yearMonths: [toNextMonth(yearMonth)],
        inputStatus: ['delayed'],
        outputStatus: ['not_started', 'in_progress'],
        assignedToUserId,
      },
    },
    {
      enabled: Boolean(self),
    },
  )
  const allTaskCards = React.useMemo(
    () =>
      (prevMonthQuery.data || [])
        .concat(thisMonthQuery.data || [])
        .concat(nextMonthQuery.data || []),
    [nextMonthQuery.data, prevMonthQuery.data, thisMonthQuery.data],
  )

  return {
    error: prevMonthQuery.error || thisMonthQuery.error || nextMonthQuery.error,
    isLoading:
      prevMonthQuery.isLoading ||
      thisMonthQuery.isLoading ||
      nextMonthQuery.isLoading,
    data: allTaskCards,
  }
}
