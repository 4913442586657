import { RouterPath } from '@/PagePath'
import { useSelfState } from '@/components/providers/SelfProvider'
import { MdOutlineAccountCircle } from 'react-icons/md'
import { SidebarLink } from './SidebarLink'
import { Text, VStack, Divider } from '@chakra-ui/react'

export const AdminMenus = () => {
  const { self } = useSelfState()
  if (self?.role !== 'admin') {
    return <></>
  }
  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'flex-start'}>
      <Text color={'gray.500'}>管理（Adminのみ）</Text>
      <Divider />
      <SidebarLink
        to={RouterPath.admin.accounts}
        icon={<MdOutlineAccountCircle />}
        text={'アカウント管理'}
      />
    </VStack>
  )
}
