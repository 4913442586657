import { Colors } from '@chakra-ui/react'

export const colors: Colors = {
  alpha: '#FFFFFF00',
  gray: {
    5: '#FCFCFC',
    10: '#F9F9F9',
    30: '#F6F6F6',
    50: '#F7FAFC',
    // 100: '#F0F0F0',
    120: '#DDDDDD',
  },
  green: {
    450: '#4D9B6F',
  },
  line: {
    talk: '#89A8D5',
  },
  blue: {
    fill: '#F4FAFF',
    text: '#177FCA',
  },
  transparent: {
    gray: '#F0F0F0CC',
    black: '#303030CC',
  },
  general: {
    background: {
      value: '#E6E6E6',
    },
  },
  text: {
    white: {
      value: '{colors.white}',
    },
    blue: {
      value: '#1565C4',
    },
    lightgreen: {
      value: '#1DB446',
    },
    gray: {
      value: '#5A5A5A',
    },
  },
  border: {
    blue: {
      value: '#0F68D2',
    },
    gray: {
      value: '#C1C1C1',
    },
  },
  button: {
    navigation: {
      base: {
        value: '{general.background}',
      },
      hover: {
        value: '#00FF00',
      },
      selected: {
        value: '#FF0000',
      },
    },
  },
}
