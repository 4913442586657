import { PagePath, PagePathParams } from '@/PagePath'
import { BackButton } from '@/components/ui/BackButton'
import { logger } from '@/lib/logger'
import { useToast, Spinner } from '@chakra-ui/react'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Authenticated, AuthenticatedComponent } from '../AuthenticatedPage'
import { trpc } from '@/lib/trpc'
import { ProjectConfigurationShow } from '@/components/pages/project/configuration/ProjectConfigurationShow'
import { ProjectConfigurationEdit } from '@/components/pages/project/configuration/ProjectConfigurationEdit'
import { ErrorPage } from '../ErrorPage'

export const ProjectConfigurationEditPage = () => {
  const { projectId } = useParams<PagePathParams['projects']['show']>()
  if (!projectId || Number.isNaN(Number(projectId))) {
    return <>invalid params</>
  }

  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <ProjectConfigurationPageInternal
            {...{
              projectId: Number(projectId),
              firebaseUser,
              self,
            }}
          />
        )
      }}
    </Authenticated>
  )
}

const ProjectConfigurationPageInternal: AuthenticatedComponent<{
  projectId: number
}> = ({ projectId, firebaseUser, self }) => {
  const toast = useToast()
  const query = trpc.project.show.useQuery({
    id: projectId,
  })

  if (self.role === 'member') {
    toast({
      title: `プロジェクトを編集する権限がありません`,
      status: 'error',
    })
    return <Navigate to={PagePath.project.show(projectId)} />
  }

  if (query.isLoading) {
    return <Spinner />
  }
  if (query.error) {
    return <ErrorPage errorMessage={query.error.message} />
  }
  if (!query.data) {
    return <ErrorPage errorMessage='データが取得できませんでした' />
  }

  return (
    <ProjectConfigurationEdit
      firebaseUser={firebaseUser}
      self={self}
      project={query.data}
    />
  )
}
