import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, RouterProvider } from 'react-router-dom'
import { ProvidersOutSideRouter } from './Providers'
import { router } from './router'

// import './index.css'
import { RootLayout } from './components/RootLayout'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryPage } from './pages/ErrorPage'

import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/500.css'
import '@fontsource/noto-sans-jp/700.css'
import { TextSpinner } from './components/ui/TextSpinner'
import { initializeSentry } from './sentry'

initializeSentry()

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>

  <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
    <RootLayout>
      <React.Suspense
        fallback={
          <div>
            <TextSpinner />
          </div>
        }
      >
        <ProvidersOutSideRouter>
          <RouterProvider router={router} />
        </ProvidersOutSideRouter>
      </React.Suspense>
    </RootLayout>
  </ErrorBoundary>,
  // </React.StrictMode>,
)
