import { taskCardCommentStatus } from '@/constants/taskCardCommentStatus'
import { Badge } from '@chakra-ui/react'
import { TaskComment } from '@prisma/client'

export const QuestionToClientStatus: React.FC<{
  status: TaskComment['status']
}> = ({ status }) => {
  const { label, variant, colorScheme } = taskCardCommentStatus[status]
  return (
    <Badge
      borderRadius={'2px'}
      paddingX={'6px'}
      paddingY={'2px'}
      fontWeight={'bold'}
      colorScheme={colorScheme}
      variant={variant}
    >
      {label}
    </Badge>
  )
}
