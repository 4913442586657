import { Avatar, Circle, Image } from '@chakra-ui/react'
import { User } from '@prisma/client'

export const Picture = ({ user }: { user: User }) => {
  return (
    <Avatar
      name={user.name}
      src={user.pictureUrl}
      boxSize={'24px'}
      borderRadius={'full'}
      loading={'lazy'}
    />
  )
}
