import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { firebaseAuthDispatcher } from '@/lib/firebaseAuth'
import { logger } from '@/lib/logger'
import {
  Button,
  Box,
  HStack,
  VStack,
  useToast,
  Heading,
  Text,
  Image,
  Spacer,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useSelfDispatcher, useSelfState } from '../providers/SelfProvider'
import { trpc } from '@/lib/trpc'
import { useFirebaseAuthState } from '../providers/FirebaseAuthProvider'
import { PagePath } from '@/PagePath'
import { sleep } from '@app/shared'
import GoogleLoginButton from '../../images/google_login.svg'

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [params, _] = useSearchParams() // 招待コードとして?code=xxxがついている場合がある
  const code = params.get('code') || ''
  const { firebaseUser, initialized } = useFirebaseAuthState()
  const { self, status } = useSelfState()
  const { clear: clearSelf, refresh } = useSelfDispatcher()
  const signUpLoginMutation = trpc.self.signUpLogin.useMutation({
    onError: (e) => {
      logger.error('signUpLogin', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  // const [loggedIn, setLoggedIn] = useAtom(loggedInAtom)
  const onClickGoogleLogin = React.useCallback(async () => {
    clearSelf()
    return firebaseAuthDispatcher
      .loginWithGoogle()
      .then(async (firebaseUser) => {
        logger.info(`succeeded to login`, { firebaseUser })
        const result = await signUpLoginMutation.mutateAsync({
          code: code,
          displayName: firebaseUser.displayName || '',
          photoUrl: firebaseUser.photoURL || '',
        })
        if (result.ok) {
          const { user, created } = result.result
          logger.info(`succeeded to signUp`, { firebaseUser, user })
          if (created) {
            toast({
              title: `新規登録しました ${firebaseUser.email}`,
              status: 'success',
            })
          } else {
            toast({
              title: `ログインしました ${firebaseUser.email}`,
              status: 'success',
            })
          }
          refresh()
          // setLoggedIn(true)
          // navigate('/')
        } else {
          logger.error(`failed to signUp`, { firebaseUser, error: result })
          toast({
            title: `ログインに失敗しました ${result.errorMessage}`,
            status: 'error',
          })
          await firebaseAuthDispatcher.logout()
        }
      })
      .catch(async (e) => {
        toast({ title: `ログインに失敗しました ${e}`, status: 'error' })
        await firebaseAuthDispatcher.logout()
      })
  }, [clearSelf, code, refresh, signUpLoginMutation, toast])

  const googleLogin = useMutation({
    mutationFn: onClickGoogleLogin,
  })

  const redirectToHome = React.useMemo(
    () =>
      Boolean(initialized && firebaseUser && status === 'initialized' && self),
    [firebaseUser, initialized, self, status],
  )

  // React.useEffect(() => {
  //   logger.debug('redirectToHome', { redirectToHome })
  //   if (redirectToHome) {
  //     toast({ title: `ログイン済みです`, status: 'info' })
  //     sleep(2000).then(() => {
  //       navigate(PagePath.dashboard())
  //     })
  //   }
  // }, [navigate, redirectToHome, toast])

  if (redirectToHome) {
    if (!toast.isActive) {
      toast({ title: `ログイン済みです`, status: 'info' })
    }
    return <Navigate to={PagePath.dashboard()} />
  }

  return (
    <VStack
      justifyContent={'center'}
      alignItems={'center'}
      w={'full'}
      h={'100vh'}
      textAlign={'center'}
      gap={'16px'}
    >
      <Text fontSize={'2xl'} fontWeight={'medium'} color={'gray.400'}>
        プロジェクト管理ツール
      </Text>

      <Image
        src={'/assets/logo.png'}
        alt={'logo'}
        w={'auto'}
        h={'64px'}
        mb={'24px'}
      />
      {/* <Box>
        {JSON.stringify({
          initialized,
          firebaseUser: !!firebaseUser,
          status,
          self: !!self,
          redirectToHome,
        })}
      </Box> */}
      {/* <Box fontWeight={'bold'}>ログイン or サインアップ</Box> */}
      {code && <Box>招待コード: {code}</Box>}
      {self && firebaseUser && (
        <Box>
          ログイン中: {self.name} ({firebaseUser.email})
        </Box>
      )}
      <Button
        type='submit'
        padding={0}
        onClick={() => googleLogin.mutateAsync()}
        isLoading={redirectToHome || googleLogin.isLoading}
      >
        <img src={GoogleLoginButton} />
        {/* <HStack w={'full'} justifyContent={'center'}>
          <FcGoogle />
          ログイン
        </HStack> */}
      </Button>
    </VStack>
  )
}
