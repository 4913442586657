import { RouterOutput } from '@/lib/trpc'
import { Spinner, VStack, HStack, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { useYearMonthContext } from '@/context/yearMonth'
import {
  createBusinessDatesPerProject,
  toNextMonth,
  toPrevMonth,
} from '@app/shared'
import { ProjectsEventCalendar } from './EventCalendar'
import { MonthlyProgress } from './MonthlyProgress'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { RecentTaskCardList } from './RecentTaskCardList'
import { NewRuleComments } from './NewRuleComments'
import { useDashboardTaskCardsQuery } from './useDashboardTaskCardsQuery'

export const DashboardForPM: React.FC<{
  projects: SelectedProject[]
  projectMonths: RouterOutput['projectMonth']['list']
}> = ({ projects, projectMonths }) => {
  const { yearMonth } = useYearMonthContext()
  const businessDatesPerProject = React.useMemo(() => {
    return createBusinessDatesPerProject(projects, projectMonths, [
      toPrevMonth(yearMonth),
      yearMonth,
      toNextMonth(yearMonth),
    ])
  }, [projectMonths, projects, yearMonth])

  const { data: taskCards } = useDashboardTaskCardsQuery({
    projectIds: projects.map((p) => p.id),
  })

  return (
    <VStack
      alignItems={'flex-start'}
      w={'full'}
      borderRadius={'4px'}
      spacing={'14'}
    >
      <ProjectsEventCalendar
        projects={projects}
        businessDatesPerProject={businessDatesPerProject}
      />
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <HStack justifyContent={'flex-start'} alignItems={'center'} mb={'4'}>
          <Heading size={'lg'}>月次進捗</Heading>
          <Text fontWeight={'bold'}>
            {yearMonth.year}年 {yearMonth.month}月
          </Text>
        </HStack>
        <MonthlyProgress projects={projects} taskCards={taskCards} />
      </VStack>
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <Heading size={'lg'}>近日中のタスクカード</Heading>
        <RecentTaskCardList
          businessDatesPerProject={businessDatesPerProject}
          projects={projects}
          taskCards={taskCards}
        />
      </VStack>

      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'24px'}
      >
        <HStack justifyContent={'flex-start'} alignItems={'center'}>
          <HStack justifyContent={'flex-start'} alignItems={'center'}>
            <Heading size={'lg'}>新ルール通知</Heading>
            <Text fontWeight={'bold'}>過去30日</Text>
          </HStack>
        </HStack>
        <React.Suspense fallback={<Spinner />}>
          <NewRuleComments
            businessDatesPerProject={businessDatesPerProject}
            projects={projects}
          />
        </React.Suspense>
      </VStack>
    </VStack>
  )
}
