import React from 'react'
import { logger } from '@/lib/logger'
import { Input, Box, HStack, VStack, useToast } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { trpc } from '@/lib/trpc'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { UserInviteSchema } from '@app/api/src/usecase/invitation'
import { MdAdd, MdClose } from 'react-icons/md'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { ProjectYearlyEventDates } from '@app/api/src/model/prisma'

export const Invite: AuthenticatedComponent = () => {
  const toast = useToast()
  const form = useForm<{ invitations: UserInviteSchema }>({
    defaultValues: {
      invitations: [],
    },
  })
  const invitations = useWatch({
    control: form.control,
    name: 'invitations',
  })
  const mutation = trpc.user.invite.useMutation({
    onSuccess: (result) => {
      if (result.ok) {
        logger.info(`invitation succeeded`, { invitations })
        toast({
          title: `招待メールを送信しました`,
          status: 'success',
        })
        form.reset()
      } else {
        logger.info(`invitation failed`, {
          invitations,
          error: result.errorMessage,
        })
        toast({
          title: `招待メールの送信に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.info(`invitation failed`, { invitations, error })
      toast({
        title: `招待メールの送信に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  const { append, remove } = useFieldArray({
    control: form.control,
    name: 'invitations',
  })

  return (
    <VStack
      justifyContent={'center'}
      alignItems={'center'}
      w={'full'}
      textAlign={'center'}
      gap={'16px'}
      padding={'32px'}
    >
      <Box>ユーザーをシステムに招待する</Box>

      <form
        onSubmit={form.handleSubmit((input) =>
          mutation.mutateAsync(input.invitations),
        )}
      >
        <VStack alignItems={'flex-start'} gap={'16px'}>
          {invitations.map((_, i) => {
            return (
              <HStack
                alignItems={'center'}
                justifyContent={'flex-start'}
                key={`invitation-${i}`}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                borderRadius={'8px'}
              >
                <label htmlFor='email'>メールアドレス</label>'
                <Input
                  id='email'
                  type='email'
                  {...form.register(`invitations.${i}.email`)}
                />
                <label htmlFor='role'>権限</label>'
                <select id='role' {...form.register(`invitations.${i}.role`)}>
                  <option value='pm'>PM</option>
                  <option value='member'>メンバー</option>
                </select>
                <Button
                  type='button'
                  variant='border'
                  onClick={() => remove(i)}
                >
                  <MdClose />
                </Button>
              </HStack>
            )
          })}
          <Button
            type='button'
            variant='border'
            onClick={() => append({ email: '', role: 'member' })}
          >
            <MdAdd />
          </Button>
        </VStack>

        <Button
          paddingX={'48px'}
          type='submit'
          variant={'border'}
          isLoading={mutation.isLoading}
          isDisabled={invitations.length === 0}
        >
          招待を送信する
        </Button>
      </form>
    </VStack>
  )
}
