import { Text, Button, HStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { MdArrowBack, MdArrowBackIos, MdOutlineArrowBack } from 'react-icons/md'

export const BackButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate(-2)}
      borderWidth={'1px'}
      borderRadius={'8px'}
      borderColor={'black'}
      bgColor={'white'}
      size={'sm'}
    >
      <HStack spacing={0} alignItems={'center'}>
        <MdArrowBackIos />
        <Text>戻る</Text>
      </HStack>
    </Button>
  )
}
