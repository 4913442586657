import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { dateTimeFormatter, sleep } from '@app/shared'
import {
  Text,
  HStack,
  VStack,
  Heading,
  StackDivider,
  useToast,
  Button,
  Divider,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormLabel,
  Textarea,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { User } from '@prisma/client'
import { MdOutlineDelete } from 'react-icons/md'
import React from 'react'
import { BusinessDates } from '@app/shared'
import { TaskCardDetail } from '../types'
import { logger } from '@/lib/logger'
import { trpc } from '@/lib/trpc'
import { TaskAttributeSelect } from '../../../ui/TaskCardAttribute'
import { TimeSlotSelect } from '../../../ui/TimeSlot'
import { LinkButton } from '@/components/ui/LinkButton'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import type { TaskCardUpdateDetailByPMSchema } from '@app/api/src/usecase/taskCard/updateTask'
import { useDialog } from '@/components/ui/Dialog'
import { TaskCardArchiveConfirmationDialog } from './ArchiveConfirmationDialog'
import { useNavigate } from 'react-router-dom'
import { ColorMark } from '@/components/ui/ColorMark'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { StickyHeader } from '@/components/ui/StickyHeader'
import type { NamedLinkSchema } from '@app/api/src/routers/trpc/schema'
import { UserAssignSelect } from '@/components/ui/UserAssignSelect'
import { NamedLinkArrayInput } from '@/components/ui/NamedLinkArrayInput'
import { ErrorCard } from '@/pages/ErrorPage'
import {
  TaskCardDeadlineInput,
  TaskCardStartInput,
} from '@/components/ui/TaskCardDateInput'

export const TaskCardEditPM: AuthenticatedComponent<{
  taskCard: TaskCardDetail
}> = ({ self, firebaseUser, taskCard }) => {
  const businessDates = BusinessDates.of(taskCard.projectMonth)
  logger.debug(`TaskCardShow`, {
    task: taskCard,
    taskBusinessDates: businessDates,
  })

  const form = useForm<TaskCardUpdateDetailByPMSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      taskCardId: Number(taskCard.id),
      type: 'basicInformation.pm',
      title: taskCard.title,
      assignedToUserId: taskCard.assignedToUserId,
      attribute: taskCard.attribute,
      startBusinessDate: taskCard.startBusinessDate,
      deadlineBusinessDate: taskCard.deadlineBusinessDate,
      startTimeslotStart: taskCard.startTimeslotStart,
      startTimeslotEnd: taskCard.startTimeslotEnd,
      deadlineTimeslotStart: taskCard.deadlineTimeslotStart,
      deadlineTimeslotEnd: taskCard.deadlineTimeslotEnd,
      description: (taskCard.description as NamedLinkSchema[]) || [],
      inputDescription: taskCard.inputDescription,
      inputLinks: (taskCard.inputLinks as NamedLinkSchema[]) || [],
      outputDescription: taskCard.outputDescription,
      outputLinks: (taskCard.outputLinks as NamedLinkSchema[]) || [],
    },
  })
  const mutation = trpc.taskCard.update.useMutation({
    onError: (e) => {
      logger.error('TaskCardEditPM', { error: e })
      toast({
        title: `保存に失敗しました ${e.message}`,
        status: 'error',
      })
    },
  })
  const util = trpc.useContext()
  const toast = useToast()
  const navigate = useNavigate()
  const { setError } = form
  const onSubmitToUpdate = React.useCallback(
    async (data: TaskCardUpdateDetailByPMSchema) => {
      if (
        data.startBusinessDate &&
        data.deadlineBusinessDate &&
        (data.startBusinessDate > data.deadlineBusinessDate ||
          (data.startTimeslotStart &&
            data.deadlineTimeslotStart &&
            data.startBusinessDate === data.deadlineBusinessDate &&
            data.startTimeslotStart > data.deadlineTimeslotStart))
      ) {
        setError('deadlineBusinessDate', {
          type: 'custom',
          message: '実施予定日よりも後の日付を入力してください',
        })
        return false
      }

      const result = await mutation.mutateAsync(data)
      toast.closeAll()
      if (result.ok) {
        await util.taskCard.show.invalidate({
          projectId: Number(taskCard.projectId),
          taskNumber: taskCard.taskNumber,
          yearMonth: {
            year: taskCard.projectMonth.year,
            month: taskCard.projectMonth.month,
          },
        })
        toast({
          title: '保存しました',
          status: 'success',
        })
        navigate(
          PagePath.taskCard.show(
            taskCard.projectId,
            taskCard.taskNumber,
            taskCard.projectMonth,
          ),
        )
      } else {
        logger.error('TaskCardHeadingEdit', {
          taskCard: Number(taskCard.id),
          result,
        })
        toast({
          title: `保存に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    [
      mutation,
      navigate,
      setError,
      taskCard.id,
      taskCard.projectId,
      taskCard.projectMonth,
      taskCard.taskNumber,
      toast,
      util.taskCard.show,
    ],
  )

  if (self.role === 'member') {
    return <ErrorCard errorMessage='権限がありません' />
  }

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(onSubmitToUpdate)}
      >
        <TaskCardEditStickyHeader
          taskCard={taskCard}
          businessDates={businessDates}
          isUpdating={mutation.isLoading}
        />
        <LayoutWithStickyHeader>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'56px'}
            paddingBottom={'120px'}
          >
            <TaskHeadingContent
              taskCard={taskCard}
              businessDates={businessDates}
            />
            <TaskBasicInformationEdit taskCard={taskCard} />
          </VStack>
        </LayoutWithStickyHeader>
      </form>
    </FormProvider>
  )
}

const TaskCardEditStickyHeader = ({
  taskCard,
  businessDates,
  isUpdating,
}: {
  taskCard: TaskCardDetail
  businessDates: BusinessDates
  isUpdating: boolean
}) => {
  const dialog = useDialog()
  const util = trpc.useContext()
  const navigate = useNavigate()
  const toast = useToast()

  const archiveMutation = trpc.taskCard.archive.useMutation({
    onSuccess: async (result) => {
      dialog.closeDialog()
      if (result.ok) {
        toast({
          title: 'タスクカードを削除しました',
          status: 'success',
        })
        await sleep(1000)
        util.taskCard.invalidate()
        navigate(
          PagePath.taskCard.list(
            Number(taskCard.projectId),
            taskCard.projectMonth,
          ),
        )
      } else {
        logger.error('TaskCardArchiveConfirmationDialog', {
          taskCard: Number(taskCard.id),
          result,
        })
        toast({
          title: `タスクカードの削除に失敗しました ${result.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      dialog.closeDialog()
      logger.error('TaskCardArchiveConfirmationDialog', {
        taskCard: Number(taskCard.id),
        error,
      })
      toast({
        title: `タスクカードの削除に失敗しました ${error}`,
        status: 'error',
      })
    },
  })
  const onClickToArchive = React.useCallback(async () => {
    dialog.openDialog({
      body: (
        <TaskCardArchiveConfirmationDialog
          taskCard={taskCard}
          onSubmit={async () => {
            await archiveMutation.mutateAsync({
              taskCardId: Number(taskCard.id),
            })
          }}
        />
      ),
    })
  }, [archiveMutation, dialog, taskCard])

  const {
    formState: { isValid },
  } = useFormContext()

  return (
    <StickyHeader title={'タスクカード編集'}>
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          size={'sm'}
          variant={'white-blue'}
          to={PagePath.taskCard.show(
            taskCard.projectId,
            taskCard.taskNumber,
            taskCard.projectMonth,
          )}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>やめる</Text>
          </HStack>
        </LinkButton>
        <Button
          type={'submit'}
          size={'sm'}
          variant={'blue-fill'}
          isDisabled={archiveMutation.isLoading || !isValid}
          isLoading={isUpdating}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>保存して終了</Text>
          </HStack>
        </Button>
        <Button
          size={'sm'}
          variant={'white-red'}
          onClick={onClickToArchive}
          isLoading={archiveMutation.isLoading}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <MdOutlineDelete />
            <Text>タスクカードを削除</Text>
          </HStack>
        </Button>
      </HStack>
    </StickyHeader>
  )
}

const TaskHeadingContent = ({
  taskCard,
  businessDates,
}: {
  taskCard: TaskCardDetail
  businessDates: BusinessDates
}) => {
  const { register, control, setValue, formState } =
    useFormContext<TaskCardUpdateDetailByPMSchema>()

  const [startBusinessDateNum, deadlineBusinessDateNum] = useWatch({
    name: ['startBusinessDate', 'deadlineBusinessDate'],
    control,
    defaultValue: {
      startBusinessDate: taskCard.startBusinessDate,
      deadlineBusinessDate: taskCard.deadlineBusinessDate,
    },
  })
  const { startBusinessDate, deadlineBusinessDate } = React.useMemo(() => {
    return {
      startBusinessDate: startBusinessDateNum
        ? businessDates.toDate(startBusinessDateNum)
        : undefined,
      deadlineBusinessDate: deadlineBusinessDateNum
        ? businessDates.toDate(deadlineBusinessDateNum)
        : undefined,
    }
  }, [businessDates, deadlineBusinessDateNum, startBusinessDateNum])
  logger.info('TaskHeadingContent', {
    taskCard,
    businessDates,
    startBusinessDateNum,
    deadlineBusinessDateNum,
    startBusinessDate,
    deadlineBusinessDate,
  })

  return (
    <VStack
      w={'full'}
      pt={'16px'}
      pb={'24px'}
      px={'24px'}
      spacing={'16px'}
      bgColor={'white'}
      boxShadow={'base'}
      borderRadius={'8px'}
    >
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          プロジェクト名
        </Text>
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={taskCard.project.color} />
          <Text fontWeight={'bold'}>{taskCard.project.name}</Text>
        </HStack>
      </HStack>
      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={'12px'}
      >
        <Text w={'160px'} color={'gray.600'}>
          No.
        </Text>
        <Text fontWeight={'bold'}>{taskCard.taskNumber}</Text>
      </HStack>
      <Divider />
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.attribute}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            属性
          </FormLabel>
          <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            <TaskAttributeSelect
              defaultValue={taskCard.attribute}
              onChange={(attribute) =>
                attribute && setValue('attribute', attribute)
              }
            />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl w={'full'} isRequired isInvalid={!!formState.errors?.title}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            タスクカード名
          </FormLabel>
          <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            <Input
              {...register('title', { required: true })}
              type={'text'}
              minW={'fit-content'}
              w={'30em'}
            />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.startBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            実施予定
          </FormLabel>
          <TaskCardStartInput />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.deadlineBusinessDate}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            期限日
          </FormLabel>
          <TaskCardDeadlineInput />
        </HStack>
      </FormControl>
      <FormControl
        w={'full'}
        isRequired
        isInvalid={!!formState.errors?.assignedToUserId}
      >
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={'12px'}
        >
          <FormLabel w={'160px'} color={'gray.600'}>
            担当者
          </FormLabel>
          <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            <UserAssignSelect
              projectId={Number(taskCard.projectId)}
              defaultValue={taskCard.assignedTo || undefined}
              onChange={(user: User) => {
                setValue('assignedToUserId', user.id)
              }}
            />
          </VStack>
        </HStack>
      </FormControl>
    </VStack>
  )
}

const TaskBasicInformationEdit = ({
  taskCard,
}: {
  taskCard: TaskCardDetail
}) => {
  const { register, formState } =
    useFormContext<TaskCardUpdateDetailByPMSchema>()
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              input
            </Text>
            {/* <Button variant={'transparent'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <MdEdit />
                <Text>編集</Text>
              </HStack>
            </Button> */}
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!formState.errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('inputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'inputLinks'} />
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              作業内容
            </Text>
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <NamedLinkArrayInput name={'description'} />
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'12px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <VStack
            alignItems={'center'}
            justifyContent={'flex-start'}
            w={'80px'}
          >
            <Text fontWeight={'bold'} size={'lg'}>
              output
            </Text>
            {/* <Button variant={'transparent'}>
              <HStack alignItems={'center'} justifyContent={'flex-start'}>
                <MdEdit />
                <Text>編集</Text>
              </HStack>
            </Button> */}
          </VStack>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <FormControl isInvalid={!!formState.errors.inputDescription}>
              <FormLabel>説明文</FormLabel>
              <Textarea {...register('outputDescription')} />
            </FormControl>
            <FormLabel>参考情報</FormLabel>
            <NamedLinkArrayInput name={'outputLinks'} />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
