import { ColorMark } from '@/components/ui/ColorMark'
import { colorCandidates } from '@/helper/color'
import {
  Box,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  getToken,
  useToken,
} from '@chakra-ui/react'
import React from 'react'

export const ColorPalette: React.FC<{
  color?: string
  onChange: (color: string) => void
}> = ({ color, onChange }) => {
  return (
    <RadioGroup onChange={onChange} defaultValue={color}>
      <SimpleGrid columns={5} spacing={4}>
        {colorCandidates.map((color) => {
          const id = `color-pallete-${color.value}`
          return (
            <HStack key={color.value}>
              <Radio id={id} value={color.value} />
              <label htmlFor={id} style={{ cursor: 'pointer' }}>
                <HStack>
                  <ColorMark color={color.value} />
                  <Text>{color.label}</Text>
                </HStack>
              </label>
            </HStack>
          )
        })}
      </SimpleGrid>
    </RadioGroup>
  )
}
