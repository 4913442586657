import { Select, SelectProps, Tag } from '@chakra-ui/react'
import { UserRole } from '@prisma/client'
import React from 'react'

export const UserRoleTag: React.FC<{ role: UserRole }> = ({ role }) => {
  switch (role) {
    case 'admin':
      return (
        <Tag
          size={'sm'}
          variant={'subtle'}
          colorScheme={'red'}
          w={'5em'}
          textAlign={'center'}
          justifyContent={'center'}
        >
          管理者
        </Tag>
      )
    case 'pm':
      return (
        <Tag
          size={'sm'}
          variant={'subtle'}
          colorScheme={'green'}
          w={'5em'}
          textAlign={'center'}
          justifyContent={'center'}
        >
          PM
        </Tag>
      )
    case 'member':
      return (
        <Tag
          size={'sm'}
          variant={'subtle'}
          colorScheme={'blue'}
          w={'5em'}
          textAlign={'center'}
          justifyContent={'center'}
        >
          メンバー
        </Tag>
      )
  }
}

export const UserRoleSelect = (props: SelectProps) => {
  return (
    <Select size={'sm'} {...props} placeholder={'権限を選択'}>
      <option value='pm'>PM</option>
      <option value='member'>メンバー</option>
      <option value='admin'>管理者</option>
    </Select>
  )
}
