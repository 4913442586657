import { boxStyle } from './box'
import { buttonStyle } from './button'
import { inputStyle } from './input'
import { tableStyle, tableTheme } from './table'

export const components = {
  Button: buttonStyle,
  ...tableStyle,
  // Table: tableTheme,
  Box: boxStyle,
  ...inputStyle,
}
