import { Badge, Center, Select, Tag } from '@chakra-ui/react'
import { TaskCard, TaskInputStatus } from '@prisma/client'
import React, { ChangeEvent } from 'react'

export const taskCardInputStatusOptions = {
  not_arrived: { label: '未着', color: 'gray.500', colorScheme: 'red' },
  delayed: {
    label: '資料受領遅延',
    color: 'red.500',
    colorScheme: 'purple',
  },
  partially_arrived: {
    label: '一部受領済み',
    color: 'orange.500',
    colorScheme: 'orange',
  },
  completed: {
    label: '受領済み',
    color: 'green.600',
    colorScheme: 'green',
  },
} as const

export const TaskInputStatusTag = ({
  inputStatus,
}: {
  inputStatus: TaskCard['inputStatus']
}) => {
  const option = taskCardInputStatusOptions[inputStatus]
  return (
    <Badge
      variant={'solid'}
      w={'90px'}
      h={'24px'}
      colorScheme={option?.colorScheme || 'gray'}
      textAlign={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      size={'sm'}
      borderRadius={'6px'}
      whiteSpace={'nowrap'}
    >
      <Center w={'full'} h={'full'}>
        {option?.label || '不明'}
      </Center>
    </Badge>
  )
}

export const TaskInputStatusSelect = ({
  inputStatus,
  onChange,
}: {
  inputStatus: TaskCard['inputStatus']
  onChange: (inputStatus: TaskCard['inputStatus']) => void
}) => {
  const [_inputStatus, setInputStatus] =
    React.useState<TaskCard['inputStatus']>(inputStatus)
  const option = taskCardInputStatusOptions[inputStatus]

  const _onChange = React.useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newValue = e.target.value as TaskCard['inputStatus']
      setInputStatus(newValue)
      onChange(newValue)
    },
    [onChange],
  )

  return (
    <Select
      color={_inputStatus ? 'white' : 'gray.500'}
      _focusWithin={{
        '> option': {
          color: 'gray.500',
        },
      }}
      bgColor={option.color}
      defaultValue={_inputStatus}
      w={'160px'}
      onChange={_onChange}
      size={'md'}
      px={'6px'}
      h={'36px'}
      borderRadius={'6px'}
    >
      {Object.keys(taskCardInputStatusOptions).map((key) => {
        const option = taskCardInputStatusOptions[key as TaskInputStatus]
        return (
          <option key={key} value={key}>
            {option.label}
          </option>
        )
      })}
    </Select>
  )
}
