import { PagePath } from '@/PagePath'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import { dateTimeFormatter, jstNow, yearMonthFromDate } from '@app/shared'
import {
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  VStack,
  Heading,
  StackDivider,
  SimpleGrid,
  Link,
} from '@chakra-ui/react'
import { MdArrowRight } from 'react-icons/md'
import React from 'react'
import { RouterOutput } from '@/lib/trpc'
import { TaskAttributeTag } from '@/components/ui/TaskCardAttribute'
import { TimeSlot } from '@/components/ui/TimeSlot'
import { LinkButton } from '@/components/ui/LinkButton'
import { StickyHeader } from '@/components/ui/StickyHeader'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { DescriptionLinkGrid } from '@/components/ui/DescriptionLinkGrid'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'
import { locationFromToPath, useLocationFrom } from '@/helper/locationState'
import { TextWithNewLine } from '@/components/ui/TextWithNewLine'
import { ProjectWithColor } from '@/components/ui/ProjectWithColor'

type TaskCardTemplate = NonNullable<RouterOutput['taskCardTemplate']['show']>
export const TaskCardTemplateShow: AuthenticatedComponent<{
  taskCardTemplate: TaskCardTemplate
}> = ({ self, firebaseUser, taskCardTemplate }) => {
  return (
    <>
      <TaskCardTemplateShowStickyHeader taskCardTemplate={taskCardTemplate} />
      <LayoutWithStickyHeader>
        <VStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'56px'}
          paddingBottom={'120px'}
          mt={'24px'}
        >
          <TaskCardTemplateHeading taskCardTemplate={taskCardTemplate} />
          <BasicInformation taskCardTemplate={taskCardTemplate} />
        </VStack>
      </LayoutWithStickyHeader>
    </>
  )
}
const TaskCardTemplateShowStickyHeader: React.FC<{
  taskCardTemplate: TaskCardTemplate
}> = ({ taskCardTemplate }) => {
  const locationFrom = useLocationFrom()
  const yearMonth = React.useMemo(() => yearMonthFromDate(jstNow()), [])
  return (
    <StickyHeader
      bgColor={'blue.100'}
      color={'blue.700'}
      title={'タスクカードテンプレートを表示中'}
    >
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          variant={'white-blue'}
          size={'sm'}
          to={
            locationFromToPath(locationFrom) ||
            PagePath.taskCard.bulkNew(
              Number(taskCardTemplate.projectId),
              yearMonth,
            )
          }
        >
          <Text>やめる</Text>
        </LinkButton>
        <LinkButton
          variant={'blue-fill'}
          size={'sm'}
          to={PagePath.template.edit(
            taskCardTemplate.projectId,
            taskCardTemplate.taskNumber,
          )}
        >
          <HStack alignItems={'center'} justifyContent={'flex-start'}>
            <Text>テンプレートを編集</Text>
          </HStack>
        </LinkButton>
      </HStack>
    </StickyHeader>
  )
}

const TaskCardTemplateHeading = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  return (
    <VStack spacing={'24px'} w={'full'}>
      <VStack
        spacing={0}
        borderRadius={'8px'}
        w={'full'}
        bgColor={'white'}
        boxShadow={'base'}
      >
        <TaskBreadCrumbs taskCardTemplate={taskCardTemplate} />
        <TaskCardTemplateHeadingContent taskCardTemplate={taskCardTemplate} />
      </VStack>
    </VStack>
  )
}

const TaskBreadCrumbs = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  return (
    <Breadcrumb
      w={'full'}
      px={'16px'}
      py={'8px'}
      bgColor={'orange.50'}
      borderTopRadius={'8px'}
      separator={<MdArrowRight />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          href={PagePath.project.show(Number(taskCardTemplate.projectId))}
        >
          <ProjectWithColor project={taskCardTemplate.project} />
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Text color={'gray.500'}>{taskCardTemplate.title}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

const TaskAssignedUser = ({
  user,
}: {
  user: TaskCardTemplate['assignedTo']
}) => {
  return (
    <HStack alignItems={'center'} whiteSpace={'nowrap'}>
      <Text>担当者</Text>
      {user ? <UserWithIcon user={user} /> : <Text>未割り当て</Text>}
    </HStack>
  )
}

const TaskCardTemplateHeadingContent = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  return (
    <VStack w={'full'} pt={'16px'} pb={'24px'} px={'24px'} spacing={'16px'}>
      <HStack w={'full'} alignItems={'center'} justifyContent={'flex-end'}>
        <HStack>
          <Text>
            作成日{' '}
            {dateTimeFormatter.jst['YYYY/MM/DD'](taskCardTemplate.createdAt)}
          </Text>
          <Text>
            最終更新{' '}
            {dateTimeFormatter.jst['YYYY/MM/DD'](taskCardTemplate.updatedAt)}
          </Text>
        </HStack>
      </HStack>

      <HStack w={'full'} alignItems={'center'} justifyContent={'flex-start'}>
        <TaskAttributeTag attribute={taskCardTemplate.attribute} />
        <Text>[{taskCardTemplate.taskNumber}]</Text>
        <Heading size={'lg'}>{taskCardTemplate.title}</Heading>
      </HStack>

      <StackDivider borderWidth={'1px'} />
      <SimpleGrid
        columns={2}
        w={'full'}
        alignItems={'center'}
        spacing={'12px'}
        // justifyContent={'space-between'}
      >
        <HStack color={'red.600'} alignItems={'center'} spacing={'12px'}>
          <Text>期限</Text>
          <HStack fontSize={'2xl'} fontWeight={'bold'} alignItems={'center'}>
            <Text>第{taskCardTemplate.deadlineBusinessDate}営業日</Text>
            <TimeSlot
              start={taskCardTemplate.deadlineTimeslotStart}
              end={taskCardTemplate.deadlineTimeslotEnd}
            />
          </HStack>
        </HStack>
        <TaskAssignedUser user={taskCardTemplate.assignedTo} />
      </SimpleGrid>

      <HStack
        w={'full'}
        alignItems={'center'}
        justifyContent={'center'}
        whiteSpace={'nowrap'}
        spacing={'32px'}
        paddingX={'32px'}
        paddingY={'16px'}
        divider={<StackDivider />}
        bgColor={'gray.50'}
      >
        <HStack
          spacing={'16px'}
          flexGrow={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text fontWeight={'bold'}>実施予定</Text>
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'4px'}
            borderRadius={'4px'}
          >
            <HStack alignItems={'center'} justifyContent={'flex-start'}>
              <Text>第{taskCardTemplate.startBusinessDate}営業日</Text>
              <TimeSlot
                start={taskCardTemplate.startTimeslotStart}
                end={taskCardTemplate.startTimeslotEnd}
              />
            </HStack>
            <HStack alignItems={'center'} justifyContent={'flex-start'}>
              <Text>
                {`${
                  taskCardTemplate.startBusinessDate
                    ? `[第${taskCardTemplate.startBusinessDate}営業日]`
                    : '[第-営業日]'
                }`}
              </Text>
              <TimeSlot
                start={taskCardTemplate.startTimeslotStart}
                end={taskCardTemplate.startTimeslotEnd}
              />
            </HStack>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  )
}

const BasicInformation = ({
  taskCardTemplate,
}: {
  taskCardTemplate: TaskCardTemplate
}) => {
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
    >
      <Heading size={'lg'}>基本情報</Heading>
      <VStack
        boxShadow={'base'}
        w={'full'}
        px={'16px'}
        py={'16px'}
        bgColor={'white'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={'16px'}
        divider={<StackDivider />}
        borderRadius={'8px'}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'24px'}
          divider={<StackDivider />}
          w={'full'}
        >
          <Text w={'80px'} fontWeight={'bold'} size={'lg'}>
            input
          </Text>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <TextWithNewLine>
              {taskCardTemplate.inputDescription}
            </TextWithNewLine>
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              borderColor={'gray.200'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              spacing={'8px'}
              px={'20px'}
              py={'16px'}
              w={'full'}
            >
              <Text fontSize={'sm'} fontWeight={'bold'}>
                参考情報
              </Text>
              <DescriptionLinkGrid links={taskCardTemplate.inputLinks} />
            </VStack>
          </VStack>
        </HStack>

        <HStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          divider={<StackDivider />}
          spacing={'24px'}
          w={'full'}
        >
          <Text fontWeight={'bold'} size={'lg'} w={'80px'}>
            作業内容
          </Text>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={'8px'}
            px={'20px'}
            py={'16px'}
            w={'full'}
          >
            <DescriptionLinkGrid links={taskCardTemplate.description} />
          </VStack>
        </HStack>

        <HStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          divider={<StackDivider />}
          spacing={'24px'}
        >
          <Text fontWeight={'bold'} size={'lg'} w={'80px'}>
            output
          </Text>

          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'full'}
          >
            <TextWithNewLine>
              {taskCardTemplate.outputDescription}
            </TextWithNewLine>
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              borderColor={'gray.200'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              spacing={'8px'}
              px={'20px'}
              py={'16px'}
              w={'full'}
            >
              <Text fontSize={'sm'} fontWeight={'bold'}>
                参考情報
              </Text>
              <DescriptionLinkGrid links={taskCardTemplate.outputLinks} />
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}
