import { HStack, TypographyProps } from '@chakra-ui/react'
import { ColorMark } from './ColorMark'
import { TextEllipsis } from './TextEllipsis'
import { Project } from '@prisma/client'
import { SelectedProject } from './ProjectSelector/type'

export const ProjectWithColor = ({
  project,
  fontWeight,
  fontSize,
}: {
  project: Project | SelectedProject
  fontWeight?: TypographyProps['fontWeight']
  fontSize?: TypographyProps['fontSize']
}) => {
  return (
    <HStack w={'full'} alignItems={'center'} justifyContent={'flex-start'}>
      <ColorMark color={project.color}></ColorMark>
      <TextEllipsis fontWeight={fontWeight} fontSize={fontSize}>
        {project.name}
      </TextEllipsis>
    </HStack>
  )
}
