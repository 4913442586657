import { VStack } from '@chakra-ui/react'

export const RootLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <VStack w={'full'} minW={'1440px'} maxW={'1580px'}>
      <Header />
      {children}
      <Footer />
    </VStack>
  )
}

const Header = () => <></>
const Footer = () => <></>
