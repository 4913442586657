import { FaBell } from 'react-icons/fa6'
import {
  MdOutlineCommentBank,
  MdOutlineEditNote,
  MdOutlineList,
} from 'react-icons/md'

import React from 'react'
import {
  Text,
  Box,
  Button,
  Circle,
  HStack,
  VStack,
  Spacer,
  Tooltip,
  Image,
  useMediaQuery,
} from '@chakra-ui/react'
import { PagePath, RouterPath } from '@/PagePath'
import { Link } from 'react-router-dom'
import { MdOutlineLogout, MdSpaceDashboard } from 'react-icons/md'
import { useSelfState } from '@/components/providers/SelfProvider'
import { ProjectList } from './ProjectList'
import { PmMenus } from './PmMenus'
import { SidebarLink } from './SidebarLink'
import { AdminMenus } from './AdminMenus'
import { ProjectFilterList } from './ProjectFilterList'
import { atom, useAtom, useAtomValue } from 'jotai'

// import { Logo } from './Logo' // SVGを使う場合
const Logo = () => {
  return <Image src={'/assets/logo.png'} alt={'logo'} w={'auto'} h={'32px'} />
}

const sidebarStateAtom = atom<{
  isOpen: boolean
}>({
  isOpen: true,
})
export const useSidebarState = () => {
  return useAtomValue(sidebarStateAtom)
}

export const Sidebar: React.FC = () => {
  const [state, setState] = useAtom(sidebarStateAtom)
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
  return (
    <VStack
      w={state.isOpen ? (isLargerThan1280 ? '17.5%' : '224px') : '5%'} // %で1280pxの時にサイドバーが224pxになる
      position={'fixed'}
      top={0}
      left={0}
      flexShrink={0}
      minHeight={'100vh'}
      h={'100%'}
      paddingX={'12px'}
      paddingY={'16px'}
      gap={'20px'}
      alignItems={'flex-start'}
      bgColor={'white'}
      boxShadow={'base'}
      overflowY={'auto'}
      color={'gray.600'}
      zIndex={'1'}
    >
      <HStack w={'full'} alignItems={'center'} justifyContent={'space-between'}>
        {/* <Button
          bgColor={'transparent'}
          onClick={() => setState({ ...state, isOpen: !state.isOpen })}
        >
          {state.isOpen ? <MdOutlineClose /> : <MdOutlineMenu />}
        </Button> */}
        <Logo />
        <SelfLogo />
      </HStack>
      <VStack w={'full'} alignItems={'center'} justifyContent={'flex-start'}>
        <SidebarLink
          to={RouterPath.dashboard}
          icon={<MdSpaceDashboard />}
          text={'ダッシュボード'}
        />
        <SidebarLink
          to={PagePath.comments}
          icon={<FaBell />}
          text={'共有事項一覧'}
        />
        <SidebarLink
          to={PagePath.questions}
          icon={<MdOutlineCommentBank />}
          text={'クライアントへの質問'}
        />
        <SidebarLink
          to={PagePath.inputStatusList}
          icon={<MdOutlineList />}
          text={'input資料進捗表'}
        />
        <SidebarLink
          to={PagePath.taskCard.list()}
          icon={<MdOutlineEditNote />}
          text={'タスクカード一覧'}
        />
      </VStack>
      <ProjectFilterList />
      <ProjectList />
      {/* <PmMenus /> */}

      <AdminMenus />

      <Spacer />
      <VStack justifyContent={'flex-start'} alignItems={'center'} w={'full'}>
        <SidebarLink
          to={RouterPath.logout}
          icon={<MdOutlineLogout />}
          text={'ログアウト'}
        />
      </VStack>
    </VStack>
  )
}

const SelfLogo: React.FC = () => {
  const { self } = useSelfState()
  const label = React.useMemo(
    () => `${self?.name || ''}[${self?.userAccount.email}](${self?.role})`,
    [self],
  )
  return (
    <Tooltip hasArrow label={label} openDelay={400}>
      <SelfLogoInner />
    </Tooltip>
  )
}

const SelfLogoInner = React.forwardRef<HTMLButtonElement>((props, ref) => {
  const { self } = useSelfState()
  const [state, setState] = useAtom(sidebarStateAtom)
  return (
    <Button
      variant={'transparent'}
      cursor={'default'}
      p={0}
      m={0}
      {...props}
      ref={ref}
      // onClick={() => setState({ ...state, isOpen: !state.isOpen })}
    >
      <Circle
        bgColor={'white'}
        color={'white'}
        size={'32px'}
        borderColor={'gray.500'}
        borderWidth={'1px'}
        overflow={'hidden'}
      >
        {self?.pictureUrl ? (
          <img width={'32px'} src={self.pictureUrl} alt={self.name || ''} />
        ) : (
          (self?.name || '...')[0].toUpperCase()
        )}
      </Circle>
    </Button>
  )
})
