import { Button } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md'

export const LeftArrowButton: React.FC<{
  isDisabled: boolean
  onClick: () => void
}> = ({ isDisabled, onClick }) => {
  return (
    <Button
      bgColor={'white'}
      _hover={{ bgColor: 'gray.100' }}
      _active={{ bgColor: 'gray.200' }}
      borderRadius={'6px'}
      borderWidth={'1px'}
      borderColor={'gray.200'}
      size={'sm'}
      paddingX={'1.5'}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <ChevronLeftIcon boxSize={'6'} color={'gray.400'} />
    </Button>
  )
}
export const RightArrowButton: React.FC<{
  isDisabled: boolean
  onClick: () => void
}> = ({ isDisabled, onClick }) => {
  return (
    <Button
      bgColor={'white'}
      _hover={{ bgColor: 'gray.100' }}
      _active={{ bgColor: 'gray.200' }}
      borderWidth={'1px'}
      borderColor={'gray.200'}
      borderRadius={'6px'}
      size={'sm'}
      paddingX={'1.5'}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <ChevronRightIcon boxSize={'6'} color={'gray.400'} />
    </Button>
  )
}
