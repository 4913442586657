import { RouterOutput, trpc } from '@/lib/trpc'
import { AuthenticatedComponent } from '@/pages/AuthenticatedPage'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  StackDivider,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { logger } from '@/lib/logger'
import { ProjectMonthlyEvents } from '../ProjectMonthlyEvents'
import { ProjectYearlyEventsEditor } from '../ProjectYearlyEvents'
import { BusinessDateMonthlyCalendarEditView } from '../../../ui/ProjectCalendar'
import { ColorPalette } from '../ColorPalette'
import { LayoutWithStickyHeader } from '@/components/Layout'
import { PagePath } from '@/PagePath'
import { Link, LinkButton } from '@/components/ui/LinkButton'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { StickyHeader } from '@/components/ui/StickyHeader'
import { ProjectUpdateSchema } from '@app/api/src/usecase/project/updateProject'
import { UserSelect } from '@/components/ui/UserSelect'
import { useNavigate } from 'react-router-dom'
import { MdOutlineDelete, MdOutlineInfo } from 'react-icons/md'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'
import { User } from '@prisma/client'
import { ProjectAssignedMemberListInput } from '../ProjectAssignedMemberListInput'

type Project = NonNullable<RouterOutput['project']['show']>
export const ProjectConfigurationEdit: AuthenticatedComponent<{
  project: Project
}> = ({ self, firebaseUser, project }) => {
  const form = useForm<ProjectUpdateSchema>({
    defaultValues: {
      ...project,
      assignedMemberIds: project.projectAssign.map((assign) => assign.userId),
    },
  })
  const toast = useToast()
  const util = trpc.useContext()
  const navigate = useNavigate()
  const mutation = trpc.project.update.useMutation({
    onSuccess: async (res) => {
      toast.closeAll()
      if (res.ok) {
        await util.project.invalidate()
        toast({
          title: '保存しました',
          status: 'success',
        })
        navigate(PagePath.project.show(project.id))
      } else {
        logger.error('ProjectConfiguration updateMutation', { res })
        toast({
          title: `保存に失敗しました ${res.errorMessage}`,
          status: 'error',
        })
      }
    },
    onError: (error) => {
      logger.error('ProjectConfiguration updateMutation', error)
      toast({
        title: '保存に失敗しました',
        status: 'error',
      })
    },
  })

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit((data) => mutation.mutateAsync(data))}
      >
        <ProjectEditStickyHeader
          project={project}
          isLoading={mutation.isLoading}
        />
        <LayoutWithStickyHeader>
          <ProjectConfigurationEditPM project={project} />
        </LayoutWithStickyHeader>
      </form>
    </FormProvider>
  )
}

const ProjectConfigurationEditPM: React.FC<{ project: Project }> = ({
  project,
}) => {
  const form = useFormContext<ProjectUpdateSchema>()
  const pmUsers = trpc.user.list.useQuery({
    filter: {
      role: ['pm', 'admin'],
    },
  })
  return (
    <VStack spacing={'56px'} alignItems={'flex-start'}>
      {/* <Heading size={'xl'}>クライアント情報</Heading> */}

      <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          クライアント情報
        </Text>
        <Card>
          <FormControl isRequired isInvalid={!!form.formState.errors?.name}>
            <VStack
              w={'full'}
              spacing={'10px'}
              px={'20px'}
              py={'24px'}
              alignItems={'flex-start'}
            >
              <FormLabel fontSize={'xl'} fontWeight={'bold'}>
                プロジェクト名
              </FormLabel>
              <Input {...form.register('name')} />
              <FormErrorMessage>
                {form.formState.errors?.name?.message}
              </FormErrorMessage>
            </VStack>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={!!form.formState.errors?.hearingSheetUrl}
          >
            <VStack
              w={'full'}
              spacing={'10px'}
              px={'20px'}
              py={'24px'}
              alignItems={'flex-start'}
            >
              <FormLabel fontSize={'xl'} fontWeight={'bold'}>
                ヒアリングシートURL
              </FormLabel>
              <Input
                {...form.register('hearingSheetUrl', {
                  validate: (value) => {
                    if (value && !value.match(/^http[s]?:\/\//)) {
                      return 'URLを指定してください'
                    }
                    return true
                  },
                })}
              />
              <FormErrorMessage>
                {form.formState.errors?.hearingSheetUrl?.message}
              </FormErrorMessage>
            </VStack>
          </FormControl>
        </Card>
      </VStack>

      <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          月次イベント
        </Text>
        <Card>
          <ProjectMonthlyEvents />
        </Card>
      </VStack>

      <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          年間イベント
        </Text>
        <Card>
          <ProjectYearlyEventsEditor />
        </Card>
      </VStack>

      <VStack
        w={'full'}
        minW={'992px'}
        spacing={'24px'}
        alignItems={'flex-start'}
      >
        <HStack spacing={'24px'} alignItems={'center'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            休業日
          </Text>
          <Link
            to={'https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html'}
            textDecoration={'underline'}
            target='_blank'
          >
            <HStack alignItems={'center'}>
              <Box w={'24px'} h={'24px'} color={'gray.500'}>
                <MdOutlineInfo size={'24px'} />
              </Box>
              <Text>日本の祝日</Text>
            </HStack>
          </Link>
        </HStack>
        <Card>
          <BusinessDateMonthlyCalendarEditView
            mode='edit'
            onChange={async (nonBusinessDates) => {
              form.setValue('nonBusinessDates', nonBusinessDates, {
                shouldDirty: true,
              })
              logger.debug(`nonBusinessDates.onChange`, nonBusinessDates)
            }}
            monthlyEvents={project.monthlyEventDates}
            defaultNonBusinessDates={project.nonBusinessDates}
          />
        </Card>
      </VStack>

      <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          担当PM
        </Text>
        <Card>
          {/* <Text>{project.assignedPmUser.name}</Text> */}
          <UserSelect
            type={'mustSelect'}
            candidates={pmUsers.data || []}
            defaultValue={project.assignedPmUser}
            onChange={(user) => {
              form.setValue('assignedPmUserId', user?.id, { shouldDirty: true })
            }}
          />
        </Card>
      </VStack>

      <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          メンバー
        </Text>
        <Card>
          <ProjectAssignedMemberListInput
            projectAssignList={project.projectAssign}
          />
        </Card>
      </VStack>

      <VStack w={'full'} spacing={'24px'} alignItems={'flex-start'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          プロジェクトのカラー
        </Text>
        <Card>
          <ColorPalette
            color={project.color}
            onChange={(color) => {
              form.setValue('color', color, { shouldDirty: true })
            }}
          />
        </Card>
      </VStack>
    </VStack>
  )
}

const ProjectEditStickyHeader: React.FC<{
  project: Project
  isLoading: boolean
}> = ({ project, isLoading }) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext()
  return (
    <StickyHeader bgColor={'blue.100'} color={'blue.700'} title={'編集モード'}>
      <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
        <LinkButton
          variant={'white-blue'}
          size={'sm'}
          to={PagePath.project.show(project.id)}
        >
          <Text>やめる</Text>
        </LinkButton>
        <Button
          variant={'blue-fill'}
          type={'submit'}
          size={'sm'}
          isLoading={isLoading}
          isDisabled={!isDirty || !isValid}
        >
          <Text>保存して終了</Text>
        </Button>
      </HStack>
    </StickyHeader>
  )
}

const Card: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <VStack
      alignItems={'flex-start'}
      bgColor={'white'}
      paddingX={'16px'}
      paddingY={'16px'}
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.200'}
    >
      {children}
    </VStack>
  )
}
