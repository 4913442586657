import { useLocation } from 'react-router-dom'

type SearchParams = URLSearchParams | { [key: string]: string | number }
export type LocationFrom = {
  path: string
  searchParams?: SearchParams
}
export const createLocationFrom = (
  path: string,
  searchParams?: SearchParams,
) => {
  return {
    path,
    searchParams,
  } as LocationFrom
}

export const useLocationFrom = () => {
  const location = useLocation()
  const from = location.state as LocationFrom | undefined
  return from
}

export const locationFromToPath = (from: LocationFrom | undefined) => {
  if (!from) {
    return undefined
  }
  const { path, searchParams } = from
  if (!searchParams) {
    return path
  }

  const urlParams = (() => {
    if (searchParams instanceof URLSearchParams) {
      return searchParams
    }
    const urlParams = new URLSearchParams()
    Object.keys(searchParams).forEach((key) => {
      urlParams.append(key, String(searchParams[key]))
    })
    return urlParams
  })()
  return `${from.path}?${urlParams.toString()}`
}
