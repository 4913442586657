import {
  Text,
  AlertDialogContent,
  AlertDialogHeader,
  VStack,
  AlertDialogCloseButton,
  AlertDialogBody,
  HStack,
  Button,
  useToast,
  Alert,
} from '@chakra-ui/react'
import React from 'react'
import { trpc } from '@/lib/trpc'
import { useDialog } from '@/components/ui/Dialog'
import { logger } from '@/lib/logger'
import { useMutation } from '@tanstack/react-query'
import { TaskCardTemplate } from '@prisma/client'

export const TaskCardTemplateDeleteConfirmationDialog: React.FC<{
  taskCardTemplate: TaskCardTemplate
  onSubmit: () => Promise<void>
}> = ({ taskCardTemplate, onSubmit }) => {
  const { closeDialog } = useDialog()
  const mutation = useMutation({
    mutationFn: async () => {
      await onSubmit()
    },
  })
  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            テンプレートを削除しますか？
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          w={'full'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          spacing={'10px'}
        >
          <Alert
            status={'warning'}
            w={'full'}
            variant={'subtle'}
            boxShadow={'base'}
          >
            一度削除したテンプレートは元に戻せません。
            <br />
            テンプレートを元に一括作成したタスクカードへの影響はありません。
          </Alert>

          <HStack w={'full'} justifyContent={'flex-end'}>
            {/* <Button
              borderRadius={'6px'}
              colorScheme={'gray'}
              size={'sm'}
              onClick={closeDialog}
            >
              キャンセル
            </Button> */}
            <Button
              isLoading={mutation.isLoading}
              colorScheme='red'
              size={'sm'}
              borderRadius={'6px'}
              onClick={() => mutation.mutateAsync()}
            >
              削除する
            </Button>
          </HStack>
        </VStack>
      </AlertDialogBody>
    </AlertDialogContent>
  )
}
