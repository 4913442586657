import { RouterOutput } from '@/lib/trpc'
import {
  VStack,
  HStack,
  Text,
  Heading,
  StackDivider,
  Badge,
  Spacer,
} from '@chakra-ui/react'
import {
  endOfMonth,
  addDays,
  getWeekOfMonth,
  setDate,
  addWeeks,
  startOfMonth,
  startOfWeek,
  endOfWeek,
} from 'date-fns'
import React from 'react'
import { YearMonth, yearMonthToDate } from '@/context/yearMonth'
import {
  BusinessDatesPerProject,
  dateTimeFormatter,
  jstNow,
  range,
  toNextMonth,
  toPrevMonth,
  yearMonthFromDate,
} from '@app/shared'
import type { ProjectMonthlyEventDates } from '@app/api/src/model/prisma'
import { dayOfWeeks } from '@/constants/dayOfWeek'
import { logger } from '@/lib/logger'
import { TextEllipsis } from '@/components/ui/TextEllipsis'
import { LeftArrowButton, RightArrowButton } from '@/components/ui/ArrowButton'
import { ProjectWithColor } from '@/components/ui/ProjectWithColor'

export const ProjectsEventCalendar: React.FC<{
  projects: RouterOutput['project']['list']
  businessDatesPerProject: BusinessDatesPerProject
}> = ({ projects, businessDatesPerProject }) => {
  // const { yearMonth } = useYearMonthContext()

  const now = React.useMemo(() => jstNow(), [])
  const nowString = React.useMemo(
    () => dateTimeFormatter.jst['YYYY-MM-DD'](now),
    [now],
  )
  const startOfWeeks = React.useMemo(() => {
    const currentMonth = yearMonthFromDate(now)
    const prevMonth = toPrevMonth(currentMonth)
    const nextMonth = toNextMonth(currentMonth)
    const f = (yearMonth: YearMonth) => {
      const date = setDate(yearMonthToDate(yearMonth), now.getDate())
      const _startOfMonth = startOfWeek(startOfMonth(date))
      const _endOfMonth = endOfMonth(date)
      const startOfWeeks = range(0, getWeekOfMonth(_endOfMonth)).map((d) => {
        return addWeeks(_startOfMonth, d)
      })
      return startOfWeeks
    }
    return [f(prevMonth), f(currentMonth), f(nextMonth)].flat().reduce(
      (acc, _startOfWeek) => {
        if (acc.length > 0) {
          const prev = acc[acc.length - 1]
          if (prev && prev.date.getTime() === _startOfWeek.getTime()) {
            return acc
          }
        }
        const { year, month } = yearMonthFromDate(_startOfWeek)
        const _endOfWeek = endOfWeek(_startOfWeek)
        if (_endOfWeek.getMonth() === _startOfWeek.getMonth()) {
          const label = `${year}年 ${month}月 ${getWeekOfMonth(
            _startOfWeek,
          )}週目`
          acc.push({ label, date: _startOfWeek })
        } else {
          const { year: year2, month: month2 } = yearMonthFromDate(_endOfWeek)
          const label = `${year}年 ${month}月 ${getWeekOfMonth(
            _startOfWeek,
          )}週目 - ${year2}年 ${month2}月 ${getWeekOfMonth(_endOfWeek)}週目`
          acc.push({ label, date: _startOfWeek })
        }
        return acc
      },
      [] as { label: string; date: Date }[],
    )
  }, [now])

  const [selectedWeekIndex, setSelectedWeekIndex] = React.useState<number>(
    () => {
      const idx = startOfWeeks.findIndex((d) => {
        return d.date.getTime() >= now.getTime()
      })
      return idx >= 1 ? idx - 1 : 0
    },
  )

  const { date: selectedStartOfWeek, label } = startOfWeeks[selectedWeekIndex]
  logger.debug(`EventCalendar`, {
    startOfWeeks,
    selectedWeekIndex,
    selectedStartOfWeek,
  })
  const eventsPerWeekDay = React.useMemo(() => {
    return dayOfWeeks.reduce<{
      [weekDay: number]: {
        date: Date
        events: {
          [projectId: number]: {
            monthly: { eventName: string; businessDate: number }[]
            yearly: { name: string }[]
          }
        }
      }
    }>((acc, { day }) => {
      const date = addDays(selectedStartOfWeek, day)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      acc[day] = {
        date,
        events: projects.reduce<{
          [projectId: number]: {
            monthly: { eventName: string; businessDate: number }[]
            yearly: { name: string }[]
          }
        }>((acc, project) => {
          const monthlyEvents = (() => {
            const businessDates =
              businessDatesPerProject[Number(project.id)]?.[year]?.[month]
            if (businessDates) {
              const businessDate = businessDates.fromDate(date)
              if (businessDate) {
                return (project.monthlyEventDates as ProjectMonthlyEventDates)
                  .filter((d) => d.businessDate === businessDate)
                  .map((d) => ({ eventName: d.name, businessDate }))
              }
            }
          })()
          const yearlyEvents = project.yearlyEventDates.filter((d) => {
            return d.date === dateTimeFormatter.jst['YYYY-MM-DD'](date)
          })
          acc[Number(project.id)] = {
            monthly: monthlyEvents || [],
            yearly: yearlyEvents || [],
          }
          return acc
        }, {}),
      }
      return acc
    }, {})
  }, [businessDatesPerProject, projects, selectedStartOfWeek])

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'8px'}
      gap={'0'}
    >
      <Heading size={'lg'}>イベントカレンダー</Heading>
      <Spacer mt={'6'} />
      <HStack w={'full'} justifyContent={'space-between'} alignItems={'center'}>
        <Text w={'full'} fontSize={'2xl'} fontWeight={'bold'}>
          {label}
        </Text>
        <HStack justifyContent={'space-between'}>
          <HStack gap={'0'}>
            <LeftArrowButton
              isDisabled={selectedWeekIndex === 0}
              onClick={() => setSelectedWeekIndex((prev) => prev - 1)}
            />
            <Spacer ml={'4'} />
            <RightArrowButton
              isDisabled={selectedWeekIndex === startOfWeeks.length - 1}
              onClick={() => setSelectedWeekIndex((prev) => prev + 1)}
            />
          </HStack>
        </HStack>
      </HStack>
      <Spacer mt={'6'} />
      <HStack
        w={'full'}
        justifyContent={'space-between'}
        alignItems={'stretch'}
        boxShadow={'base'}
        spacing={0}
        divider={<StackDivider />}
      >
        {dayOfWeeks.map(({ id, name, day, color }, i) => {
          const date = addDays(selectedStartOfWeek, i)
          const eventsPerDay = eventsPerWeekDay[day]
          const isToday =
            dateTimeFormatter.jst['YYYY-MM-DD'](date) === nowString
          return (
            <VStack
              alignItems={'center'}
              // alignItems={'stretch'}
              justifyContent={'center'}
              key={`week-day-${id}`}
              w={'full'}
              bgColor={'gray.100'}
              spacing={'1'}
            >
              <VStack
                p={'1'}
                alignItems={'center'}
                justifyContent={'center'}
                w={'full'}
                color={color}
              >
                <Text fontWeight={'medium'} fontSize={'sm'}>
                  {name}
                </Text>
                {/* TODO: 0埋めしないようにしたい */}
                <Text fontWeight={'medium'} fontSize={'sm'}>
                  {dateTimeFormatter.jst['DD'](date)}
                </Text>
              </VStack>
              <VStack
                w={'full'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                bgColor={isToday ? '#CFDAF5' : 'white'}
                minHeight={'100px'}
                maxHeight={'480px'}
                overflowY={'auto'}
                flexGrow={1}
                px={'6px'}
                py={'4px'}
              >
                {Object.entries(eventsPerDay.events).map(
                  ([projectId, events]) => {
                    const project = projects.find(
                      (p) => String(p.id) === projectId,
                    )
                    if (!project) {
                      return <></>
                    }
                    return events.yearly
                      .map((event, i) => (
                        <Event
                          key={`event-yearly-${projectId}-${i}`}
                          project={project}
                        >
                          <TextEllipsis fontSize={'sm'} fontWeight={'bold'}>
                            {event.name}
                          </TextEllipsis>
                        </Event>
                      ))
                      .concat(
                        events.monthly.map((event, i) => (
                          <Event
                            key={`event-monthly-${projectId}-${i}`}
                            project={project}
                          >
                            <TextEllipsis fontSize={'sm'} fontWeight={'bold'}>
                              {`${event.eventName}[第${event.businessDate}営業日]`}
                            </TextEllipsis>
                          </Event>
                        )),
                      )
                  },
                )}
              </VStack>
            </VStack>
          )
        })}
      </HStack>
    </VStack>
  )
}

const Event: React.FC<{
  project: RouterOutput['project']['list'][number]
  children: React.ReactNode
}> = ({ project, children }) => {
  return (
    <Badge
      bgColor={'white'} // 透過しないようにwhiteを下に敷いておく
      color={'white'}
      size={'sm'}
      w={'full'}
      p={'0px'}
      textTransform={'none'}
    >
      <VStack
        alignItems={'flex-start'}
        bgColor={`${project.color}44`} // projectごとの色を薄くしてる
        justifyContent={'center'}
        spacing={'2px'}
        px={'8px'}
        py={'4px'}
        color={'gray.700'}
      >
        <ProjectWithColor project={project} fontWeight={'normal'} />
        {children}
      </VStack>
    </Badge>
  )
}
