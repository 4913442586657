import { Text, HStack, TypographyProps } from '@chakra-ui/react'
import { User } from '@prisma/client'
import { Picture } from './picture'
import { TextEllipsis } from '../TextEllipsis'

export const UserWithIcon = ({
  user,
  fontSize,
}: {
  user: User
  fontSize?: TypographyProps['fontSize']
}) => {
  return (
    <HStack w={'full'} alignItems={'center'} justifyContent={'flex-start'}>
      <Picture user={user} />
      <TextEllipsis
        fontWeight={'medium'}
        fontSize={fontSize ?? 'sm'}
        textAlign={'left'}
      >
        {user.name}
      </TextEllipsis>
    </HStack>
  )
}
