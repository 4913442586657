import { Box } from '@chakra-ui/react'

export const ColorMark = ({
  size,
  color,
}: {
  size?: string
  color: string
}) => {
  return (
    <Box minW={size || '2'} minH={size || '2'} bg={color} borderRadius='full' />
  )
}
