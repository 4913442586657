import LinkIconSvg from '@/images/LinkIcon.svg'
import { Image } from '@chakra-ui/react'

export const DocumentLinkIcon = () => {
  return (
    <Image
      w={'24px'}
      h={'24px'}
      borderRadius={'4px'}
      p={'4px'}
      src={LinkIconSvg}
      bgColor={'blue.600'}
    />
  )
}
