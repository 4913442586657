import { TaskCardFilterSchema } from '@app/api/src/usecase/taskCard/predicates'
import { HStack, Box, Checkbox } from '@chakra-ui/react'
import { TaskAttributeSelect } from './TaskCardAttribute'
import { UserSelect } from './UserSelect'
import { User } from '@prisma/client'
import { atom, useAtom } from 'jotai'
import React from 'react'
import { TaskCardOutputStatusSelect } from '../pages/taskcard/TaskCardOutputStatus'
import { taskCardDerivedStatusDelayedList } from '@app/shared'
import { RouterOutput } from '@app/api'

type TaskCardTableFilterComponentState = Pick<
  TaskCardFilterSchema,
  'attribute' | 'assignedToUserId' | 'outputStatus' | 'derivedStatus'
>

export const useTaskCardTableFilter = ({
  userCandidates: _userCandidates,
  enabled,
}: {
  userCandidates: User[]
  enabled: {
    attribute: boolean
    assignedToUserId: boolean
    outputStatus: boolean
    onlyDelayed: boolean
  }
}) => {
  const userCandidates = React.useMemo(
    () => _userCandidates.uniqBy((u) => u.id),
    [_userCandidates],
  )
  const {
    attribute,
    assignedToUserId: assignUser,
    outputStatus: status,
    onlyDelayed,
  } = enabled
  const states = React.useMemo(
    () => atom<TaskCardTableFilterComponentState>({}),
    [],
  )
  const [taskCardsFilter, setTaskCardsFilter] = useAtom(states)
  const [onlyDelayedChecked, setOnlyDelayedChecked] = React.useState(false)

  // return true if taskCard should be displayed
  const apply = React.useCallback(
    (t: RouterOutput['taskCard']['list'][number]) => {
      // taskCardsFilterを使ってフィルタリング
      if (
        !onlyDelayedChecked &&
        !taskCardsFilter.outputStatus &&
        !taskCardsFilter.assignedToUserId &&
        !taskCardsFilter.attribute
      ) {
        return true
      }
      if (
        onlyDelayedChecked &&
        !taskCardDerivedStatusDelayedList.includes(t.derivedStatus)
      ) {
        return false
      }
      if (
        taskCardsFilter.outputStatus &&
        !taskCardsFilter.outputStatus.includes(t.outputStatus)
      ) {
        return false
      }
      if (
        taskCardsFilter.assignedToUserId &&
        taskCardsFilter.assignedToUserId !== t.assignedToUserId
      ) {
        return false
      }
      if (
        taskCardsFilter.attribute &&
        taskCardsFilter.attribute !== t.attribute
      ) {
        return false
      }
      return true
    },
    [onlyDelayedChecked, taskCardsFilter],
  )

  const Component = React.useMemo(
    () => (
      <HStack alignItems={'center'}>
        {status && (
          <Box w={'fit-content'}>
            <TaskCardOutputStatusSelect
              key={onlyDelayedChecked ? 'disabled' : 'enabled'} // 再描画される
              defaultValue={taskCardsFilter.outputStatus?.[0] ?? undefined}
              onChange={(outputStatus) => {
                // ステータスを指定すると「遅延のみ表示」を解除
                setOnlyDelayedChecked(false)
                setTaskCardsFilter((prev) => ({
                  ...prev,
                  outputStatus: outputStatus ? [outputStatus] : undefined,
                  derivedStatus: undefined,
                }))
              }}
            />
          </Box>
        )}

        {attribute && (
          <Box w={'fit-content'}>
            <TaskAttributeSelect
              defaultValue={undefined}
              onChange={(attribute) => {
                setTaskCardsFilter((prev) => ({
                  ...prev,
                  attribute: attribute ?? undefined,
                }))
              }}
            />
          </Box>
        )}

        {assignUser && (
          <Box w={'fit-content'}>
            <UserSelect
              type={'select'}
              candidates={userCandidates || []}
              defaultValue={undefined}
              placeholder='担当者を選択'
              onChange={(user) => {
                setTaskCardsFilter((prev) => ({
                  ...prev,
                  assignedToUserId: user?.id,
                }))
              }}
            />
          </Box>
        )}

        {onlyDelayed && (
          <HStack
            w={'fit-content'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Checkbox
              id={'only-delayed'}
              bgColor={'white'}
              isChecked={onlyDelayedChecked}
              onChange={(e) => {
                setOnlyDelayedChecked(e.target.checked)
                setTaskCardsFilter((prev) => ({
                  ...prev,
                  outputStatus: undefined,
                  derivedStatus: e.target.checked
                    ? taskCardDerivedStatusDelayedList
                    : undefined,
                }))
              }}
            />
            <label htmlFor='only-delayed'>遅延のみ表示</label>
          </HStack>
        )}
      </HStack>
    ),
    [
      assignUser,
      attribute,
      onlyDelayed,
      onlyDelayedChecked,
      setTaskCardsFilter,
      status,
      taskCardsFilter.outputStatus,
      userCandidates,
    ],
  )

  return {
    taskCardsFilter: {
      condition: taskCardsFilter,
      apply: apply,
    },
    TaskCardFilterComponent: Component,
  }
}
