import { PagePath } from '@/PagePath'
import { HStack, VStack, Text } from '@chakra-ui/react'
import { Project } from '@prisma/client'
import { CiShare1 } from 'react-icons/ci'
import { LinkButton } from './LinkButton'
import { TaskAttributeTag } from './TaskCardAttribute'
import { TextEllipsis } from './TextEllipsis'
import { RouterOutput } from '@/lib/trpc'
import { ColorMark } from './ColorMark'

export const TaskCardBanner: React.FC<{
  project: RouterOutput['project']['list'][number]
  taskCard: RouterOutput['taskComment']['list'][number]['taskCard']
}> = ({ project, taskCard }) => {
  return (
    <HStack
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={'4px'}
      spacing={'8px'}
      px={'16px'}
      py={'8px'}
      bgColor={'gray.50'}
    >
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <ColorMark color={project.color} />
          <TextEllipsis
            fontSize={'xs'}
            fontWeight={'medium'}
            color={'gray.700'}
          >
            {project.name}
          </TextEllipsis>
        </HStack>
        {taskCard ? (
          <HStack fontSize={'md'} spacing={'8px'}>
            <TaskAttributeTag attribute={taskCard.attribute} />
            <Text color={'gray.500'}>[{taskCard.taskNumber}]</Text>
            <Text>{taskCard.title}</Text>
          </HStack>
        ) : (
          <Text color={'gray.500'}>タスクカードが見つかりません</Text>
        )}
      </VStack>
      <LinkButton
        bgColor={'transparent'}
        to={
          taskCard
            ? PagePath.taskCard.show(
                BigInt(project.id),
                taskCard.taskNumber,
                taskCard.projectMonth,
              )
            : '#'
        }
      >
        <CiShare1 />
      </LinkButton>
    </HStack>
  )
}
