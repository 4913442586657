export const colorCandidates = [
  { label: 'レッド', value: '#F56565' },
  { label: 'オレンジ', value: '#ED8936' },
  { label: 'イエロー', value: '#ECC94B' },
  { label: 'ライトグリーン', value: '#48BB78' },
  { label: 'グリーン', value: '#38A169' },
  { label: 'スカイブルー', value: '#63B3ED' },
  { label: 'ブルー', value: '#4299E1' },
  { label: 'パープル', value: '#9F7AEA' },
  { label: 'ピンク', value: '#ED64A6' },
  { label: 'グレー', value: '#718096' },
]
export const colorLabel = (colorHex: string) => {
  const color = colorCandidates.find((c) => c.value === colorHex)
  return color?.label
}
