import { TaskCommentStatus } from '@prisma/client'

export const taskCardCommentStatus: {
  [key in TaskCommentStatus]: {
    label: string
    colorScheme: string
    variant: 'subtle'
  }
} = {
  noop: { label: 'なし', colorScheme: 'gray', variant: 'subtle' },
  resolved: { label: '解決済み', colorScheme: 'green', variant: 'subtle' },
  todo: { label: '未回答', colorScheme: 'red', variant: 'subtle' },
}
