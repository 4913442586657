import { Comments } from '@/components/pages/taskComment/list'
import { Authenticated } from './AuthenticatedPage'
import { YearMonthContextProvider } from '@/context/yearMonth'

export const CommentsPage = () => {
  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <YearMonthContextProvider>
            {(yearMonth) => (
              <Comments
                {...{
                  firebaseUser,
                  self,
                  yearMonth,
                }}
              ></Comments>
            )}
          </YearMonthContextProvider>
        )
      }}
    </Authenticated>
  )
}
