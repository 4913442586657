import { useSelfState } from '@/components/providers/SelfProvider'
import { Text, VStack } from '@chakra-ui/react'

export const EmptyProjectMessage = () => {
  const { self } = useSelfState()
  return (
    <VStack
      w={'full'}
      h={'500px'}
      spacing={'16px'}
      // bgColor={'white'}
      px={'20px'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {!self || self.role === 'member' ? (
        <>
          <NoAccessibleProjectIcon />
          <VStack w={'full'} justifyContent={'center'} alignItems={'center'}>
            <Text color={'gray.500'} fontWeight={'medium'} fontSize={'lg'}>
              参加しているプロジェクトがありません
            </Text>
            <Text color={'gray.400'} fontSize={'md'}>
              PMがプロジェクトにあなたを追加すると、担当タスクが表示されます
            </Text>
          </VStack>
        </>
      ) : (
        <>
          <NoSelectedProjectIcon />
          <VStack w={'full'} justifyContent={'center'} alignItems={'center'}>
            <Text color={'gray.500'} fontWeight={'medium'} fontSize={'lg'}>
              プロジェクトを選択してください
            </Text>
            <Text color={'gray.400'} fontSize={'md'}>
              プロジェクトを選択すると、タスクやスケジュールが表示されます。
            </Text>
          </VStack>
        </>
      )}
    </VStack>
  )
}
const NoAccessibleProjectIcon = () => {
  return (
    <svg
      width='367'
      height='296'
      viewBox='0 0 367 296'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1611_60890)'>
        <path
          d='M182.968 282.422C239.026 282.422 284.471 278.755 284.471 274.232C284.471 269.708 239.026 266.041 182.968 266.041C126.909 266.041 81.4648 269.708 81.4648 274.232C81.4648 278.755 126.909 282.422 182.968 282.422Z'
          fill='#EDF2F7'
        />
        <path
          d='M337.549 224.206C411.133 147.19 333.756 17.8067 248.702 3.33599C160.569 -11.6555 62.4829 22.7243 8.83566 129.673C-21.3597 189.87 26.1669 312.092 146.106 251.515C221.324 213.522 271.633 293.197 337.549 224.206Z'
          fill='#EDF2F7'
        />
        <path
          d='M199.232 213.267H166.705V271.729H199.232V213.267Z'
          fill='#A0AEC0'
        />
        <path
          d='M222.97 276.146H143.9C143.9 265.453 152.576 256.789 163.273 256.789H203.594C214.295 256.789 222.966 265.457 222.966 276.146H222.97Z'
          fill='#A0AEC0'
        />
        <path
          d='M166.705 241.695H199.232V253.449L166.705 241.695Z'
          fill='#A0AEC0'
        />
        <path
          d='M167.355 256.789H196.994'
          stroke='#A0AEC0'
          strokeWidth='2.34'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M284.716 86.2373H76.2833C71.4119 86.2373 67.4629 90.1831 67.4629 95.0505V232.883C67.4629 237.75 71.4119 241.696 76.2833 241.696H284.716C289.588 241.696 293.537 237.75 293.537 232.883V95.0505C293.537 90.1831 289.588 86.2373 284.716 86.2373Z'
          fill='#A0AEC0'
        />
        <path
          d='M286.746 93.1865H73.0625V231.59H286.746V93.1865Z'
          fill='#E2E8F0'
        />
        <path
          d='M199.514 25.4913C201.376 25.3384 202.76 23.7068 202.607 21.847C202.454 19.9872 200.821 18.6034 198.96 18.7563C197.099 18.9092 195.714 20.5409 195.867 22.4007C196.02 24.2605 197.653 25.6442 199.514 25.4913Z'
          stroke='#A0AEC0'
          strokeWidth='2.06'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M31.2711 130.283C31.6514 128.456 30.4774 126.667 28.649 126.287C26.8205 125.907 25.0299 127.08 24.6496 128.907C24.2693 130.734 25.4432 132.523 27.2717 132.903C29.1002 133.283 30.8907 132.11 31.2711 130.283Z'
          stroke='#718096'
          strokeWidth='2.06'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M356.845 223.693C358.642 223.184 359.686 221.316 359.177 219.521C358.668 217.726 356.798 216.682 355.002 217.191C353.205 217.7 352.161 219.568 352.67 221.363C353.179 223.159 355.048 224.202 356.845 223.693Z'
          stroke='#718096'
          strokeWidth='2.06'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M129.497 109.114H94.4697C88.0553 109.114 82.8555 114.31 82.8555 120.719V204.055C82.8555 210.464 88.0553 215.66 94.4697 215.66H129.497C135.911 215.66 141.111 210.464 141.111 204.055V120.719C141.111 114.31 135.911 109.114 129.497 109.114Z'
          fill='white'
        />
        <path
          d='M263.283 109.114H163.203C155.665 109.114 149.555 115.22 149.555 122.751V202.023C149.555 209.554 155.665 215.66 163.203 215.66H263.283C270.821 215.66 276.931 209.554 276.931 202.023V122.751C276.931 115.22 270.821 109.114 263.283 109.114Z'
          fill='white'
        />
        <path
          d='M111.421 136.705C113.274 136.705 114.779 135.201 114.779 133.35C114.779 131.498 113.274 129.994 111.421 129.994C109.567 129.994 108.062 131.498 108.062 133.35C108.062 135.201 109.567 136.705 111.421 136.705Z'
          fill='white'
        />
        <path
          d='M196.67 185.64H256.422'
          stroke='#CBD5E0'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.67 197.443H239.906'
          stroke='#CBD5E0'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.598 180.792H168.137V202.236H189.598V180.792Z'
          fill='#EDF2F7'
        />
        <path
          d='M196.67 156.604H256.422'
          stroke='#CBD5E0'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.67 168.408H239.906'
          stroke='#CBD5E0'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.598 151.757H168.137V173.201H189.598V151.757Z'
          fill='#EDF2F7'
        />
        <path
          d='M196.67 127.252H256.422'
          stroke='#CBD5E0'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.67 139.056H239.906'
          stroke='#CBD5E0'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.598 122.404H168.137V143.848H189.598V122.404Z'
          fill='#EDF2F7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M302.894 121.06C302.879 121.122 302.095 122.113 300.853 123.444C300.238 124.106 301.613 125.582 302.428 125.284C303.243 124.987 304.293 122.348 304.293 122.348L302.894 121.063V121.06Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M304.838 126.885C304.838 126.885 307.036 131.427 308.854 135.193C309.34 136.195 310.426 136.841 311.699 136.884C312.973 136.928 314.234 136.36 314.998 135.401C315.249 135.087 315.492 134.778 315.723 134.488C316.773 133.173 316.911 131.505 316.08 130.213C313.952 126.897 310.073 120.855 310.073 120.855L304.83 126.885H304.838Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M305.795 128.999L307.354 132.084L309.298 127.617L305.795 128.999Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M312.972 119.963C313.208 118.507 312.228 117.136 310.79 116.897C309.254 116.643 307.314 116.326 305.778 116.071C304.34 115.832 303.2 116.874 302.745 118.276C302.208 119.928 301.903 123.491 301.989 126.102C302.04 127.574 302.945 129.074 304.383 129.313C305.919 129.567 310.766 128.847 311.656 126.141C312.334 124.082 312.624 122.101 312.969 119.959L312.972 119.963Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M308.949 123.401C310.653 125.887 314.05 126.893 314.865 128.31C314.865 128.31 314.301 126.353 315.089 125.354C316.578 123.471 317.107 120.875 316.025 118.506C315.633 117.649 315.191 116.913 314.705 116.439C311.927 113.738 308.384 113.096 305.207 115.053C304.407 115.543 303.482 116.96 303.482 116.96C303.639 117.614 306.805 117.59 307.957 119.086C309.156 120.64 307.914 121.897 308.949 123.404V123.401Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M302.859 125.797L303.988 125.738C303.988 125.738 304.125 127.32 303.134 127.026C302.922 126.964 302.859 125.797 302.859 125.797Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M309.641 117.927C309.653 117.994 309.14 124.779 307.404 123.804C303.184 121.432 308.576 118.628 304.979 117.606L309.641 117.927Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M310.693 123.855C310.109 124.912 308.933 125.37 308.067 124.881C307.202 124.391 306.974 123.138 307.558 122.081C308.142 121.024 309.317 120.566 310.183 121.055C311.049 121.545 311.277 122.798 310.693 123.855Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M279.02 291.882C279.02 294.156 293.839 296 312.118 296C330.398 296 345.217 294.156 345.217 291.882C345.217 289.607 330.398 287.763 312.118 287.763C293.839 287.763 279.02 289.607 279.02 291.882Z'
          fill='#EDF2F7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M334.846 285.068C334.846 285.068 341.833 250.692 335.912 237.091L338.847 201.635L320.199 191.956L328.589 285.405L334.842 285.064L334.846 285.068Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M328.458 283.91L325.308 286.858C324.418 287.633 319.705 289.144 319.705 289.144C319.191 290.107 320.065 290.616 320.825 290.742L334.508 290.456C334.508 290.456 337.698 289.802 335.225 283.722C332.678 284.818 329.007 285.797 328.458 283.91Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M319.035 198.014L321.218 238.74L325.72 278.331H341.637C341.637 278.331 340.52 271.448 340.81 269.263C341.104 267.078 342.789 246.363 338.447 236.829L340.747 193.793L319.031 198.014H319.035Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M300.787 285.069C300.787 285.069 313.593 250.693 309.976 237.091L323.992 190.461L301.908 191.96L294.475 285.409L300.787 285.069Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M333.363 167.353L303.634 169.616C298.799 171.389 300.731 199.246 300.731 199.246L320.28 200.151C329.602 200.002 340.816 201.396 341.235 191.956C342.003 174.639 333.367 167.349 333.367 167.349L333.363 167.353Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M294.814 283.949L291.581 286.967C290.672 287.762 286.009 289.32 286.009 289.32C285.453 290.303 286.276 290.816 287.004 290.941L300.304 290.585C300.304 290.585 303.438 289.904 301.397 283.726C298.862 284.853 295.237 285.867 294.818 283.949H294.814Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M300.465 196.968L295.003 238.739L292.801 278.33H308.717C308.717 278.33 308.764 271.447 309.427 269.263C310.089 267.078 315.285 246.362 312.554 236.829L321.586 198.013L300.457 196.968H300.465Z'
          fill='#CBD5E0'
        />
        <path
          d='M330.409 200.167L323.465 200.394L319.52 207.915'
          stroke='#CBD5E0'
          strokeWidth='1.57'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M255.465 196.506L248.361 212.136L264.019 205.487L255.465 196.506Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M255.466 196.506L250.662 207.077C250.733 208.921 251.74 209.876 253.828 209.814L264.02 205.487L255.466 196.506Z'
          fill='#EDF2F7'
        />
        <path
          d='M343.572 123.178C341.284 120.496 336.742 120.774 333.482 123.8L256.097 195.582C252.833 198.609 261.152 208.365 264.412 205.339L341.797 133.557C345.061 130.53 345.856 125.86 343.572 123.174V123.178Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M340.806 124.991C338.255 122.614 335.312 121.713 334.235 122.974C333.157 124.235 334.349 127.187 336.899 129.56C339.45 131.936 342.393 132.837 343.471 131.576C344.548 130.315 343.357 127.363 340.806 124.991Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M334.23 122.974L340.81 116.901L349.446 126.036L343.274 131.768L334.23 122.974Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M347.326 119.004C344.775 116.628 341.832 115.727 340.754 116.988C339.677 118.249 340.868 121.201 343.419 123.573C345.97 125.946 348.913 126.85 349.99 125.59C351.068 124.329 349.877 121.377 347.326 119.004Z'
          fill='#E2E8F0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M340.75 116.988L347.329 110.915L355.961 120.049L349.794 125.777L340.75 116.988Z'
          fill='#E2E8F0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M353.563 113.315C351.094 110.907 348.258 109.932 347.227 111.142C346.196 112.348 347.36 115.28 349.829 117.688C352.297 120.096 355.134 121.071 356.165 119.861C357.195 118.655 356.032 115.723 353.563 113.315Z'
          fill='#E2E8F0'
        />
        <path
          d='M256.873 201.071L335.488 128.467'
          stroke='#EDF2F7'
          strokeWidth='1.79'
          strokeMiterlimit='10'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M303.933 132.707L314.575 129.959L317.557 129.669L322.717 130.131C325.758 131.016 331.13 131.243 334.892 141.763L336.898 149.973C340.605 159.378 340.374 173.868 342.411 183.77C327.243 191.894 303.004 194.329 296.954 187.693C295.951 177.075 293.443 160.337 294.509 151.074C295.465 142.758 301.092 139.308 303.936 132.703L303.933 132.707Z'
          fill='#E2E8F0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M326.566 131.271C332.475 132.938 356.985 155.498 349.521 163.888C335.081 180.113 315.865 156.911 315.865 156.911C315.865 156.911 340.783 166.081 339.764 164.883C338.745 163.685 324.69 146.92 324.69 146.92L326.563 131.271H326.566Z'
          fill='#E2E8F0'
        />
        <path
          d='M282.825 191.091L281.097 195.03C280.752 195.817 280.133 196.455 279.357 196.827L276.215 198.331C275.118 198.855 273.836 198.123 273.73 196.917L276.183 192.09C276.724 191.025 277.708 190.144 278.934 189.627L280.98 188.766L282.825 191.095V191.091Z'
          fill='#CBD5E0'
        />
        <path
          d='M303.934 132.707C303.934 132.707 294.392 134.441 292.66 150.725C292.288 154.233 292.551 165.423 290.403 168.575C280.204 183.546 277.535 188.609 277.535 188.609L284.957 194.282L305.885 171.397L303.934 132.711V132.707Z'
          fill='#E2E8F0'
        />
        <path
          d='M332.572 142.084C332.572 142.084 338.732 165.869 339.755 168.7'
          stroke='#A0AEC0'
          strokeWidth='1.89'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M297.918 177.591C298.192 177.289 304.231 172.286 303.819 168.445C303.408 164.604 302.138 149.656 302.138 149.656L302.589 148.614'
          stroke='#A0AEC0'
          strokeWidth='1.89'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M347.18 111.11C346.15 112.316 347.313 115.249 349.782 117.657C352.251 120.065 355.087 121.039 356.118 119.83'
          stroke='#4A5568'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.591 154.041H95.2129C95.4182 149.728 96.445 146.374 99.5253 143.226C103.016 139.735 107.876 138.16 112.668 138.16C121.498 138.16 128.754 143.157 128.754 152.535C128.754 159.928 124.647 163.555 119.239 167.731C116.296 169.99 116.09 171.907 116.09 174.781V176.63H107.808C107.808 173.96 107.876 171.222 108.492 168.621C109.177 166.02 110.957 164.103 112.942 162.46C116.57 159.654 119.65 157.258 119.65 153.288C119.65 148.975 116.707 145.758 112.394 145.758C109.861 145.758 107.397 146.785 106.028 148.907C105.001 150.481 104.659 152.193 104.591 154.041ZM116.296 189.772H107.603V181.079H116.296V189.772Z'
          fill='#EDF2F7'
        />
      </g>
      <defs>
        <clipPath id='clip0_1611_60890'>
          <rect width='367' height='296' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const NoSelectedProjectIcon = () => {
  return (
    <svg
      width='537'
      height='378'
      viewBox='0 0 537 378'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1611_68800)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M182.556 286.121C143.848 300.999 105.87 246.629 92.4437 211.436C51.7817 104.83 184.295 -45.357 328.721 56.9291C380.736 93.7692 447.849 144.194 451.738 200.247C457.402 281.915 368.998 320.868 293.969 291.867C228.364 266.503 227.497 268.848 182.556 286.127V286.121Z'
          fill='#EDF2F7'
        />
        <path
          d='M248.51 355.195C309.908 355.195 359.681 350.181 359.681 343.996C359.681 337.811 309.908 332.797 248.51 332.797C187.111 332.797 137.338 337.811 137.338 343.996C137.338 350.181 187.111 355.195 248.51 355.195Z'
          fill='#F7FAFC'
        />
        <path
          d='M318.287 111.756H180.91C173.405 111.756 167.32 117.84 167.32 125.346V331.411C167.32 338.917 173.405 345.001 180.91 345.001H318.287C325.793 345.001 331.877 338.917 331.877 331.411V125.346C331.877 117.84 325.793 111.756 318.287 111.756Z'
          fill='#E2E8F0'
        />
        <path
          d='M315.202 127.25H175.209V334.013H315.202V127.25Z'
          fill='white'
        />
        <path
          d='M267.547 123.766H261.771C261.771 120.632 259.205 118.066 256.071 118.066H234.334C231.2 118.066 228.635 120.632 228.635 123.766H222.858C219.723 123.766 217.158 126.331 217.158 129.466C217.158 132.6 219.723 135.165 222.858 135.165H267.552C270.687 135.165 273.252 132.6 273.252 129.466C273.252 126.331 270.687 123.766 267.552 123.766H267.547Z'
          fill='#A0AEC0'
        />
        <path
          d='M130.66 175.064C131.222 172.684 129.748 170.3 127.368 169.738C124.989 169.176 122.604 170.65 122.042 173.03C121.481 175.41 122.955 177.794 125.334 178.356C127.714 178.918 130.099 177.444 130.66 175.064Z'
          stroke='#A0AEC0'
          strokeWidth='1.08479'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M291.449 62.6557C292.011 60.2759 290.537 57.8914 288.157 57.3297C285.778 56.768 283.393 58.2419 282.831 60.6217C282.27 63.0015 283.744 65.386 286.123 65.9477C288.503 66.5094 290.888 65.0355 291.449 62.6557Z'
          stroke='#A0AEC0'
          strokeWidth='1.08479'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M302.018 228.433H255.189C259.253 213.858 260.484 198.124 259.817 181.604H306.645C307.179 198.38 305.994 214.192 302.018 228.433Z'
          fill='#F7FAFC'
        />
        <path
          d='M284.647 195.651L286.048 199.078C286.222 199.509 286.607 199.806 287.074 199.873L290.798 200.422C291.973 200.596 292.322 202.063 291.394 202.905L288.444 205.573C288.074 205.906 287.879 206.388 287.92 206.86L288.249 210.631C288.351 211.821 287.033 212.724 286.017 212.165L282.795 210.385C282.39 210.164 281.887 210.164 281.446 210.385L277.927 212.165C276.819 212.724 275.649 211.821 275.952 210.631L276.911 206.86C277.029 206.388 276.911 205.906 276.603 205.573L274.1 202.905C273.31 202.063 273.91 200.596 275.11 200.422L278.927 199.873C279.404 199.806 279.835 199.509 280.087 199.078L282.057 195.651C282.677 194.568 284.211 194.568 284.653 195.651H284.647Z'
          fill='#E2E8F0'
        />
        <path
          d='M278.28 173.399L273.754 176.125L281.107 188.333L285.633 185.607L278.28 173.399Z'
          fill='#E2E8F0'
        />
        <path
          d='M231.176 298.948H184.348C189.17 285.122 191.222 270.193 191.412 254.525H238.241C237.902 270.439 235.891 285.435 231.176 298.948Z'
          fill='#E2E8F0'
        />
        <path
          d='M198.85 267.915H226.948'
          stroke='#CBD5E0'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.096 283.598H209.024'
          stroke='#CBD5E0'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M228.416 224.052H176.703C184.337 215.495 190.534 196.01 190.149 176.479H237.978C239.333 193.378 235.306 209.503 228.416 224.052Z'
          fill='#E2E8F0'
        />
        <path
          d='M215.839 172.518L212.002 168.886L202.204 179.236L206.042 182.868L215.839 172.518Z'
          fill='#F7FAFC'
        />
        <path
          d='M197.25 191.105H225.343'
          stroke='white'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M194.971 203.689H223.069'
          stroke='white'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M304.352 291.494H253.824C257.641 274.964 258.995 259.368 256.831 245.111H307.358C309.087 260.276 307.83 275.764 304.352 291.494Z'
          fill='#CBD5E0'
        />
        <path
          d='M269.98 259.886H298.079'
          stroke='#A0AEC0'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M269.98 275.569H284.027'
          stroke='#A0AEC0'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M230.775 156.574H195.028C193.766 156.574 192.734 155.543 192.734 154.281C192.734 153.018 193.766 151.987 195.028 151.987H230.775C232.037 151.987 233.068 153.018 233.068 154.281C233.068 155.543 232.037 156.574 230.775 156.574Z'
          fill='#F7FAFC'
        />
        <path
          d='M297.57 156.574H261.823C260.56 156.574 259.529 155.543 259.529 154.281C259.529 153.018 260.56 151.987 261.823 151.987H297.57C298.832 151.987 299.863 153.018 299.863 154.281C299.863 155.543 298.832 156.574 297.57 156.574Z'
          fill='#F7FAFC'
        />
        <path
          d='M245.205 155.051V322.393'
          stroke='#CBD5E0'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M230.656 123.844H255.189'
          stroke='#E2E8F0'
          strokeWidth='0.921543'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M279.691 228.376L302.013 228.433C302.013 228.433 307.256 211.934 306.676 194.835'
          stroke='#A0AEC0'
          strokeWidth='0.895213'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M325.924 128.389V328.282'
          stroke='#CBD5E0'
          strokeWidth='0.826756'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M438.964 289.001C438.964 291.448 436.984 293.428 434.537 293.428C432.09 293.428 430.109 291.448 430.109 289.001C430.109 286.554 432.09 284.573 434.537 284.573C436.984 284.573 438.964 286.554 438.964 289.001Z'
          stroke='#A0AEC0'
          strokeWidth='1.08479'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M387.493 353.728C409.514 353.728 427.365 350.967 427.365 347.561C427.365 344.155 409.514 341.395 387.493 341.395C365.472 341.395 347.621 344.155 347.621 347.561C347.621 350.967 365.472 353.728 387.493 353.728Z'
          fill='#F7FAFC'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M385.241 209.779L388.417 280.586L392.752 340.348L398.026 340.82C400.699 319.366 410.215 303.226 403.315 277.262L406.732 229.495L396.595 212.119L385.236 209.79L385.241 209.779Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M392.972 339.989C392.972 339.989 391.525 344.878 391.423 347.931C391.407 348.454 391.674 349.013 392.146 349.444C392.618 349.87 393.233 350.111 393.803 350.091C395.019 350.039 396.409 349.978 397.271 349.942C397.805 349.921 398.271 349.726 398.595 349.398C398.79 349.198 399.026 348.962 399.272 348.711C400.4 347.561 399.98 346.289 399.446 344.499C398.892 342.647 398.543 340.543 398.543 340.543C395.619 338.399 392.972 339.989 392.972 339.989Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M364.857 285.106L381.361 339.538L385.619 341.554L387.133 336.891C387.133 336.891 387.646 294.325 382.705 284.455C377.765 274.584 364.857 285.101 364.857 285.101V285.106Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M380.951 338.543L376.662 343.611C375.867 344.801 370.716 347.987 370.716 347.987C370.444 349.29 371.68 349.639 372.645 349.567L389.067 345.196C389.067 345.196 392.683 343.478 387.512 336.952C384.834 339.009 382.29 340.636 380.951 338.543Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M362.18 216.926L358.84 296.844C358.84 296.844 367.166 306.545 411.891 299.317C411.071 282.382 417.34 234.681 411.04 216.926H362.18Z'
          fill='#E2E8F0'
        />
        <path
          d='M397.097 171.97C397.097 171.97 403.668 199.411 406.557 204.136C410.425 210.457 422.035 203.885 422.035 203.885L432.89 191.275L421.819 194.615L409.789 166.163C408.614 163.125 398.348 166.722 396.137 164.331C396.137 164.331 394.049 164.069 397.097 171.975V171.97Z'
          fill='#E2E8F0'
        />
        <path
          d='M349.226 182.266C339.628 178.732 336.129 176.931 326.566 173.16L323.514 177.654C341.562 188.094 340.315 192.193 349.195 192.398C355.47 185.35 351.253 183.574 349.221 182.266H349.226Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M374.317 156.312L387.409 154.778L391 154.917L404.642 159.673C409.233 161.945 411.367 167.286 409.613 172.118L406.273 187.73C405.139 201.438 407.704 209.015 411.034 216.915C391.693 224.098 368.279 225.791 362.174 216.915C362.728 203.813 362.323 190.033 364.631 178.131C366.596 167.989 369.982 163.484 374.317 156.302V156.312Z'
          fill='#E2E8F0'
        />
        <path
          d='M378.787 155.789C378.787 155.789 376.012 165.896 383.266 166.737C393.213 167.886 398.492 156.939 398.492 156.939L389.222 153.799L378.787 155.789Z'
          fill='white'
        />
        <path
          d='M381.767 155.343C381.767 155.343 379.73 162.002 384.696 162.628C391.94 163.541 394.546 156.169 394.546 156.169L389.221 153.804L381.767 155.343Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M374.334 156.317C374.334 156.317 370.753 157.195 364.376 162.874C356.311 170.051 344.24 182.533 343.239 185.103C342.234 187.673 351.627 192.67 351.627 192.67L369.84 176.489L374.339 156.323L374.334 156.317Z'
          fill='#E2E8F0'
        />
        <path
          d='M363.822 187.443L364.766 174.694L363.981 172.432'
          stroke='#CBD5E0'
          strokeWidth='0.826756'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M326.915 177.346L321.661 178.433C320.61 178.649 319.522 178.485 318.583 177.967L314.792 175.858C313.468 175.124 313.289 173.293 314.443 172.313L321.117 171.318C322.59 171.097 324.19 171.461 325.601 172.334L327.951 173.785L326.909 177.341L326.915 177.346Z'
          fill='#CBD5E0'
        />
        <path
          d='M398.496 172.99L404.935 192.67'
          stroke='#CBD5E0'
          strokeWidth='0.826756'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M425.826 210.066L403.909 211.015C402.786 211.067 402.165 210.215 402.534 209.133L414.072 175.176C414.442 174.093 415.663 173.165 416.786 173.113L438.702 172.164C439.826 172.113 440.447 172.965 440.077 174.047L428.54 208.004C428.17 209.086 426.949 210.015 425.826 210.066Z'
          fill='#A0AEC0'
        />
        <path
          d='M431.694 196.256C432.654 196.661 433.757 196.482 434.536 195.794L436.66 193.917C437.343 193.317 437.789 192.475 437.922 191.552L438.461 187.802C438.646 186.493 437.522 185.375 436.286 185.626L432.218 189.659C431.32 190.546 430.745 191.844 430.601 193.286L430.509 194.255C430.427 195.107 430.904 195.918 431.694 196.251V196.256Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M378.929 140.599C378.899 140.676 377.714 141.773 375.887 143.21C374.979 143.923 376.431 146.057 377.514 145.842C378.596 145.626 380.443 142.497 380.443 142.497L378.929 140.599Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M380.909 148.016C380.909 148.016 381.576 153.736 382.13 158.477C382.279 159.739 383.09 160.821 384.249 161.309C385.409 161.796 386.742 161.611 387.733 160.831C388.056 160.575 388.374 160.324 388.671 160.088C390.031 159.015 390.657 157.24 390.277 155.542C389.302 151.182 387.533 143.245 387.533 143.245L380.904 148.011L380.909 148.016Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M381.014 151.162L381.675 154.569L384.569 151.224L381.014 151.162Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M390.463 142.04C391.028 140.449 390.212 138.7 388.637 138.136C386.959 137.53 384.841 136.771 383.163 136.171C381.588 135.606 380.095 136.545 379.295 138.028C378.351 139.782 377.268 143.733 376.827 146.693C376.581 148.365 377.289 150.243 378.859 150.807C380.536 151.413 386.138 151.597 387.703 148.74C388.893 146.559 389.627 144.384 390.458 142.045L390.463 142.04Z'
          fill='#CBD5E0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M390.141 150.213C392.973 150.054 395.107 147.571 394.819 144.749C394.573 142.353 394.091 139.752 393.147 138.418C390.428 134.586 386.288 131.99 380.655 135.197C380.655 135.197 380.48 142.651 386.103 149.813C386.883 150.803 388.253 151.116 389.387 150.572L390.141 150.213Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M377.843 146.268L379.592 146.411C379.592 146.411 379.305 148.499 377.92 148.13C377.438 148.002 377.843 146.263 377.843 146.263V146.268Z'
          fill='white'
        />
        <path
          d='M380.648 135.202C380.648 135.202 387.743 128.733 395.577 134.525C396.105 134.915 401.625 137.885 400.881 140.809C400.137 143.734 406.509 145.273 405.078 147.961C403.646 150.649 407.848 155.595 404.334 156.908C400.82 158.221 403.046 160.833 399.999 161.161C396.952 161.494 392.401 163.203 391.011 159.771C390.436 158.35 392.627 147.084 387.671 145.529C382.715 143.975 382.248 138.285 382.248 138.285L380.643 135.207L380.648 135.202Z'
          fill='#A0AEC0'
        />
        <path
          d='M397.794 140.27C397.794 140.27 398.543 134.201 392.612 138.813C392.094 139.218 387.862 138.31 388.605 141.234C389.349 144.159 382.783 144.585 384.306 147.221C387.461 152.69 383.542 154.163 386.374 156.625C387.913 157.964 386.441 161.253 389.488 161.581C392.535 161.914 397.086 163.623 398.476 160.191C399.051 158.77 396.86 147.504 401.816 145.949C406.772 144.395 398.656 143.456 398.656 143.456L397.794 140.27Z'
          fill='#A0AEC0'
        />
        <path
          d='M382.261 143.225C382.261 143.225 380.881 142.117 380.44 136.792C380.347 135.648 380.799 134.509 381.686 133.78C382.343 133.242 383.174 132.775 384.041 132.837C384.041 132.837 383.841 140.296 385.123 143.225C386.406 146.155 383.708 146.991 383.708 146.991L382.266 143.225H382.261Z'
          fill='#A0AEC0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M385.277 146.432C384.4 147.504 382.979 147.776 382.107 147.047C381.234 146.319 381.234 144.857 382.112 143.784C382.989 142.712 384.41 142.44 385.282 143.169C386.154 143.897 386.154 145.359 385.277 146.432Z'
          fill='#CBD5E0'
        />
        <path
          d='M364.857 294.146C364.857 294.146 392.807 301.292 407.915 291.488'
          stroke='#CBD5E0'
          strokeWidth='0.826756'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M330.701 174.494L328.613 173.971L325.92 179.465C325.92 179.465 328.967 182.143 329.137 181.61C329.311 181.076 330.696 174.499 330.696 174.499L330.701 174.494Z'
          fill='white'
        />
        <path
          d='M355.531 188.827C355.531 188.827 350.114 196.548 343.701 194.117C337.293 191.685 326.633 181.994 326.633 181.994L331.065 173.816L347.974 181.004L355.531 188.822V188.827Z'
          fill='#E2E8F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_1611_68800'>
          <rect
            width='367'
            height='332.392'
            fill='white'
            transform='translate(85 22.8037)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
