import { PagePathParams } from '@/PagePath'
import { TaskCardsBulkNew } from '@/components/pages/taskcard/bulkNew'
import { YearMonth, YearMonthContextProvider } from '@/context/yearMonth'
import { trpc } from '@/lib/trpc'
import {
  Authenticated,
  AuthenticatedComponent,
} from '@/pages/AuthenticatedPage'
import { Spinner } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ErrorPage } from '../ErrorPage'

export const TaskCardsBulkNewPage = () => {
  const { projectId } = useParams<PagePathParams['tasks']['bulkNew']>()
  if (!projectId || Number.isNaN(Number(projectId))) {
    return <>invalid params</>
  }
  return (
    <Authenticated>
      {({ self, firebaseUser }) => {
        return (
          <YearMonthContextProvider
            options={{
              saveInUrl: true,
            }}
          >
            {(yearMonth) => (
              <TaskCardsBulkNewPageInternal
                {...{
                  projectId: Number(projectId),
                  yearMonth,
                  firebaseUser,
                  self,
                }}
              ></TaskCardsBulkNewPageInternal>
            )}
          </YearMonthContextProvider>
        )
      }}
    </Authenticated>
  )
}

const TaskCardsBulkNewPageInternal: AuthenticatedComponent<{
  projectId: number
  yearMonth: YearMonth
}> = ({ projectId, yearMonth, firebaseUser, self }) => {
  const projectQuery = trpc.project.show.useQuery({
    id: projectId,
    yearMonth,
  })
  const query = trpc.taskCardTemplate.list.useQuery(
    {
      filter: {
        projectIds: projectId ? [projectId] : [],
      },
    },
    {
      enabled: !!projectId,
    },
  )
  if (projectQuery.isInitialLoading || query.isInitialLoading) {
    return <Spinner />
  }

  if (self.role === 'member') {
    return <ErrorPage errorMessage='権限がありません' />
  }
  if (query.error) {
    return <ErrorPage errorMessage={query.error.message} />
  }
  if (projectQuery.error) {
    return <ErrorPage errorMessage={projectQuery.error.message} />
  }
  if (!projectQuery.data) {
    return <ErrorPage errorMessage='データが取得できませんでした' />
  }

  return (
    <TaskCardsBulkNew
      project={projectQuery.data}
      projectMonth={
        projectQuery.data.projectMonths.length > 0
          ? projectQuery.data.projectMonths[0]
          : undefined
      }
      templates={query.data || []}
      yearMonth={yearMonth}
    />
  )
}
