import { theme } from '@chakra-ui/react'
export const buttonStyle = {
  base: {
    display: 'flex',
    cursor: 'pointer',
    textAlign: 'center',
    justifyContent: 'center',
    color: 'black',
    bg: {
      base: 'white',
      _hover: 'gray.50',
      _active: 'gray.100',
    },
  },
  size: {
    sm: { padding: '4px', fontSize: '12px' },
    md: { paddingX: '8px', paddingY: '4px', fontSize: '16px' },
    lg: { padding: '8px', fontSize: '24px' },
  },
  variants: {
    black: {
      bg: '#333',
      color: 'white',
      textAlign: 'center',
    },
    transparent: {
      backgroundColor: 'transparent',
      _hover: {
        bg: 'transparent',
      },
      _active: {
        bg: 'transparent',
      },
    },
    'transparent-clickable': {
      backgroundColor: 'transparent',
      _hover: {
        bg: 'gray.50',
      },
      _active: {
        bg: 'gray.100',
      },
    },
    sidebar: {
      backgroundColor: 'transparent',
      _hover: {
        bg: 'gray.50',
        '> a': {
          textDecoration: 'none',
        },
      },
      _active: {
        bg: 'gray.100',
      },
      fontWeight: 'normal',
      color: 'gray.600',
      borderWidth: '0',
    },
    'white-blue': {
      backgroundColor: 'white',
      _hover: {
        bg: 'gray.10',
      },
      _active: {
        bg: 'gray.50',
      },
      color: 'blue.text',
      border: '1px solid #CCCCCC',
    },
    'white-red': {
      backgroundColor: 'white',
      _hover: {
        bg: 'gray.10',
      },
      _active: {
        bg: 'gray.50',
      },
      _disabled: {
        bg: 'gray.500',
        cursor: 'not-allowed',
      },
      _loading: {
        bg: 'white',
      },
      color: 'red.500',
      border: '1px solid #CCCCCC',
    },
    'blue-navigation': {
      fontSize: 'sm',
      backgroundColor: 'none',
      _hover: {
        bg: 'gray.10',
      },
      _active: {
        bg: 'gray.50',
      },
      color: 'blue.text',
    },
    'blue-fill': {
      backgroundColor: 'blue.500',
      _hover: {
        bg: 'blue.400',
        _disabled: {
          bg: 'blue.300',
        },
      },
      _active: {
        bg: 'blue.300',
      },
      _disabled: {
        bg: 'blue.300',
      },
      color: 'white',
    },
    forLink: {
      bg: 'none',
    },
    'green-fill': (props: any) => {
      return theme.components.Button.variants?.solid({
        ...props,
        colorScheme: 'green',
      })
    },
    selected: {
      cursor: 'default',
      bg: {
        base: 'button.navigation.selected',
      },
    },
    normal: {
      bg: {
        base: 'button.navigation.base',
        _hover: 'button.navigation.hover',
      },
    },
    border: {
      borderWidth: '1px',
      borderColor: 'black',
      borderRadius: '24px',
    },
    navigation: {
      color: 'black',
      bg: {
        base: 'white',
        _hover: 'gray.50',
        _active: 'gray.100',
      },
      borderWidth: '1px',
      borderColor: 'black',
      borderRadius: '24px',
    },
  },
}
