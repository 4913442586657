import type { Project, TaskCard } from '@prisma/client'
import { YearMonth } from './context/yearMonth'

export const RouterParamKey = {
  projectId: 'projectId',
  taskNumber: 'taskNumber',
  taskCardId: 'taskCardId',
} as const

export type RouterPath = (typeof RouterPath)[keyof typeof RouterPath]
export const RouterPath = {
  dashboard: '/',
  login: '/login',
  comments: '/comments',
  questions: '/questions',
  inputStatusList: '/inputStatusList',
  invite: '/invite',
  admin: {
    accounts: '/admin/accounts',
  },
  signup: '/signup',
  logout: '/logout',
  project: {
    list: `/projects`,
    new: `/projects/new`,
    show: `/projects/:${RouterParamKey.projectId}`,
    edit: `/projects/:${RouterParamKey.projectId}/edit`,
  },
  taskCard: {
    // manage: '/taskCards/manage',
    list: '/taskCards',
    show: `/projects/:${RouterParamKey.projectId}/taskCards/:${RouterParamKey.taskNumber}`,
    new: `/projects/:${RouterParamKey.projectId}/taskCards/new`,
    bulkNew: `/projects/:${RouterParamKey.projectId}/taskCards/bulkNew`,
    edit: `/projects/:${RouterParamKey.projectId}/taskCards/:${RouterParamKey.taskNumber}/edit`,
  },
  template: {
    // list: `/projects/:${RouterParamKey.projectId}/templates`,
    show: `/projects/:${RouterParamKey.projectId}/templates/:${RouterParamKey.taskNumber}`,
    new: `/projects/:${RouterParamKey.projectId}/templates/new`,
    edit: `/projects/:${RouterParamKey.projectId}/templates/:${RouterParamKey.taskNumber}/edit`,
  },
} as const

export type PagePathParams = {
  projects: {
    show: {
      [RouterParamKey.projectId]: string
    }
  }
  tasks: {
    new: {
      [RouterParamKey.projectId]: string
    }
    show: {
      [RouterParamKey.projectId]: string
      [RouterParamKey.taskNumber]: string
    }
    bulkNew: {
      [RouterParamKey.projectId]: string
    }
  }
  templates: {
    new: {
      [RouterParamKey.projectId]: string
    }
  }
}

export const PagePath = {
  dashboard: (param?: { projectIds?: number[] } | { filterId: number }) => {
    const params = new URLSearchParams()
    if (param) {
      if ('filterId' in param) {
        params.set('filterId', String(param.filterId))
      } else {
        params.set('projectIds', (param.projectIds || []).join(','))
      }
      return `${RouterPath.dashboard}?${params.toString()}`
    } else {
      return RouterPath.dashboard
    }
  },
  login: RouterPath.login,
  invite: RouterPath.invite,
  signup: RouterPath.signup,
  logout: RouterPath.logout,
  admin: {
    accounts: RouterPath.admin.accounts,
  },
  comments: RouterPath.comments,
  questions: RouterPath.questions,
  inputStatusList: RouterPath.inputStatusList,
  taskCard: {
    // manage: (projectId?: number) =>
    //   `/taskCards/manage?projectId=${projectId || ''}`,
    list: (projectId?: number, yearMonth?: YearMonth) =>
      `/taskCards?projectId=${projectId || ''}&year=${
        yearMonth?.year || ''
      }&month=${yearMonth?.month || ''}`,
    show: (
      projectId: Project['id'],
      taskNumber: TaskCard['taskNumber'],
      yearMonth?: YearMonth,
    ) => {
      return `/projects/${projectId}/taskCards/${taskNumber}?year=${
        yearMonth?.year || ''
      }&month=${yearMonth?.month || ''}`
    },
    bulkNew: (projectId: number, yearMonth: YearMonth) => {
      return `/projects/${projectId}/taskCards/bulkNew?year=${
        yearMonth?.year || ''
      }&month=${yearMonth?.month || ''}`
    },
    new: (projectId: number, yearMonth: YearMonth) => {
      return `/projects/${projectId}/taskCards/new?year=${
        yearMonth?.year || ''
      }&month=${yearMonth?.month || ''}`
    },
    edit: (
      projectId: Project['id'],
      taskNumber: TaskCard['taskNumber'],
      yearMonth?: YearMonth,
    ) => {
      return `/projects/${projectId}/taskCards/${taskNumber}/edit?year=${
        yearMonth?.year || ''
      }&month=${yearMonth?.month || ''}`
    },
  },
  template: {
    // list: (projectId: number) => `/projects/${projectId}/templates`,
    show: (projectId: Project['id'], taskNumber: TaskCard['taskNumber']) =>
      `/projects/${projectId}/templates/${taskNumber}`,
    new: (projectId: number) => `/projects/${projectId}/templates/new`,
    edit: (projectId: Project['id'], taskNumber: TaskCard['taskNumber']) =>
      `/projects/${projectId}/templates/${taskNumber}/edit`,
  },
  project: {
    list: '/projects',
    new: '/projects/new',
    show: (projectId: number | bigint) => `/projects/${projectId}`,
    edit: (projectId: number | bigint) => `/projects/${projectId}/edit`,
  },
} as const
