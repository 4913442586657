import {
  Box,
  Center,
  Container,
  HStack,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React from 'react'
import { useSidebarState } from './ui/Sidebar'
import { TextSpinner } from './ui/TextSpinner'
import { useWindowSize } from '@/helper/windowSize'

// <Sidebar />がreact-routerによって常時表示されていることを前提としたレイアウト
export const SidebarLayout = ({ children }: { children: React.ReactNode }) => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
  const { isOpen } = useSidebarState()
  const [width, height] = useWindowSize()

  return (
    <Box
      position={'fixed'}
      top={0}
      left={isOpen ? (isLargerThan1280 ? '17.5%' : '224px') : '4%'} // %で1280pxの時にサイドバーが224pxになる
      bgColor={'gray.50'}
      alignItems={'flex-start'}
      flexGrow={1}
      h={'full'}
      w={isOpen ? (isLargerThan1280 ? '82.5%' : width - 224) : '95%'} // %で1280pxの時に1056pxになる
      minHeight={'100vh'}
      overflow={'auto'}
    >
      <React.Suspense
        fallback={
          <Center h={'full'}>
            <TextSpinner />
          </Center>
        }
      >
        <Box>{children}</Box>
      </React.Suspense>
    </Box>
  )
}
