import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { ProjectWithColor } from '@/components/ui/ProjectWithColor'
import { TaskAttributeTag } from '@/components/ui/TaskCardAttribute'
import { TextEllipsis } from '@/components/ui/TextEllipsis'
import { UserSelect } from '@/components/ui/UserSelect'
import { RouterOutput } from '@app/api'
import {
  Text,
  VStack,
  HStack,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  StackDivider,
  Alert,
} from '@chakra-ui/react'
import { User } from '@prisma/client'
import { useMutation } from '@tanstack/react-query'
import React from 'react'

export const TaskCardBulkAssignChangeDialog: React.FC<{
  project: SelectedProject
  selectedTaskCards: RouterOutput['taskCard']['list']
  users: RouterOutput['user']['list']
  onSubmit: (newUser: User) => Promise<any>
}> = ({ project, selectedTaskCards, users, onSubmit }) => {
  const [newUser, setNewUser] = React.useState<User | undefined>(undefined)
  const mutation = useMutation({
    mutationFn: async () => {
      if (!newUser) {
        return
      }
      return onSubmit(newUser)
    },
  })

  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            タスクカードの担当者変更
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          w={'full'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          spacing={'24px'}
        >
          <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              変更対象のプロジェクト
            </Text>
            <ProjectWithColor project={project} />
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              選択したタスクカード({selectedTaskCards.length}件)
            </Text>
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              spacing={'6px'}
              divider={<StackDivider />}
            >
              {selectedTaskCards.map((taskCard) => (
                <HStack
                  key={taskCard.id}
                  w={'full'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  spacing={'8px'}
                  px={'8px'}
                  py={'6px'}
                >
                  <TaskAttributeTag attribute={taskCard.attribute} />
                  <Text
                    w={'fit-content'}
                    color={'gray.400'}
                    whiteSpace={'nowrap'}
                  >
                    {taskCard.taskNumber}
                  </Text>
                  <TextEllipsis>{taskCard.title}</TextEllipsis>
                </HStack>
              ))}
            </VStack>
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              新たに担当者にするメンバー
            </Text>
            <UserSelect
              candidates={users}
              defaultValue={undefined}
              onChange={(user) => {
                setNewUser(user || undefined)
              }}
            />
          </VStack>
          <Alert
            status={'warning'}
            w={'full'}
            variant={'subtle'}
            px={'16px'}
            py={'12px'}
            bgColor={'orange.50'}
            color={'gray.700'}
            boxShadow={'base'}
          >
            選択したタスクカードすべての担当者を一括で変更します。
          </Alert>
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              w={'fit-content'}
              variant={newUser ? 'white-red' : 'gray'}
              borderRadius={'6px'}
              isDisabled={!newUser}
              isLoading={mutation.isLoading}
              onClick={() => mutation.mutateAsync()}
            >
              担当者を一括変更
            </Button>
          </HStack>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}></AlertDialogFooter>
    </AlertDialogContent>
  )
}
