import React from 'react'
import { Button, ButtonProps, StyleProps } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import { LinkButton } from '../LinkButton'

export const SidebarNavigationButton: React.FC<
  {
    to: string
    children: React.ReactNode
  } & ButtonProps
> = ({ to, children, ...rest }) => {
  return (
    <LinkButton to={to} variant={'sidebar'} {...rest}>
      {children}
    </LinkButton>
  )
}
