import { PagePath } from '@/PagePath'
import { useDialog } from '@/components/ui/Dialog'
import { Link } from '@/components/ui/LinkButton'
import { SelectedProject } from '@/components/ui/ProjectSelector/type'
import { ProjectWithColor } from '@/components/ui/ProjectWithColor'
import { TextWithLinks } from '@/components/ui/TextWithLInks'
import { UserWithIcon } from '@/components/ui/user/UserWithIcon'
import { taskCardCommentLabel } from '@/constants/taskCardCommentType'
import { RouterOutput } from '@/lib/trpc'
import { dateTimeFormatter } from '@app/shared'
import {
  Text,
  AlertDialogContent,
  AlertDialogHeader,
  VStack,
  AlertDialogCloseButton,
  AlertDialogBody,
  HStack,
  FormControl,
  Select,
  FormErrorMessage,
  Input,
  Textarea,
  Button,
  AlertDialogFooter,
} from '@chakra-ui/react'
import { Project } from '@prisma/client'

export const TaskCommentPreviewDialog: React.FC<{
  project: SelectedProject
  taskComment: RouterOutput['taskComment']['list'][number]
}> = ({ project, taskComment }) => {
  const { closeDialog } = useDialog()
  if (!taskComment.taskCard) {
    return <> </>
  }
  return (
    <AlertDialogContent width={'800px'} marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize='lg' fontWeight='bold'>
            共有事項
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          w={'full'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          spacing={'10px'}
        >
          <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              起票者
            </Text>
            <UserWithIcon user={taskComment.user} />
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              対象のタスクカード
            </Text>
            <ProjectWithColor project={project} />
            <Link
              color={'blue.500'}
              textDecoration={'underline'}
              onClick={closeDialog}
              to={PagePath.taskCard.show(
                taskComment.taskCard.projectId,
                taskComment.taskCard.taskNumber,
                taskComment.taskCard.projectMonth,
              )}
            >
              {taskComment.taskCard.title}
            </Link>
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              内容
            </Text>
            <TextWithLinks>{taskComment.body}</TextWithLinks>
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
            <Text fontWeight={'bold'} color={'gray.700'}>
              追加日
            </Text>
            <Text>
              {dateTimeFormatter.jst['YYYY/MM/DD(E) HH:mm'](
                taskComment.timestamp,
              )}
            </Text>
          </VStack>
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              w={'fit-content'}
              variant={'blue-fill'}
              borderRadius={'6px'}
              onClick={closeDialog}
            >
              閉じる
            </Button>
          </HStack>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'flex-end'}>
        {/* <Button
          onClick={() => mutation.mutateAsync()}
          isLoading={mutation.isLoading}
          w={'fit-content'}
          variant={'blue-fill'}
          borderRadius={'6px'}
        >
          投稿する
        </Button> */}
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
