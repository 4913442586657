import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AuthenticatedPage404, Page404 } from '../pages/404'
import { DashboardPage } from '../pages/DashboardPage'
import { RouterPath } from '../PagePath'
import { InvitePage } from '@/pages/InvitePage'
import { TaskCardShowPage } from '@/pages/taskcard/show'
import { Sidebar } from '@/components/ui/Sidebar'
import { Box } from '@chakra-ui/react'
import { CommentsPage } from '@/pages/CommentsPage'
import { ProjectConfigurationShowPage } from '@/pages/project/show'
import { AdminAccountsPage } from '@/pages/admin/accounts'
import { ProjectCreatePage } from '@/pages/project/new'
import { TaskCardTemplateShowPage } from '@/pages/taskCardTemplate/show'
import { TaskCardEditPage } from '@/pages/taskcard/edit'
import { TaskCardTemplateEditPage } from '@/pages/taskCardTemplate/edit'
import { ClientQuestionsPage } from '@/pages/ClientQuestionsPage'
import { TaskCardTemplateCreatePage } from '@/pages/taskCardTemplate/new'
import { TaskCardCreatePage } from '@/pages/taskcard/new'
import { TaskCardListPage } from '@/pages/taskcard/list'
import { TaskCardsBulkNewPage } from '@/pages/taskcard/bulkNew'
import { InputStatusListPage } from '@/pages/InputStatusListPage'
import { ProjectConfigurationEditPage } from '@/pages/project/edit'

export const AuthenticatedRouter = () => {
  // <AuthenticatedPage />でそれぞれを囲むこと担保
  return (
    <>
      <Sidebar />
      <Routes>
        <Route path={RouterPath.dashboard} element={<DashboardPage />} />
        <Route path={RouterPath.invite} element={<InvitePage />} />

        <Route path={RouterPath.project.new} element={<ProjectCreatePage />} />
        <Route
          path={RouterPath.project.show}
          element={<ProjectConfigurationShowPage />}
        />
        <Route
          path={RouterPath.project.edit}
          element={<ProjectConfigurationEditPage />}
        />

        <Route path={RouterPath.taskCard.show} element={<TaskCardShowPage />} />
        {/* ↓いらない */}
        {/* <Route 
          path={RouterPath.taskCard.manage}
          element={<TaskCardsManagePage />}
        /> */}
        <Route
          path={RouterPath.taskCard.bulkNew}
          element={<TaskCardsBulkNewPage />}
        />
        <Route
          path={RouterPath.taskCard.new}
          element={<TaskCardCreatePage />}
        />
        <Route path={RouterPath.taskCard.edit} element={<TaskCardEditPage />} />

        <Route path={RouterPath.taskCard.list} element={<TaskCardListPage />} />
        <Route
          path={RouterPath.template.show}
          element={<TaskCardTemplateShowPage />}
        />
        <Route
          path={RouterPath.template.new}
          element={<TaskCardTemplateCreatePage />}
        />
        <Route
          path={RouterPath.template.edit}
          element={<TaskCardTemplateEditPage />}
        />

        <Route path={RouterPath.comments} element={<CommentsPage />} />
        <Route path={RouterPath.questions} element={<ClientQuestionsPage />} />
        <Route
          path={RouterPath.inputStatusList}
          element={<InputStatusListPage />}
        />
        <Route
          path={RouterPath.admin.accounts}
          element={<AdminAccountsPage />}
        />

        <Route path='*' element={<AuthenticatedPage404 />} />
      </Routes>
    </>
  )
}
