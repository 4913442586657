import {
  HStack,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputRightAddon,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { TimeSlotSelect } from './TimeSlot'

export const TaskCardStartInput = () => {
  const {
    register,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext<{
    startBusinessDate: number | undefined
    startTimeslotStart: string | undefined
    startTimeslotEnd: string | undefined
    deadlineBusinessDate: number | undefined
  }>()
  return (
    <>
      <HStack spacing={0} alignItems={'center'} justifyContent={'flex-start'}>
        <InputGroup>
          <InputLeftAddon>第</InputLeftAddon>
          <NumberInput
            min={1}
            max={31}
            keepWithinRange={false}
            clampValueOnBlur={false}
            onChange={() => {
              clearErrors('deadlineBusinessDate')
            }}
          >
            <NumberInputField
              borderRadius={0}
              {...register('startBusinessDate', {
                required: true,
                min: 1,
                max: 31,
                valueAsNumber: true,
              })}
              w={'5em'}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <InputRightAddon>営業日</InputRightAddon>
        </InputGroup>
        <TimeSlotSelect
          defaultValue={{
            start: getValues('startTimeslotStart') || '09:00',
            end: getValues('startTimeslotEnd') || '12:00',
          }}
          onChange={({ start, end }) => {
            clearErrors('deadlineBusinessDate')
            setValue('startTimeslotStart', start || undefined)
            setValue('startTimeslotEnd', end || undefined)
          }}
        />
      </HStack>
      <FormErrorMessage>{errors?.startBusinessDate?.message}</FormErrorMessage>
    </>
  )
}

export const TaskCardDeadlineInput = () => {
  const {
    register,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<{
    deadlineBusinessDate: number | undefined
    deadlineTimeslotStart: string | undefined
    deadlineTimeslotEnd: string | undefined
  }>()
  return (
    <>
      <HStack spacing={0} alignItems={'center'} justifyContent={'flex-start'}>
        <InputGroup>
          <InputLeftAddon>第</InputLeftAddon>
          <NumberInput
            min={1}
            max={31}
            keepWithinRange={false}
            clampValueOnBlur={false}
            onChange={() => {
              clearErrors('deadlineBusinessDate')
            }}
          >
            <NumberInputField
              borderRadius={0}
              {...register('deadlineBusinessDate', {
                required: true,
                min: 1,
                max: 31,
                valueAsNumber: true,
              })}
              w={'5em'}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <InputRightAddon>営業日</InputRightAddon>
        </InputGroup>
        <TimeSlotSelect
          defaultValue={{
            start: getValues('deadlineTimeslotStart') || '09:00',
            end: getValues('deadlineTimeslotEnd') || '12:00',
          }}
          onChange={({ start, end }) => {
            clearErrors('deadlineBusinessDate')
            setValue('deadlineTimeslotStart', start || undefined)
            setValue('deadlineTimeslotEnd', end || undefined)
          }}
        />
      </HStack>
      <FormErrorMessage>
        {errors?.deadlineBusinessDate?.message}
      </FormErrorMessage>
    </>
  )
}
