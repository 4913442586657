import React from 'react'
import { Button, ButtonProps, StyleProps } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import { LocationFrom } from '@/helper/locationState'

export const LinkButton: React.FC<
  {
    to: string
    locationFrom?: LocationFrom
    children: React.ReactNode
  } & ButtonProps
> = ({ to, locationFrom, children, ...rest }) => {
  return (
    <Link w={rest.w || 'fit-content'} to={to} state={locationFrom}>
      <Button {...rest}>{children}</Button>
    </Link>
  )
}

type LinkProps = React.ComponentProps<typeof ChakraLink> &
  React.ComponentProps<typeof RouterLink>

export const Link = ({ children, ...props }: LinkProps) => {
  return (
    <ChakraLink as={RouterLink} {...props}>
      {children}
    </ChakraLink>
  )
}
