import { RouterOutput } from '@app/api'
import { TaskComment } from '@prisma/client'

export type QuestionToClient = Omit<
  RouterOutput['taskComment']['list'][number],
  'type'
> & {
  type: 'question_client'
}
export const isClientQuestion = (
  comment: TaskComment,
): comment is QuestionToClient => {
  return comment.category === 'question_client'
}
