import { FirebaseUser, firebaseAuthDispatcher } from '@/lib/firebaseAuth'
import { logger } from '@/lib/logger'
import React from 'react'

const initialAuthState: AuthState = {
  initialized: false,
}
type AuthErrorCode = 'unexpected'

type AuthState = {
  initialized: boolean
  firebaseUser?: FirebaseUser
  errorCode?: AuthErrorCode
}

const FirebaseAuthStateContext = React.createContext<AuthState>({
  ...initialAuthState,
})
export const useFirebaseAuthState = () => {
  return React.useContext(FirebaseAuthStateContext)
}
export const useFirebaseAuthDispatcher = () => {
  // いらないけどAPIの統一性のため
  return firebaseAuthDispatcher
}

export const FirebaseAuthProviderContainer: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { state } = useFirebaseAuth()

  return (
    <FirebaseAuthStateContext.Provider value={state}>
      {children}
    </FirebaseAuthStateContext.Provider>
  )
}

const useFirebaseAuth = (): { state: AuthState } => {
  const [authState, setAuthState] = React.useState<AuthState>({
    ...initialAuthState,
  })
  React.useEffect(() => {
    let cancel = false

    const unsubscribe = firebaseAuthDispatcher.underlying.onAuthStateChanged(
      async (firebaseUser) => {
        logger.info(`onAuthStateChanged got user`, { firebaseUser })
        if (cancel) {
          return
        }
        if (firebaseUser) {
          await firebaseAuthDispatcher.getIdToken()
          setAuthState((pre) => ({ ...pre, initialized: true, firebaseUser }))
          return
        }

        setAuthState((pre) => ({
          ...pre,
          initialized: true,
          firebaseUser: undefined,
        }))
      },
      (error) => {
        if (cancel) {
          return
        }

        logger.error(`onAuthStateChanged got error`, error)
        setAuthState((pre) => ({
          ...pre,
          initialized: true,
          errorCode: 'unexpected',
        }))
      },
    )

    return () => {
      cancel = true
      unsubscribe()
    }
  }, [])

  return {
    state: authState,
  }
}
