import { Box, BoxProps, Tooltip } from '@chakra-ui/react'

export const TextEllipsis: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Tooltip hasArrow label={children} openDelay={400}>
    <Box
      overflow={'hidden'}
      whiteSpace={'normal'}
      textOverflow={'ellipsis'}
      // maxW={'300px'}
      wordBreak={'break-all'}
      lineHeight={'1.5'}
      w={'full'}
      display={'block'}
      color={'inherit'}
      style={{
        WebkitLineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
      }}
      {...rest}
    >
      {children}
    </Box>
  </Tooltip>
)
