import { useYearMonthContext } from '@/context/yearMonth'
import { Text, HStack } from '@chakra-ui/react'
import React from 'react'
import { LeftArrowButton, RightArrowButton } from './ArrowButton'

export const YearMonthSelector: React.FC<{ bgColor?: string }> = ({
  bgColor,
}) => {
  const { yearMonth, dispatchers } = useYearMonthContext()
  const nextAvailable = React.useMemo(() => {
    // const now = yearMonthFromDate(jstNow())
    // return yearMonth.year >= now.year && yearMonth.month >= now.month
    return true
    // }, [yearMonth.month, yearMonth.year])
  }, [])

  return (
    <HStack
      w={'full'}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgColor={bgColor || undefined}
      p={'12px'}
      borderRadius={'4px'}
      whiteSpace={'nowrap'}
    >
      <LeftArrowButton
        isDisabled={false}
        onClick={() => dispatchers.prevMonth()}
      />
      <Text fontWeight={'bold'} width={'6em'} textAlign={'center'}>
        {yearMonth.year}年{yearMonth.month}月
      </Text>
      <RightArrowButton
        isDisabled={!nextAvailable}
        onClick={() => dispatchers.nextMonth()}
      />
    </HStack>
  )
}
