import React from 'react'
import { ColorProps, Select, Text, TypographyProps } from '@chakra-ui/react'

type TimeSlot = {
  start: string | undefined | null
  end: string | undefined | null
}
export const TimeSlot: React.FC<
  TimeSlot & {
    textColor?: ColorProps['color']
    fontWeight?: TypographyProps['fontWeight']
    fontSize?: TypographyProps['fontSize']
    wordBreak?: TypographyProps['wordBreak']
    whiteSpace?: TypographyProps['whiteSpace']
  }
> = ({
  start,
  end,
  textColor,
  fontSize,
  fontWeight,
  wordBreak,
  whiteSpace,
}) => {
  const color = textColor || 'gray.400'
  if (start && end) {
    return (
      <Text
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        wordBreak={wordBreak}
        whiteSpace={whiteSpace}
      >
        {start} ~ {end}
      </Text>
    )
  } else if (start || end) {
    return (
      <Text
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        wordBreak={wordBreak}
        whiteSpace={whiteSpace}
      >
        {start || end}
      </Text>
    )
  } else {
    return (
      <Text
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        wordBreak={wordBreak}
        whiteSpace={whiteSpace}
      >
        -
      </Text>
    )
  }
}

const timeSlotOptions = {
  '9:00~12:00': { start: '09:00', end: '12:00' },
  '12:00~15:00': { start: '12:00', end: '15:00' },
  '15:00~18:00': { start: '15:00', end: '18:00' },
}
export const TimeSlotSelect: React.FC<{
  defaultValue: TimeSlot
  onChange: (timeSlot: TimeSlot) => void
}> = ({ defaultValue, onChange }) => {
  const v = React.useMemo(() => {
    const key = Object.entries(timeSlotOptions).find(
      ([, { start, end }]) =>
        start === defaultValue.start && end === defaultValue.end,
    )
    return key ? key[0] : undefined
  }, [defaultValue])
  return (
    <Select
      isRequired
      onChange={async (v) => {
        const timeSlot =
          timeSlotOptions[v.target.value as keyof typeof timeSlotOptions]
        onChange(timeSlot)
      }}
      defaultValue={v}
    >
      {Object.keys(timeSlotOptions).map((label) => (
        <option key={label} value={label}>
          {label}
        </option>
      ))}
    </Select>
  )
}
