import { YearMonthSelector } from '@/components/ui/YearMonthSelector'
import { YearMonth, YearMonthContextProvider } from '@/context/yearMonth'
import { calendarDates, isSaturday, isSunday } from '@/helper/dates'
import { ProjectMonthlyEventDates } from '@app/api/src/model/prisma'
import { dateTimeFormatter, jstNow } from '@app/shared'
import {
  VStack,
  Flex,
  Button,
  useToast,
  keyframes,
  useToken,
  HStack,
  Box,
} from '@chakra-ui/react'
import { atom, useAtom } from 'jotai'
import React from 'react'
import {
  FormProvider,
  UseFieldArrayReturn,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { MonthlyProjectCalendarView } from './view'
import { startOfMonth } from 'date-fns'

export const BusinessDateMonthlyCalendarEditView: React.FC<{
  mode: 'new' | 'edit'
  monthlyEvents: ProjectMonthlyEventDates
  defaultNonBusinessDates: string[]
  onChange: (nonBusinessDates: string[]) => void
}> = ({ mode, monthlyEvents, defaultNonBusinessDates, onChange }) => {
  // 単なるstring[]を扱うことが出来ないのでuseFormContextではなく新規でformを作成して取り回す
  const form = useForm<{ nonBusinessDates: { date: string }[] }>({
    defaultValues: {
      nonBusinessDates: defaultNonBusinessDates.map((date) => ({ date })),
    },
  })
  const fieldArray = useFieldArray({
    control: form.control,
    name: 'nonBusinessDates',
  })
  const token = useToken('colors', ['blue.500', 'blue.200', 'blue.100'])
  const blink = React.useMemo(() => {
    return keyframes`  
      0% {border-color: ${token[0]}}
      50% {border-color: ${token[1]}}
      100% {border-color: ${token[2]}}
  `
  }, [token])

  return (
    <FormProvider {...form}>
      <VStack
        w={'full'}
        alignItems={'center'}
        px={'20px'}
        py={'24px'}
        justifyContent={'center'}
      >
        <YearMonthContextProvider options={{ saveInUrl: false }}>
          {(yearMonth) => {
            return (
              <MonthlyEventCalendarEditView
                {...{
                  yearMonth,
                  monthlyEvents,
                  fieldArray,
                  onChange,
                }}
              />
            )
          }}
        </YearMonthContextProvider>
      </VStack>
    </FormProvider>
  )
}

const MonthlyEventCalendarEditView: React.FC<{
  yearMonth: YearMonth
  monthlyEvents: ProjectMonthlyEventDates
  fieldArray: UseFieldArrayReturn<{
    nonBusinessDates: { date: string }[]
  }>
  onChange: (nonBusinessDates: string[]) => void
}> = ({ yearMonth, monthlyEvents, fieldArray, onChange }) => {
  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const { year, month } = yearMonth
  const { monthDates } = calendarDates(yearMonth)

  const { fields, append, remove } = fieldArray
  const startOfMonthOfToday = React.useMemo(() => startOfMonth(jstNow()), [])
  const toast = useToast()
  const onClickCell = React.useCallback(
    (date: Date, excluded: boolean) => {
      if (date < startOfMonthOfToday) {
        toast.closeAll()
        toast({
          title: '先月以前の日付は変更できません',
          status: 'warning',
        })
        return false
      }
      if (excluded) {
        const target = { date: dateTimeFormatter.jst['YYYY-MM-DD'](date) }
        append(target)
      } else {
        const index = fields.findIndex(
          (field) => field.date === dateTimeFormatter.jst['YYYY-MM-DD'](date),
        )
        if (index !== -1) {
          remove(index)
        }
      }
      setIsDirty(true)
      return true
    },
    [startOfMonthOfToday, setIsDirty, toast, append, fields, remove],
  )

  const nonBusinessDates = React.useMemo(() => {
    return fields
      .filter((field) =>
        field.date.startsWith(
          `${String(year).padStart(2, '0')}-${String(month).padStart(2, '0')}-`,
        ),
      )
      .map(({ date }) => date)
  }, [fields, month, year])

  const setWeekendAsNonBusinessDates = React.useCallback(() => {
    const dates = new Set(nonBusinessDates)
    const results = monthDates
      .filter(
        (date) =>
          date > startOfMonthOfToday && (isSaturday(date) || isSunday(date)),
      )
      .map((date) => dateTimeFormatter.jst['YYYY-MM-DD'](date))
      .filter((s) => !dates.has(s))

    if (results.length === 0) {
      return
    }
    append(results.map((date) => ({ date }))) // formとして変更したものとしておく
    setIsDirty(true)
  }, [append, monthDates, nonBusinessDates, startOfMonthOfToday])

  React.useEffect(() => {
    // fieldArrayでappend/removeした同じスコープ内でfieldsを参照しても値が変わっていないので別のeffectで監視する
    if (isDirty) {
      onChange(fields.map((d) => d.date))
      setIsDirty(false)
    }
  }, [isDirty, fields, onChange, setIsDirty])

  return (
    <VStack
      justifyContent={'center'}
      alignItems={'center'}
      w={'fit-content'}
      spacing={'8px'}
    >
      <HStack w={'full'} justifyContent={'flex-end'}>
        <Button
          size={'sm'}
          variant={'white-blue'}
          onClick={setWeekendAsNonBusinessDates}
        >
          土日を一括選択する
        </Button>
      </HStack>
      <YearMonthSelector bgColor={'gray.50'} />
      <Flex w={'full'} justifyContent={'center'}>
        <MonthlyProjectCalendarView
          monthlyEvents={monthlyEvents}
          nonBusinessDates={nonBusinessDates}
          onClickCell={onClickCell}
          yearMonth={yearMonth}
        />
      </Flex>
    </VStack>
  )
}
